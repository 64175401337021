
import { dataFetchingFinish, dataFetchingStart } from '../../../slices/modalSlice'
import { getErrorss } from '../../../../functions/errors/GetErrors'
import { type AppDispatch, type RootState } from '../../../store'
import { ACCESS_KEY, urlAPI } from '../../../../urls'
import { offersSlice, setOffers } from '../../../slices/offersSlice'
import { getQueryOffers } from '../../../../functions/shared/api/queries/getQueryOffers'

export const updateStatusOfferAgent = (bodyClient: any) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) as string
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const offerID = bodyClient.offerID
            dispatch(dataFetchingStart())
            const bodyObject = {
                method: 'PUT',
                collection: 'offers',
                id: Number(offerID),
                query: getQueryOffers(),
                //  QueryString.stringify({
                //     populate: ['Type', 'Documents', 'User', 'Unit']
                // }, {
                //     encodeValuesOnly: true
                // })
                body: {
                    Statuses: [bodyClient.status],
                    Notify: (bodyClient.notify)
                }
            }

            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'BSO-Hash': '02ns30nsBSO!',
                    Authorization: `Bearer ${jwt}`
                },
                body: JSON.stringify(bodyObject)
            })
            const dataJSON = await response.json()

            if (dataJSON.success) {
                if (dataJSON.response.error != null) {
                    dispatch(offersSlice.actions.offersFetchingError(getErrorss(dataJSON)))
                    dispatch(dataFetchingFinish())
                    return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
                } else {
                    dispatch(dataFetchingFinish())
                    dispatch(setOffers(dataJSON.response.data))
                    return {
                        ...dataJSON,
                        textNavigate: '', // '/agent/schedule',
                        isSuccessful: true,
                        textNotification: 'Wait change text',
                        title: 'Offer has been changed'
                    }
                }
            } else {
                dispatch(offersSlice.actions.offersFetchingError(getErrorss(dataJSON)))
                dispatch(dataFetchingFinish())
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } catch (e) {
            dispatch(dataFetchingFinish())
            console.error(e)
        }
    }
}
