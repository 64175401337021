import React from 'react'

import { type Unit } from '../../../../store/slices/unitsSlice'
import { type IOffer } from '../../../../store/slices/offersSlice'
import TimelineRent from './TimelineRent'
import TimelineSale from './TimelineSale'
import TimelineOfferAgentToAgent from './TimelineOfferAgentToAgent'
import { USER_ID_KEY } from '../../../../urls'
import TimelineOfferAgentToLandlord from './TimelineOfferAgentToLandlord'

interface IProps {
    offer: IOffer
    unit: Unit

}
function OfferTimeline({ offer, unit }: IProps): JSX.Element {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const typeUnit = unit?.attributes?.Marketing?.Use
    return (
        <div>
            {
                unit?.attributes?.Statuses.includes('Door close')
                    ? <TimelineOfferAgentToAgent offer={offer} unit={unit} isMyUnit={unit?.attributes?.User.data?.id === Number(userID)} />
                    : offer?.attributes?.AgentToLandlordID != null
                        ? <TimelineOfferAgentToLandlord offer={offer} unit={unit} isMyUnit={unit?.attributes?.User.data?.id === Number(userID)} />
                        : typeUnit === 'For rent'
                            ? <TimelineRent offer={offer} unit={unit} />
                            : typeUnit === 'For sale'
                                ? <TimelineSale offer={offer} unit={unit} />
                                : <div className="card shadow-sm my-4">
                                    <div className="card-body p-0 m-5 text-center">
                                        <span className="fw-bolder px-2 me-2 mb-2 fs-6 p-1 py-3 ">Our admin&apos;s messed up, restart the app.</span>
                                    </div>
                                </div>}
        </div>
    )
}

export default OfferTimeline
