import { type NavigateFunction } from 'react-router-dom'
import { reduceViewing, reduceViewingAfterCreate } from '../agent/socket/reduceViewing'
import { reduceViewingAfterUpdateKey } from '../agent/socket/reduceViewingAfterUpdateKey'
import { swalError, swalSuccess } from '../swalOptions'
import { REACT_APP_BSO_SUPPORT_USER_ID, REACT_APP_BSO_USER_ID, USER_CATEGORY_KEY, USER_EMAIL_KEY, USER_ID_KEY } from '../../urls'
import { setAdvertises, setIsAlreadyOpenLink } from '../../store/slices/advertisesSlice'
import { type ISettings, setCurrentUserUnits, type User, userSlice, userUpdate } from '../../store/slices/userSlice'
import { addViewing, setViewings } from '../../store/slices/viewingsSlice'
import { store, type AppDispatch } from '../../store/store'
import { getChat } from '../../store/actionsCreators/chatActions'
import { getChatList } from '../../store/actionsCreators/chatListActions'
import { fetchSocketUser, fetchUser, fetchUserById, fetchUsers } from '../../store/actionsCreators/userActions'
import { setStatuses } from '../../store/slices/agentSlice'
import getAgentStatuses from '../agent/documents/getAgentStatuses'
import { documentAddSuccess, documentAddUpdate } from '../../store/slices/documentSlice'
import { setNewViewingRescheduleSocket } from '../../store/slices/swalsSlice'
import { showFeedbackPopup } from '../agent/viewings/showFeedbackPopup'

import awaitAction from '../utils/awaitAction'
import { fetchSingleUnit } from '../../store/actionsCreators/unitsActions'
import { removeDataAttributes } from '../adapters/universal/removeDataAttributes'
import { setUnitsMainState, unitsFetchingSuccess } from '../../store/slices/unitsSlice'
import customLog from '../log'
import { setIsAlreadyOpenLinkExtendAreas } from '../../store/slices/areasSlice'
import _ from 'lodash'
import { setIsUserVerified } from '../../store/slices/modalSlice'
import { is24HoursPassed } from '../agent/date/is24HoursPassed'
import { processOfferSwal } from '../agent/offers/showNegotiateSwals'
import { addOffer, type IOffer, setOffers } from '../../store/slices/offersSlice'
import { setSocketTimeout } from '../../store/slices/appSlice'
import { reduceOffer } from '../agent/socket/reduceOffer'
import { reduceAdvertise } from '../agent/socket/reduceAdvertice'
import { reduceDocument } from '../agent/socket/reduceDocument'


export const agentSocket = async (webSocket: WebSocket, dispatch: AppDispatch, navigate: NavigateFunction): Promise<void> => {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const userCATEGORY = window.localStorage.getItem(USER_CATEGORY_KEY)

    webSocket.onmessage = async (event: MessageEvent) => {
        try {
            const message = event.data
            if (typeof message === 'string' && (message.startsWith('{') || message.startsWith('['))) {
                const data = JSON.parse(message)

          

                if(Number.isFinite(data?.timestamp)){
                    dispatch(setSocketTimeout(data?.timestamp))
                }

                if ('action' in data && 'isDev' in data) {
                    const [collection, action] = data.action.split('.')
                    const entry = data?.entry
                    if ((data?.isDev && process.env.REACT_APP_IS_DEV === 'true') || 
                    (!data?.isDev) && (!process.env.REACT_APP_IS_DEV)) {
                        console.log({collection ,action,entry})
                        if (collection === 'chat-room') {
                   
                            if (action === 'update' && entry.id === store.getState().chat?.chat?.id) {
                         
                                void dispatch(getChat(entry.id, 1, undefined, true))
                            }
                            if (action === 'update' && _.some(store.getState().chatList.chatList, { id: entry.id })) {
                               
                                void dispatch(getChatList())
                            }
                            if (action === 'create') {
                                void dispatch(getChatList())
                            }
                        }
                        if (collection === 'unit-key') {
                            if (action === 'create') {
                                if (entry.Holder.id === Number(userID)) {
                                    const isThere = store.getState().viewings.viewings.filter((viewEl) => viewEl.id === entry?.Viewings?.[0]?.id) // ?.find((view) => view.attributes.User.data?.id === Number(userID))
                                    if (isThere != null && isThere.length > 0) {
                                        dispatch(setViewings(reduceViewing(entry?.Viewings?.[0], isThere[0])))
                                    }
                                }
                            } if (action === 'update') {
                                if (entry.Holder.id === Number(userID)) {
                                    const agentViewingsIDS = store.getState().viewings.viewings.filter((el) => el?.attributes?.User?.data?.id === Number(userID))?.map((el) => el.id)
                                    const isThere = store.getState().viewings.viewings.filter(
                                        view => view.id === entry?.Viewings?.filter((el: any) => agentViewingsIDS.includes(el.id))[0].id
                                    )
                                    //     const isThere = store.getState().viewings.viewings.filter((viewEl) => viewEl.id === entry?.Viewings?.[0]?.id) // ?.find((view) => view.attributes.User.data?.id === Number(userID))
                                    if (isThere != null && isThere.length > 0) {
                                        dispatch(setViewings(reduceViewingAfterUpdateKey(entry, isThere[0])))
                                    }
                                }
                            }
                        }

                        if (collection === 'user') {
                            if (action === 'create') {
                                if(entry?.id){
                                    void dispatch(fetchSocketUser(entry?.id))
                                }
                            } if (action === 'update') {
                                if (entry.id === Number(userID)) {
                                    const actualUser = store.getState().user?.currentUser
                                    const updateUser: User = { ...actualUser, ...entry }
                                    dispatch(userUpdate(updateUser))
                                    if (updateUser.emailVerified) {
                                        setIsUserVerified(true)
                                    } else {
                                        setIsUserVerified(false)
                                    }
                                    console.log(updateUser,"updateUser")
                                    const docs = updateUser?.Documents
                                    dispatch(setStatuses(getAgentStatuses(docs)))
                                }
                              
                            } if (action === 'delete') {
                                dispatch(userSlice.actions.allUsersFetchingSuccess(
                                    store.getState().user.allUsers.filter(user => user.id !== entry.id)
                                ))
                            }
                        } 
                        if (collection === 'document') {
                            if (action === 'update') {
                                if (entry?.User?.id === Number(userID)) {
                                    dispatch(documentAddUpdate(entry))
                                    const userDocs = store.getState().documents.documents.filter((doc) => doc.attributes.User.data?.id === Number(userID))
                                    dispatch(setStatuses(getAgentStatuses(userDocs)))
                                }   else {
                                    if(typeof entry?.RefID === 'string' && entry?.RefID.includes("REF_DOC") && entry?.Category){
                                        const reducedDocument = reduceDocument(entry, undefined);
                                        dispatch(documentAddSuccess(reducedDocument))
                                    }
                                }
                                // else {
                                //     dispatch(fetchSocketUser(entry?.User?.id ?? -1, true))
                                //     if (entry.id === store.getState().chat?.chat?.User?.id) {
                                //         if (store.getState().chat?.chat?.id != null) {
                                //             void dispatch(getChat(Number(store.getState().chat.chat.id), 1, undefined, true))
                                //         }
                                //     }
                                // }
                            }
                            if (action === 'create') {
                                if (store?.getState().user.currentUser?.Offers?.some((doc : any) => doc.id === entry?.id)) {
                                    if(typeof entry?.RefID === 'string' && entry?.RefID.includes("REF_DOC") && entry?.Category){
                                        const reducedDocument = reduceDocument(entry, undefined);
                                        dispatch(documentAddSuccess(reducedDocument))
                                    }
                                }  
                            }
                        }

                        if (collection === 'unit') {
                            if (action === 'update' && store.getState().units?.units?.some(unit => unit.id === entry.id)) {
                                // dispatch(fetchUnits(true))
                                const updatedUnit = await dispatch(fetchSingleUnit(entry.id))
                                    .then((result: any) => {
                                        if (_.some((store.getState().user.currentUser as any).Units, { id: entry.id })) {
                                            const unitsCopy = _.cloneDeep((store.getState().user.currentUser as any).Units)
                                            const index = _.findIndex(unitsCopy, { id: entry.id })
                                            unitsCopy[index] = { id: entry.id, ...removeDataAttributes(result) }
                                            dispatch(setCurrentUserUnits(unitsCopy))
                                        }
                                        if (_.some(store.getState().chat.searchedInvites, (invite) => invite?.Unit?.attributes?.id === entry.id)) {
                                            const invitesCopy = _.cloneDeep(store.getState().chat.searchedInvites)
                                            const index = _.findIndex(invitesCopy, (invite) => invite?.Unit?.attributes?.id === entry.id)
                                            if (invitesCopy?.[index]?.Unit?.attributes != null) {
                                                (invitesCopy[index].Unit as any).attributes = result
                                            }
                                        }
                                        dispatch(setUnitsMainState(updatedUnit))
                                        // dispatch(unitsFetchingSuccess([...store.getState().units.units.filter((unit) => unit.id !== entry.id), { id: entry.id, attributes: result }]))
                                    })
                                    .catch((err: any) => {
                                        customLog(err)
                                    })
                                if (_.some((store.getState().user.currentUser as any).Units, { id: entry.id })) {
                                    dispatch(fetchSingleUnit(entry.id))
                                        .then((result: any) => {
                                            const unitsCopy = _.cloneDeep((store.getState().user.currentUser as any).Units)
                                            const index = _.findIndex(unitsCopy, { id: entry.id })
                                            unitsCopy[index] = { id: entry.id, ...removeDataAttributes(result) }
                                            dispatch(setCurrentUserUnits(unitsCopy))
                                        })
                                        .catch((err: any) => {
                                            customLog(err)
                                        })
                                }
                                if (_.some(store.getState().chat.searchedInvites, (invite) => invite?.Unit?.attributes?.id === entry.id)) {
                                    const invitesCopy = _.cloneDeep(store.getState().chat.searchedInvites)
                                    const index = _.findIndex(invitesCopy, (invite) => invite?.Unit?.attributes?.id === entry.id)
                                    dispatch(fetchSingleUnit(entry.id))
                                        .then((result: any) => {
                                            if (invitesCopy?.[index]?.Unit?.attributes != null) {
                                                (invitesCopy[index].Unit as any).attributes = result
                                            }
                                        })
                                        .catch((err: any) => {
                                            customLog(err)
                                        })
                                }
                            } else if (action === 'create') {
                                if (entry?.User?.id === store.getState().user.currentUser?.id) {
                                    dispatch(fetchSingleUnit(entry.id))
                                        .then((result: any) => {
                                            const unitsCopy = _.cloneDeep((store.getState().user.currentUser as any).Units)
                                            unitsCopy.push(result)
                                            dispatch(setCurrentUserUnits(unitsCopy))
                                        })
                                        .catch((err: any) => {
                                            customLog(err)
                                        })
                                }
                            }
                        } 

                        if (collection === 'order') {
                            if (action === 'paid') {
                                const status = data?.link.split('status=')[1]
                                if (data?.link.includes('agent_settings_for_subscriptions') && status === 'true') { //  && status === 'true'
                                    const firstSlice = data?.link.split('/agent_settings_for_subscriptions/')
                                    const userEmail = firstSlice?.[1].split('/')[0]
                                    const userEMAIL = window.localStorage.getItem(USER_EMAIL_KEY)
                                    const userID = window.localStorage.getItem(USER_ID_KEY)
                                    const currentUser = store.getState().user.currentUser
                                    const userSett = currentUser?.Settings as ISettings[]
                                    if (userEMAIL === userEmail) {
                                        if (!location.pathname.includes('/agent/modal/agent_settings_for_subscriptions/')) {
                                            if (!(
                                                currentUser &&
                                            userSett &&
                                            userSett.length > 0 &&
                                            userSett[0].Areas &&
                                            userSett[0].Areas.length > 1
                                            )) {
                                                if (data?.link.includes('Standard')) {
                                                    navigate(`/agent/modal/agent_settings_for_subscriptions/${userID as string}/${'Standard'}`)
                                                } if (data?.link.includes('Premium')) {
                                                    navigate(`/agent/modal/agent_settings_for_subscriptions/${userID as string}/${'Premium'}`)
                                                }
                                            }
                                        }
                                    }
                                } else if (data?.link.includes('/agent/modal/agent_advertise_create_duration/')) {
                                    if (status === 'true') {
                                        const userID = window.localStorage.getItem(USER_ID_KEY)
                                        const firstSlice = data?.link.split('/agent_advertise_create_duration/')
                                        const unitId = firstSlice[1].split('/')[0]
                                        const userId = firstSlice[1].split('/')[1]?.split('?')[0]
                                        const isAlreadyOpenLink = store.getState().advertises.isAlreadyOpenLink
                                   
                                        if (userID === userId) {
                                            if (!isAlreadyOpenLink) {
                                                dispatch(setIsAlreadyOpenLink(true))
                                                //  navigate(`/agent/modal/agent_advertise_create_duration/${unitId}/${userId}`)
                                                void swalSuccess('You are successfully purchased Bricks').then(async (result: any) => {
                                                    if (result.isConfirmed) {
                                                        navigate(`/agent/modal/agent_advertise_create_duration/${unitId}/${userId}`)
                                                    }
                                                })
                                            } else {
                                                if (data?.try === 9) {
                                                    dispatch(setIsAlreadyOpenLink(false))
                                                }
                                            }
                                        }
                                    } else {
                                        void swalError('Sorry, your payment for Bricks was declined').then(async (result: any) => {
                                            if (result.isConfirmed) {
                                            // navigate(`/agent/modal/agent_advertise_create_duration/${unitId}/${userId}`)
                                            }
                                        })
                                    }
                                } else if (data?.link.includes('/agent/modal/agent_settings_for_areas_extension/')) {
                                    if (data.paid) { // status === 'true'
                                        const userID = window.localStorage.getItem(USER_ID_KEY)
                                        const firstSlice = data?.link.split('/agent/modal/agent_settings_for_areas_extension/')
                                        const unitId = firstSlice[1].split('/')[0]
                                        const userId = firstSlice[1].split('/')[1].split('?')[0]
                                        const isAlreadyOpenLinkExtendAreas = store.getState().areas.isAlreadyOpenLinkExtendAreas
                                        if (userID === userId) {
                                            if (!isAlreadyOpenLinkExtendAreas) {
                                                dispatch(setIsAlreadyOpenLinkExtendAreas(true))
                                                //  navigate(`/agent/modal/agent_settings_for_areas_extension/${unitId}/${userId}`)
                                                void swalSuccess('You are successfully purchased Areas Extension').then(async (result: any) => {
                                                    if (result.isConfirmed) {
                                                        navigate(`/agent/modal/agent_settings_for_areas_extension/${unitId}/${userId}`)
                                                    }
                                                })
                                            } else {
                                                if (data?.try === 9) {
                                                    dispatch(setIsAlreadyOpenLinkExtendAreas(false))
                                                }
                                            }
                                        }
                                    } else {
                                        void swalError('Sorry, your payment for Areas Extension was declined').then(async (result: any) => {
                                            if (result.isConfirmed) {
                                            // navigate(`/agent/modal/agent_advertise_create_duration/${unitId}/${userId}`)
                                            }
                                        })
                                    }
                                } else {
                                    const userID = window.localStorage.getItem(USER_ID_KEY)
                                    if (data.user === Number(userID)) {
                                        const isAlreadyOpenLink = store.getState().advertises.isAlreadyOpenLink
                                        if (!isAlreadyOpenLink) {
                                            dispatch(setIsAlreadyOpenLink(true))
                                            if (data.paid) {
                                                void swalSuccess('You are successfully purchased Bricks').then(async (result: any) => {
                                                    if (result.isConfirmed) {
                                                        navigate('/agent/profile/me')
                                                    }
                                                })
                                            } else {
                                                void swalSuccess('Sorry, your payment for Bricks was declined').then(async (result: any) => {
                                                    if (result.isConfirmed) {
                                                        navigate('/agent/profile/me')
                                                    }
                                                })
                                            }
                                        } else {
                                            if (data?.try === 9) {
                                                dispatch(setIsAlreadyOpenLink(false))
                                            }
                                        }
                                    }
                                }
                            }
                        } 
                        if (collection === 'viewing') {
                            console.log( entry?.User?.id,"entry?.User?.id",Number(userID),"Number(userID)")
                            console.log(entry?.Unit?.User?.id ,"entry?.Unit?.User?.id ")
                            if (entry?.User?.id === Number(userID) || entry?.Unit?.User?.id === Number(userID)) { 
                                if (action === 'update') {
                                    const isThere = store.getState().viewings.viewings.filter((viewEl) => viewEl.id === entry.id)
                                    if (isThere != null && isThere?.[0]?.attributes?.User.data?.id === Number(userID)) {
                                        dispatch(setViewings(reduceViewing(entry, isThere[0])))
                                        if (entry?.Statuses.includes('Rescheduled by BSO') && entry?.Notify.includes('Agent')) {
                                            dispatch(setNewViewingRescheduleSocket(true))
                                        }

                                        if (entry?.Statuses.includes('Waiting for feedback') && entry?.NotifyViewedDate === null) {
                                            const { oneCycleShowPopupsViewingReschedule, firstLengthSolidStackViewingsReschedule } = store.getState().swalsStates
                                            const agentViewings = store.getState().viewings.viewings.filter((el) => el?.attributes?.User?.data?.id === Number(userID))
                                            const viewingsCompleted = agentViewings.filter((view) => view?.attributes?.Statuses?.includes('Waiting for feedback') && (view?.attributes?.NotifyViewedDate === null || is24HoursPassed(view?.attributes?.NotifyViewedDate)))
                                            if (oneCycleShowPopupsViewingReschedule || (firstLengthSolidStackViewingsReschedule === 0 && !oneCycleShowPopupsViewingReschedule)) {
                                                if (viewingsCompleted.length > 0) {
                                                    showFeedbackPopup(viewingsCompleted[0], dispatch, navigate)
                                                }
                                            }
                                        }
                                    }
                                }
                                if (action === 'create') {
                                    if (entry?.User.id !== Number(userID)) {
                                        dispatch(addViewing(reduceViewingAfterCreate(entry)))
                                        if (entry?.Statuses.includes('Rescheduled by BSO') && entry?.Notify.includes('Agent')) {
                                            dispatch(setNewViewingRescheduleSocket(true))
                                        }
                                    }
                                }
                            }
                           
                        } 
                        if (collection === 'offer') {      
                            if (entry?.User?.id === Number(userID) || entry?.Unit?.User?.id === Number(userID)) { 
                                if (action === 'update') {
                                    const storeOffer = store.getState().offers.offers.filter((offer) => offer.id === entry.id)
                                    if (storeOffer != null && storeOffer?.[0]?.attributes?.User.data?.id === Number(userID)) {
                                        dispatch(setOffers(reduceOffer(entry , storeOffer?.[0])))
                                        if(!storeOffer?.[0]?.attributes?.AgentToAgentID || storeOffer?.[0]?.attributes?.Unit.data?.attributes.User?.data?.id === Number(REACT_APP_BSO_USER_ID)){
                                            awaitAction(async () =>  { void processOfferSwal(reduceOffer(entry,storeOffer?.[0])  as IOffer, dispatch, navigate); })
                                        }
                                    }
                                } if (action === 'create') {
                                    if (entry?.User.id !== Number(userID)) {
                                        dispatch(addOffer(reduceOffer(entry, undefined)))
                                    }
                                }
                            }
                        }
                        if (collection === 'unit-advertise') {
                            if(Number(userID) === entry.id){
                                if (action === 'update') {
                                    const isThere = store.getState().advertises.advertises.filter((viewAdv) =>  viewAdv.id === entry.id)
                                    if (isThere != null && isThere?.[0]?.attributes?.User.data?.id === Number(userID)) {
                                        dispatch(setAdvertises(reduceAdvertise(entry, isThere[0])))
                                    //     if (isThere?.[0]?.attributes?.Statuses?.includes('Docs received') &&
                                    // ((isThere?.[0]?.attributes?.Links) === null || isThere?.[0]?.attributes?.Links === undefined)
                                    //     ) {
                                    //         // if (!location.pathname.includes('/agent/modalView/advertise_modal/') || !location.pathname.includes('/agent_advertise_create_start_date/')) {
                                    //         //     showAdvertisesSwals(`${isThere?.[0].id},`, navigate, 'Warning')
                                    //         //     dispatch(setAlreadyShown(true))
                                    //         // }
                                    //     }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } catch (err) {
            // console.log('Failed socket message:', event.data)
            console.log(err)
        }
    }
}
