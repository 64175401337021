import React from 'react'



export default function TakeUnitsPortfolio({ unitID }: any): JSX.Element {
    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    const handleLoad = () => {
        window.open(`https://bso.ae/api/app/downloadPDF?id=${String(unitID)}&type=Unit%20preview&dev=${isDev}`, '_self')
    };


    return (
        <>
            <div className="container mx-auto mb-2 m-0 py-1 w-100 mt-4 position-relative"
                onClick={handleLoad}
                style={{ cursor: 'pointer' }}
            >
                <div className="row">
                    <div className="col-3 btn btn-bso max-w-100px text-center opacity-75 mx-auto">
                        <i className="fs-7x  fs-lg-4x las la-share-alt-square"></i>
                    </div>
                    <div className="col-9 d-flex align-items-center bg-light-secondary">
                        <span className="d-flex flex-column align-items-start justify-content-center mx-2">
                            <span className="fs-3 fw-bold">
                                Share unit's details
                            </span>
                            <span className="fs-7"> Load unit's portfolio with photos and description for advertise  <i className="las la-angle-right"></i></span>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}
