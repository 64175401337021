import { type AppDispatch, type RootState} from '../store'
import { ACCESS_KEY, REACT_APP_BSO_USER_ID, USER_ID_KEY, urlAPI } from '../../urls'

import { animationOptions, swalOptions } from '../../functions/swalOptions'
import _ from 'lodash'

import swalConfig from '../../app/texts/swalTexts'
import {  registerLocale } from 'i18n-iso-countries'
import en from 'i18n-iso-countries/langs/en.json'
import { offersSlice, setOffers } from '../slices/offersSlice'
import Swal from 'sweetalert2'
import { type IOffer } from '../../types'
import { removeDataAttributes } from '../../functions/adapters/universal/removeDataAttributes'
import dayjs from 'dayjs'
import { offerAdminViewStatuses } from '../../constants'
import { removeNotifyByDeviceToken } from '../../functions/shared/notify-helpers/removeNotify'
import { getQueryOffers } from '../../functions/shared/api/queries/getQueryOffers'
import { sendNotification } from '../../functions/shared/notifications/sendNotification'
registerLocale(en)





export const setOfferStatus = (offerID: number, status?: string, data?: Record<string, any>, notify?: { rule: string, receiver: string }, slipLink?: string) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    try {
        if (status != null && ['Canceled', 'Rejected'].includes(status)) {
            const confirmationResult = await Swal.fire({
                ...swalOptions('warning'),
                title: 'Are you sure?',
                html: 'This offer will be canceled if you continue',
                showConfirmButton: true,
                confirmButtonText: 'Continue',
                showCancelButton: true,
                cancelButtonText: 'Go back'
            })
            if (!confirmationResult.isConfirmed) {
                return
            }
        }

        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const offer = removeDataAttributes(
            _.cloneDeep(
                _.find(getState().offers.offers, (offerItem: any) => offerItem.id === offerID) ?? {}
            )
        )

        if (data?.Type != null) {
            offer.Type = [{
                ...(offer?.Type[0] ?? {}),
                ...((data.Type as any[])?.[0] ?? {})
            }]
        }

        if (status !== undefined) {
            offer.Statuses = [status]
        }

        offer.Notify = !offerAdminViewStatuses.includes(String(status)) ? notify?.receiver : null

        if (slipLink !== undefined) {
            offer.Type[0].DepositSlipLink = slipLink
        }

        if (data != null && data?.Documents?.length > 0) {
            offer.Documents = [...offer.Documents, ...data.Documents]
        }

        const bodyObject = {
            method: 'PUT',
            collection: 'offers',
            id: offerID,
            body: offer,
            query:  getQueryOffers()
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        if (notify != null) {
            // const notifiedUserID = notify.receiver === 'BSO' ? 0 :
            //  console.log('OFFER', offer)
            // let notifyUserID: number
            /* if (notify.receiver === 'BSO') {
                notifyUserID = Number(process.env.REACT_APP_BSO_USER_ID)
            } else { */
            /* const units = getState().units?.units
            const unit = _.find(units, { id: offer?.Unit?.id })
            const notifyUserID = (unit as any)?.attributes?.User?.data?.id */
            // }

            await sendNotification(new Date(new Date().getTime() + 10000), offer?.User?.id, offer.id, notify?.rule, 'Offer - ' + String(status))
        }
        return await response.json()
    } catch (error) {
        console.log(error)
    }
}

export const saveAgentOfferNegotiate = (unitID: string | undefined, objectID: string | undefined) => (data: Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const unit = getState().units.units.find(unitItem => unitItem.id === Number(unitID))
    const receiver = unit?.attributes?.User?.data?.id === Number(process.env.REACT_APP_BSO_USER_ID) ? 'BSO' : 'Landlord'
    data.Type[0].ContractStartDate = dayjs(data.Type[0].ContractStartDate).add(dayjs(data.Type[0].ContractStartDate).utcOffset(), 'minutes').toISOString()
    await dispatch(setOfferStatus(Number(objectID), 'Negotiated by agent', data, {
        receiver,
        rule: 'admin-offer-negotiate'
    }))
    return {
        isSuccessful: true,
        textNotification: swalConfig.negotiateOfferSwal.negotiateNegotiated.message,
        textNavigate: -1
    }
}

export const removeOfferNotify = (offerID: number, newNotify: string | null = null) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const offers = _.cloneDeep(getState().offers?.offers ?? [])
        const offer: IOffer = _.find(offers, (offerItem: any) => offerItem.id === offerID) ?? { attributes: {} }
        offer.attributes.Notify = newNotify
        const bodyObject = {
            method: 'PUT',
            collection: 'offers',
            id: offerID,
            body: { ...offer.attributes, User: offer.attributes.User.data, Unit: offer.attributes.Unit.data },
            query: getQueryOffers()
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json()
        if (responseJSON.success === true) {
            if (responseJSON.response.error != null) {
                dispatch(setOffers(responseJSON?.response?.data))
                await removeNotifyByDeviceToken({
                    userID: getState().user.currentUser.id as number,
                    deviceToken: getState().user.currentUser.DeviceToken as string
                })
            } 
        }      
    } catch (err) {
        console.log(err)
    }
}






export const processSocketOffer = (entry: any) => (dispatch: AppDispatch, getState: () => RootState): { isSuccessful: boolean } => {
    try {
        const allOffers = getState().offers.offers
        const offer = _.find(allOffers, (offer) => offer.id === entry.id)
        const newOffer = {
            id: entry.id,
            attributes: {
                ...entry,
                Unit: {
                    data: {
                        id: entry.Unit.id,
                        attributes: {
                            Marketing: entry.Unit.Marketing
                        }
                    }
                },
                User: {
                    data: {
                        id: entry.User.id
                    }
                }
            }
        }
        if (offer == null) {
            dispatch(offersSlice.actions.setOffers([...allOffers, newOffer]))
        } else {
            dispatch(offersSlice.actions.setOffers(
                [
                    ...(_.filter(allOffers, (offerItem) => offerItem.id !== offer.id)),
                    newOffer
                ]
            ))
        }
        return {
            isSuccessful: true
        }
    } catch (err) {
        console.error(err)
        return {
            isSuccessful: false
        }
    }
}
