
import { type AppDispatch, type RootState } from '../../../store'
import swalConfig from '../../../../app/texts/swalTexts'
import { dataFetchingFinish, dataFetchingStart, setWhereAfterToGo } from '../../../slices/modalSlice'
import { createDocument } from '../../documentsActions/createDocument'
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../../urls'
import { sendNotification } from '../../../../functions/shared/notifications/sendNotification'
import notifyDelay from '../../../../functions/shared/notify-helpers/notifyDelay'
import {  setOffers } from '../../../slices/offersSlice'
import { uploadMultipleForOffers } from '../../documentsActions/upload/uploadMultipleForOffers'
import { getErrorss, getMessageForError } from '../../../../functions/errors/GetErrors'
import { getOfferTypeName } from '../../../../functions/agent/offers/getOfferTypeName'
import { getQueryOffers } from '../../../../functions/shared/api/queries/getQueryOffers'
import { documentAddSuccess } from '../../../slices/documentSlice'

export const addOfferPaymentConfirmation = (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ isSuccessful: boolean, textNotification?: string, textNavigate?: string }> => {
   
    try {
        dispatch(dataFetchingStart())
        const { objectID , email , phone } = data
        const jwt = window.localStorage.getItem(ACCESS_KEY)  ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY)
        const offer = getState().offers.offers.find((offerItem) => offerItem.id === Number(objectID))
        const saleType = getOfferTypeName(offer).includes('sale')

        const fileList =  data.deposit_cheque
        if (fileList != null && fileList?.length > 0) {
            const { isSuccessful, response } = await dispatch(createDocument(fileList, {
                RefID: `REF_DOC_deposit_cheque_${new Date().getTime()}_${0}`,
                Category:  'Deposit Cheque',
                Statuses: ['In-process'],
                Offer: offer?.id
            }, 'offers', String(offer?.id)))
            if (isSuccessful && response?.data?.id != null) {
                dispatch(documentAddSuccess(response?.data))
                let visaDoc = null
                if(data.visa != null ){
                    visaDoc = await dispatch(uploadMultipleForOffers(data, 'agent_to_bso_offer'))
                    
                }
                const bodyObject = {
                    method: 'PUT',
                    id: Number(objectID),
                    collection: 'offers',
                    query: getQueryOffers(),
                    body: {
                        ...offer?.attributes,
                        Type: [{
                            ...(offer?.attributes?.Type?.[0]),
                            Email: email,
                            Phone: phone
                        }],
                        Documents: [...(offer?.attributes?.Documents?.data?.map(doc => ({ id: doc.id })) ?? []), ...(visaDoc ? [{ id: visaDoc[0] }] : []), { id: response?.data?.id }] , // , response?.data?.id
                        Statuses: ['Waiting for slip approval'],
                        RejectReason: null,
                        Notify: 'BSO', 
                    }
                }
                const responseOffer = await fetch(`${urlAPI}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'BSO-Hash': '02ns30nsBSO!',
                        Authorization: `Bearer ${String(jwt)}`
                    },
                    body: JSON.stringify(bodyObject)
                })
                const responseJSON = await responseOffer.json()
  
                const typeOffer = offer?.attributes?.Type?.[0]?.__component?.split('.')[1] ?? ''
                if (responseJSON.success === true && responseJSON?.response?.data?.id != null) {
                    await sendNotification(new Date(new Date().getTime() + 10000), Number(userID) ,  Number(objectID), 'admin-offer-deposit', 'Offer -  Waiting for slip approval')
                    await sendNotification(notifyDelay(), Number(userID), Number(objectID), 'admin-offer-client-info', 'Offer - Waiting booking form sign')
                   
                    dispatch(setOffers(responseJSON.response.data))
                    dispatch(dataFetchingFinish())
                    return {
                        textNotification: saleType ? "Your deposit cheque copy loaded successfully" : swalConfig.waitingPaySlipOfferSwal.slipCorrect.message,
                        textNavigate:`/agent/modalView/offer_modal/${offer?.attributes?.Unit?.data?.id}/${objectID as string}/catalog?type=${typeOffer ?? 'not'}`,
                        isSuccessful: true
                    }
                } else {
                    dispatch(dataFetchingFinish())
                    return {
                        textNotification: getErrorss(responseJSON),
                        isSuccessful: false
                    }}
            } else {
                dispatch(dataFetchingFinish())
                return {
                    textNotification: getErrorss(response),
                    isSuccessful: false
                }
            }
        } else {
            dispatch(dataFetchingFinish())
            return {
                textNotification: 'Please upload deposit cheque copy',
                isSuccessful: false
            }
        }

    } catch (err) {
        console.error(err)
        dispatch(dataFetchingFinish())
        return {
            textNotification: getErrorss(getMessageForError("Something went wrong. Try again later")),
            isSuccessful: false
        }
    }

}
