import { type AppDispatch, type RootState } from '../../store'
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../urls'

import { dataFetchingFinish, dataFetchingStart, modalSlice, setWhereAfterToGo } from '../../slices/modalSlice'
import { getErrorss } from '../../../functions/errors/GetErrors'
import _ from 'lodash'
import qs from 'qs'
import dayjs from 'dayjs'
import { rentConfig, saleConfig } from '../../../functions/shared/modals/fillAdminOfferDocumentModal'

import { setUnitSold } from '../unitsActions'
import notifyDelay from '../../../functions/shared/notify-helpers/notifyDelay'
import { sendNotification } from '../../../functions/shared/notifications/sendNotification'
import { createDocument } from '../documentsActions/createDocument'
import { setOffers } from '../../slices/offersSlice'
import { getQueryOffers } from '../../../functions/shared/api/queries/getQueryOffers'

export const updateOfferByMagic = (id: number, data: Record<string, any>, successAlert: string, notifyRule: string, notifyMessage: string, omitNotify: boolean = false, notifyBSO: boolean = false, notifyBoth: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)

    try {
        dispatch(dataFetchingStart())
        const offer = getState().offers?.offers?.find((offer) => offer.id === id)
        const userID = offer?.attributes?.User?.data?.id
  
        if (data?.Type != null) {
            if ('ContractStartDate' in data.Type) {
                data.Type.ContractStartDate = dayjs(data.Type.ContractStartDate).add(dayjs(data.Type.ContractStartDate).utcOffset(), 'minutes').toISOString()
            }
            const offer = _.find(getState().offers.offers, offerItem => offerItem.id === id)
            data.Type = [{
                ...(offer?.attributes?.Type[0] ?? {}),
                ...((data.Type as any[])?.[0] ?? {})
            }] as any
        }


        const bodyObject = {
            method: 'PUT',
            collection: 'offers',
            id,
            body: { 
                ...data,
                Notify: 'Agent'  // !omitNotify ? notifyBSO ? 'BSO' : notifyBoth ? 'Both' : 'Agent' : undefined 
            },
            query: getQueryOffers()
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${String(jwt)}`
            },
            body: JSON.stringify(bodyObject)
        })
        const dataJSON = await response.json()
        if (dataJSON.success === true) {
            if (dataJSON.response.error != null) {
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                await sendNotification(notifyDelay(), Number(userID), id, notifyRule, notifyMessage)
           
                dispatch(setOffers(dataJSON.response.data))
                dispatch(dataFetchingFinish())
                dispatch(setWhereAfterToGo('/landlord/schedule'))
                return { ...dataJSON, textNavigate: `/landlord/modalView/magic_offer_modal/${String(offer?.attributes?.Unit?.data?.id)}/${String(offer?.id)}/catalog?type=${String(offer?.attributes?.Type?.[0]?.__component ?? 'not').slice(6)}`, isSuccessful: true, textNotification: successAlert }
            }
        } else {
            dispatch(dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (err) {
        return {
            textNavigate: '',
            isSuccessful: false,
            textNotification: 'Something went wrong. Try again later'
        }
    }
}

export const uploadOfferDocument = (id: number, data: Record<string, any>, successAlert: string, notifyRule: string, notifyMessage: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const offer = getState().offers.offers.find((offer) => offer.id === id)
        if (offer != null) {
            let nextStatus: string
            let documentCategory: string
            const offerStatus = offer.attributes?.Statuses?.[0]
            const offerType = offer.attributes?.Type?.[0]?.__component
            if (offerType === 'offer.sale' || offerType === 'offer.sale-commercial') {
                nextStatus = saleConfig[offerStatus].nextStatus
                documentCategory = saleConfig[offerStatus].documentCategory
                if (nextStatus === 'Mortgage process' && offer?.attributes?.Type?.[0]?.Type !== 'Mortgage Buyer') {
                    nextStatus = 'Obtaining NOC'
                }
            } else {
                nextStatus = rentConfig[offerStatus].nextStatus
                documentCategory = rentConfig[offerStatus].documentCategory
            }
            dispatch(modalSlice.actions.dataFetchingStart())
            const { isSuccessful } = await dispatch(createDocument(data.document, {
                RefID: `REF_DOC<->${documentCategory.toLowerCase().replaceAll(' ', '_')}<->${new Date().getTime()}`,
                Category: documentCategory,
                Offer: id,
                Statuses: ['In-process']
            }, 'offers', String(id)))
            dispatch(modalSlice.actions.dataFetchingFinish())
            if (isSuccessful) {
                if (nextStatus === 'Completed') {
                    void dispatch(setUnitSold((offer?.attributes as any).Unit?.data?.id))
                    return await dispatch(updateOfferByMagic(id, { Statuses: [nextStatus] }, successAlert, 'user-offer-completed', notifyMessage, true))
                } else {
                    return await dispatch(updateOfferByMagic(id, { Statuses: [nextStatus] }, successAlert, notifyRule, notifyMessage, false, true))
                }
            }
        } else {
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (err) {
        return {
            isSuccessful: false,
            textNotification: 'Something went wrong. Try again later'
        }
    }
}
