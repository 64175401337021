import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import CustomFormLable from './CustomFormLable'
import CustomSelect from '../selects/CustomSelect'
import { Space, Switch } from 'antd'
import _ from 'lodash'
import DefaultInput from '../inputs/DefaultInput'

export default function AdditionalUnitFields({
    setValue,
    errors,
    register,
    control
}: any): JSX.Element {
    const configSelectors = useAppSelector(state => state.app.config?.units)

    const [switchChecked, setChecked] = useState(false)

    const rowAmenities = { id: 'Amenities', content: ['Property Amenities', 'Select amenities'], element: 'select', multiple: true, selectors: configSelectors?.Amenities }
    const rowAppliances = { id: 'Appliances', content: ['Property Appliances', 'Select appliances'], element: 'select', multiple: true, selectors: configSelectors?.Appliances }
    const rowFit = { id: 'Fit', content: ['Property Fit', 'Select fit'], element: 'select', selectors: configSelectors?.Fit }
    const rowView = { id: 'View', content: ['Property view', 'Select view'], element: 'select', selectors: configSelectors?.View }
    const rowNumber = { id: 'Number', content: ['Unit number', 'Input unit number'], minLength: 1, element: 'input', required: false, description: 'This field is optional, but completing it will enhance the credibility of your listing.' }

    return (
        <>

            <div className="fv-row  mt-8 p-0 w-100">
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase"> Do you want add additional unit's information ?</div>
                    </div>
                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Switch unCheckedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>NO</span>} defaultChecked={switchChecked}
                                checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                onChange={(ch) => { setChecked(ch) }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-4"></div>

                {
                    switchChecked
                        ? <>
                            <div className={'text-start'}>
                                <DefaultInput row={rowNumber} register={register} errors={errors} />
                                <CustomSelect control={control} register={register} data={rowAmenities} setValue={setValue} errors={errors} />
                                <CustomSelect control={control} register={register} data={rowAppliances} setValue={setValue} errors={errors} />
                                <CustomSelect control={control} register={register} data={rowFit} setValue={setValue} errors={errors} />
                                <CustomSelect control={control} register={register} data={rowView} setValue={setValue} errors={errors} />
                            </div>
                        </>
                        : null
                }

            </div >

        </>

    )
}
