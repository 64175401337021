import { type IAdvertise } from '../../../store/slices/advertisesSlice'
import { type IOffer } from '../../../store/slices/offersSlice'
import { type IViewing } from '../../../store/slices/viewingsSlice'
import { type IChatMessage } from '../../../types'
import { getStatusBroadcast } from '../../shared/date/getStatusBroadcast'
import { getCurrentDubaiDateString } from '../date/getCurrentDubaiDateString'

interface ResultColors {
    mainColor: string
    borderColor: string
    thin: string
    textColor: string
}

export const generateBgAndBorderColFromOfferMessage = (messageBr: IChatMessage): ResultColors => {
    const resultColors = { mainColor: 'light-secondary', borderColor: 'secondary', thin: '', textColor: 'secondary' }

    if (messageBr.HideDate && getStatusBroadcast(messageBr.HideDate) === 'Active') {
        resultColors.mainColor = 'light-secondary'
        resultColors.borderColor = 'secondary'
        resultColors.thin = 'fw-bold'
        resultColors.textColor = 'gray-700'
    } else if (messageBr.HideDate && getStatusBroadcast(messageBr.HideDate) === 'Completed') {
        resultColors.mainColor = 'light-success'
        resultColors.borderColor = 'success'
        resultColors.thin = 'fw-bold'
        resultColors.textColor = 'success'
    }
    return resultColors
}

export const generateBgAndBorderCol = (viewOrOffer: IViewing | IOffer | IAdvertise, isViewing: boolean = false): ResultColors => {
    const resultColors = { mainColor: 'light-secondary', borderColor: 'secondary', thin: '', textColor: 'secondary' }
    if (Array.isArray(viewOrOffer?.attributes?.Statuses)) {
        if (viewOrOffer?.attributes?.Statuses.includes('Approved') || viewOrOffer?.attributes?.Statuses.includes('Advertisement started') || viewOrOffer?.attributes?.Statuses.includes('Links approved')) {
            resultColors.mainColor = 'light-success'
            resultColors.borderColor = 'success'
            resultColors.thin = 'fw-bold'
            resultColors.textColor = 'success'
        } else if (viewOrOffer?.attributes?.Statuses.includes('Confirmed')) {
            resultColors.mainColor = 'light-success'
            resultColors.borderColor = 'success'
            resultColors.thin = 'fw-bold'
            resultColors.textColor = 'success'
        } else if (viewOrOffer?.attributes?.Statuses.includes('Completed') || viewOrOffer?.attributes?.Statuses.includes('Advertisement closed') || viewOrOffer?.attributes?.Statuses.includes('Waiting for complain')) {
            resultColors.mainColor = 'light-dark'
            resultColors.borderColor = 'dark'
            resultColors.thin = 'fw-bold'
            resultColors.textColor = 'dark'
        } else if (viewOrOffer?.attributes?.Statuses.includes('Canceled') || viewOrOffer?.attributes?.Statuses.includes('Canceled by other agent')) {
            resultColors.mainColor = 'light-danger'
            resultColors.borderColor = 'danger'
            resultColors.thin = 'fw-bold'
            resultColors.textColor = 'danger'
        } else if (viewOrOffer?.attributes?.Statuses.includes('Rejected') || viewOrOffer?.attributes?.Statuses.includes('Links rejected')) {
            resultColors.mainColor = 'light-danger'
            resultColors.borderColor = 'danger'
            resultColors.thin = 'fw-bold'
            resultColors.textColor = 'danger'
        } else if (viewOrOffer?.attributes?.Statuses.includes('Rescheduled by agent') || viewOrOffer?.attributes?.Statuses.includes('Rescheduled by other agent') || viewOrOffer?.attributes?.Statuses.includes('Rescheduled by BSO') || viewOrOffer?.attributes?.Statuses.includes('Documents received')) {
            resultColors.mainColor = 'light-info'
            resultColors.borderColor = 'info'
            resultColors.thin = 'fw-bold'
            resultColors.textColor = 'info'
        } else if (viewOrOffer?.attributes?.Statuses.includes('Waiting for complain')) {
            resultColors.mainColor = 'light-secondary'
            resultColors.borderColor = 'secondary'
            resultColors.thin = 'fw-bold'
            resultColors.textColor = 'secondary'
        } else {
            resultColors.mainColor = 'light-warning'
            resultColors.borderColor = 'warning'
            resultColors.thin = 'fw-bold'
            resultColors.textColor = 'warning'
        }
    }
    if (isViewing) {
        if (viewOrOffer && viewOrOffer.hasOwnProperty('attributes') && (viewOrOffer as IViewing).attributes) {
            const viewingTime = new Date((viewOrOffer as IViewing).attributes?.Datetime ?? (viewOrOffer as IViewing).attributes?.createdAt)
            const curTime = new Date(getCurrentDubaiDateString())

            if (viewingTime < curTime) {
                resultColors.mainColor = 'light-secondary'
                resultColors.borderColor = 'secondary'
                resultColors.thin = ''
                resultColors.textColor = 'gray-700'
            }
        }
    }
    return resultColors
}
