import { type AppDispatch, type RootState } from '../../../store'
import { dataFetchingFinish, modalSlice } from '../../../slices/modalSlice'
import { ACCESS_KEY, urlAPI } from '../../../../urls'
import QueryString from 'qs'
import { offersSlice } from '../../../slices/offersSlice'
import { getErrorss } from '../../../../functions/errors/GetErrors'
import { setFilterNavigate } from '../../../slices/scheduleSlice'
import { deleteAgentToBSOOfferCommunicationStorageInfo } from '../agentToBSOOfferCommunication/agentToBSOOfferCommunicationStorage'
import { getQueryOffers } from '../../../../functions/shared/api/queries/getQueryOffers'
import { sendNotification } from '../../../../functions/shared/notifications/sendNotification'

export const makeOfferAgentToLandlordOfferInfo = (unitID: number, offerID: number) => (data: Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(modalSlice.actions.dataFetchingStart())
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY)
        const unit = getState().units.units.find((unitItem) => unitItem.id === unitID)
        const offer = getState().offers?.offers?.find((offer) => offer.id === Number(offerID))
        if (unit != null && offer != null) {
            const isSale = unit.attributes?.Marketing?.Use === 'For sale'
            const offerType = {
                ...offer?.attributes?.Type?.[0],
                Offer: data.Type.Offer,
                Payments: data.Type.Payments,
                AdditionalNotes: data.Type.AdditionalNotes,
                ...(
                    isSale
                        ? {
                            BankName: data.Type.BankName,
                            BankPreApproval: data.Type.PreApprovedAmount != null,
                            PreApprovedAmount: data.Type.PreApprovedAmount
                        }
                        : {
                            Duration: data.Type.Duration,
                            ContractStartDate: data.Type.ContractStartDate
                        }
                )
            }
            const bodyObject = {
                method: 'PUT',
                collection: 'offers',
                id: offerID,
                query: getQueryOffers(),
                body: {
                    Type: [offerType]
                }
            }
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'BSO-Hash': '02ns30nsBSO!',
                    Authorization: `Bearer ${String(jwt)}`
                },
                body: JSON.stringify(bodyObject)
            })
            const responseJSON = await response.json()
            if (responseJSON.success === true) {
                if (responseJSON.response.error != null) {
                    dispatch(offersSlice.actions.offersFetchingError(getErrorss(responseJSON)))
                    dispatch(dataFetchingFinish())
                    return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
                } else {
                    void sendNotification(new Date(new Date().getTime() + 10000), Number(getState().user.currentUser.id), responseJSON.response.data ?? {}, 'admin-offer-in-process', 'Offer - in process')
                    dispatch(setFilterNavigate('offers'))
                    dispatch(modalSlice.actions.dataFetchingFinish())
                    deleteAgentToBSOOfferCommunicationStorageInfo()
                    return {
                        textNavigate: '/agent/schedule',
                        isSuccessful: true,
                        textNotification: 'Offer information has been saved',
                        title: 'Success'
                    }
                }
            }
        }
    } catch (err: any) {
        console.error(`Error in makeOfferAgentToLandlordOfferInfo ${String(err.message)}`)
    }
    dispatch(modalSlice.actions.dataFetchingFinish())
    return {
        isSuccessful: false,
        textNotification: 'Something went wrong. Try again later'
    }
}
