import { type AppDispatch, type RootState } from '../store'
import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../urls'

import { type ApiResponse } from '../../types/userTypes/TAuth'
import getChatListAdapter from '../../functions/adapters/chatList/getChatListAdapter'
import { setChatList } from '../slices/chatListSlice'
import { getQueryChats } from '../../functions/shared/api/queries/getQueryChats'

export const getChatList = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''

        const bodyObject = {
            method: 'GET',
            collection: 'users',
            id: +userID,
            //  query: getQueryChats()
            query: 'populate[Chats][populate][Users][populate][0]=ContactInfo,Type,DeviceToken' +
                 '&populate[Chats][populate]=LastMessage'
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json() as unknown as ApiResponse
        if (responseJSON.success) {
            if (responseJSON.response.error != null) {
                // error handling
                return {
                    ...responseJSON,
                    isSuccessful: false
                }
            } else {
                dispatch(
                    setChatList(
                        getChatListAdapter(responseJSON, +userID)
                    )
                )
            }
        }
        return {
            ...responseJSON,
            isSuccessful: true
        }
    } catch (error) {
        console.log(error)
        return {
            isSuccessful: false
        }
    }
}
