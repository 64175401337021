import React from 'react'
import ActionCard from '../actionCard'
import { useNavigate } from 'react-router-dom'
import LandlordHeader from '../../LandlordHeader'
import { useParams } from 'react-router'
import { updateViewingByMagic } from '../../../../store/actionsCreators/viewingActions/magicViewingActions'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { swalSuccess } from '../../../../functions/swalOptions'
import { type RootState } from '../../../../store/store'
import _ from 'lodash'
import confirmAction from '../../../../functions/swals/confirmAction'
import dayjs from 'dayjs'

const ViewingActions = (): JSX.Element => {
    const { unitID, viewingID } = useParams()
    const viewings = useAppSelector((state: RootState) => state.viewings.viewings)
    const viewing = _.find(viewings, (viewing) => viewing.id === Number(viewingID))
    const keyBookingStatus = viewing?.attributes?.KeyBooking?.data?.attributes?.Status
    const keyBookingDropOutDate = viewing?.attributes?.KeyBooking?.data?.attributes?.DropOutDate
    const units = useAppSelector((state: RootState) => state.units.units)
    const unit = _.find(units, (unit) => unit.id === Number(unitID))
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    return (
        <>
            <LandlordHeader active={'Viewing actions'} />
            <div className={'d-flex flex-column gap-4 p-4 mt-4'}>
                {
                    ((viewing?.attributes?.Statuses?.[0] === 'In-process' && !_.includes(unit?.attributes?.Statuses, 'Digital lock') && !_.includes(unit?.attributes?.Statuses, 'Keys in BSO office')) || (viewing?.attributes?.Statuses?.[0] === 'Rescheduled by agent' && !_.includes(unit?.attributes?.Statuses, 'Keys in BSO office'))) &&
                    <ActionCard
                        title={'Approve'}
                        description={'Approve viewing'}
                        onClick={() => {
                            confirmAction({
                                callback: () => {
                                    void dispatch(updateViewingByMagic(Number(viewingID), { Statuses: ['Approved'] }, 'Viewing has been approved', 'user-viewing-approved', 'Great, viewing has been approved'))
                                        .then(() => {
                                            void swalSuccess('Viewing has been approved')
                                        })
                                }
                            })
                        }}
                        icon={'check_circle'}
                    />
                }
                {
                    // (viewing?.attributes?.Statuses?.[0] === 'In-process' || viewing?.attributes?.Statuses?.[0] === 'Rescheduled by agent' || viewing?.attributes?.Statuses?.[0] === 'Rescheduled by BSO' || viewing?.attributes?.Statuses?.[0] === 'Rescheduled by landlord') &&
                    viewing?.attributes?.Statuses?.[0] !== 'Rejected' && viewing?.attributes?.Statuses?.[0] !== 'Canceled' && viewing?.attributes?.Statuses?.[0] !== 'Completed' &&
                    <ActionCard
                        title={'Reject'}
                        description={'Reject viewing'}
                        onClick={() => {
                            navigate(`/landlord/modal/reject_viewing_modal/${unitID ?? '-'}/${viewingID ?? '-'}`)
                        }}
                        icon={'cancel'}
                    />
                }
                {
                    _.includes(unit?.attributes?.Statuses, 'Keys in BSO office') && _.includes(['In-process', 'Approved', 'Rejected', 'Rescheduled by agent', 'Rescheduled by BSO', 'Rescheduled by landlord'], viewing?.attributes?.Statuses?.[0]) &&
                    <ActionCard
                        title={'Reschedule and set keys'}
                        description={'Reschedule and set key bookings'}
                        onClick={() => {
                            navigate(`/landlord/modal/reschedule_viewing_modal_keys/${unitID ?? '-'}/${viewingID ?? '-'}`)
                        }}
                        icon={'event_available'}
                    />
                }
                {
                    _.includes(unit?.attributes?.Statuses, 'Digital lock') && _.includes(['In-process', 'Approved', 'Rejected', 'Rescheduled by agent', 'Rescheduled by BSO', 'Rescheduled by landlord'], viewing?.attributes?.Statuses?.[0]) &&
                    <ActionCard
                        title={'Reschedule and set pass'}
                        description={'Reschedule and set digital lock pass'}
                        onClick={() => {
                            navigate(`/landlord/modal/reschedule_viewing_modal_lock/${unitID ?? '-'}/${viewingID ?? '-'}`)
                        }}
                        icon={'event_available'}
                    />
                }
                {
                    //  (_.includes(unit?.attributes?.Statuses, 'Door open') || _.includes(unit?.attributes?.Statuses, 'Occupied') || _.includes(unit?.attributes?.Statuses, 'Building Management')) && _.includes(['In-process', 'Approved', 'Rejected', 'Rescheduled by agent', 'Rescheduled by BSO', 'Rescheduled by landlord'], viewing?.attributes?.Statuses?.[0]) &&


                    _.includes(['In-process', 'Approved', 'Rejected', 'Rescheduled by agent', 'Rescheduled by BSO', 'Rescheduled by landlord'], viewing?.attributes?.Statuses?.[0]) &&
                    <ActionCard
                        title={'Reschedule'}
                        description={'Reschedule viewing'}
                        onClick={() => {
                            navigate(`/landlord/modal/reschedule_viewing_modal/${unitID ?? '-'}/${viewingID ?? '-'}`)
                        }}
                        icon={'event_available'}
                    />
                }
                {viewing?.attributes?.Statuses?.[0] === 'Waiting for complain' && (!_.includes(unit?.attributes?.Statuses, 'Keys in BSO office') || keyBookingStatus === 'Completed' || dayjs().diff(dayjs(keyBookingDropOutDate), 'hour') >= 24) &&
                    <ActionCard
                        title={'Make complaint'}
                        description={'Make complaint on viewing'}
                        onClick={() => {
                            navigate(`/landlord/modal/complain_viewing_modal/${unitID ?? '-'}/${viewingID ?? '-'}`)
                        }}
                        icon={'description'}
                    />
                }
                {(viewing?.attributes?.Statuses?.[0] === 'In-process' || viewing?.attributes?.Statuses?.[0] === 'Rescheduled by agent' || viewing?.attributes?.Statuses?.[0] === 'Rejected') && _.includes(unit?.attributes?.Statuses, 'Digital lock') &&
                    <ActionCard
                        title={'Approve and set digital lock pass'}
                        description={'Set digital lock pass on viewing'}
                        onClick={() => {
                            navigate(`/landlord/modal/lock_pass_viewing_modal/${unitID ?? '-'}/${viewingID ?? '-'}`)
                        }}
                        icon={'lock'}
                    />
                }
                {(viewing?.attributes?.Statuses?.[0] === 'In-process' || viewing?.attributes?.Statuses?.[0] === 'Rescheduled by agent' || viewing?.attributes?.Statuses?.[0] === 'Rejected') && _.includes(unit?.attributes?.Statuses, 'Keys in BSO office') &&
                    <ActionCard
                        title={'Approve and set key bookings'}
                        description={'Set key bookings on viewing'}
                        onClick={() => {
                            navigate(`/landlord/modal/set_key_bookings_modal/${unitID ?? '-'}/${viewingID ?? '-'}`)
                        }}
                        icon={'key'}
                    />
                }
                {
                    (!_.includes(['Waiting for feedback', 'Waiting for complain', 'Completed', 'Canceled'], viewing?.attributes?.Statuses?.[0]) && _.includes(unit?.attributes?.Statuses, 'Keys in BSO office')) &&
                    <ActionCard
                        title={'Set manually processed'}
                        description={'Move to the feedback step manually'}
                        onClick={() => {
                            confirmAction({
                                callback: () => {
                                    void dispatch(updateViewingByMagic(Number(viewingID), { Statuses: ['Waiting for feedback'] }, 'Viewing has been processed manually', 'user-viewing-approved', 'Great, viewing has been approved', undefined, true))
                                        .then(() => {
                                            void swalSuccess('Viewing has been processed manually')
                                        })
                                }
                            })
                        }}
                        icon={'key'}
                    />
                }
            </div>
        </>
    )
}

export default ViewingActions
