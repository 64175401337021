import React, { useEffect, useState } from 'react'
import UseUserType, { type UserType } from '../../../../hooks/UseUserType'
import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../../urls'
import { timeStringInScheduler } from '../../../../functions/agent/date/timeStringInScheduler'
import { type IAdvertise } from '../../../../store/slices/advertisesSlice'
import { Link } from 'react-router-dom'
import { generateBgAndBorderCol } from '../../../../functions/agent/schedule/generateBgAndBorderCol'
import { nanoid } from 'nanoid'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import Icon from '../../../../app/icon/Icon'
import { cancelAwaitingAdvertise } from '../../../../store/actionsCreators/unitActions/cancelAwaitingAdvertise'
import { swalAttentionLeave } from '../../../../functions/swalOptions'
interface IProps {
    advertise: IAdvertise
}

function AdvertiseAwaitingCard({ advertise }: IProps): JSX.Element {
    const { userType, userTypeLowercase } = UseUserType()
    const userID = window.localStorage.getItem(USER_ID_KEY)
    let notifyApp: UserType | 'BSO' = userType
    if (userID === REACT_APP_BSO_USER_ID) {
        notifyApp = 'BSO'
    }
    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    const [statusNotify, setStatusNotify] = useState(advertise?.attributes?.Notify)

    const currentUnit = useAppSelector(
        (state: RootState) => state.units.units
    )?.filter(
        (unit) => unit.id === Number(advertise?.attributes?.Unit?.data?.id)
    )[0]
    let unitNumber, buildName, location
    if (advertise?.attributes?.Name) {
        [unitNumber, buildName, location] = advertise?.attributes?.Name?.split(' <-> ')
    } else {
        [unitNumber, buildName, location] = [
            currentUnit?.attributes?.Number ?? 'Not number',
            currentUnit?.attributes?.Property?.data?.attributes?.Name ?? 'Not title',
            currentUnit?.attributes?.Property?.data?.attributes?.Area ?? 'Not location'
        ]
    }

    let endDateString = 'No date yet'


    if (advertise?.attributes?.EndDate) {
        const [endDate, timeEndDate] = timeStringInScheduler(
            advertise?.attributes?.EndDate ?? ''
        ).split(',')
        const [dayE, mounthE, yearE] = endDate.split(' ')
        endDateString = `${dayE}  / ${mounthE} ${yearE}`
    }

    const dispatch = useAppDispatch()
    useEffect(() => {
        setStatusNotify(advertise?.attributes?.Notify)
    }, [advertise, dispatch])


    return (

        <Link
            to={`/${userTypeLowercase}/modalView/unit_modal/${advertise?.attributes?.Unit?.data?.id}/not/catalog`}
            className="text-decoration-none"
        >
            <div
                className={`d-flex flex-row row card shadow-sm g-0 p-2 px-3 m-0 align-items-center justify-content-between position-relative border border-1 
                    border-warning ${generateBgAndBorderCol(advertise).borderColor}
                     bg-light-warning ${generateBgAndBorderCol(advertise).borderColor}`}
            >
                {statusNotify === notifyApp || statusNotify === 'Both'
                    ? (<span className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger">
                        !
                    </span>)
                    : null}


                <div className="d-flex flex-column col-8  px-2 ">
                    {isDev
                        ? notifyApp !== 'BSO' && <span className={`position-absolute badge 
                         badge-light-warning ${generateBgAndBorderCol(advertise).textColor} border 
                         border-warning ${generateBgAndBorderCol(advertise).borderColor} 
                         text-warning ${generateBgAndBorderCol(advertise).textColor} p-1`} style={{ top: '-7px', left: '-5px' }}>
                            {'BSO UNIT'}
                        </span>
                        : null}
                    <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                        <Icon
                            name={'location_on'}
                            styles={{ minWidth: '12.9px' }}
                        />
                        <span className={'text-dots-app'}>{location}{' '}</span>
                    </span>

                    <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                        <Icon
                            name={'info'}
                            styles={{ minWidth: '12.9px' }}
                        />
                        <span className={'text-dots-app'}>{unitNumber}, {buildName}</span>
                    </span>



                    <div className="fs-6 d-flex align-items-center py-1">
                        <span
                            className={`me-1 badge  h-10px min-w-10px w-10px badge-circle badge-outline 
                                badge-warning ${generateBgAndBorderCol(advertise).borderColor} 
                                bg-light-warning ${generateBgAndBorderCol(advertise).borderColor}`}
                        >
                            {' '}
                        </span>

                        <div className="d-flex flex-column ">
                            <div key={nanoid()} className="fs-6 fw-bold text-dark lh-1 ps-1">RESERVED</div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column col-4 align-items-end lh-sm">
                    {endDateString
                        && <>
                            <span className="fs-8 text-gray-700">Potential</span>
                            <span className="fs-8 text-gray-700"> start date</span>
                            <span className="fs-6 text-dark fw-bold mb-1">{endDateString}</span>
                        </>
                    }
                    <div
                        onClick={(e) => {
                            e.preventDefault()
                            void swalAttentionLeave('Are you sure want to cancel this reservation?').then(async (result: any) => {
                                if (result.isConfirmed) {
                                    dispatch(cancelAwaitingAdvertise(advertise?.attributes?.Unit?.data?.id))
                                }
                            }).catch(console.log)


                        }}
                        className={`fs-7 pt-1 badge border border-warning ${generateBgAndBorderCol(advertise).borderColor}`}
                    >
                        CANCEL
                        <Icon
                            name={'expand_more'}
                            className={'text-dark fs-2 ms-1'}
                            useWrapper
                        />
                    </div>
                </div>
            </div>

        </Link>

    )
}

export default AdvertiseAwaitingCard
