
import { store, type AppDispatch } from '../../store/store'
import { getChat } from '../../store/actionsCreators/chatActions'
import _ from 'lodash'
import { getChatList } from '../../store/actionsCreators/chatListActions'
import { addViewing, setViewings } from '../../store/slices/viewingsSlice'
import { reduceViewing, reduceViewingAfterCreate } from '../agent/socket/reduceViewing'
import { REACT_APP_BSO_SUPPORT_USER_ID, REACT_APP_BSO_USER_ID, USER_CATEGORY_KEY, USER_ID_KEY } from '../../urls'
import { reduceViewingAfterUpdateKey } from '../agent/socket/reduceViewingAfterUpdateKey'
import { fetchSocketUser, fetchUser, fetchUserById, fetchUsers } from '../../store/actionsCreators/userActions'
import { setIsUserVerified } from '../../store/slices/modalSlice'
import { setStatuses } from '../../store/slices/agentSlice'
import getAgentStatuses from '../agent/documents/getAgentStatuses'
import { fetchDocuments } from '../../store/actionsCreators/documentActions'
import { swalSuccess } from '../swalOptions'
import { getCorrectName } from '../agent/agentModuleComponent/getCorrectName'
import { documentAddSuccess, documentAddUpdate } from '../../store/slices/documentSlice'
import { fetchSingleUnit, fetchUnits } from '../../store/actionsCreators/unitsActions'
import customLog from '../log'
import { setCurrentUserUnits, userSlice, userUpdate, type User } from '../../store/slices/userSlice'
import { addAdvertise, setAdvertises } from '../../store/slices/advertisesSlice'
import { fetchViewings } from '../../store/actionsCreators/viewingsActions'
import { fetchAdvertises } from '../../store/actionsCreators/advertiseActions/fetchAdvertiseAction'
import { setHash } from '../../store/slices/hashSlice'
import awaitAction from '../utils/awaitAction'
import { processOfferSwalForLandlord } from '../landlord/offers/showNegotiateSwals'
import { fetchOffer } from '../../store/actionsCreators/offerActions/fetchOffer'
import { addOffer, setOffers, updateOffer, type IOffer } from '../../store/slices/offersSlice'
import { type NavigateFunction } from 'react-router-dom'
import { processOfferSwalAdmin } from '../agent/offers/processOfferSwalAdmin'
import { setSocketTimeout } from '../../store/slices/appSlice'
import { reduceOffer } from '../agent/socket/reduceOffer'
import { reduceAdvertise } from '../agent/socket/reduceAdvertice'
import { setUnitsMainState } from '../../store/slices/unitsSlice'
import { reduceDocument } from '../agent/socket/reduceDocument'

export const landlordSocket = async (webSocket: WebSocket, dispatch: AppDispatch, navigate: NavigateFunction): Promise<void> => {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const userCATEGORY = window.localStorage.getItem(USER_CATEGORY_KEY)

    webSocket.onmessage = async (event: MessageEvent) => {
        try {
            const message = event.data
            if (typeof message === 'string' && (message.startsWith('{') || message.startsWith('['))) {
                const data = JSON.parse(message)
           
                if(!("timestamp" in data)){
                    console.log(data)
                }
                if(Number.isFinite(data?.timestamp)){
                    dispatch(setSocketTimeout(data?.timestamp))
                }
   
                if ('action' in data && 'isDev' in data) {
                    const [collection, action] = data.action.split('.')
                    const entry = data?.entry
                    if ((data?.isDev && process.env.REACT_APP_IS_DEV === 'true')
                         || (!data?.isDev) && (!process.env.REACT_APP_IS_DEV)) {
                        if (collection === 'chat-room') {
                            if (action === 'update' && entry.id === store.getState().chat?.chat?.id) {
                                void dispatch(getChat(entry.id, 1, undefined, true))
                            }
                            if (action === 'update' && _.some(store.getState().chatList.chatList, { id: entry.id })) {
                    
                                void dispatch(getChatList())
                            }
                            if (action === 'create') {
                                void dispatch(getChatList())
                            }
                        }
                        if (collection === 'unit-key') {
                            if (action === 'create') {
                                if (entry.Holder.id === Number(userID)) {
                                    const isThere = store.getState().viewings.viewings.filter((viewEl) => viewEl.id === entry?.Viewings?.[0]?.id) // ?.find((view) => view.attributes.User.data?.id === Number(userID))
                                    if (isThere != null && isThere.length > 0) {
                                        dispatch(setViewings(reduceViewing(entry?.Viewings?.[0], isThere[0])))
                                    }
                                }
                            }
                            if (action === 'update') {
                          
                                if (entry.Holder.id === Number(userID)) {
                                    const agentViewingsIDS = store.getState().viewings.viewings.filter((el) => el?.attributes?.User?.data?.id === Number(userID))?.map((el) => el.id)

                                    const isThere = store.getState().viewings.viewings.filter(
                                        view => view.id === entry?.Viewings?.filter((el: any) => agentViewingsIDS.includes(el.id))[0].id
                                    )
                                    //     const isThere = store.getState().viewings.viewings.filter((viewEl) => viewEl.id === entry?.Viewings?.[0]?.id) // ?.find((view) => view.attributes.User.data?.id === Number(userID))
                                    if (isThere != null && isThere.length > 0) {
                                        dispatch(setViewings(reduceViewingAfterUpdateKey(entry, isThere[0])))
                                    }
                                }
                            }
                        }

                        if (collection === 'user') {
                            if (action === 'create') {
                                // dispatch(userSlice.actions.allUsersFetchingSuccess(
                                //     [...store.getState().user.allUsers , entry]
                                // ))

                                if(entry?.id){
                                    void dispatch(fetchSocketUser(entry?.id))
                                }
                            }
                            if (action === 'update') {
                                if (entry.id === Number(userID)) {
                                    const actualUser = store.getState().user?.currentUser
                                    const updateUser: User = { ...actualUser, ...entry }
                                    dispatch(userUpdate(updateUser))
                                    if (updateUser.emailVerified) {
                                        setIsUserVerified(true)
                                    } else {
                                        setIsUserVerified(false)
                                    }
                                    const docs = updateUser?.Documents
                                    dispatch(setStatuses(getAgentStatuses(docs)))
                                } else {
                                    if(userCATEGORY ===  "Landlord" && Number(userID) === Number(REACT_APP_BSO_USER_ID)){
                                        if (entry.id === store.getState().chat?.chat?.User?.id) {
                                            void dispatch(fetchUserById(entry?.id ?? -1))
                                            if (store.getState().chat?.chat?.id != null) {
                                                void dispatch(getChat(Number(store.getState().chat.chat.id), 1, undefined, true))
                                            }
                                        }
                                    }
                                }   
                            }
                        }
                        if (collection === 'document') {
                            if (action === 'update') {
                                if (entry?.User?.id === Number(userID)) {
                                    dispatch(documentAddUpdate(entry))
                                    // const userDocs = store.getState().documents.documents.filter((doc) => doc.attributes.User.data?.id === Number(userID))
                                    // dispatch(setStatuses(getAgentStatuses(userDocs)))
                                } else {
                                    const storeDoc = store.getState().documents.documents.filter((doc) => doc.id === entry.id)
                                    if(storeDoc){
                                        dispatch(documentAddUpdate(entry))
                                    }
                              
                                }
                            }
                            if (action === 'create') {
                                if(userCATEGORY ===  "Landlord" && Number(userID) === Number(REACT_APP_BSO_USER_ID)){
                                    if(typeof entry?.RefID === 'string' && entry?.RefID.includes("REF_DOC") && entry?.Category){
                                        const reducedDocument = reduceDocument(entry, undefined);
                                        dispatch(documentAddSuccess(reducedDocument))
                                    }
                                }
                            }
                        }
                     

                        if (collection === 'unit') {
                            if(action === 'update' && store.getState().units?.units?.some(unit => unit.id === entry.id)){
                                const updatedUnit = await dispatch(fetchSingleUnit(entry.id))
                              
                              
                   
                                //   console.log({collection , entry})
                                /* if (_.some(store.getState().units.catalog, { id: entry.id })) {
                            dispatch(fetchSingleUnit(entry.id))
                                .then((result: any) => {
                                    const unitsCopy = _.cloneDeep(store.getState().units.catalog)
                                    const index = _.findIndex(unitsCopy, { id: entry.id })
                                    unitsCopy[index] = { id: entry.id, attributes: result }
                                    dispatch(unitsSetCatalog(unitsCopy))
                                })
                                .catch((err: any) => {
                                    customLog(err)
                                })
                        } */
                                if (_.some((store.getState().user.currentUser as any).Units, { id: entry.id })) {
                                    /* dispatch(fetchSingleUnit(entry.id))
                                .then((result: any) => {
                                    const unitsCopy = _.cloneDeep((store.getState().user.currentUser as any).Units)
                                    const index = _.findIndex(unitsCopy, { id: entry.id })
                                    unitsCopy[index] = { id: entry.id, ...result }
                                    dispatch(setCurrentUserUnits(unitsCopy))
                                })
                                .catch((err: any) => {
                                    customLog(err)
                                }) */
                                    //  dispatch(fetchUser(true))
                                }
                                if (_.some(store.getState().chat.searchedInvites, (invite) => invite?.Unit?.attributes?.id === entry.id)) {
                                    const invitesCopy = _.cloneDeep(store.getState().chat.searchedInvites)
                                    const index = _.findIndex(invitesCopy, (invite) => invite?.Unit?.attributes?.id === entry.id)
                                    dispatch(fetchSingleUnit(entry.id))
                                        .then((result: any) => {
                                            if (invitesCopy?.[index]?.Unit?.attributes != null) {
                                                (invitesCopy[index].Unit as any).attributes = result
                                            }
                                        })
                                        .catch((err: any) => {
                                            customLog(err)
                                        })
                                }
                                dispatch(setUnitsMainState(updatedUnit))
                            } else if (action === 'create') {
                                if (entry.User?.id === store.getState().user.currentUser?.id) {
                                    const unitsCopy = _.cloneDeep((store.getState().user.currentUser as any).Units)
                                    unitsCopy.push(entry)
                                    dispatch(setCurrentUserUnits(unitsCopy))
                                }
                            } else if (action === 'delete') {
                                if (_.some((store.getState().user.currentUser as any).Units, { id: entry.id })) {
                                    dispatch(setCurrentUserUnits((store.getState().user.currentUser as any).Units.filter((unit: any) => unit.id !== entry.id)))
                                }
                            }
                        }
                        if (collection === 'viewing') {
                            if (entry.Type != null && entry.Unit != null && entry.User != null) {
                                if ( entry?.Unit?.User?.id === Number(process.env.REACT_APP_BSO_USER_ID)){
                                    if (action === 'create') {
                                        if (entry?.User.id !== Number(userID)) {
                                            dispatch(addViewing(reduceViewingAfterCreate(entry)))
                                        }
                                    }
                                    if (action === 'update') {
                                        const isThere = store.getState().viewings.viewings.filter((viewEl) => viewEl.id === entry.id)
                                        dispatch(setViewings(reduceViewing(entry, isThere[0])))
                                    }
                                }}
                        }
                        if (collection === 'offer') {
                            if (entry.Type != null && entry.Unit != null && entry.User != null) {
                                if ( entry?.Unit?.User?.id === Number(process.env.REACT_APP_BSO_USER_ID)){
                                    const storeOffer = store.getState().offers.offers.filter((offer) => offer.id === entry.id)?.[0]
                                    if (action === 'update') {
                                        dispatch(setOffers(reduceOffer(entry, storeOffer)))
                                        awaitAction(async () =>  { void processOfferSwalAdmin(data  as IOffer, dispatch, navigate); })
                                    } if (action === 'create') {
                                        dispatch(addOffer(reduceOffer(entry, storeOffer)))
                                        awaitAction(async () =>  { void processOfferSwalAdmin(data  as IOffer, dispatch, navigate); })
                                    }
                                }
                            } 
                        }
                        if (collection === 'unit-advertise') {
                            if(Number(userID) === Number(process.env.REACT_APP_BSO_USER_ID)){
                                if (action === 'update') {
                                    const advertise = _.find(store.getState().advertises.advertises, advertise => advertise.id === entry.id)
                                    if (advertise != null && (advertise?.attributes?.User.data?.id === Number(userID) || userID === process.env.REACT_APP_BSO_USER_ID)) {
                                        dispatch(setAdvertises(reduceAdvertise(entry, advertise)))
                                    }
                                } if (action === 'create') {
                                    const advertise = { id: entry.id, attributes: { ...entry } }
                                    const unit = _.find(store.getState().units.units, (unit) => unit.id === entry.Unit.id)
                                    const user = _.find(store.getState().user.allUsers, (user) => user.id === entry.User.id)
                                    advertise.attributes.Unit = { id: unit?.id, data: unit }
                                    advertise.attributes.User = { id: user?.id, data: user }
                                    dispatch(addAdvertise(advertise))
                                }
                            } if(Number(userID) === entry.id){
                                if (action === 'update') {
                                    const advertise = _.find(store.getState().advertises.advertises, advertise => advertise.id === entry.id)
                                    if (advertise != null && (advertise?.attributes?.User.data?.id === Number(userID) || userID === process.env.REACT_APP_BSO_USER_ID)) {
                                        dispatch(setAdvertises(reduceAdvertise(entry, advertise)))
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

}
