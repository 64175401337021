import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import CustomModalFooter from './CustomModalFooter'
import CustomSelect from './selects/CustomSelect'

import PasswordMeterHook from './customModalUtils/PasswordMeterHook'

import MyDataPicker from '../MyDataPicker'
import SetButton from '../../components/shared/elements/SetButton'
import SetSwitch from '../../components/shared/elements/SetSwitch'

import ViewingSelectTime from '../../components/agentModule/componentsViewings/ViewingSelectTime'
import { store } from '../../store/store'

import CustomFormLable from './customModalUtils/CustomFormLable'

import CustomSlider from './customModalUtils/CustomSlider'
import CustomInlineSelect from './selects/CustomInlineSelect'
import Loader from './loaders/Loader'
import CustomSelectAdd from './selects/CustomSelectAdd'
import CustomFileInput from './inputs/CustomFileInput'
import RulesModals from '../../components/shared/auth/RulesModals'
import { verifyResetPasswordEmail, verifyResetPasswordPhone } from '../../store/actionsCreators/authActions'
import { Form } from 'antd'
import MyCarousel from '../MyCarousel'
import { nanoid } from 'nanoid'

import _ from 'lodash'
import CustomSelectAreas from './selects/CustomSelectAreas'

import { getSubsDetails } from '../../functions/agent/subscriptions/getSubsDetails'
import PickUpKeysTimeSelect from './inputs/PickUpKeysTimeSelect'
import AgentAdvertiseCreateLinks from '../../components/agentModule/advertise/AgentAdvertiseCreateLinks'

import CustomRangeInput from './inputs/CustomRangeInput'

import { isShure } from '../../functions/swals/isShure'
import CustomSelectAreasExtend from './selects/CustomSelectAreasExtend'
import CustomCheckLink from './customModalUtils/CustomCheckLink'
import CustomMultipleImagesInput from './inputs/CustomMultipleImagesInput'
import CustomDocumentInput from './inputs/CustomDocumentInput'
import { Controller, useForm } from 'react-hook-form'
import softlyIncludes from '../../functions/search/softlyIncludes'
import CustomEmiratesIDInput from './inputs/CustomEmiratesIdInput'
import AuthStepper from '../../components/shared/auth/stepper/AuthStepper'
import LinkToLogin from './customModalUtils/LinkToLogin'
import CustomInputCode from './inputs/inputCode/CustomInputCode'
import { dataFetchingFinish, dataFetchingStart, setResendInProcess } from '../../store/slices/modalSlice'
import { DocumentSegmented } from './segmented/DocumentSegmented'
import { USER_AREAS_EXTEND_COUNT, USER_ID_BACK_ID_LOAD, USER_ID_FRONT_ID_KEY, USER_ID_KEY, scanningInstructions } from '../../urls'
import CustomMarkdownRenderer from './customModalUtils/CustomMarkdownRenderer'
import AgentAdvertiseSelect from '../../components/agentModule/advertise/AgentAdvertiseSelect'
import DescriptionAreasExtend from './selects/DescriptionAreasExtend'
import DescriptionScanQR from './customModalForView/descriptions/DescriptionScanQR'
import { swalAttentionLeave, swalError, swalSuccess } from '../../functions/swalOptions'
import { type IResponseObjectJSON } from '../../types/userTypes/TAuth'
import DescriptionCreateAdLinks from './customModalForView/descriptions/DescriptionCreateAdLinks'
import CustomSingleFileInput from './inputs/CustomSingleFileInput'
import CustomTimeRangeInput from './inputs/CustomTimeRangesInput'
import CustomDynamicStatuses from './selects/CustomDynamicStatuses'
import ScanTitleDeedForAgentEditUnit from './inputs/ScanTitleDeedForAgentCreateUnit'

import AgentBroadcastSelect from '../../components/agentModule/broadcast/AgentBroadcastSelect'
// import CustomModalUnitInfo from './CustomModalUnitInfo'
import SupportInModal from './supportInModal/SupportInModal'
import CustomModalUnitInfo from './customModalUtils/CustomModalUnitInfo'
import ViewingActionsForOtherAgents from '../../components/agentModule/componentsViewings/ViewingActionsForOtherAgents'
import StateTypeOffer from '../../components/agentModule/componentsOffers/StateTypeOffer'
import StateTypeOfferLast from '../../components/agentModule/componentsOffers/StateTypeOfferLast'
import GroupInput from './inputs/GroupInput'
import AreaInput from './inputs/AreaInput'
import UnitDescriptonModal from '../../components/agentModule/componentsOffers/UnitDescriptonModal'
import OfferActionsForOtherAgents from '../../components/agentModule/componentsOffers/OfferActionsForOtherAgents'
import CustomSwitch from './inputs/CustomSwitch'
import DataPickerForAuth from './inputs/DataPickerForAuth'
import AdditionalUnitFields from './customModalUtils/AdditionalUnitFields'
import VerificationUnitByTitleDeed from './inputs/VerificationUnitByTitleDeed'
import StateMarketingTypeUnit from '../../components/agentModule/componentsUnits/StateMarketingTypeUnit'
import AgentToBSOOfferClientInfo
    from '../../components/agentModule/componentsOffers/agentToBSO/AgentToBSOOfferClientInfo'
import AgentToBSOOfferOfferInfo
    from '../../components/agentModule/componentsOffers/agentToBSO/AgentToBSOOfferOfferInfo'
import CustomMultipleFilesInput from './inputs/CustomMultipleFilesInput'
import StateTypeUnit from '../../components/agentModule/componentsUnits/StateTypeUnit'
import AdditionalPropertyFields from './customModalUtils/AdditionalPropertyFields'
import AgentCreateBroadcastInstruction from '../../components/agentModule/broadcast/AgentCreateBroadcastInstruction'
import AgentToBSOOfferAdditionalInfo
    from "../../components/agentModule/componentsOffers/agentToBSO/AgentToBSOOfferAdditionalInfo";
import ProfileDetailsShowList from './extraDetails/ProfileDetailsShowList'
import PathHowCreateUnit from '../../components/agentModule/componentsUnits/PathHowCreateUnit'
import AreaInputLinks from './inputs/AreaInputLinks'
import SwitchPaymentType from '../../components/agentModule/componentsOffers/SwitchPaymentType'
import DownloadPaySlip from './extraDetails/DownloadPaySlip'
import StateTypeContract from '../../components/agentModule/componentsOffers/StateTypeContract'
import { deleteData } from '../../store/actionsCreators/userActions/deleteUserAction'
import StateClientData from '../../components/agentModule/componentsOffers/agentToBSO/StateClientData'
import StateNegotiableOffer from '../../components/agentModule/componentsOffers/agentToBSO/StateNegotiableOffer'

interface IProps {
    register?: any
    filteredElements?: any
    modal?: any
    handleSubmit?: any
    onSubmit?: any
    isSubmitted?: any
    errors?: any
    password?: any
    passwordConfirm?: any
    handleInputChange?: any
    isAuth?: any
    control?: any
    unitID?: any
    objectID?: any
    clearErrors?: any
    getValues?: any
    setValue?: any
    trigger?: any
    setError?: any
}

export default function CustomFieldsHook({ clearErrors, isAuth, control, unitID, objectID, handleInputChange, filteredElements, modal, register, handleSubmit, onSubmit, isSubmitted, errors, password, passwordConfirm, setValue, trigger, getValues, setError }: IProps): JSX.Element {
    const loading = useAppSelector(state => state.auth.loading)
    const currentUser = useAppSelector(state => state.user.currentUser)
    const resendInProcess = useAppSelector((state) => state.modal.resendInProcess)
    const service = useAppSelector((state) => state.service)
    const userNUMBER = store.getState().auth.userNUMBER
    const userEMAIL = store.getState().auth.userEMAIL
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const storageCountAreasExtend = window.localStorage.getItem(USER_AREAS_EXTEND_COUNT)
    let unitArea: null | string = null
    const [chooseExtAreaIdUnit, countAreasExtend] = storageCountAreasExtend
        ? (typeof storageCountAreasExtend === 'string' ? storageCountAreasExtend.split(' - ') : [undefined, '3'])
        : [undefined, '3']
    if (chooseExtAreaIdUnit && Number(chooseExtAreaIdUnit) === Number(unitID)) {
        const units = store.getState().units?.units?.filter((unit) => unit.id === Number(unitID))
        unitArea = units?.[0]?.attributes?.Property?.data?.attributes?.Area ?? null
    }

    const recentHandler = async (footerRow: any) => {
        dispatch(setResendInProcess(true))
        let callback: IResponseObjectJSON
        footerRow.content?.[1] === 'email'
            ? callback = await dispatch(verifyResetPasswordEmail({ renew: footerRow.renew }))
            : callback = await dispatch(verifyResetPasswordPhone({ renew: footerRow.renew }))

        if (callback.isSuccessful) {
            void swalSuccess(callback.textNotification, () => {
                dispatch(setResendInProcess(false))
            })
        } else {
            void swalError(callback.textNotification, () => {
                dispatch(setResendInProcess(false))
            })
        }
    }

    //  console.log(form, "form")

    return (

        <>
            {modal?.[0]?.form?.map((row: any, i: number) => (
                <div

                    key={`key_${row.id}_${i}`}
                    id={row.id} /* id to manipulate element */
                    /** For the power attorney page. Toggles in SetSwitch component */
                    className={
                        (row.visual !== undefined && (row.visual === 1) !== (service.switchUnit)) || row.displayNone === true
                            ? 'd-none'
                            : ''
                    }
                    style={row?.defaultHidden === true ? { visibility: 'hidden', position: 'absolute' } : {}}
                >
                    {row.element === 'loader' && (
                        <Loader />
                    )}
                    {row.element === 'header' && (
                        <div className="text-center py-2 my-2 " >
                            <h1 className="text-dark fs-1 mb-4">
                                {row.content?.[0]}
                            </h1>
                        </div>
                    )}
                    {row.element === 'steps' && (
                        <AuthStepper stepTitle={row.title} stepDescription={row.description} step={row.step} countSteps={row?.countSteps} isShowHint={row?.isShowHint} />
                    )}

                    {row.id === 'dynamicStatuses' && (
                        <div className="text-center py-2 my-2">
                            <CustomDynamicStatuses setValue={setValue} row={row} register={register} errors={errors} control={control} clearErrors={clearErrors} />
                        </div>
                    )}

                    {row.id === 'show_add_unit_options' && (
                        <div className="text-center py-2 my-2">
                            <AdditionalUnitFields setValue={setValue} row={row} register={register} errors={errors} control={control} clearErrors={clearErrors} />
                        </div>
                    )}
                    {row.id === 'show_add_property_options' && (
                        <div className="text-center py-2 my-2">
                            <AdditionalPropertyFields setValue={setValue} row={row} register={register} errors={errors} control={control} clearErrors={clearErrors} />
                        </div>
                    )}
                    {row.id === 'segment_doc_type' && (
                        <div className="text-center py-2 my-2">
                            <DocumentSegmented type={row?.type} redirect={row.redirect} />
                        </div>
                    )}
                    {row.id === 'unit_carousel_images' && (
                        <div className="text-center py-2 my-2">
                            <CustomModalUnitInfo unitID={Number(unitID)} />
                        </div>
                    )}

                    {row.id === 'custom_input_code' && (
                        <CustomInputCode />
                    )}

                    {row.element === 'header-phone' && (
                        <div className="text-center py-2 my-2">
                            <div
                                className="text-dark m-0 fw-bold fs-4"
                            >
                                Enter the verification code we sent to <span className={'text-primary'}> {userNUMBER}</span> via SMS to verify your details
                            </div>
                        </div>
                    )}

                    {row.element === 'header-mail' && (
                        <div className="text-center py-2 my-2">
                            <div
                                className="text-dark m-0 fw-bold fs-4"
                            >
                                Enter the verification code we sent to <span className={'text-primary'}> {userEMAIL}</span> via Email to verify your details
                            </div>
                        </div>

                    )}
                    {row.element === 'progress-emirates-front' && (
                        <div className="d-flex align-items-center mb-2">
                            <div className={'flex-grow-1 bg-secondary bg-active-bso active rounded h-5px me-2 !important'}></div>
                            <div className={'flex-grow-1 bg-secondary rounded h-5px me-2'}></div>
                        </div>
                    )}
                    {row.element === 'progress-emirates-back' && (
                        <div className="d-flex align-items-center mb-2">
                            <div className={'flex-grow-1 bg-secondary bg-active-bso active rounded h-5px me-2 !important'}></div>
                            <div className={'flex-grow-1 bg-secondary bg-active-bso active rounded h-5px me-2 !important'}></div>
                        </div>
                    )}
                    {row.element === 'header-link' && (
                        <div className="text-center py-2 my-2">
                            <div className=" fw-semi-bold">
                                {row.content?.[0]}
                                <span className="d-inline-block position-relative ms-1">
                                    {row.id === 'first_scan_way' && modal[0].id === 'write_yourself_fields'
                                        ? <div className="text-decoration-none" onClick={() => { isShure(navigate, row.content?.[2]) }}>
                                            <div className="d-inline-block link-dark fw-bold">
                                                {row.content?.[1]}
                                            </div>
                                            <span className="d-inline-block position-absolute h-2px bottom-0 end-0 start-0 bg-bso translate rounded"></span>
                                        </div>

                                        : <Link to={row.content?.[2]} className="text-decoration-none">
                                            <div
                                                className="d-inline-block link-dark fw-bold"
                                            >
                                                {row.content?.[1]}
                                            </div>
                                            <span className="d-inline-block position-absolute h-2px bottom-0 end-0 start-0 bg-bso translate rounded"></span>
                                        </Link>}

                                </span>
                            </div>
                        </div>
                    )}
                    {row.element === 'header-onclick' && (
                        <div className="text-center  py-2 my-2">
                            <div className="fw-semi-bold">
                                {row.content?.[0]}
                                <span className="d-inline-block position-relative ms-1">

                                    {loading
                                        ? <div

                                            // onClick={() => { console.log('CLICK') }}
                                            className="d-inline-block link-dark fw-bold"
                                        >
                                            {row.content?.[1]}
                                        </div>
                                        : <div
                                            onClick={() => {
                                                row.content?.[2] === 'email' ? dispatch(verifyResetPasswordEmail({ renew: row.renew })) : dispatch(verifyResetPasswordPhone({ renew: row.renew }))
                                            }}
                                            // onClick={() => { console.log('CLICK') }}
                                            className="d-inline-block link-dark fw-bold"
                                        >
                                            {row.content?.[1]}
                                        </div>
                                    }
                                    <span className="d-inline-block position-absolute h-2px bottom-0 end-0 start-0 bg-bso translate rounded"></span>
                                </span>
                            </div>
                        </div>
                    )}
                    {row.element === 'label-primary' && (
                        <div className="text-center py-4">
                            <h1 className="text-dark m-0 fs-3">
                                {row.content?.[0]}
                            </h1>
                        </div>
                    )}
                    {(row.element === 'label-secondary') && (row.content?.[1] != null) && (
                        <div className="d-flex flex-row justify-content-between items-center mb-2 m-0 p-0 w-100">
                            <label className="form-label fw-bold text-dark m-0">{row.content?.[0]}</label>
                            <div className="fw-normal text-dark mb-2" style={row.preLine
                                ? {
                                    whiteSpace: 'pre-line'
                                }
                                : {}}>
                                {row.content?.[1]} {row.content?.[2]}
                            </div>
                        </div>
                    )}
                    {(row.element === 'label-secondary-html') && (row.content?.[0] != null) && (
                        <div className="d-flex flex-row justify-content-between items-center mb-2 m-0 p-0 w-100">
                            <div className="fw-normal text-dark mb-2" style={row.preLine
                                ? {
                                    whiteSpace: 'pre-line'
                                }
                                : {}}>
                                <div dangerouslySetInnerHTML={{
                                    __html: row.content[0]
                                }} />
                            </div>
                        </div>
                    )}
                    {row.element === 'document-scan-input' && (
                        <CustomEmiratesIDInput
                            row={row}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            register={register}
                            type={row.id === 'title_deed' ? 'Title Deed' : undefined}
                        />
                    )}

                    {row.element === 'input' && (
                        <div className="fv-row mb-2 m-0 p-0 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={row} />
                            {/* <MyInput /> */}
                            <div className={'d-flex gap-4'}>
                                <input
                                    {...register(row.id, {
                                        required: row.required === true ? `Field "${row.content?.[0]}" is required` : undefined,
                                        // minLength: row.minLength != null ? row.minLength : 2,ß
                                        maxLength: row.maxLength != null ? row.maxLength : 256,
                                        validate: (value: string) => {
                                            let result: boolean = true
                                            if (row.regexp != null) {
                                                result = row.required ? new RegExp(row.regexp).test(value) : new RegExp(row.regexp).test(value) || value === ''
                                            }
                                            if (row.dateBeforeToday === true) {
                                                const splitDate = value.split('/')
                                                const date = new Date(+splitDate[0] < 30 ? +splitDate[0] + 2000 : +splitDate[0], +splitDate[1] - 1, +splitDate[2])
                                                result = result && date.getTime() < new Date().getTime()
                                            }
                                            if (row.dateAfterToday === true) {
                                                const splitDate = value.split('/')
                                                const date = new Date(+splitDate[0] < 30 ? +splitDate[0] + 2000 : +splitDate[0], +splitDate[1] - 1, +splitDate[2])
                                                result = result && date.getTime() >= new Date().getTime()
                                            }
                                            if (row.validateEquals != null) {
                                                const target = store.getState().modal.scannedData[row.validateEquals[0]]
                                                const accuracy = row.validateEquals[2] ?? 0
                                                if (target == null) {
                                                    result = true
                                                } else {
                                                    result = softlyIncludes({ target, value, accuracy })
                                                }
                                            }
                                            return result
                                        }
                                    })}
                                    className="form-control form-control-solid form-control-lg"
                                    placeholder={row.content?.[1]}
                                    defaultValue={row.defaultValue}
                                    onInput={row.onInput != null ? row.onInput : () => { }}
                                    type="text"
                                    autoComplete="off"
                                />
                                {row.onRemove != null
                                    ? <i className="ki-duotone ki-cross-square fs-5x"
                                        onClick={row.onRemove}
                                    >
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>

                                    : <></>
                                }
                                {
                                    row.onAdd != null
                                        ? <i className="ki-duotone ki-plus-square fs-5x"
                                            onClick={row.onAdd}
                                        >
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                            <span className="path3"></span>
                                            <span className="path4"></span>
                                        </i>
                                        : <></>
                                }
                            </div>
                            {
                                _.get(errors, row.id) != null && (
                                    _.get(errors, row.id).type === 'minLength'
                                        ? <div className="text-danger">{`${row.content?.[0]} can not be less than 2 characters `} </div>
                                        : _.get(errors, row.id).type === 'maxLength'
                                            ? <div className="text-danger">{`${row.content?.[0]} cannot exceed ${row.maxLength ? row.maxLength : 256} characters more `} </div>
                                            : _.get(errors, row.id).type === 'validate'
                                                ? <div className="text-danger">{row.validateEquals?.[1] ?? `${row.content?.[0]} must be valid`} </div>
                                                : <div className="text-danger">{_.get(errors, row.id).message}</div>
                                )
                            }

                        </div>
                    )}
                    {
                        row.element === 'input-range' && (
                            <CustomRangeInput
                                row={row}
                                control={control}
                                register={register}
                                errors={errors}
                            />
                        )
                    }
                    {
                        row.element === 'check-link' && (
                            <CustomCheckLink
                                row={row}
                                control={control}
                                register={register}
                                errors={errors}
                                getValues={getValues}
                                setValue={setValue}
                            />
                        )
                    }
                    {row.element === 'textarea' && (
                        row.typeArea === "forLinks" ?
                            <AreaInputLinks
                                register={register}
                                errors={errors}
                                row={row} clearErrors={clearErrors} setError={setError} /> :
                            <AreaInput
                                register={register}
                                errors={errors}
                                row={row} />
                    )}
                    {row.element === 'input-code' && (
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={row} />
                            <input
                                {...register(row.id, {
                                    required: 'OTP is required',
                                    pattern: {
                                        value: /^\d+$/,
                                        message: 'Only numeric characters are allowed'
                                    },
                                    minLength: 6, maxLength: 6
                                })}
                                className="form-control form-control-solid form-control-lg"
                                placeholder={row.content?.[1]}
                                name={row.id}
                                defaultValue={row.content[2]}
                                type="tel"
                            />
                            {errors[row.id] && (
                                <div className="text-danger">{errors[row.id].message}</div>
                            )}
                            {
                                errors[row.id] && errors[row.id].type === 'minLength' && (
                                    <div className="text-danger">{'OTP cannot be less than 6 digits'} </div>
                                )
                            }
                            {
                                errors[row.id] && errors[row.id].type === 'maxLength' && (
                                    <div className="text-danger">{'OTP cannot exceed 6 digits more '} </div>
                                )
                            }
                            {
                                errors[row.id] && errors[row.id].type === 'pattern' && (
                                    <div className="text-danger">{'Valid OTP is incorrect'} </div>
                                )
                            }

                        </div>
                    )}
                    {row.element === 'input-date' && modal[0].id !== 'agent_set_viewing_modal' && (
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={row} />
                            <div>
                                {modal[0].id === 'write_yourself_fields'
                                    ? <DataPickerForAuth control={control} register={register} row={row} clearErrors={clearErrors} />
                                    : <MyDataPicker control={control} register={register} row={row} setValue={setValue} />
                                }
                            </div>
                            {_.get(errors, row.id) != null && (
                                _.get(errors, row.id).type === 'validate'
                                    ? <div className="text-danger">{
                                        modal[0].id === 'write_yourself_fields'
                                            ? 'You must be 18 years or older'
                                            : 'Entered date is invalid for this field'
                                    } </div>
                                    // ${row.content?.[0]} must be at least ${row.yearsBeforeToday} years ago
                                    : <div className="text-danger">{_.get(errors, row.id).message}</div>
                            )}

                        </div>
                    )}

                    {row.element === 'input-email' && (
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={row} />
                            <input
                                {...register(row.id, { required: `Field "${row.content?.[0]}" is required`, pattern: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/g })}
                                className="form-control form-control-solid form-control-lg"
                                placeholder={row.content?.[1]}
                                type="email"
                                name={row.id}
                                autoComplete={row.autocomplete ? row.autocomplete : 'off'}

                            // defaultValue={} // userEMAIL || currentUser?.email || ''
                            //    disabled={modal[0].id === 'my_profile_reset_password_way_email'}
                            //     value={modal[0].id === 'my_profile_reset_password_way_email' ? userEMAIL ? currentUser?.email : undefined : undefined}
                            />
                            {errors[row.id] && (
                                <div className="text-danger">{errors[row.id].message}</div>
                            )}
                            {
                                errors[row.id] && errors[row.id].type === 'pattern' && (
                                    <div className="text-danger">{'Enter a valid email '} </div>
                                )
                            }
                        </div>
                    )}
                    {row.element === 'input-phone' && (
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={row} />
                            <input
                                {...register(row.id, { required: `Field "${row.content?.[0]}" is required`, pattern: /^([+])([0-9]{1,3})([0-9]{9,10})$/g })}
                                className="form-control form-control-solid form-control-lg"
                                type="tel"
                                placeholder={row.content?.[1]}
                                name={row.id}
                                autoComplete="off"
                            //  defaultValue={userNUMBER || currentUser?.phone || undefined}
                            //    disabled={modal[0].id === 'my_profile_reset_password_way_phone'}
                            //  value={modal[0].id === 'my_profile_reset_password_way_phone' ? userNUMBER ? currentUser?.phone : undefined : undefined}

                            />
                            {errors[row.id] && (
                                <div className="text-danger">{errors[row.id].message}</div>
                            )}
                            {
                                errors[row.id] && errors[row.id].type === 'pattern' && (
                                    <div className="text-danger">{`Mobile format is incorrect`} </div>
                                )
                            }

                        </div>
                    )}
                    {row.element === 'input-password' && (
                        <div
                            className="fv-row my-2 py-2 w-100 fv-plugins-icon-container"
                        >
                            <CustomFormLable row={row} />
                            <PasswordMeterHook password={password} control={control} register={register} row={row} id={row.id} errors={errors} isSubmitted={isSubmitted} modal={modal} />

                        </div>

                    )}
                    {row.element === 'input-password-confirm' && (
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={row} />
                            <input
                                {...register(row.id, { required: `Field "${row.content?.[0]}" is required` })}
                                className="form-control form-control-solid form-control-lg"
                                type="password"
                                placeholder={row.content?.[1]}
                                name={row.id}
                            />
                            {errors[row.id] && (
                                <div className="text-danger">{errors[row.id].message}</div>
                            )}
                        </div>
                    )}
                    {(row.element === 'input-file') && (
                        <CustomSingleFileInput row={row} errors={errors} register={register} getValues={getValues} />
                    )}
                    {(row.element === 'input-document') && (
                        <CustomDocumentInput row={row} control={control} errors={errors} />
                    )}
                    {(row.id === 'imagePaceholder') && (
                        row.element === 'id_back'
                            ? <div className='p-0 w-100 '>
                                <img
                                    className="w-100 h-350px object-fit-contain mb-2 rounded-0 rounded-bottom " // opacity-75
                                    src={scanningInstructions.EID_Back}
                                    alt="cover_emirates_id"
                                />
                                <div className='d-flex justify-content-center'>
                                    <button
                                        type='button'
                                        onClick={async () => {
                                            void swalAttentionLeave("The Emirates ID will be reloaded. Are you sure you want to continue?").then(async (result: any) => {
                                                if (result.isConfirmed) {
                                                    dispatch(dataFetchingStart())
                                                    await deleteData('documents', Number(window.localStorage.getItem(USER_ID_FRONT_ID_KEY)))
                                                    window.localStorage.removeItem(USER_ID_BACK_ID_LOAD)
                                                    window.localStorage.removeItem(USER_ID_FRONT_ID_KEY)
                                                    dispatch(dataFetchingFinish())



                                                    navigate(
                                                        currentUser?.tutorialCompleted ?
                                                            '/auth/register_scan_emirates'
                                                            :
                                                            modal[0].id === 'update_emirates_back_together'
                                                                ? '/agent/modal/update_emirates_front_together/INagentMODULE/to_choose_subscription' : `/agent/modal/update_emirates_front/not/${userID}`)
                                                }
                                            })
                                        }}

                                        className={`btn btn-sm btn-secondary lh-sm w-50 text-uppercase fw-bold my-4 me-2${loading ? ' disabled' : ''}`}>
                                        <span className="indicator-label">
                                            Back in reload front side
                                        </span>
                                    </button>
                                </div>
                            </div>
                            : row.element === 'visa'
                                ? <div className='p-0 w-100 '>
                                    <img
                                        className="w-100 h-350px object-fit-contain mb-2 rounded-0 rounded-bottom "
                                        src={scanningInstructions.Passport}
                                        alt="cover_visa"
                                    />
                                </div>
                                : row.element === 'rera'
                                    ? <div className='p-0 w-100 '>
                                        <img
                                            className="w-100 h-350px object-fit-contain mb-2 rounded-0 rounded-bottom "
                                            src={scanningInstructions.RERA}
                                            alt="cover_rera"
                                        />
                                    </div>
                                    : row.element === 'id_front'
                                        ? <div className='p-0 w-100 '>
                                            <img
                                                className="w-100 h-350px object-fit-contain mb-2 rounded-0 rounded-bottom "
                                                src={scanningInstructions.EID_Front}
                                                alt="cover_id_front"
                                            />
                                        </div>
                                        : row.element === 'scan_qr'
                                            ? <div className='p-0 w-100 '>
                                                <DescriptionScanQR />
                                            </div>
                                            : null
                    )}
                    {(row.element === 'separator') && (
                        <div className="fv-row mb-2 m-0 p-0 w-100">
                            <div className="separator border border-bso border-3 mx-n7 p-0 my-8"></div>
                        </div>
                    )}
                    {(row.element === 'input-file-multiple') && (
                        <CustomFileInput
                            register={register}
                            control={control}
                            errors={errors}
                            row={row}
                            getValues={getValues}
                        />
                    )}
                    {
                        (row.element === 'input-images-multiple') && (
                            <CustomMultipleImagesInput
                                register={register}
                                control={control}
                                errors={errors}
                                row={row}
                                getValues={getValues}
                            />
                        )
                    }
                    {
                        (row.element === 'input-files-multiple') && (
                            <CustomMultipleFilesInput
                                control={control}
                                errors={errors}
                                row={row}
                                getValues={getValues}
                            />
                        )
                    }
                    {
                        row.id === 'advertise_create_start_date' && (
                            <>
                                <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                    <CustomFormLable row={row} />
                                    <MyDataPicker control={control} register={register} row={row} setValue={setValue} isBlockPast={true} />
                                </div>
                                {errors[row.id] && (
                                    <div className="text-danger">{errors[row.id].message}</div>
                                )}
                            </>
                        )

                    }
                    {(row.element === 'input-time-ranges') && (
                        <CustomTimeRangeInput
                            row={row}
                            errors={errors}
                        />
                    )}
                    {(row.element === 'input-date-time') && (
                        <>
                            <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                <ViewingSelectTime setValue={setValue} trigger={trigger} control={control} register={register} row={row} unitID={unitID} viewingID={objectID} errors={errors} clearErrors={clearErrors} modal={modal} />
                            </div>

                        </>
                    )}
                    {(row.id === 'description_unit_modal') && (
                        <>
                            <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                <UnitDescriptonModal unitID={unitID} />
                            </div>
                        </>
                    )}
                    {(row.id === 'UnitType') && (
                        <>
                            <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                <StateTypeUnit unitID={unitID} setValue={setValue} errors={errors} register={register} control={control} />
                            </div>
                        </>
                    )}
                    {/* draft create */}
                    {(row.id === 'CreateUnitType') && (
                        <>
                            <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                <PathHowCreateUnit />
                            </div>
                        </>
                    )}

                    {(row.id === 'state_offer_type_for_agent_to_agent') && (
                        <>
                            <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                <StateTypeOffer setValue={setValue} errors={errors} register={register} control={control} />
                            </div>

                        </>
                    )}
                    {(row.id === 'state_offer_type_for_agent_to_agent_last') && (
                        <>
                            <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                <StateTypeOfferLast setValue={setValue} errors={errors} register={register} control={control} />
                            </div>
                        </>
                    )}
                    {(row.id === 'negotiable_state') && (
                        <>
                            <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                <StateNegotiableOffer setValue={setValue} errors={errors} register={register} control={control} objectID={objectID} />
                            </div>
                        </>
                    )}

                    {(row.id === 'marketing_type_unit') && (
                        <>
                            <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                <StateMarketingTypeUnit setValue={setValue} errors={errors} register={register} control={control} />
                            </div>
                        </>
                    )}
                    {(row.element === 'input-date-time-range') && (
                        <>
                            <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                <CustomFormLable row={row} />
                                <PickUpKeysTimeSelect objectID={objectID} setValue={setValue} trigger={trigger} control={control} register={register} row={row} unitID={unitID} viewingID={objectID} errors={errors} clearErrors={clearErrors} modal={modal} />
                            </div>
                            {_.get(errors, row.id) != null && (
                                <div className="text-danger">{_.get(errors, row.id).message}</div>
                            )}
                        </>
                    )}
                    {row.element === 'advertise_create_duration' &&
                        <div className="fv-row mb-2 m-0 p-0 mb-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={row} />
                            <AgentAdvertiseSelect setValue={setValue} trigger={trigger} control={control} register={register} row={row} unitID={unitID} viewingID={objectID} errors={errors} clearErrors={clearErrors} modal={modal} />
                        </div>
                    }
                    {row.element === 'broadcast_create_duration' &&
                        <div className="fv-row mb-2 m-0 p-0 mb-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={row} />
                            <AgentBroadcastSelect setValue={setValue} trigger={trigger} control={control} register={register} row={row} unitID={unitID} viewingID={objectID} errors={errors} clearErrors={clearErrors} modal={modal} />
                        </div>
                    }
                    {row.id === 'broadcast_create_instruction' &&
                        <div className="fv-row mb-2 m-0 p-0 mb-2 w-100 fv-plugins-icon-container">
                            <AgentCreateBroadcastInstruction unitID={unitID} modal={modal} />
                        </div>
                    }

                    {row.id === 'viewing_actions_from_agent' &&
                        <ViewingActionsForOtherAgents />
                    }
                    {row.id === 'offer_actions_from_agent' &&
                        <OfferActionsForOtherAgents />
                    }

                    {row.id === 'verification_unit_by_title_deed' && (
                        <VerificationUnitByTitleDeed control={control} setValue={setValue} register={register} errors={errors} row={row} />
                    )}
                    {row.id === 'agent_edit_unit_title_deed' && (
                        <ScanTitleDeedForAgentEditUnit row={row} errors={errors} />
                    )}

                    {row.id === 'show_profile_details' && (
                        <ProfileDetailsShowList />
                    )}

                    {
                        row.element === 'advertise_create_links' &&
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <DescriptionCreateAdLinks />
                            <CustomFormLable row={row} />
                            <AgentAdvertiseCreateLinks form={form} setValue={setValue} trigger={trigger} control={control} register={register} row={row} unitID={unitID} objectID={objectID} errors={errors} clearErrors={clearErrors} modal={modal} />
                            {_.get(errors, row.id) != null && (
                                <div className="text-danger">{_.get(errors, row.id).message}</div>
                            )}
                        </div>
                    }

                    {row.element === 'select' && (
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            {row.id === 'locations' && row.id !== 'locations_extension'

                                ? <>
                                    {modal[0].id === 'agent_settings_for_subscriptions'
                                        ? <DescriptionAreasExtend countAreasExtend={getSubsDetails(objectID).areas} unitArea={unitArea} />
                                        : null}
                                    <CustomFormLable row={row} description={`${getSubsDetails(objectID).areas} districts are available on subscription'`} />
                                    <CustomSelectAreas currentUser={currentUser} control={control} register={register} data={row} maxAreas={getSubsDetails(objectID).areas} />
                                    {_.get(errors, row.id) != null && (
                                        <div className="text-danger my-2">{_.get(errors, row.id).message}</div>
                                    )}
                                </>
                                : row.id !== 'locations_extension'
                                    ? <>
                                        <CustomSelect control={control} register={register} data={row} setValue={setValue} errors={errors} />

                                    </>
                                    : null
                            }

                            {row.id === 'locations_extension' && modal[0].id === 'agent_settings_for_areas_extension'
                                ? <>
                                    <DescriptionAreasExtend countAreasExtend={countAreasExtend} unitArea={unitArea} />
                                    <CustomFormLable row={row} description={` Only ${currentUser.AreasExtentionAmount ?? countAreasExtend ?? 0} area can be added to your list`} />
                                    <CustomSelectAreasExtend currentUser={currentUser} control={control} register={register} data={row} unitID={unitID} />
                                    {_.get(errors, row.id) != null && (
                                        <div className="text-danger my-2">{_.get(errors, row.id).message}</div>
                                    )}
                                    <div className="h-150px" />
                                </>
                                : null}

                        </div>
                    )}

                    {row.element === 'inline-select' && (
                        <>
                            <CustomFormLable row={row} />
                            <CustomInlineSelect control={control} register={register} data={row} />
                        </>
                    )}

                    {row.element === 'select-add' && (
                        <CustomSelectAdd row={row}
                            errors={errors}
                            register={register}
                            control={control}
                        />
                    )}
                    {row.element === 'input-switch' && (
                        <CustomSwitch row={row}
                            errors={errors}
                            register={register}
                            control={control}
                        />
                    )}
                    {row.element === 'input-checkbox' && (
                        <div className="fv-row my-2 py-2  w-100  ">
                            <div className='d-flex align-items-center'>
                                <label className="form-check form-check-solid form-check-inline">
                                    <input
                                        {...register(row.id, { required: 'Please accept Terms & Conditions and Privacy Policy to proceed' })} // `${row.content?.[1]} and ${row.content?.[2]}`
                                        className="form-check-input h-25px w-25px pt-2"
                                        type="checkbox"
                                        name={row.id}
                                    />
                                </label>
                                <span className="fw-semi-bold fs-5 py-2 my-2">
                                    {row.content[0]}
                                    <RulesModals row={row.content[1]} />
                                    {'  '}and{'  '}
                                    <RulesModals row={row.content[2]} />
                                </span>
                            </div>
                            {/* <label className="form-check form-check-solid form-check-inline mt-2">
                            </label> */}
                            {errors[row.id] && (
                                <div className="text-danger">{errors[row.id].message}</div>
                            )}
                        </div>
                    )}

                    {(row.element === 'input-group') && (
                        <GroupInput register={register} errors={errors} row={row} />

                    )}
                    {(row.element === 'label-text-1') && (
                        <div className="fv-row my-2 py-2 w-100">
                            <p className="py-2"><span className="align-items-center  text-dark fs-6 ms-0">{row.content?.[0]}</span>
                                <span className="align-items-center text-dark fs-6 fw-bold ms-0">{row.content?.[1]}</span>
                                <span className="align-items-center text-dark fs-6  ms-0">{row.content?.[2]}</span>
                            </p>
                        </div>
                    )}
                    {(row.element === 'label-text-2') && (
                        <div className="fv-row my-2 py-2w-100">
                            <span className="d-flex align-items-center text-start text-primary fs-3 fw-bold ms-0">{row.content?.[0]}</span>
                        </div>
                    )}
                    {(row.element === 'label-text-3') && (
                        <div className="fv-row my-2 py-2 w-100">
                            <div className="d-flex pe-2 my-2 align-items-center">
                                <i className={`ki-outline ${row.content?.[1]} fs-1 pe-2`} />
                                <span className="align-items-center  text-dark fs-6 ms-0">{row.content?.[0]}</span></div>
                        </div>// bi-arrow-left-right
                    )}
                    {(row.element === 'label-text-4') && (
                        <div className="fv-row my-2 py-2 w-100">
                            <div className="d-flex pe-2 align-items-center">
                                {
                                    typeof row.content?.[1] === 'string'
                                        ? <img className="h-25px" src={row.content?.[1]} />
                                        : row.content?.[1] || <></>
                                }

                                <span className="align-items-center  text-dark fs-6 ms-0">{row.content?.[0]}</span>
                            </div>
                        </div>
                    )}
                    {(row.element === 'label-badge') && (
                        <div className="fv-row my-2 py-2 w-100 d-flex flex-wrap gap-2">
                            {
                                row.content?.map((badge: any) => (
                                    <p className={`badge ${badge?.text != null ? 'badge-secondary' : 'badge-danger'} mb-0`} key={nanoid()}>
                                        <span className={`align-items-center ${badge?.text != null ? 'text-dark' : 'text-white'} fs-6 ms-0 me-2`}>{badge?.label}:</span>
                                        <span className={`align-items-center ${badge?.text != null ? 'text-dark' : 'text-white'} fs-6 fw-bold ms-0`}>{badge?.text ?? 'Empty'}</span>
                                    </p>
                                ))
                            }
                        </div>
                    )}
                    {/* agent_unit_filters_modal */}
                    {(row.element === 'label-text-5') && (
                        <div className="fv-row my-2 py-2 w-100 d-flex justify-content-center">
                            <span className="d-flex align-items-center text-start py-3 text-dark fs-3 fw-bold">{row.content?.[0]}</span>
                        </div>
                    )}
                    {(row.element === 'img-background') && (
                        <div data-id={`${modal[0].id}_${row.id}`} className="anime-card rounded-2 text-center m-0 h-200px overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center shadow-sm mb-4 ms-0" style={modal[0].background !== undefined ? { backgroundImage: `url(/assets/media${modal[0].background})` } : {}}>
                        </div>
                    )}
                    {(row.element === 'set-package') && (
                        <div className="fv-row mb-2 m-0 p-0 w-100 fv-plugins-icon-container">
                            <div className="row g-0 d-flex flex-row">
                                {
                                    row.id === 'plan'
                                        ? service.service?.plans?.map((el: any) => (
                                            <div key={el.id}>{
                                                el.show !== false
                                                    ? <SetButton data={el} type="plan" />
                                                    : null
                                            }</div>
                                        ))
                                        : row.id === 'dop'
                                            ? service.service?.dop?.map((el: any) => (
                                                <div key={el.id}>
                                                    <SetButton data={el} type="dop" />
                                                </div>
                                            ))
                                            : row.tbody !== undefined
                                                ? row.tbody?.map((el: any) => (
                                                    <div key={el.id}>
                                                        <SetButton data={el} type="tbody" />
                                                    </div>
                                                ))
                                                : null
                                }
                            </div>
                        </div>
                    )}

                    {(row.element === 'switch') && (
                        <SetSwitch />
                    )}
                    {(row.element === 'table') && (
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <div className="row g-0 d-flex flex-row">
                                {row.id === 'tasks'
                                    ? <>
                                        {service.tasks?.map((el: any) =>
                                            (<div key={el.id}><SetButton data={el} type="task"></SetButton></div>))}
                                        <span data-post-button="add_work_task" className="btn btn-bso">Add Task</span>
                                    </>
                                    : row.id === 'vendors'
                                        ? service.vendors?.map((el: any) => (
                                            <div key={el.id}><SetButton data={el} type="vendor"></SetButton></div>
                                        ))
                                        : row.id === 'tasks2'
                                            ? service.tasks?.map((el: any) => (
                                                <div key={el.id}><SetButton data={el} type="tasks2"></SetButton></div>
                                            ))
                                            : row.id === 'works'
                                                ? service.tasks?.map((el: any) => (
                                                    <div key={el.id}><SetButton data={el} type="works"></SetButton></div>
                                                ))
                                                : null
                                }
                            </div>
                        </div>)}
                    {(row.element === 'slider-images') && (
                        <div className="fv-row my-2 py-2 w-100">
                            <MyCarousel showTwoColumns={false}>
                                {
                                    (row.content ?? []).map((el: string) => (
                                        <div key={nanoid()} className="text-center p-4">
                                            <div className="card-rounded w-100 overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center"
                                                style={{
                                                    backgroundImage: `url(${el})`,
                                                    height: row.height != null ? row.height : '200px'
                                                }}
                                            >
                                                <div className="w-100"
                                                    style={{
                                                        height: row.height != null ? row.height : '200px'
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </MyCarousel>
                        </div>
                    )}
                    {(row.element === 'markdown') && (
                        <CustomMarkdownRenderer row={row} />
                    )}
                    {
                        (row.element === 'range') && (
                            <CustomSlider data={row} control={control} />
                            /* <>
                                <CustomFormLable row={row}/>
                                <Slider range min={1} max={100}/>
                            </> */
                        )
                    }
                    {(row.element === 'action_button') && (
                        <CustomSelectAdd
                            row={row}
                            errors={errors}
                            register={register}
                            control={control}
                            buttonView={true}
                            unitID={unitID}
                            objectID={objectID}
                        />
                    )}


                    {(row.element === 'button') && (
                        <div className="d-flex gap-2 align-items-center fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <div className={'btn btn-bso'}
                                onClick={row?.onClick != null ? row?.onClick : () => { }}
                            >
                                {
                                    row.content[0]
                                }
                            </div>
                        </div>
                    )}
                    {(row.element === 'hidden-input') && (
                        <>
                            <Controller
                                name={row.id}
                                control={control}
                                rules={row.required != null
                                    ? {
                                        required: `${row.content?.[0]}`
                                    }
                                    : {}
                                }
                                render={({ field }) => (
                                    <input type={'text'} {...field} style={{ position: 'absolute', height: '0px', width: '0px', left: '-500px' }} />
                                )}
                            />
                            {
                                _.get(errors, row.id) != null && (<div className="text-danger">{_.get(errors, row.id).message}</div>)
                            }
                        </>
                    )}
                    {(row.element === 'contact-support-button') && (
                        <SupportInModal />
                    )}

                    {(row.id === 'switch_deposit_slip') && (
                        <SwitchPaymentType
                            errors={errors}
                            register={register}
                            offerID={Number(objectID)}
                        />
                    )}
                    {(row.id === 'state_client_data_offer_modal') && (
                        <StateClientData
                            errors={errors}
                            register={register}
                            offerID={Number(objectID)}
                        />
                    )}


                    {(row.id === 'agent_offer_choose_contract_type') && (
                        <StateTypeContract
                            errors={errors}
                            register={register}
                            offerID={Number(objectID)}
                        />
                    )}


                    {(row.id === 'agent_to_bso_offer_client_info') && (
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <AgentToBSOOfferClientInfo
                                setValue={setValue}
                                errors={errors}
                                register={register}
                                control={control}
                                unitID={Number(unitID)}
                                offerID={Number(objectID)}
                            />
                        </div>
                    )}

                    {(row.id === 'agent_to_bso_offer_offer_info') && (
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <AgentToBSOOfferOfferInfo
                                setValue={setValue}
                                errors={errors}
                                register={register}
                                control={control}
                                unitID={Number(unitID)}
                                offerID={Number(objectID)}
                            />
                        </div>
                    )}
                    {(row.id === 'agent_to_bso_offer_additional_info') && (
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <AgentToBSOOfferAdditionalInfo
                                setValue={setValue}
                                errors={errors}
                                register={register}
                                control={control}
                                unitID={Number(unitID)}
                                offerID={Number(objectID)}
                            />
                        </div>
                    )}
                </div >
            ))
            }

            {
                isAuth
                    ? (Boolean(modal[0].footer)) && modal[0].footer.map((footerRow: any, i: number) =>
                    (<>
                        {footerRow.id === 'submit' &&
                            <div key={`${footerRow.id}_${String(i)}`} className="fv-row my-2 py-2 w-100">
                                <button type={'submit'} className={`btn btn-lg btn-bso w-100  me-2${loading ? ' disabled' : ''}`}>
                                    {loading && !resendInProcess
                                        ? <span className="indicator-progress  d-block">
                                            Please wait...
                                            <span className={'spinner-border spinner-border-sm align-middle ms-2'}></span>
                                        </span>
                                        : <span className="indicator-label">
                                            {footerRow.content[0]}
                                        </span>
                                    }
                                </button>
                            </div>
                        }

                        {footerRow.id === 'recent' &&
                            <div key={`${footerRow.id}_${String(i)}`} className="fv-row my-2 py-2 w-100">
                                <button
                                    type='button'
                                    onClick={() => {

                                        void recentHandler(footerRow)
                                    }}

                                    className={`btn btn-lg btn-secondary w-100  me-2${loading ? ' disabled' : ''}`}>
                                    {loading && resendInProcess
                                        ? <span className="indicator-progress  d-block">
                                            Please wait...
                                            <span className={'spinner-border spinner-border-sm align-middle ms-2'}></span>
                                        </span>
                                        : <span className="indicator-label">
                                            {footerRow.content[0]}
                                        </span>
                                    }
                                </button>

                            </div>}

                        {
                            footerRow.element === 'header-link' &&
                            <LinkToLogin row={footerRow} modal={modal} navigate={navigate} />
                        }

                    </>)
                    )

                    : <CustomModalFooter key={nanoid()} objectID={objectID} unitID={unitID} modal={modal} form={form} />
            }

        </>
    )
}



