import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import { fetchUser, fetchUsers, updateUserTokenDevice } from '../../store/actionsCreators/userActions'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { fetchProperties } from '../../store/actionsCreators/propertyActions'
import { fetchDocuments } from '../../store/actionsCreators/documentActions'
import SearchProvider from '../../app/SearchProvider'
import useOrientation from '../../hooks/UseOrientation'
import LandscapePlaceholder from '../shared/landscape-placeholder'
import { getChatList } from '../../store/actionsCreators/chatListActions'
import { fetchViewings } from '../../store/actionsCreators/viewingsActions'
import { fetchUnits } from '../../store/actionsCreators/unitsActions'
import { fetchAppConfig } from '../../store/actionsCreators/appActions'
import { fetchAdvertises } from '../../store/actionsCreators/advertiseActions/fetchAdvertiseAction'
import Loader from '../../app/custom/loaders/Loader'
import asyncQueue from '../../functions/utils/asyncQueue'

import { ACCESS_KEY, USER_ID_KEY } from '../../urls'
import _ from 'lodash'
import { landlordSocket } from '../../functions/socket/landlordSocket'
import { type AppDispatch, type RootState } from '../../store/store'
import { type NavigateFunction } from 'react-router-dom'

import AlertsComponentVerify from '../../AlertsComponentVerify'

import { getAllTasks } from '../../store/actionsCreators/taskActions/getAllTasks'

import useLandlordLoadProcessData from "./useLandlordLoadProcessData";
import { fetchOffers } from '../../store/actionsCreators/offerActions/fetchOffers'
let webSocket: WebSocket | null = null;
let retryCount = 0;
export default function LandlordModule(): JSX.Element {
    let userID = window.localStorage.getItem(USER_ID_KEY)

    const dispatch = useAppDispatch()
    const isUserVerified = useAppSelector((state: RootState) => state.modal.isUserVerified)
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const [dataLoaded, setDataLoaded] = useState(false)
    const { orientation } = useOrientation()
    const socketTimeout = useAppSelector((state: RootState) => state.app.socketTimeout)

    const navigate = useNavigate()
    const initializeWebSocket = (dispatch: AppDispatch, navigate: NavigateFunction): void => {
        if (webSocket != null && (webSocket.readyState === WebSocket.OPEN || webSocket.readyState === WebSocket.CONNECTING)) {
            //  console.log("WebSocket is already open or connecting. No need to create a new one.");
            return;
        } else {
            webSocket?.close()
        }
        setTimeout(() => {
            webSocket = new WebSocket('wss://bso.ae/ws');
            void landlordSocket(webSocket, dispatch, navigate)
            webSocket.onclose = () => {
                //  console.log('WebSocket connection closed. Attempting to reconnect...');
                retryCount++;
                const retryDelay = Math.min(1000 * 2 ** retryCount, 30000);
                setTimeout(() => { initializeWebSocket(dispatch, navigate); }, retryDelay);
            }
            webSocket.onerror = (error) => {
                //   console.error('WebSocket error:', error);
                webSocket?.close();
            };
        }, 1)
    }
    useEffect(() => {
        const currentTime = new Date().getTime();
        const timestampDifference = currentTime - socketTimeout;


        if (timestampDifference > 40000 || timestampDifference < 40000) {
            //  console.log('Timestamp difference too large, reloading WebSocket connection...');
            initializeWebSocket(dispatch, navigate);
        }
    }, [socketTimeout, dispatch, navigate])

    useEffect(() => {
        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        }



        const checkToken = async (dispatch: AppDispatch, navigate: NavigateFunction): Promise<void> => {
            const maxAttempts = 16;
            let attemptCount = 0;
            let currentToken: string | null = null;

            while (attemptCount < maxAttempts && !currentToken) {
                try {
                    currentToken = (window as any).devicetoken || window.localStorage.getItem('devicetoken');
                    if (currentToken) {
                        await dispatch(updateUserTokenDevice(currentToken));
                        initializeWebSocket(dispatch, navigate);
                        break;
                    } else {
                        attemptCount++;
                        await new Promise(resolve => setTimeout(resolve, 500));
                    }
                } catch (error) {
                    console.error('Error updating token:', error);
                    break;
                }
            }
            if (!currentToken) {
                const fallbackToken = `BrowserTokenUnfound${userID}`;
                await dispatch(updateUserTokenDevice(fallbackToken));
                initializeWebSocket(dispatch, navigate);
            }
        };

        void (async () => {
            const jwt = window.localStorage.getItem(ACCESS_KEY)
            userID = window.localStorage.getItem(USER_ID_KEY)

            if (jwt && userID) {
                const results = await asyncQueue([
                    dispatch(fetchUser()),
                    dispatch(fetchViewings()),
                    dispatch(fetchOffers()),
                    dispatch(fetchUnits()),
                    dispatch(fetchDocuments()),
                    dispatch(fetchUsers()),
                    dispatch(fetchProperties()),
                    dispatch(getChatList()),
                    dispatch(fetchAdvertises()),
                    dispatch(fetchAppConfig()),
                    dispatch(getAllTasks())
                ])

                const isLoad = results.every((el: any) => {
                    return el?.isSuccessful === true || el?.success === true
                })

                if (isLoad) {
                    const userAgent = navigator.userAgent
                    const isIPhone = _.includes(userAgent.toLowerCase(), 'iphone')
                    const isAndroid = _.includes(userAgent.toLowerCase(), 'android')

                    if (isAndroid) {
                        try {
                            const { initializeApp } = await import('firebase/app')
                            const firebaseApp = initializeApp(firebaseConfig)
                            const { getMessaging, getToken, onMessage } = await import('firebase/messaging')
                            const messaging = getMessaging(firebaseApp)

                            if (Notification.permission === 'default') {
                                const permission = await Notification.requestPermission()
                                if (permission === 'granted') {
                                    const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
                                    if (currentToken != null) {
                                        //  console.log('FB Token:', currentToken.slice(0, 5) + '...')
                                        await dispatch(updateUserTokenDevice(currentToken))
                                        initializeWebSocket(dispatch, navigate)
                                    } else {
                                        await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                        initializeWebSocket(dispatch, navigate)
                                    }
                                } else if (permission === 'denied') {
                                    await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                    initializeWebSocket(dispatch, navigate)
                                }
                            } else if (Notification.permission === 'granted') {
                                const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
                                if (currentToken != null) {
                                    //   console.log('FB Token:', currentToken.slice(0, 5) + '...')
                                    await dispatch(updateUserTokenDevice(currentToken))
                                    initializeWebSocket(dispatch, navigate)
                                } else {
                                    await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                    initializeWebSocket(dispatch, navigate)
                                }
                            } else if (Notification.permission === 'denied') {
                                await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                initializeWebSocket(dispatch, navigate)
                            }

                            onMessage(messaging, (payload) => {
                                navigator.serviceWorker.ready.then((registration) => {
                                    registration.showNotification(payload.notification?.title as string, {
                                        body: payload.notification?.body
                                    })
                                }).catch((e) => { console.log(e) })
                            })
                        } catch (err) {
                            console.log(err)
                        }
                        setDataLoaded(true)
                    } else if (isIPhone) {
                        setDataLoaded(true)
                        await checkToken(dispatch, navigate)
                    } else {
                        setDataLoaded(true)
                        await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                        initializeWebSocket(dispatch, navigate)
                    }
                } else {
                    setDataLoaded(false)
                    navigate('/auth/login')
                }
            }
        })()
        const body = document.querySelector('body')
        if (body != null) {
            body.setAttribute('style', 'user-select: text !important;')
        }
    }, [])

    // useEffect(() => {
    //     if (dataLoaded) {
    //         // логика по загрузке нужных роутов в лэндлорде аналог  AgentLoadProcessData
    //     }
    // }, [dataLoaded])

    useLandlordLoadProcessData(dataLoaded)




    if (!dataLoaded) {
        return <Loader />
    }

    return (
        <SearchProvider>
            {orientation === 'landscape'
                ? <LandscapePlaceholder />
                : <>
                    {
                        // TODO implement
                        isUserVerified || currentUser?.emailVerified === true
                            ? null
                            : [
                                // CREATE FIRST UNIT
                                // '/landlord/modal/landlord_create_unit',
                                // '/landlord/modal/landlord_create_unit_owners_passport',
                                // '/landlord/modal/landlord_create_unit_correct_owner_info',
                                // '/landlord/modal/landlord_create_unit_poa',
                                // '/landlord/modal/landlord_create_unit_info',
                                // '/landlord/modal/landlord_create_main_info_first',
                                // '/landlord/modal/landlord_create_main_info_second',
                                // '/landlord/modal/landlord_create_unit_additional_info',
                                // '/landlord/modal/landlord_create_unit_additional_info_outdoor',
                                // '/landlord/modal/landlord_create_unit_additional_info_files',
                                '/landlord/modal/v2/landlord_create_unit_choose_role/-/-',
                                '/landlord/modal/v2/landlord_create_unit_representative_auth_method/-/-',
                                '/landlord/modal/v2/landlord_create_unit_owner_passport/-/-',
                                '/landlord/modal/v2/landlord_create_unit_id_verification/-/-',
                                '/landlord/modal/v2/landlord_create_unit_find_owner/-/-',
                                '/landlord/modal/v2/landlord_create_unit_wait_for_owner',
                                '/landlord/modal/v2/landlord_create_unit_upload_title_deed/-/-',
                                '/landlord/modal/v2/landlord_create_unit_property_info/-/-',
                                '/landlord/modal/v2/landlord_create_unit_unit_info/-/-',
                                '/landlord/modal/v2/landlord_create_unit_unit_features/-/-',
                                '/landlord/modal/v2/landlord_create_unit_users_passport/-/-',
                                '/landlord/modal/v2/landlord_create_unit_verify_user_id/-/-',

                                '/landlord/modal/update_passport_together',
                                '/landlord/modal/update_emirates_back_together',
                                '/landlord/modal/update_emirates_front_together',
                                '/auth/register_scan_emirates'
                            ].some((path) => location.pathname.includes(path)) // 'landlord/modal/update_passport_together') || location.pathname.includes('/landlord/modal/update_emirates_back_together') || location.pathname.includes('/landlord/modal/update_emirates_front_together') || location.pathname.includes('/auth/register_scan_emirates')
                                ? null
                                : <AlertsComponentVerify />
                    }
                    <Outlet />
                </>
            }
        </SearchProvider>
    )
}
