
import React, { type ReactElement, useEffect, useState } from 'react'
import { type IViewing } from '../../../../store/slices/viewingsSlice'
import { type IUnitKey, type Unit } from '../../../../store/slices/unitsSlice'
import { SchedulerStatus } from '../../../../functions/agent/date/SchedulerStatus'
import { type TimelineItem } from './ViewingTimeline'
import { Steps } from 'antd'

import Icon from '../../../icon/Icon'
interface IProps {
    viewing: IViewing
    unit: Unit
    unitKey: IUnitKey
    userType: string
    isMyUnit: boolean
}

function TimelineAgentToLandlord({ viewing, unit, unitKey, userType, isMyUnit }: IProps): JSX.Element {
    let process = 0
    const unitStatuses = unit.attributes.Statuses
    const viewingStatuses = viewing?.attributes?.Statuses

    const texts = {
        stepApprovedOrRejectedAgentToLandlord: isMyUnit ? 'Your unit\'s viewing has been approved by you' : 'Your viewing time has been approved',
        descriptionApprovedOrRejectedAgentToLandlord: '',
        stepInProcessAgentToLandlord: isMyUnit ? 'Approve viewing on your unit' : 'Wait for viewing approval',
        descriptionInProcessAgentToLandlord: isMyUnit ? '' : 'Landlord will approve your viewing soon',
        stepCompleted: 'Viewing completed successfully',
        descriptionCompleted: 'Congratulations on another completed viewing! Let’s make a new one with the BSO Club!'
    }

    if (unitStatuses.includes('Door close') && viewingStatuses != null) {
        if (viewingStatuses?.includes('Rescheduled by landlord') && !viewingStatuses?.includes('Rescheduled by agent') && !viewingStatuses?.includes('In-process')) {
            process = 0
        }
        if (viewingStatuses?.includes('In-process') || viewingStatuses?.includes('Rescheduled by landlord')) {
            process = 0
        } else if (viewingStatuses?.includes('Canceled')) {
            process = 0
            texts.stepInProcessAgentToLandlord = 'Viewing cancelled'
            texts.descriptionInProcessAgentToLandlord = 'Your viewing has been cancelled'
        } else if (viewingStatuses?.includes('Approved')) {
            process = 1
        } else if (viewingStatuses?.includes('Rejected')) {
            process = 1
            texts.stepApprovedOrRejectedAgentToLandlord = isMyUnit ? 'Your have rejected this viewing' : 'Your viewing was rejected by landlord' // 'Viewing rejected'
            texts.descriptionApprovedOrRejectedAgentToLandlord = isMyUnit ? '' : 'Unfortunately, landlord has decided to reject your viewing because of the valuable reason stated above. Please reschedule the time of this viewing or create a new one'
        } else if (viewingStatuses?.includes('Completed')) {
            process = 2
        }
    }

    const [line, setLine] = useState('middle')

    const iconProcess = (step: number, process: number): ReactElement<any, any> | undefined => {
        if (process + 1 > step || process === 4) {
            return undefined
        }
        return (<div className="ant-steps-item-icon">
            <span className="ant-steps-icon" style={{ color: 'black' }}>
                {step}
            </span>
        </div>
        )
    }

    const groupAgentToLandlord: TimelineItem[] = [
        {
            title: (
                <div>
                    <div className='lh-sm mb-1'>{texts.stepInProcessAgentToLandlord}</div>
                    <div className={'d-flex align-items-center'}>

                        {process === 0 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                        <div className="mt-2">{process === 0 ? SchedulerStatus(viewing?.attributes?.Statuses ?? [], 'fs-6') : undefined}</div>
                    </div>
                    {process === 0 && viewingStatuses != null && (viewingStatuses?.includes('Rescheduled by BSO') || viewingStatuses?.includes('Canceled')) && viewing?.attributes?.RejectReason &&
                        <div className={'d-flex align-items-center'}>
                            <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">{viewingStatuses?.includes('Canceled') ? 'Cancel ' : 'Reschedule '}Reason :</div>
                            <div className="d-block mt-2"> {SchedulerStatus([`${viewing?.attributes?.RejectReason}`], 'fs-6')} </div>
                        </div>
                    }

                </div>
            ),
            description: texts.descriptionInProcessAgentToLandlord,
            status: process === 0 && viewingStatuses?.includes('Canceled') ? 'finish' : undefined
        } satisfies TimelineItem,

        ...(viewingStatuses?.includes('Canceled')

            ? []
            : [

                {
                    title: (
                        <div>
                            <div className='lh-sm mb-1'> {texts.stepApprovedOrRejectedAgentToLandlord} </div>
                            <div className={'d-flex align-items-center'}>
                                {process === 1 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                <div className="subTitle">{process === 1 ? SchedulerStatus(viewing?.attributes?.Statuses ?? [], 'fs-6') : undefined}</div>
                            </div>

                            {process === 1 && viewingStatuses?.includes('Rejected') && viewing?.attributes?.RejectReason &&
                                <div className={'d-flex align-items-center'}>
                                    <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Reject Reason :</div>
                                    <div className="d-block mt-2"> {SchedulerStatus([`${viewing?.attributes?.RejectReason}`], 'badge badge-light-danger  border border-danger text-truncate mw-100 d-inline-block fs-6')} </div>
                                </div>
                            }
                        </div>
                    ),
                    description: texts.descriptionApprovedOrRejectedAgentToLandlord
                },
                ...(!viewingStatuses?.includes('Rejected')
                    ? [
                        {
                            title: (
                                <div >
                                    <div className='lh-sm mb-1'> {texts.stepCompleted} </div>
                                    <div className={'d-flex align-items-center'}>
                                        {process === 2 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                        <div className="subTitle d-block">{process === 2 ? SchedulerStatus(viewing?.attributes?.Statuses ?? [], 'fs-6 my-0') : undefined}</div>
                                    </div>
                                </div>
                            ),
                            description: texts.descriptionCompleted,
                            status: process === 2 ? 'finish' : undefined
                        } satisfies TimelineItem]
                    : [])
            ]

        )

    ]

    const [sliceSteps, setSliceSteps] = useState([groupAgentToLandlord[process]])

    useEffect(() => {
        groupAgentToLandlord.map((el, i) => el.icon = iconProcess(i + 1, process))
    }, [sliceSteps, process])
    return (
        <div className=''>

            {<Steps
                current={line === 'next' ? 0 : line === 'middle' ? 0 : process}
                percent={60}
                items={sliceSteps}
            />}

            <div className={'d-flex mt-2 pt-2'} style={{ justifyContent: process === 0 || process === 2 ? 'flex-end' : 'space-evenly' }}>
                <div className="d-flex justify-content-between align-items-center mx-4">
                    {process !== 0 && <div className={'text-end'}>
                        <div
                            className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                            onClick={line === 'prew'
                                ? () => { setSliceSteps([groupAgentToLandlord[process]]); setLine('middle') }
                                : () => {
                                    setSliceSteps([...groupAgentToLandlord].slice(0, process + 1))
                                    setLine('prew')
                                }
                            }
                        >
                            <div className="d-flex justify-content-around align-items-center">
                                <Icon name={line === 'prew' ? 'play_circle' : 'expand_circle_up'} className={'fs-5'} />
                                <span className="ms-1 fs-8">{line === 'prew' ? 'Current step' : 'Previous steps'}</span>
                            </div>
                        </div>
                    </div>}
                    <div className="d-flex justify-content-between align-items-center mx-2">
                        {process !== 2 && !viewing?.attributes?.Statuses?.includes('Canceled') && <div className={'text-end'}>
                            <div
                                className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                                onClick={
                                    line === 'next'
                                        ? () => { setSliceSteps([groupAgentToLandlord[process]]); setLine('middle') }
                                        : () => { setSliceSteps([...groupAgentToLandlord].slice(process)); setLine('next') }}
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    <Icon name={line === 'next' ? 'play_circle' : 'expand_circle_down'} className={'fs-5'} />
                                    <span className="ms-1 fs-8">   {line === 'next' ? 'Current step' : 'Next steps'}</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

        </div>

    )
}

export default TimelineAgentToLandlord
