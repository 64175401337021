import React, { useEffect, useState } from 'react'
import { type IViewing } from '../../../../store/slices/viewingsSlice'
import { type IUnitKey, type Unit } from '../../../../store/slices/unitsSlice'
import { SchedulerStatus } from '../../../../functions/agent/date/SchedulerStatus'
import { type TimelineItem } from './ViewingTimeline'
import { Button, Steps } from 'antd'
import ProgressLine from './ProgressLine'
import Icon from '../../../icon/Icon'
import { ShowReason } from '../timelineUtils/ShowReason'

interface IProps {
    viewing: IViewing
    unit: Unit
    unitKey: IUnitKey
    userType: string
}

function TimelineBuildingManagement({ viewing, unit, unitKey, userType }: IProps): JSX.Element {
    let process = 0
    const unitStatuses = unit.attributes.Statuses
    const viewingStatuses = viewing?.attributes?.Statuses

    let stepApprovedOrRejectedBuildingManagement = 'Your viewing time has been approved'
    let descriptionApprovedOrRejectedBuildingManagement = ''

    let stepInProcessBuildingManagement = viewingStatuses?.includes('Rescheduled by BSO') ? 'Please set another convenient viewing time for you' : 'Wait for viewing approval'
    let descriptionInProcessBuildingManagement = viewingStatuses?.includes('Rescheduled by BSO') ? "The request will be fulfilled again." : 'Our specialist will approve your viewing soon.'

    const stepFeedback = 'Add feedback about the finished viewing'
    let descriptionFeedback = 'Please add feedback about the results of this viewing by selecting options on the quiz page and try to submit an offer if your client is ready for it.'


    const stepWaitingComplain = 'Wait for your performance review'
    let descriptionWaitingComplain = 'Your viewing is completed! But we have one more step to make free breath about it: our specialist will send you a review of your performance during this viewing.'

    const stepCompleted = 'Viewing completed successfully'
    const descriptionCompleted = 'Congratulations on another completed viewing! Let’s make a new one with the BSO Club!'

    if (unitStatuses.includes('Building Management') || unitStatuses.includes('Special Request')) {
        if (viewingStatuses?.includes('Rescheduled by BSO') && !viewingStatuses?.includes('Rescheduled by agent') && !viewingStatuses?.includes('In-process')) {
            process = 0
        }
        if (viewingStatuses?.includes('In-process') || viewingStatuses?.includes('Rescheduled by agent')) {
            process = 0
        } else if (viewingStatuses?.includes('Canceled')) {
            process = 0

            stepInProcessBuildingManagement = 'Viewing cancelled'
            descriptionInProcessBuildingManagement = 'Your viewing has been cancelled'
        } else if (viewingStatuses?.includes('Approved')) {
            process = 1
        } else if (viewingStatuses?.includes('Rejected')) {
            process = 1
            stepApprovedOrRejectedBuildingManagement = 'Your viewing was rejected by BSO'
            descriptionApprovedOrRejectedBuildingManagement = 'Unfortunately, our specialist has decided to reject your viewing because of the valuable reason stated above. Please reschedule the time of this viewing or create a new one.'
        } else if (viewingStatuses?.includes('Waiting for feedback')) {
            process = 2
        } else if (viewingStatuses?.includes('Waiting for complain')) {
            if (viewing?.attributes?.Feedback !== null) {
                descriptionFeedback = viewing?.attributes?.Feedback
            }
            process = 3
        } else if (viewingStatuses?.includes('Completed')) {
            process = 4

            if (viewing?.attributes?.Feedback !== null) {
                descriptionFeedback = viewing?.attributes?.Feedback
            }
            if (viewing?.attributes?.Complaint !== null) {
                descriptionWaitingComplain = viewing?.attributes?.Complaint
            }
        }
    }

    const [line, setLine] = useState('middle')

    const iconProcess = (step: number, process: number) => {
        if (process + 1 > step || process === 4) {
            return undefined
        }
        return (<div className="ant-steps-item-icon">
            <span className="ant-steps-icon" style={{ color: 'black' }}>
                {step}
            </span>
        </div>
        )
    }

    const groupBuildingManagement: TimelineItem[] = [
        {
            title: (
                <div>
                    <div className='lh-sm mb-1'>{stepInProcessBuildingManagement}</div>
                    <div className={'d-flex align-items-center'}>

                        {process === 0 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                        <div className="mb-1">{process === 0 ? SchedulerStatus(viewing?.attributes?.Statuses ?? [], 'fs-6') : undefined}</div>
                    </div>
                    {process === 0 && (viewingStatuses?.includes('Rescheduled by BSO') || viewingStatuses?.includes('Canceled')) && viewing?.attributes?.RejectReason &&
                        <ShowReason statuses={viewingStatuses} reason={viewing?.attributes?.RejectReason} />

                    }

                </div>
            ),
            description: descriptionInProcessBuildingManagement,
            status: process === 0 && viewingStatuses?.includes('Canceled') ? 'finish' : undefined
        } satisfies TimelineItem,

        ...(viewingStatuses?.includes('Canceled')

            ? []
            : [

                {
                    title: (
                        <div>
                            <div className='lh-sm mb-1'> {stepApprovedOrRejectedBuildingManagement} </div>
                            <div className={'d-flex align-items-center'}>
                                {process === 1 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                <div className="subTitle">{process === 1 ? SchedulerStatus(viewing?.attributes?.Statuses ?? [], 'fs-6') : undefined}</div>
                            </div>

                            {process === 1 && viewingStatuses?.includes('Rejected') && viewing?.attributes?.RejectReason &&
                                <ShowReason statuses={viewingStatuses} reason={viewing?.attributes?.RejectReason} />
                            }
                        </div>
                    ),
                    description: descriptionApprovedOrRejectedBuildingManagement
                },
                ...(!viewingStatuses?.includes('Rejected')
                    ? [{
                        title: (
                            <div>
                                <div className='lh-sm mb-1'> {stepFeedback} </div>
                                {process > 2 && <div
                                    className="mb-1">{SchedulerStatus(['Your Feedback'], 'fs-6 mt-0')}</div>}
                                <div className={'d-flex align-items-center'}>
                                    {process === 2 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                    <div className="subTitle">{process === 2 ? SchedulerStatus(viewing?.attributes?.Statuses ?? [], 'fs-6') : undefined}</div>
                                </div> </div>
                        ),
                        description: descriptionFeedback
                    },

                    {
                        title: (
                            <div>
                                <div className='lh-sm mb-1'>{stepWaitingComplain} </div>
                                {process > 3 && <div
                                    className="mb-1">{SchedulerStatus(['BSO team feedback'], 'fs-6 mt-0')}</div>}
                                <div className={'d-flex align-items-center'}>
                                    {process === 3 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                    <div className="subTitle">{process === 3 ? SchedulerStatus(viewingStatuses?.includes('Waiting for complain') ? ['Completed'] : viewing?.attributes?.Statuses ?? [], 'fs-6 my-0') : undefined}</div>
                                </div>
                            </div>
                        ),
                        description: descriptionWaitingComplain
                    },
                    {
                        title: (
                            <div >
                                <div className='lh-sm mb-1'> {stepCompleted} </div>
                                <div className={'d-flex align-items-center'}>
                                    {process === 4 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                    <div className="subTitle d-block">{process === 4 ? SchedulerStatus(viewing?.attributes?.Statuses ?? [], 'fs-6 my-0') : undefined}</div>
                                </div> </div>
                        ),
                        description: descriptionCompleted,
                        status: process === 4 ? 'finish' : undefined
                    } satisfies TimelineItem]
                    : [])
            ]

        )

    ]

    const [sliceSteps, setSliceSteps] = useState([groupBuildingManagement[process]])

    useEffect(() => {
        groupBuildingManagement.map((el, i) => el.icon = iconProcess(i + 1, process))
    }, [sliceSteps, process])
    return (
        <div className='my-8 pt-8' style={{ maxWidth: '450px', width: '100%' }}>

            {<Steps
                current={line === 'next' ? 0 : line === 'middle' ? 0 : process}
                percent={60}
                items={sliceSteps}
                direction='vertical'
            />}

            <div className={'d-flex'} style={{ justifyContent: process === 0 || process === 4 ? 'flex-end' : 'space-evenly' }}>
                <div className="d-flex justify-content-between align-items-center mx-4">
                    {process !== 0 && <div className={'text-end'}>
                        <div
                            className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                            onClick={line === 'prew'
                                ? () => { setSliceSteps([groupBuildingManagement[process]]); setLine('middle') }
                                : () => {
                                    setSliceSteps([...groupBuildingManagement].slice(0, process + 1))
                                    setLine('prew')
                                }
                            }
                        >
                            <div className="d-flex justify-content-around align-items-center">
                                <Icon name={line === 'prew' ? 'play_circle' : 'expand_circle_up'} className={'fs-5'} />
                                <span className="ms-1 fs-8">{line === 'prew' ? 'Current step' : 'Previous steps'}</span>
                            </div>
                        </div>
                    </div>}
                    <div className="d-flex justify-content-between align-items-center mx-2">
                        {process !== 4 && !viewing?.attributes?.Statuses?.includes('Canceled') && <div className={'text-end'}>
                            <div
                                className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                                onClick={
                                    line === 'next'
                                        ? () => { setSliceSteps([groupBuildingManagement[process]]); setLine('middle') }
                                        : () => { setSliceSteps([...groupBuildingManagement].slice(process)); setLine('next') }}
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    <Icon name={line === 'next' ? 'play_circle' : 'expand_circle_down'} className={'fs-5'} />
                                    <span className="ms-1 fs-8">   {line === 'next' ? 'Current step' : 'Next steps'}</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

        </div>

    )
}

export default TimelineBuildingManagement
