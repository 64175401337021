export const textsOfferTimelineRent = {
    stepClientData: "Request for Client Documents and Additional Information",
    descriptionStepClientData: 'To proceed further with the offer process, we kindly request you to upload the necessary client documents along with any additional information required. This will help us ensure a smooth and efficient progression of the process.', // 'It will be as deposit , its usual practice before baying property , it will be refound when began pay process.Need to change text.   ', // 'Our specialist will be sent your offer soon.',


    stepInProcess: "Wait for Client Documents and Additional Information approval",
    descriptionInProcess: 'Our specialist will approve',


    // stepInProcess: 'Wait for the offer to be sent to the landlord.',
    // descriptionInProcess: 'Our specialist will be sent your offer soon.',

    stepApprovedOrRejected: 'Your offer was sent to landlord.',
    descriptionApprovedOrRejected: 'Please wait for the unit’s landlord\'s confirmation of your offer.',

    stepConfirmed: 'Your offer was confirmed by the landlord.',
    descriptionConfirmed: 'Please send us your client\'s deposit pay slip and contact info: email address and phone number.',

    stepPaySlip: 'Wait for the deposit pay slip\'s approval.',
    descriptionPaySlip: 'Our specialist will approve the deposit pay slip for the offer that you sent to us before as soon as possible.',

    stepConfirmSlip: 'Deposit pay slip was approved by BSO.',
    descriptionConfirmSlip: 'Our specialist successfully approved your uploaded deposit pay slip for this offer, now you are ready to finalize this offer, and your client will contact you soon about the offer finalization.',

    stepBFSending: 'Wait for the next updates: we are sending the booking form to your client.',
    descriptionBFSending: 'Please wait until our specialist completes the required steps for this offer process.',

    stepBFSinging: 'Wait for the next updates: your client is only one step away from signing the booking form.',
    descriptionBFSinging: 'Please wait until your client signed the booking form that we sent to his email.',

    stepBF: 'Wait for the next updates: we are sending the booking form to your client.',
    descriptionStepBF: 'Please wait until our specialist completes the required steps for this offer process.',

    stepCompleted: 'Deal closed successfully.',
    descriptionCompleted: 'Congratulations on another completed offer! Let’s make a new one with the BSO Club!',
    addTitle: '',

    stepPayUploading: 'Upload the check transfer slip received from your client.',
    descriptionPayUploading: `Inform your tenant, he should send to BSO the booking deposit.` //  in the amount of ${price} AED.
}