import React from 'react';
import CustomFormLable from '../customModalUtils/CustomFormLable';
import _ from 'lodash';

interface IProps {
    register: any;
    errors: any;
    row: any;
}

export default function GroupInput({ register, errors, row }: IProps): JSX.Element {
    return (
        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
            <CustomFormLable row={row} />
            <div className="input-group">
                <input
                    {...register(row.id,
                        {
                            required: row.required ? `Field "${row.content?.[0]}" is required` : false,
                            validate: (value: string) => {
                                let result: boolean = true;
                                if (row.required && !value) {
                                    return `Field "${row.content?.[0]}" is required`;
                                }
                                if (row.regexp != null && value) {
                                    result = new RegExp(row.regexp).test(value);
                                    if (!result) {
                                        return `Field "${row.content?.[0]}" does not match the required pattern`;
                                    }
                                }
                                if (row.moreThan != null && value) {
                                    result = result && Number(value) > Number(row.moreThan);
                                    if (!result) {
                                        return `Field "${row.content?.[0]}" must be more than ${row.moreThan}`;
                                    }
                                }
                                if (row.dateBeforeToday === true && value) {
                                    if (value?.split != null) {
                                        const splitDate = value.split('/');
                                        let date: Date;
                                        if (splitDate?.length > 1) {
                                            date = new Date(+splitDate[0] < 30 ? +splitDate[0] + 2000 : +splitDate[0], +splitDate[1] - 1, +splitDate[2]);
                                        } else {
                                            date = new Date(splitDate[0]);
                                        }
                                        result = result && date.getTime() < new Date().getTime();
                                        if (!result) {
                                            return `Field "${row.content?.[0]}" must be a date before today`;
                                        }
                                    }
                                }
                                if (row.dateAfterToday === true && value) {
                                    if (value?.split != null) {
                                        const splitDate = value.split('/');
                                        let date: Date;
                                        if (splitDate?.length > 1) {
                                            date = new Date(+splitDate[0] < 30 ? +splitDate[0] + 2000 : +splitDate[0], +splitDate[1] - 1, +splitDate[2]);
                                        } else {
                                            date = new Date(splitDate[0]);
                                        }
                                        result = result && date.getTime() >= new Date().getTime();
                                        if (!result) {
                                            return `Field "${row.content?.[0]}" must be a date after today`;
                                        }
                                    }
                                }
                                return true;
                            }
                        }
                    )}
                    name={row.id}
                    className="form-control form-control-solid form-control-lg"
                    type="text"
                    placeholder={row.content?.[2]}
                    defaultValue={row.default || row?.defaultValue}
                    onInput={row.onInput != null ? row.onInput : () => { }}
                />
                <span className="input-group-text border-0">{row.content?.[1]}</span>
            </div>
            {
                _.get(errors, row.id) != null && (
                    _.get(errors, row.id).type === 'minLength'
                        ? <div className="text-danger">{`${row.content?.[0]} can not be less than 2 characters `} </div>
                        : _.get(errors, row.id).type === 'maxLength'
                            ? <div className="text-danger">{`${row.content?.[0]} cannot exceed  60 characters more `} </div>
                            : _.get(errors, row.id).type === 'validate'
                                ? <div className="text-danger">{`${row.content?.[0]} must be valid `} </div>
                                : <div className="text-danger">{_.get(errors, row.id).message}</div>
                )
            }
        </div>
    );
}