import { type NavigateFunction } from "react-router-dom";
import { type IOffer } from "../../../store/slices/offersSlice";
import { type AppDispatch } from "../../../store/store";
import { swalAttentionLeave } from "../../swalOptions";

export const processOfferSwalAdmin = async (offer: IOffer, dispatch: AppDispatch, navigate: NavigateFunction): Promise<void> => {
    //  console.log(offer,"offer")
    const status = offer?.attributes?.Statuses?.[0]

    switch (status) {
        // case 'Negotiated by agent':
        //     await swalAttentionLeave('Do you want open new create offer from agent?').then(async (result: any) => {
        //         if (result.isConfirmed) {
        //             navigate(`/landlord/modalView/magic_offer_modal/${String(offer?.attributes?.Unit?.data?.id)}/${String(offer.id)}/catalog?type=${String(offer?.attributes?.Type?.[0]?.__component ?? 'not').slice(6)}`)
        //         }
        //     }).catch(console.log)
        //     break
        // case 'In-process':
        //     await swalAttentionLeave('Do you want open new update offer from agent?').then(async (result: any) => {
        //         if (result.isConfirmed) {
        //             navigate(`/landlord/modalView/magic_offer_modal/${String(offer?.attributes?.Unit?.data?.id)}/${String(offer.id)}/catalog?type=${String(offer?.attributes?.Type?.[0]?.__component ?? 'not').slice(6)}`)
        //         }
        //     }).catch(console.log)
        //     break
        default:
            break
    }
    
}




