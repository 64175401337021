import React from 'react'
import CustomFormLable from '../customModalUtils/CustomFormLable'
import softlyIncludes from '../../../functions/search/softlyIncludes'
import _ from 'lodash'
import { store } from '../../../store/store'
interface IProps {
    register: any
    errors: any
    row: any
}

export default function DefaultInput({ register, errors, row }: IProps): JSX.Element {
    return (

        <div className="fv-row  py-2 w-100 fv-plugins-icon-container">
            <CustomFormLable row={row} />
            {/* <MyInput /> */}
            <div className={'d-flex gap-4'}>
                <input
                    {...register(row.id, {
                        required: row.required === true ? `Field "${row.content?.[0]}" is required` : undefined,
                        // minLength: row.minLength != null ? row.minLength : 2,ß
                        maxLength: row.maxLength != null ? row.maxLength : 256,
                        validate: (value: string) => {
                            let result: boolean = true
                            if (row.regexp != null) {
                                result = row.required ? new RegExp(row.regexp).test(value) : new RegExp(row.regexp).test(value) || value === ''
                            }
                            if (row.dateBeforeToday === true) {
                                const splitDate = value.split('/')
                                const date = new Date(+splitDate[0] < 30 ? +splitDate[0] + 2000 : +splitDate[0], +splitDate[1] - 1, +splitDate[2])
                                result = result && date.getTime() < new Date().getTime()
                            }
                            if (row.dateAfterToday === true) {
                                const splitDate = value.split('/')
                                const date = new Date(+splitDate[0] < 30 ? +splitDate[0] + 2000 : +splitDate[0], +splitDate[1] - 1, +splitDate[2])
                                result = result && date.getTime() >= new Date().getTime()
                            }
                            if (row.validateEquals != null) {
                                const target = store.getState().modal.scannedData[row.validateEquals[0]]
                                const accuracy = row.validateEquals[2] ?? 0
                                if (target == null) {
                                    result = true
                                } else {
                                    result = softlyIncludes({ target, value, accuracy })
                                }
                            }
                            return result
                        }
                    })}
                    className="form-control form-control-solid form-control-lg"
                    placeholder={row.content?.[1]}
                    defaultValue={row.defaultValue}
                    onInput={row.onInput != null ? row.onInput : () => { }}
                    type="text"
                    autoComplete="off"
                />
                {row.onRemove != null
                    ? <i className="ki-duotone ki-cross-square fs-5x"
                        onClick={row.onRemove}
                    >
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>

                    : <></>
                }
                {
                    row.onAdd != null
                        ? <i className="ki-duotone ki-plus-square fs-5x"
                            onClick={row.onAdd}
                        >
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                        </i>
                        : <></>
                }
            </div>
            {
                _.get(errors, row.id) != null && (
                    _.get(errors, row.id).type === 'minLength'
                        ? <div className="text-danger">{`${row.content?.[0]} can not be less than 2 characters `} </div>
                        : _.get(errors, row.id).type === 'maxLength'
                            ? <div className="text-danger">{`${row.content?.[0]} cannot exceed ${row.maxLength ? row.maxLength : 256} characters more `} </div>
                            : _.get(errors, row.id).type === 'validate'
                                ? <div className="text-danger">{row.validateEquals?.[1] ?? `${row.content?.[0]} must be valid`} </div>
                                : <div className="text-danger">{_.get(errors, row.id).message}</div>
                )
            }

        </div>

    )
}
