import React, { useState } from 'react'
import { type RootState } from '../../../../store/store'
import { useAppSelector } from '../../../../hooks/redux'
import { regExps } from '../../../../constants'
import { generateDefaultValueFormField } from '../../../../functions/agent/AgentModals'
import { calculatePercentageChange } from '../StateTypeOfferLast'
import _ from 'lodash'
import CustomFormLable from '../../../../app/custom/customModalUtils/CustomFormLable'
export default function StateNegotiableOffer({
    setValue,
    errors,
    register,
    control,
    objectID
}: any): JSX.Element {
    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const offer = offers.find((offerItem) => offerItem.id === Number(objectID))
    const rowTypeOffer = { id: 'Type[0].Offer', content: ['New price', 'AED', 'Client’s offer for the property'], element: 'input-group', required: true, regexp: regExps.isNumber, ...(generateDefaultValueFormField(offer?.attributes?.Type[0]?.Offer)) }
    const [currentOfferValue, setCurrentOfferValue] = useState<number>(offer?.attributes?.Type[0]?.Offer || 0)
    const [newOfferValue, setNewOfferValue] = useState<number>(offer?.attributes?.Type[0]?.Offer || 0)

    const handleOfferChange = (value: string) => {
        setCurrentOfferValue(isNaN(parseFloat(value)) ? 0 : parseFloat(value))
        const numericValue = parseFloat(value)
        setNewOfferValue(isNaN(numericValue) ? 0 : numericValue)
    }

    const percentageChange = calculatePercentageChange(offer?.attributes?.Type[0]?.Offer || 0, newOfferValue)
    const isPositiveChange = percentageChange >= 0

    return (
        <>
            <div className="d-flex justify-content-between mt-n4">
                <div className="d-flex flex-wrap d-grid gap-4">
                    <div className="rounded fw-bold px-4 py-2 border-end-dashed border-end border-gray-300  ">
                        <span className="fs-5 fw-semibold text-gray-600"> Previous Deal</span>
                        <div className="my-2">
                            <span className="fs-3hx fw-bold text-gray-800 me-2 lh-1 ls-n2">{rowTypeOffer.defaultValue}</span>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-wrap d-grid gap-4">
                    <div className="rounded fw-bold px-4 py-2">
                        <span className="fs-5 fw-semibold text-gray-600"> Your Deal Now</span>
                        <div className="my-2">
                            <span className="fs-3hx fw-bold text-gray-800 me-2 lh-1 ls-n2 text-break">{currentOfferValue}</span>
                            <span className={`badge fs-base ${isPositiveChange ? 'badge-light-success' : 'badge-light-danger'}`}>
                                <i className={`ki-duotone ki-arrow-${isPositiveChange ? 'up' : 'down'} fs-5 text-${isPositiveChange ? 'success' : 'danger'} ms-n1`}>
                                    <span className="path1"></span><span className="path2"></span>
                                </i>
                                {Math.abs(percentageChange).toFixed(2)}%
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fv-row mt-2 pt-2 w-100 fv-plugins-icon-container">
                <CustomFormLable row={rowTypeOffer} />
                <div className="input-group">
                    <input
                        {...register(rowTypeOffer.id, {
                            required: `Field "${rowTypeOffer.content?.[0]}" is required`,
                            validate: (value: string) => {
                                let result: boolean = true
                                if (rowTypeOffer.regexp != null) {
                                    result = new RegExp(rowTypeOffer.regexp).test(value)
                                }
                                handleOfferChange(value)
                                return result
                            }
                        })}
                        name={rowTypeOffer.id}
                        className="form-control form-control-solid form-control-lg"
                        type="text"
                        placeholder={rowTypeOffer.content?.[2]}
                        defaultValue={rowTypeOffer?.defaultValue}
                    />
                    <span className="input-group-text border-0">{rowTypeOffer.content?.[1]}</span>
                </div>
                {
                    _.get(errors, rowTypeOffer.id) != null && (
                        _.get(errors, rowTypeOffer.id).type === 'validate'
                            ? <div className="text-danger">{`${rowTypeOffer.content?.[0]} must be valid `} </div>
                            : <div className="text-danger">{_.get(errors, rowTypeOffer.id).message}</div>
                    )
                }
            </div>
        </>
    )
}
