import React from 'react';

interface CustomButtonProps {
    userDevice: string;
    rowContent: string;
    onClick: () => void;
}
export const CustomButton: React.FC<CustomButtonProps> = ({
    userDevice,
    rowContent,
    onClick
}) => {
    return (

        <button
            className={`btn btn-bso mt-2 border-bso2px py-4 px-2 w-100 mx-1 ${userDevice === 'IPhone' ? 'mb-6' : ''}  `}
            onClick={onClick}
        >
            <span className="indicator-label">
                {rowContent}
            </span>
        </button>
    );
};

