import { getErrorss, getMessageForError } from "../../../../../functions/errors/GetErrors"
import { getQueryOffers } from "../../../../../functions/shared/api/queries/getQueryOffers"
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from "../../../../../urls"
import { dataFetchingFinish, dataFetchingStart } from "../../../../slices/modalSlice"
import {  setOffers } from "../../../../slices/offersSlice"
import { type AppDispatch, type RootState } from "../../../../store"

export const addOfferSaleContractType = (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ isSuccessful: boolean, textNotification?: string, textNavigate?: string }> => {
  
    try {
        dispatch(dataFetchingStart())
        const { objectID ,  contract_b } = data
        const jwt = window.localStorage.getItem(ACCESS_KEY)  ?? ''
        const offer = getState().offers.offers.find((offerItem) => offerItem.id === Number(objectID))
      
        const bodyObject = {
            method: 'PUT',
            id: Number(objectID),
            collection: 'offers',
            query: getQueryOffers(),
            body: {
                ...offer?.attributes,
                Type: [{
                    ...(offer?.attributes?.Type?.[0]),
                    ...(contract_b ? {ContractBNumber: contract_b} : {}),
                }],
                Statuses: [ contract_b ?  'F Form requested' : 'Manual contract requested'],
                Notify: 'BSO', 
            }
        }
      
        const responseOffer = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'BSO-Hash': '02ns30nsBSO!',
                Authorization: `Bearer ${String(jwt)}`
            },
            body: JSON.stringify(bodyObject)
        })
        const responseJSON = await responseOffer.json()
  
        const typeOffer = offer?.attributes?.Type?.[0]?.__component?.split('.')[1] ?? ''
        if (responseJSON.success === true && responseJSON?.response?.data?.id != null) {
        
            dispatch(setOffers(responseJSON.response.data))
            dispatch(dataFetchingFinish())
            return {
                textNotification: 'Contract type has been successfully chosen',
                textNavigate: `/agent/modalView/offer_modal/${offer?.attributes?.Unit?.data?.id}/${objectID as string}/not?type=${typeOffer ?? 'not'}`,
                isSuccessful: true
            }
        } else {
            dispatch(dataFetchingFinish())
            return {
                textNotification: getErrorss(responseJSON),
                isSuccessful: false
            }}
    } catch (err) {
        console.error(err)
        dispatch(dataFetchingFinish())
        return {
            textNotification: getErrorss(getMessageForError("Something went wrong. Try again later")),
            isSuccessful: false
        }
    }
  
}
