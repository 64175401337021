import { type Document } from "../../../../store/slices/documentSlice";
import { store } from "../../../../store/store";

export function downloadPaySlip(objectID: number): void {

    const documents = store.getState().documents.documents;
    const document = documents
        .filter((doc: Document) => (doc.attributes)?.Offer?.data?.id === Number(objectID) && ['Deposit Cheque', 'Offer Confirmation'].includes((doc.attributes)?.Category as string))
        .sort((a, b) => b?.id - a?.id)?.[0];

    if (document) {
        window.open(document.attributes?.Link, '_blank');
    } else {
        console.error('Document not found');
    }
}