import Swal, { type SweetAlertResult } from 'sweetalert2'

import { getErrorss } from './errors/GetErrors'
import { type Unit } from '../store/slices/unitsSlice'

import { isPastDate } from './agent/date/isPastDate'
import swalConfig from '../app/texts/swalTexts'
import { untitledImage, USER_TIME_INTERVAL_VERIFY_KEY } from '../urls'

export const animationOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        className: 'w-150px h-150px mb-1'
    }
}
//
export function swalOptions (icon: string): any {
    return {
        grow: 'row',
        icon,
        allowOutsideClick: false,
        buttonsStyling: false,
        customClass: {
            icon: 'mx-auto my-4',
            title: 'p-0 m-4',
            loader: 'mx-10 my-2',
            htmlContainer: 'p-0 m-4 h-auto ', // mh-100
            confirmButton: 'btn btn-bso w-100 mx-auto my-2',
            denyButton: 'btn btn-bso w-100 mx-auto my-2',
            cancelButton: 'btn btn-light w-100 mx-auto my-2',
            input: 'form-control form-control-solid form-control-lg w-100 m-0 mx-auto mb-4',
            inputLabel: 'form-label justify-content-start w-100 mx-auto mb-2 mt-4',
            actions: 'm-0 my-2',
            validationMessage: 'fv-plugins-message-container invalid-feedback bg-white p-0 m-0 mx-auto mt-n2 w-75 justify-content-start'
        }
    }
}

// export function swalOptionsV2 (icon: string): any {
//     return {
//         grow: 'row',
//         icon,
//         allowOutsideClick: false,
//         buttonsStyling: false,
//         customClass: {
//             icon: 'mx-auto my-4',
//             title: 'p-0 m-4',
//             loader: 'mx-10 my-2',
//             htmlContainer: 'p-0 m-4 h-100 ', // mh-100
//             confirmButton: 'btn btn-bso w-100 mx-auto my-2',
//             denyButton: 'btn btn-bso w-100 mx-auto my-2',
//             cancelButton: 'btn btn-light w-100 mx-auto my-2',
//             input: 'form-control form-control-solid form-control-lg w-100 m-0 mx-auto mb-4',
//             inputLabel: 'form-label justify-content-start w-100 mx-auto mb-2 mt-4',
//             actions: 'm-0 my-2',
//             validationMessage: 'fv-plugins-message-container invalid-feedback bg-white p-0 m-0 mx-auto mt-n2 w-75 justify-content-start'
//         }
//     }
// }

export async function swalError(message: string = '', callback?: () => void): Promise<SweetAlertResult<any>> {
    const iconText = 'Error';
    const options = {
        ...swalOptions('error'),
        ...{
            title: swalConfig.error.title,
            html: message === '' ? swalConfig.error.message : message,
            showConfirmButton: true,
            confirmButtonText: swalConfig.error.confirmButtonText,
            iconHtml: `<div id="lottie-icon-error" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 9999; pointer-events: none;"></div>`,
            didOpen: async () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0];
                if (lottieContainer) {
                    // Задержка перед инициализацией анимации (если требуется)
                    //   setTimeout(() => {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{
                            ...animationOptions,
                            animationData: require(`../animations/Error.json`),
                        },
                    });
                    // }, 1000); // Небольшая задержка, чтобы контейнер успел отобразиться
                }
            },
        },
    };

    const result = await Swal.fire(options);

    if ((callback != null) && result.isConfirmed) {
        callback();
    }

    return result;
}

export async function swalSuccess (message: string = '', callback?: () => void, title?: string ,icon? : string): Promise<SweetAlertResult<any>> {
    const iconText = icon || 'Success'
    const options = {
        ...swalOptions('success'),
        ...{
            title: title === undefined ? swalConfig.success.title : title,
            html: message === '' ? swalConfig.success.message : message,
            showConfirmButton: true,
            iconHtml: '<div  id="lottie-icon-success" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 9999; pointer-events: none;"></div>',
            confirmButtonText: swalConfig.success.confirmButtonText,
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                    })
                }
            }
        }
    }
    const result = await Swal.fire(options)
    if ((callback != null) && result.isConfirmed) {
        callback()
    }
    return result
}

export const showInputAlert = async (title: string, textArea: string = ""): Promise<SweetAlertResult<any>> => {
    const iconText = 'Info';
    return await Swal.fire({
        ...swalOptions('info'),
        ...{
            title,
            html: `
            <div class="">
           
                <label class=' required form-label fw-bold text-dark text-uppercase text-start'>
                    ${textArea ? "Active process" : "Reason"} 
                </label>
                <input id="swal-input1" class="form-control form-control-solid form-control-lg mb-2" placeholder="${swalConfig.inputAlert.inputPlaceholder}">
                ${textArea ? `
                 <label class='text-start required form-label fw-bold text-dark text-uppercase'>Details / description / next steps </label>
                <textarea id="swal-input2" class="form-control form-control-solid form-control-lg mb-2" placeholder="${textArea}"></textarea>` : ''}
               ${textArea ?  `<div className='d-flex align-items-center'>
             <label className="form-check form-check-solid form-check-inline">
             </label><input type="checkbox" id="switch" class=" form-check-input h-25px w-25px pt-2" />
             <span className="fw-semi-bold fs-5 py-2 my-2">
                Choose upload file and write title to description </span>
         </div>` : ""} 
             
                </div>`,
            showCancelButton: swalConfig.inputAlert.showCancelButton,
            confirmButtonText: swalConfig.inputAlert.confirmButtonText,
            cancelButtonText: swalConfig.inputAlert.cancelButtonText,
            reverseButtons: swalConfig.inputAlert.reverseButtons,
            iconHtml: `<div id="lottie-icon-${iconText}"></div>`,
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0];
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                    });
                }
            },
            preConfirm: () => {
                const input1 = (document.getElementById('swal-input1') as HTMLInputElement).value;
                const input2 = textArea ? (document.getElementById('swal-input2') as HTMLTextAreaElement).value : null;
                const loadDocument = (document.getElementById('switch') as HTMLInputElement).checked;
                if (!input1 || (textArea && !input2)) {
                    Swal.showValidationMessage('Please fill out all required fields.');
                    return false;
                }
                return { input1, input2 , loadDocument};
            }
        }
    });
};

export const showFileInputAlert = async (title: string): Promise<SweetAlertResult<any>> => {
    const iconText = 'Info';
    return await Swal.fire({
        ...swalOptions('info'),
        ...{
            title,
            input: swalConfig.fileInputAlert.input,
            inputAttributes: swalConfig.fileInputAlert.inputAttributes,
            showCancelButton: swalConfig.fileInputAlert.showCancelButton,
            confirmButtonText: swalConfig.fileInputAlert.confirmButtonText,
            cancelButtonText: swalConfig.fileInputAlert.cancelButtonText,
            reverseButtons: swalConfig.fileInputAlert.reverseButtons,
            iconHtml: `<div id="lottie-icon-${iconText}"></div>`,
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0];
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                    });
                }
            }
        }
    });
};

export const swalSettingsViewingsWindow = Swal.mixin({
    customClass: {
        denyButton: 'btn btn-bso w-100 mx-auto my-2',
        confirmButton: 'btn btn-bso w-100 mx-auto my-2',
        cancelButton: 'btn btn-danger w-100 mx-auto my-2'
    },
    buttonsStyling: false,
    allowOutsideClick: true
})

export async function swalViewingsWindow (view: any, unit: Unit): Promise<SweetAlertResult<any>> {
    const propertyHeadline = (unit?.attributes?.Property?.data?.attributes?.Area ?? '') + ' - ' + unit?.attributes?.Property?.data?.attributes?.Name + ' - ' + unit?.attributes?.Number ?? unit?.attributes?.Marketing?.Headline ?? 'Not added'
    const dateTimeViewing = view?.attributes?.Datetime
    const isPastDateFlag = isPastDate(dateTimeViewing)
    const isActualUnit = unit?.attributes?.Statuses?.includes('Archived')
    const iconText = 'Warning'
    //  console.log(propertyHeadline,"propertyHeadline")
    if (!propertyHeadline) {
        return await Promise.resolve({} as SweetAlertResult<any>); 
    }
    return await new Promise((resolve) => {
        swalSettingsViewingsWindow
            .fire({
                title: swalConfig.viewingsWindow.title(propertyHeadline),
                html:
                dateTimeViewing
                    ? isPastDateFlag
                        ? isActualUnit ? swalConfig.viewingsWindow.StatusText : swalConfig.viewingsWindow.DateText(dateTimeViewing, propertyHeadline)
                        : swalConfig.viewingsWindow.dateMessage(dateTimeViewing, propertyHeadline)
                    : swalConfig.viewingsWindow.defaultTextIsManagerHasNotBeenAble,
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: Boolean(dateTimeViewing) && !isPastDateFlag,
                confirmButtonText: swalConfig.viewingsWindow.confirmButtonText,
                cancelButtonText: swalConfig.viewingsWindow.cancelButtonText,
                denyButtonText: swalConfig.viewingsWindow.denyButtonText,
                reverseButtons: false,
                imageUrl: unit?.attributes?.Images[0] ?? untitledImage,
                imageWidth: 400,
                imageHeight: 200,
                // allowOutsideClick: !isPastDateFlag,
                imageAlt: 'Custom image'
                // iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
                //  didOpen: () => {
                //      const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                //      if (lottieContainer != null) {
                //          lottie.loadAnimation({
                //              container: lottieContainer,
                //              renderer: 'svg',
                //              ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                //          })
                //      }
                //  }
            }).then((result: any) => {
                resolve(result)
            }).catch((err) => { console.log(err) })
    })
}

export async function swalNeedLoadDocs (): Promise<SweetAlertResult<any>> {
    const iconText = 'Warning'
    return await new Promise((resolve) => {
        Swal.fire({
            ...swalOptions(iconText.toLowerCase()),
            ...{
                title: swalConfig.needLoadDocs.title,
                html: swalConfig.needLoadDocs.message,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: swalConfig.needLoadDocs.confirmButtonText,
                cancelButtonText: swalConfig.needLoadDocs.cancelButtonText,
                iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
                didOpen: () => {
                    const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                    if (lottieContainer != null) {
                        lottie.loadAnimation({
                            container: lottieContainer,
                            renderer: 'svg',
                            ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                        })
                    }
                }
            }
        })
            .then((result: any) => {
                resolve(result)
            }).catch((err) => { console.log(err) })
    })
}

export async function swalInProgress (message: string = ''): Promise<SweetAlertResult<any>> {
    const iconText = 'Warning'
    return await new Promise((resolve) => {
        Swal.fire({
            ...swalOptions(iconText.toLowerCase()),
            title: swalConfig.inProgress.title,
            text: message,
            icon: 'warning',
            confirmButtonText: swalConfig.inProgress.confirmButtonText,

            iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                    })
                }
            }
        })
            .then((result: any) => {
                resolve(result)
            }).catch((err) => { console.log(err) })
    })
}

export async function swalJustAlertAndRedirect (message: string, title = 'Success', icon = 'success'): Promise<SweetAlertResult<any>> {
    let textBtn = swalConfig.justAlertAndRedirect.confirmButtonText
    const iconText = 'Warning'
    if (message.includes('Standard')) {
        textBtn = swalConfig.justAlertAndRedirect.confirmButtonAfterChooseStandardPlan
    }
    const options = {
        ...swalOptions(iconText.toLowerCase()),
        ...{
            title,
            html: message,
            showConfirmButton: true,
            confirmButtonText: textBtn,
            iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                    })
                }
            }
        }
    }
    return await new Promise((resolve) => {
        Swal.fire(options)
            .then((result: any) => {
                resolve(result)
            }).catch((err) => { console.log(err) })
    })
}

export async function swalNeedRera (message: string = '', icon: string , typeUnit: string , title? : string): Promise<SweetAlertResult<any>> {
    const iconText = icon 
    // const showConfirm = icon === "Warning"
    const isShowImagesBtn =  typeUnit === "For rent"
    const isDev = process.env.REACT_APP_IS_DEV === 'true'
    const result = await Swal.fire(
        {
            ...swalOptions(iconText.toLowerCase()),
            title: title || swalConfig.needRera.title,
            html: message,
            showConfirmButton : isDev , // true , // showConfirm,
            showDenyButton: true,
            showCancelButton: true , 
            reverseButtons: false ,
            confirmButtonText:  swalConfig.needRera.confirmButtonText,
            denyButtonText: swalConfig.needRera.denyButtonText,
            cancelButtonText: swalConfig.needRera.cancelButtonText  , 
            iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                    })
                }
            }
        })
    return result
}

export async function swalSureLogout (): Promise<SweetAlertResult<any>> {
    const iconText = 'Warning'
    const result = await Swal.fire({
        ...swalOptions(iconText.toLowerCase()),

        title: swalConfig.sureLogout.title,
        text: swalConfig.sureLogout.message,
        iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
        showDenyButton: true,
        confirmButtonText: swalConfig.sureLogout.confirmButtonText,
        denyButtonText: swalConfig.sureLogout.denyButtonText,
        reverseButtons: true,
        // customClass: {
        //     denyButton: 'd-block btn btn-bso w-100 mx-auto my-2',
        //     confirmButton: 'btn btn-light w-100 mx-auto my-2'
        //
        // },
        didOpen: () => {
            const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]

            if (lottieContainer != null) {
                lottie.loadAnimation({
                    container: lottieContainer,
                    renderer: 'svg',
                    ...{ ...animationOptions, animationData: require('../animations/Warning.json') }
                })
            }
        }

    })

    return result
}
export async function swalSureDelete (): Promise<SweetAlertResult<any>> {
    const iconText = 'Warning'
    const result = await Swal.fire({
        ...swalOptions(iconText.toLowerCase()),
        title: swalConfig.sureDelete.title,
        text: swalConfig.sureDelete.message,
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: swalConfig.sureDelete.confirmButtonText,
        denyButtonText: swalConfig.sureDelete.denyButtonText,
        reverseButtons: true,
        // customClass: {
        //     denyButton: 'btn btn-bso w-75 mx-auto my-2',
        //     confirmButton: 'btn btn-light w-75 mx-auto my-2'

        // },
        iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
        didOpen: () => {
            const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
            if (lottieContainer != null) {
                lottie.loadAnimation({
                    container: lottieContainer,
                    renderer: 'svg',
                    ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                })
            }
        }
    })
    return result
}


export async function swalAttentionLeave (message: string = 'Are you sure ?',title? : string , confirmText?:string): Promise<SweetAlertResult<any>> {
    const iconText = 'Warning'
    const result = await Swal.fire({
        ...swalOptions(iconText.toLowerCase()),
        title: title || swalConfig.attentionLeave.title,
        html: message,
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: false,
        confirmButtonText: confirmText || swalConfig.attentionLeave.confirmButtonText,
        //  denyButtonText: swalConfig.attentionLeave.denyButtonText,
        cancelButtonText: swalConfig.attentionLeave.denyButtonText,
        reverseButtons: false,
        iconHtml: '<div  id="lottie-icon-warning" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 9999; pointer-events: none;"></div>',
        didOpen: () => {
            const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
            if (lottieContainer != null) {
                lottie.loadAnimation({
                    container: lottieContainer,
                    renderer: 'svg',
                    ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                })
            }
        }

    })
    return result
}

// странный флаг редирект  redirect = false в аргументах и showDenyButton ,не влияющий на логику , проверить поведение
export async function swalRejectedDoc (message = ''): Promise<SweetAlertResult<any>> {
    const iconText = 'Error'
    return await new Promise((resolve) => {
        Swal.fire({
            ...swalOptions(iconText.toLowerCase()),
            title: swalConfig.rejectedDoc.title,
            text: message,
            icon: 'error',
            showDenyButton: false, // redirect
            showCancelButton: false,
            denyButtonText: swalConfig.rejectedDoc.denyButtonText,
            confirmButtonText: swalConfig.rejectedDoc.confirmButtonText,
            reverseButtons: false,
            iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                    })
                }
            }
        }).then((result: any) => {
            resolve(result)
        }).catch(console.log)
    })
}

export async function swalVerifyEmail (callback?: () => void): Promise<SweetAlertResult<any>> {
    const iconText = 'Error'
    const lastTimeVerify = window.localStorage.getItem(USER_TIME_INTERVAL_VERIFY_KEY)
 
    return await new Promise((resolve) => {
        const config = {
            ...swalOptions('error'),
            customClass: {
                confirmButton: 'btn btn-bso w-100 mx-auto my-2',
                denyButton: 'btn btn-bso w-100 mx-auto my-2',
                cancelButton: 'btn btn-bso w-100 mx-auto my-2'
            },
            title: swalConfig.verifyEmail.title,
            html: swalConfig.verifyEmail.message,
            showConfirmButton: true,
            showLoaderOnConfirm: true,
            showLoaderOnDeny: false,
            confirmButtonText: swalConfig.verifyEmail.confirmButtonText,
            showDenyButton: true,
            denyButtonText: swalConfig.verifyEmail.denyButtonText,
            showCancelButton: (lastTimeVerify && new Date() > new Date(Number(lastTimeVerify))) || !lastTimeVerify,
            cancelButtonText: 'Resend verification mail',
            iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                    })
                }
            },

            preConfirm: async () => {
                if (callback != null) {
                    try {
                        const dataJSON: any = await callback()
                        if (dataJSON.success) {
                            if (dataJSON.response.error != null) {
                                throw new Error(getErrorss(dataJSON))
                            } else {
                                return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
                            }
                        } else {
                            throw new Error(getErrorss(dataJSON))
                        }
                    } catch (error: any) {
                        Swal.showValidationMessage(`Request failed: ${error.message}`)
                    }
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }
        Swal.fire(config).then((result: any) => {
            resolve(result)
        }) // .catch(console.log)
    })
}
//
export async function mainSwalDeal (
    text: string, 
    title?: string ,
    confirmButtonText?: string | undefined,
    denyButtonText?: string | undefined 
): Promise<SweetAlertResult<any>> {
        
    title = title || swalConfig.mainDeal.title
    confirmButtonText = confirmButtonText || swalConfig.mainDeal.defaultconfirmButtonText
    denyButtonText = denyButtonText || swalConfig.mainDeal.defaultdenyButtonText
    
    const iconText = 'Warning'
    return await new Promise((resolve) => {
        Swal.fire({
            title,
            html: text,
            icon: 'warning',
            ...swalOptions('warning'),

            showCancelButton: false,
            showDenyButton: true,
            confirmButtonText,
            denyButtonText,
            reverseButtons: false,
            iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                    })
                }
            }
        }).then((result: any) => {
            resolve(result)
        }).catch(console.log)
    })
}





export async function mainSwalDoAndSkip (
    title: string ,
    text: string, 
    confirmButtonText: string,
    cancelButtonText: string,
    icon? : string, 
): Promise<SweetAlertResult<any>> {
    const iconText = icon ?? 'Warning'
    return await new Promise((resolve) => {
        Swal.fire({
            title,
            html: text,
            icon: iconText.toLocaleLowerCase() ,
            ...swalOptions('warning'),
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText,
            cancelButtonText,
            iconHtml: '<div id="lottie-icon-"${iconText}></div>',
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                    })
                }
            }
        }).then((result: any) => {
            resolve(result)
        }).catch(console.log)
    })
}





export async function swalNegatiateOffer (message: string, callback: (...args: any) => any, negotiate: boolean = true): Promise<SweetAlertResult<any>> {
    const iconText = 'Warning'
    const options = {
        ...swalOptions('warning'),
        ...{
            // title,
            html: message,
            buttons: {
                deny: true,
                confirm: negotiate,
                cancel: true
            },
            // showInputLabel: true,
            showDenyButton: true,
            showConfirmButton: negotiate,
            showCancelButton: true,
            cancelButtonText: swalConfig.negotiateOfferSwal.cancelButtonText,
            confirmButtonText: swalConfig.negotiateOfferSwal.confirmButtonText,
            // inputLabel: swalConfig.negotiateOfferSwal.inputLabel,
            // input: negotiate ? 'text' : undefined,
            denyButtonText: swalConfig.negotiateOfferSwal.denyButtonText,
            reverseButtons: false,
            allowOutsideClick: true,
            iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                    })
                }
            }
        }
    }
    const result = await Swal.fire(options)
    callback(result)
    return result
}

export async function swalSearchInfo (): Promise<SweetAlertResult<any>> {
    const iconText = 'Info'
    const options = {
        ...swalOptions('info'),
        ...{
            title: swalConfig.chatSearchInfo.title,
            html: swalConfig.chatSearchInfo.html,
            iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                    })
                }
            }
        }
    }
    return await Swal.fire(options)
}

export async function swalConfirm (title: string, message: string): Promise<SweetAlertResult<any>> {
    const iconText = 'Info'
    const options = {
        ...swalOptions('info'),
        ...{
            title,
            html: message,
            showConfirmButton: true,
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: 'No',
            iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
                    })
                }
            }
        }
    }
    return await Swal.fire(options)
}

export async function landlordRegistrationSwal (): Promise<SweetAlertResult<any>> {
    const iconText = 'Info'
    const options = {
        ...swalOptions('info'),
        ...{
            title: 'Attention!',
            html: 'If you are not the landlord, but some landlord\'s representative, please register in app using real landlord personal info, documents and POA',
            showConfirmButton: true,
            confirmButtonText: 'OK',
            // iconHtml: `<div id="lottie-icon-${iconText}"></div>`,
            // didOpen: () => {
            //     const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
            //     if (lottieContainer != null) {
            //         lottie.loadAnimation({
            //             container: lottieContainer,
            //             renderer: 'svg',
            //             ...{ ...animationOptions, animationData: require(`../animations/${iconText}.json`) }
            //         })
            //     }
            // }
        }
    }
    return await Swal.fire(options)
}
