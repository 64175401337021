import React from 'react'

export default function Approved({ fontsize = '', statusText = 'Approved' }: { fontsize?: string | undefined, statusText?: string | undefined }): JSX.Element {
    return (
        <div className="">
            <div

                className={`badge badge-light-success  border border-success ${fontsize}`}
            >
                {statusText}
            </div>
        </div>
    )
}
