import Swal from 'sweetalert2'
import { animationOptions, swalOptions } from '../swalOptions'

interface ConfirmActionProps {
    title?: string
    description?: string
    confirmText?: string
    cancelText?: string
    callback: () => void
}





export default function confirmAction (
    {
        title = 'Are you sure?',
        confirmText = 'Yes, I am',
        description = undefined,
        cancelText = 'Cancel',
        callback
    }: ConfirmActionProps
): void {
    const iconText = 'Warning';
    void Swal.fire({
        ...swalOptions('warning'),
        title,
        html: description,
        confirmButtonText: confirmText,
        showCancelButton: true,
        cancelButtonText: cancelText,
        iconHtml: `<div id="lottie-icon-${iconText}"></div>`,
        didOpen: () => {
            const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0];
            if (lottieContainer != null) {
                lottie.loadAnimation({
                    container: lottieContainer,
                    renderer: 'svg',
                    ...{ ...animationOptions, animationData: require(`../../animations/${iconText}.json`) }
                });
            }
        }
    }).then((result) => {
        if (result.isConfirmed) {
            callback()
        }
    })
}
