import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { type RootState } from '../../../../../store/store'
import { setScheduleFilters } from '../../../../../store/slices/modalSlice'



export default function ScheduleSortOffersByStatuses(): JSX.Element {
    const scheduleFilters = useAppSelector((state: RootState) => state.modal.scheduleFilters)
    const dispatch = useAppDispatch()
    const statuses = [
        'all',
        'Negotiated offers',
        'Rejected',
        'Approved',
        'Resubmit required',
        'Client docs required',
        'Confirmed',
        'Canceled',
        'Completed',
        'In-process',
        'Other'
    ];

    return (
        <div className="d-flex flex-wrap">
            {statuses.map((status) => (
                <div key={status} className={`form-check form-check-custom ${scheduleFilters.byOfferStatus === status ? 'form-check-warning' : 'form-check-success'} form-check-solid my-2`}>
                    <input
                        className="ms-6 form-check-input h-25px w-25px border border-5 border-gray-300"
                        type="radio"
                        value={status}
                        checked={scheduleFilters.byOfferStatus === status}
                        onChange={() => dispatch(setScheduleFilters({ ...scheduleFilters, byOfferStatus: status }))}
                    />
                    <div className="ms-2 py-2 fw-semibold lh-1">
                        {status.toUpperCase()}
                    </div>
                </div>
            ))}
        </div>
    );
}
