import { dataFetchingFinish, dataFetchingStart, modalSlice } from '../../slices/modalSlice'
import { updateOfferByMagic } from './magicOfferActions'
import { setUnitSold } from '../unitsActions'

import { type RootState } from '../../store'
import { createDocument } from '../documentsActions/createDocument'

interface MagicCheckBookingFormProps {
    SignedBookingForm: FileList
}

export const magicCheckBookingForm = (unitID: string | undefined, objectID: string | undefined) => (data: MagicCheckBookingFormProps) => async (dispatch: any, getState: () => RootState) => {
    try {
        dispatch(dataFetchingStart())
        const { isSuccessful } = await dispatch(createDocument(data.SignedBookingForm, {
            RefID: `REF_DOC<->booking_form<->${new Date().getTime()}`,
            Category: 'Booking Form',
            Offer: Number(objectID),
            Unit: Number(unitID),
            Statuses: ['Approved']
        }, 'offers', objectID))
        const offer = getState().offers.offers.find(offerItem => offerItem.id === Number(objectID))
        if (isSuccessful === true) {
            await dispatch(updateOfferByMagic(Number(objectID), { Statuses: ['Completed'] }, 'Booking form is successfully signed', 'admin-offer-completed', 'Offer is completed'))
            void dispatch(setUnitSold(Number(unitID)))
            // old logic from the original code , now we are ask deposit slip before booking form
            // if (['offer.sale', 'offer.sale-company'].includes(String(offer?.attributes?.Type?.[0]?.__component))) {
            //     await dispatch(updateOfferByMagic(Number(objectID), { Statuses: ['Completed'] }, 'Booking form is successfully signed', 'admin-offer-completed', 'Offer is completed'))
            //     void dispatch(setUnitSold(Number(unitID)))
            // } else {
            //     await dispatch(updateOfferByMagic(Number(objectID), { Statuses: ['Completed'] }, 'Booking form is successfully signed', 'admin-offer-completed', 'Offer is completed'))
            //     void dispatch(setUnitSold(Number(unitID)))
            // //  await dispatch(updateOfferByMagic(Number(objectID), { Statuses: ['Waiting deposit pay slip'] }, 'Booking form is successfully signed', 'user-offer-deposit', 'ATTENTION upload a deposit pay slip for offer'))
            // }
      
        }
        dispatch(dataFetchingFinish())
        return {
            textNotification : 'Booking signed form is successfully loaded',
            isSuccessful,
            textNavigate: `/landlord/modalView/magic_offer_modal/${String(unitID)}/${String(objectID)}/catalog?type=${String(offer?.attributes?.Type?.[0]?.__component ?? 'not').slice(6)}`
        }
    } catch (error) {
        return {
            isSuccessful: false,
            textNotification: 'Something went wrong. Try again later'
        }
    }
}
