
import React, { useEffect, useState } from 'react'
import { Drawer } from 'antd'
import { useForm } from 'react-hook-form'
import RulesModals from '../auth/RulesModals'
import { dataFetchingFinish, dataFetchingStart } from '../../../store/slices/modalSlice'
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../urls'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import getAgentStatuses from '../../../functions/agent/documents/getAgentStatuses'
import { setStatuses } from '../../../store/slices/agentSlice'
import { userSlice } from '../../../store/slices/userSlice'
import { swalError } from '../../../functions/swalOptions'

interface IProps {
    title: string
}
export default function NewReliesDriver({ title }: IProps): JSX.Element {
    const dispatch = useAppDispatch()
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const [show, setShow] = useState(false)
    const [agree, setAgree] = useState(false)
    const [showError, setShowError] = useState(false)
    const loading = useAppSelector((state: RootState) => state.modal.loading)



    useEffect(() => {
        setShow(true)
    }, [])

    // const showPopupScheduleFilters = useAppSelector((state: RootState) => state.modal.showPopupScheduleFilters)

    // // console.log(showPopupAddOnsBricks, 'showPopupAddOnsBricks')
    // const dispatch = useAppDispatch()


    const onClose = (): void => {
        setShow(false)
    }
    return (
        <>
            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'87%'}
                styles={{
                    body: {
                        maxHeight: '88vh',
                        marginBottom: '3px',
                        paddingTop: '0px',
                        marginTop: '0px',
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }
                }}
                placement={'bottom'}
                closable={false}
                // onClose={onClose}
                open={show}
                rootStyle={{ zIndex: '1100' }}
                key={title}
                maskClosable={true}
            >
                <div className="w-100 mw-450px  mx-auto">
                    <div key={'new-relies'}
                        className="content d-flex flex-column p-4"
                    >

                        <>
                            <h1 className='text-center mx-4 my-2 px-2'>  <strong>We have a new release from BSO to you.</strong> </h1> <br />
                            <img
                                className=" w-100 h-375px object-fit-cover mb-2 rounded-0 rounded-bottom rounded-top opacity-100"
                                src={"/assets/media/app-preview.png"}
                                alt={'mark_lable'}
                            />

                            <div className="d-flex flex-column border border-1  border-dashed border-gray-300 text-center py-2 card-rounded my-2">
                                <span className="fs-2 fw-bold counted text-gray-800">1. Agent listing:
                                </span>
                                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui, ea. Dolor consectetur, temporibus ex praesentium rem distinctio voluptas. Corporis possimus aliquam adipisci molestias voluptate repellendus pariatur necessitatibus amet non quidem?
                                </span>
                            </div>
                            <div className="d-flex flex-column border border-1  border-dashed border-gray-300 text-center py-2 card-rounded my-2">
                                <span className="fs-2 fw-bold counted text-gray-800"> 2. Simple offers:
                                </span>
                                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum dolor aperiam accusamus reiciendis maiores! Sunt, natus nisi id architecto minima soluta perferendis excepturi commodi sint rem ea suscipit possimus quas.
                                </span>
                            </div>
                            <div className="d-flex flex-column border border-1  border-dashed border-gray-300 text-center py-2 card-rounded my-2">
                                <span className="fs-2 fw-bold counted text-gray-800"> 3. Statuses:
                                </span>
                                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestiae, laboriosam est nesciunt illo, et repudiandae animi, facere consequatur ea earum excepturi. Tempora molestias nihil dicta porro minus mollitia. Quisquam, ducimus!
                                </span>
                            </div>
                            <div className="d-flex flex-column border border-1  border-dashed border-gray-300 text-center py-2 card-rounded my-2">
                                <span className="fs-2 fw-bold counted text-gray-800"> Expanded Marketing Reach:
                                </span>
                                <span className="fw-semi-bold text-gray-600 fs-7 pb-1 text-justify px-4">
                                    With Bricks, you can boost your marketing efforts by showcasing
                                    properties on external platforms. This helps you reach a wider audience and attract potential
                                    clients more effectively.
                                </span>
                            </div>
                            <div className="fv-row my-2 py-2  w-100  mx-4 px-2">
                                <div className='d-flex align-items-center'>
                                    <label className="form-check form-check-solid form-check-inline">
                                        <input
                                            onChange={(e) => { setAgree(e.target.checked); if (!agree) { setShowError(false); } }}
                                            //  {...register("toc", { required: })} // `${row.content?.[1]} and ${row.content?.[2]}`
                                            className="form-check-input h-30px w-30px pt-2"
                                            type="checkbox"
                                            name={"toc"}
                                        />
                                    </label>
                                    <span className="fw-semi-bold fs-5 py-2 my-2">
                                        I Accept the
                                        <RulesModals row={'Terms & Conditions'} />
                                        {'  '}and{'  '}
                                        <RulesModals row={'Privacy Policy'} />
                                    </span>
                                </div>
                                {(showError) && (
                                    <div className="text-danger">{'Please accept Terms & Conditions and Privacy Policy to proceed'}</div>
                                )}
                            </div>
                            <div className="btn btn-bso fs-6 w-100 py-4 d-flex align-items-center justify-content-center"
                                onClick={async () => {
                                    if (agree) {
                                        dispatch(dataFetchingStart())
                                        const req = await fetch(`${urlAPI}`, {
                                            method: 'POST',
                                            body: JSON.stringify({
                                                method: 'PUT',
                                                collection: 'users',
                                                id: Number(userID),
                                                body: {
                                                    TermsAndConditionsApply: true
                                                }
                                            }),
                                            headers: {
                                                'Content-Type': 'application/json',
                                                Authorization: `Bearer ${window.localStorage.getItem(ACCESS_KEY) ?? ''}`
                                            }
                                        })
                                        const reqJSON = await req.json()
                                        console.log(reqJSON, "reqJSON")
                                        if (reqJSON.success) {
                                            if (reqJSON.response.error != null) {
                                                swalError(reqJSON)
                                            } else {
                                                dispatch(userSlice.actions.userFetchingSuccess(reqJSON?.response))
                                                if (reqJSON.response?.Category === 'Agent') {
                                                    dispatch(setStatuses(getAgentStatuses(reqJSON.response?.Documents)))
                                                }

                                            }
                                        }

                                        dispatch(dataFetchingFinish())
                                        onClose()

                                    } else {
                                        setShowError(true)
                                    }
                                }
                                }
                            >
                                {
                                    loading ?
                                        <span className="indicator-progress  d-block">
                                            Please wait...
                                            <span className={'spinner-border spinner-border-sm align-middle ms-2'}></span></span> :
                                        <span className="indicator-label">
                                            Yes I am agree
                                        </span>
                                }
                            </div>
                        </>
                    </div>
                </div >

            </Drawer >
        </>
    )
}
