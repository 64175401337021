
import { getErrorss, getMessageForError } from "../../../../../functions/errors/GetErrors"
import { getQueryOffers } from "../../../../../functions/shared/api/queries/getQueryOffers"
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from "../../../../../urls"
import { documentAddSuccess } from "../../../../slices/documentSlice"
import { dataFetchingFinish, dataFetchingStart, setWhereAfterToGo } from "../../../../slices/modalSlice"
import { setOffers, type IOffer } from "../../../../slices/offersSlice"
import { type AppDispatch, type RootState } from "../../../../store"
import { createDocument } from "../../../documentsActions/createDocument"

export const addOfferCustomDocument = (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ isSuccessful: boolean, textNotification?: string, textNavigate?: string }> => {

    try {
        dispatch(dataFetchingStart())
        const { objectID , custom_document } = data
        const jwt = window.localStorage.getItem(ACCESS_KEY)  ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY)
        const offer = getState().offers.offers.find((offerItem) => offerItem.id === Number(objectID)) as IOffer
      


        const fileList = custom_document
        if (fileList != null && fileList?.length > 0) {
            const docName = offer?.attributes?.StatusUpdateDescription?.split("UPLOAD-URGENT")?.[1].trim() ?? "Document"
            const { isSuccessful, response } = await dispatch(createDocument(fileList, {
                RefID: `REF_DOC_${docName}_${new Date().getTime()}_${0}`,
                Name:  docName,
                Statuses: ['In-process'],
                Category: "Offer Process Document" ,
                Offer: offer?.id
            }, 'offers', String(offer?.id)))
            if (isSuccessful && response?.data?.id != null) {
                dispatch(documentAddSuccess(response.data))   
                const bodyObject = {
                    method: 'PUT',
                    id: Number(objectID),
                    collection: 'offers',
                    query: getQueryOffers(),
                    body: {
                        ...offer?.attributes,
                        Type: [{
                            ...(offer?.attributes?.Type?.[0]),
                        }],
                        Documents:  [...(offer?.attributes?.Documents?.data?.map(doc => ({ id: doc.id })) ?? []),  { id: response?.data?.id }] , // , response?.data?.id
                        Statuses: ['Waiting for updates'],
                        RejectReason: null,
                        StatusUpdateDescription: `Wait ${docName} approving`,
                        Notify: 'BSO', 
                    }
                }
                const responseOffer = await fetch(`${urlAPI}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'BSO-Hash': '02ns30nsBSO!',
                        Authorization: `Bearer ${String(jwt)}`
                    },
                    body: JSON.stringify(bodyObject)
                })
                const responseJSON = await responseOffer.json()
                const typeOffer = offer?.attributes?.Type?.[0]?.__component?.split('.')[1] ?? ''
                if (responseJSON.success === true && responseJSON?.response?.data?.id != null) {
                    // TO DO doc is loaded
                    //    await sendNotification(notifyDelay(), Number(userID), Number(objectID), 'admin-offer-client-info', 'Offer - Waiting booking form sign')
           
                    dispatch(setOffers(responseJSON.response.data))
                    dispatch(dataFetchingFinish())
                    return {
                        textNotification:  "Your document loaded successfully",
                        textNavigate:`/agent/modalView/offer_modal/${offer?.attributes?.Unit?.data?.id}/${objectID as string}/catalog?type=${typeOffer ?? 'not'}`,
                        isSuccessful: true
                    }
                } else {
                    dispatch(dataFetchingFinish())
                    return {
                        textNotification: getErrorss(responseJSON),
                        isSuccessful: false
                    }}
            } else {
                dispatch(dataFetchingFinish())
                return {
                    textNotification: getErrorss(response),
                    isSuccessful: false
                }
            }
        } else {
            dispatch(dataFetchingFinish())
            return {
                textNotification: 'Please upload document again',
                isSuccessful: false
            }
        }

    } catch (err) {
        console.error(err)
        dispatch(dataFetchingFinish())
        return {
            textNotification: getErrorss(getMessageForError("Something went wrong. Try again later")),
            isSuccessful: false
        }
    }

}
