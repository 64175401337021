import { type NavigateFunction } from 'react-router-dom'
import Swal from 'sweetalert2'
import { swalOptions } from '../../../../functions/swalOptions'
import { type AppDispatch, type RootState } from '../../../store'

const AGENT_TO_BSO_OFFER_COMMUNICATION_STORAGE_KEY = 'agent-to-bso-offer-communication-storage-key'

type AgentToBSOOfferCommunicationStorageInfoStep = 'client_info' | 'offer_info' | 'client_bank'
type AgentToBSOOfferCommunicationStorageInfoVariant = 'create' | 'resubmit'

export const writeAgentToBSOOfferCommunicationStorageInfo = (unitID: number, offerID: number, step: AgentToBSOOfferCommunicationStorageInfoStep, variant: AgentToBSOOfferCommunicationStorageInfoVariant): void => {
    window.localStorage.setItem(AGENT_TO_BSO_OFFER_COMMUNICATION_STORAGE_KEY, JSON.stringify({ unitID, offerID, step, variant }))
}

export const readAgentToOfferCommunicationStorageInfo = (): { offerID: number, unitID: number, step: AgentToBSOOfferCommunicationStorageInfoStep, variant: AgentToBSOOfferCommunicationStorageInfoVariant } | null => {
    const storedData = window.localStorage.getItem(AGENT_TO_BSO_OFFER_COMMUNICATION_STORAGE_KEY)
    if (storedData != null) {
        const storedJSON = JSON.parse(storedData)
        if (storedJSON.unitID != null && storedJSON.offerID != null) {
            return {
                unitID: Number(storedJSON.unitID),
                offerID: Number(storedJSON.offerID),
                step: storedJSON.step,
                variant: storedJSON.variant
            }
        }
    }
    return null
}

export const deleteAgentToBSOOfferCommunicationStorageInfo = (): void => {
    window.localStorage.removeItem(AGENT_TO_BSO_OFFER_COMMUNICATION_STORAGE_KEY)
}

export const agentToBSOOfferCommunicationCheckBrokenPipeline = (navigate: NavigateFunction) => (dispatch: AppDispatch, getState: () => RootState) => {
    const storedData = readAgentToOfferCommunicationStorageInfo()
    const unit = getState().units.units.find(unitItem => unitItem.id === storedData?.unitID)
    const offer = getState().offers.offers.find(offerItem => offerItem.id === storedData?.offerID)
   
    if (unit != null && offer != null && offer?.attributes?.Statuses?.[0] !== 'Canceled') {
        const html = '<div class="d-flex justify-content-between">' +
                '<div>Offer Unit:</div>' +
                `<strong>${String(unit?.attributes?.Marketing?.Headline)}</strong>` +
            '</div>' +
            '<div class="d-flex justify-content-between">' +
                '<div>Offer Client:</div>' +
                `<strong>${String(offer?.attributes?.Type?.[0]?.FirstName ?? offer?.attributes?.Type?.[0]?.BusinessName)} ${String(offer?.attributes?.Type?.[0]?.LastName ?? '')}</strong>` +
            '</div>'

        void Swal.fire({
            ...swalOptions('info'),
            title: 'You didn\'t fill the info for offer',
            html,
            showConfirmButton: true,
            confirmButtonText: 'Fill the info',
            showDenyButton: true,
            denyButtonText: 'Don\'t ask me again',
            allowOutsideClick: true
        })
            .then((result) => {
                if (result.isConfirmed) {
                    switch (storedData?.step) {
                        case 'client_info':
                            navigate(`/agent/modal/${storedData.variant === 'resubmit' ? 'agent_to_bso_resubmit_offer_info' : 'agent_to_bso_offer_offer_info'}/${unit.id}/${offer.id}`)
                            break
                       
                        default:
                            break
                    }

                } else if (result.isDenied) {
                    deleteAgentToBSOOfferCommunicationStorageInfo()
                }
            })
    }
}
