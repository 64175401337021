import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { USER_EMAIL_KEY } from '../../urls'

interface Unit {
    id: number
    createdAt: string
    updatedAt: string
    RefID: string
    GroupID: null | number
    Number: number
    SizeArea: number
    Bedrooms: number
    Bathrooms: number
    Type: string
    Fit: string
    View: string
    Features: string[]
    Amenities: string[]
    Appliances: string[]
    Viewings?: any
    Images: string[]
    ManagedBy: string
    Statuses: string[]
    ImagesOnly : boolean | null
    SpecialBrokers : any

    Property?: {
        id: number
        createdAt: string
        updatedAt: string
        Developer: null
        Name: null | string
        Area: null | string
        PlotNumber: null | string
        Images: null | string[]
        Statuses: null | string
        MAKANI: null | string
        Type: null | string
        Community: null | string
        GoogleMapsLink: null | string
        Address: null | string
        Utilities: Array<{ id: number
            BuildingManagementName: null | string
            CoolingProviderName: null | string
            GasSupplierName: null | string }>
    }
    Marketing?: null | Array<{ id: number
        Headline: null | string
        Price: null | number
        Status: null | string
        Use: null | string
        DiscountHeadline: null | string
        DiscountPrice: null | string
        DiscountStartDatetime: null | string
        DiscountEndDatetime: null | string
        Description: null | string }>
    UnitKeys?: null | Array< {
        id: number
        PickUpDate: string
        DropOutDate: string
        createdAt: string
        updatedAt: string
    }>
}

interface Contact {
    __component: string
    Country: string | null
    DateOfBirth: string | null
    EmiratesIDNumber: string | null
    Gender: string | null
    Name: string
    PassportNumber: string | null
    Photo: string | null
    id: number
    blocked: boolean
    confirmed: boolean
}

export interface OrderService {
    __component: string
    id: number
    Package: string
    StartDate: string
    ExpiryDate: string
}

export interface IOrder {
    id: number
    createdAt: string | null
    updatedAt: string | null
    Name: string | null
    Amount: number
    InvoiceRefNumber: string | null
    InvoiceDate: string | null
    InvoiceDueDate: string | null
    Statuses: string[] | null
    Service: OrderService[]
    Unit: string | null
    InvoiceURL: string | null
    ReceiptURL: string | null
    SessionID: string | null

}

export interface Documents {
    ExpiryDate?: string | null
    Link?: string | null
    PrepareDate?: string | null
    RefID?: string | null
    SignDate?: string | null
    Statuses?: string[]
    createdAt: string | null
    id: number | null
    updatedAt: string | null
    Type?: Record<string, any>
}

export interface ISettings {
    __component: 'settings.agent'
    id: number
    Emirate: string | null
    Activity: string | null
    Type: string | null
    Areas: [] | string[]
    Performance: string | null
    BSOCredits: string | null
    Membership: string | null
    Business: number | null
    BroadcastCredits: number | null
    MarketingCredits: number | null
    Notifies: {
        id: number
        Broadcast: boolean
        Chat: boolean
        Messages: boolean
        Offers: boolean
        Requests: boolean
        Scheduler: boolean
        ViewingBefore1Hour: boolean
        ViewingBefore2Hours: boolean
        ViewingBefore30Minutes: boolean
        Viewings: boolean }
    Company: string | null
    WallpaperLink  : string | null
    GifLink : string | null
    ShowStats : boolean
    Languages : null | string[]
}

export interface IContactInfo {
    id: number
    Avatar: string | null
    Email: string | null
    Phone: string | null
    ResidentialAddress: string | null
    PostalCode: string | null
    FirstName: string | null
    FamilyName: string | null
    DateOfBirth: string | null
    Country: string | null
    Gender: string | null
}

export interface IClient {
    id: number
    FirstName: string
    LastName: string
    Email: string
    Phone: string
    Areas?: string[]
    Features?: string[]
    Amenities?: string[]
    Appliances?: string[]
    Bedrooms?: number
    Bathrooms?: number
    PriceStart?: number
    PriceEnd?: number
    createdAt?: string
    updatedAt?: string
}

export interface User {
    BankDetails: null
    Category: string
    DeviceToken: string | null
    RefID: null
    Chats: any
    Clients: IClient[]
    Documents?: Documents[] | null
    Settings: ISettings[] | null
    Orders?: IOrder[] | null
    Type: Contact[]
    blocked: boolean
    confirmed: boolean
    createdAt: string
    email: string
    emailVerified: boolean
    newEmail: string | null
    id: number
    phone: string
    Referrals: any
    phoneVerified: null
    ContactInfo: IContactInfo | null
    Viewings?: any
    Offers?: any
    Advertises?: any
    Units?: null | Unit[]
    provider: string
    updatedAt: string
    username: string
    AreasExtentionAmount: string | null
    tutorialCompleted: boolean | null
    LastPayDestination: string | null
    DBState: string | null
    Stats : {
        ViewingClicks:number| null
        OfferClicks:number| null
        AdvertiseClicks:number| null 
        AgentToAgentViewingClicks:number| null 
        AgentToAgentOfferClicks:number| null 
        LoginCounts :  Array<Record<string, any>> | null
        DBState: string | null
    }
    FavoriteUnits : null | number[]
    About : string | null
    TermsAndConditionsApply : boolean | null
}

interface UserState {
    currentUser: User | Record<string, unknown>
    allUsers: User[] | []
    profiles: User[]
    referrals: User[]
    searchString: string
    clientsSearchString: string
    loading: boolean
    deletingAccount: boolean
    error: string
    page: number
    pageCount: number
    stopPagination: boolean
    accountsScrollPosition: number
  
}

const initialState: UserState = {
    currentUser: {},
    allUsers: [],
    profiles: [],
    referrals: [],
    searchString: '',
    clientsSearchString: '',
    loading: false,
    deletingAccount: false,
    error: '',
    page: 1,
    pageCount: -1,
    stopPagination: false,
    accountsScrollPosition: 0,


}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {

        setDeletingAccount (state, action) {
            state.deletingAccount = action.payload
        },
        userFetching (state) {
            state.loading = true
        },
        userFetchingSuccess (state: UserState, action: PayloadAction<User>) {
            state.error = ''
            state.currentUser = action.payload
            state.loading = false
            window.localStorage.setItem(USER_EMAIL_KEY, action.payload.email)

        },
        userUpdate (state: UserState, action: PayloadAction<User>) {
            const prewUser = { ...state.currentUser }
            const newUserChanges = { ...action.payload }
            state.currentUser = { ...prewUser, ...newUserChanges }
            state.loading = false
        },
        allUsersFetchingSuccess (state: UserState, action: PayloadAction<any>) {
            state.error = ''
            state.allUsers = action.payload
            state.loading = false
        },
        setProfiles (state: UserState, action: PayloadAction<any>) {
            state.profiles = action.payload
        },
        userFetchingError (state, action) {
            state.loading = false
            state.error = action.payload.message
        },
        userSetSearchString (state: UserState, action: PayloadAction<string>) {
            state.searchString = action.payload
        },
        clientsSetSearchString (state: UserState, action: PayloadAction<string>) {
            state.clientsSearchString = action.payload
        },
        setReferrals (state, action) {
            state.referrals = action.payload
        },
        accountsSetPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload
        },
        accountsSetPageCount: (state, action: PayloadAction<number>) => {
            state.pageCount = action.payload
        },
        accountsSetStopPagination: (state, action: PayloadAction<boolean>) => {
            state.stopPagination = action.payload
        },
        accountsSetScrollPosition: (state, action: PayloadAction<number>) => {
            state.accountsScrollPosition = action.payload
        },
        setCurrentUserUnits: (state: UserState, action) => {
            state.currentUser.Units = action.payload
        }
    }
})

export const {
    userFetchingSuccess,
    userUpdate,
    userSetSearchString,
    clientsSetSearchString,
    setProfiles,
    setReferrals,
    accountsSetPage,
    accountsSetPageCount,
    accountsSetStopPagination,
    accountsSetScrollPosition,
    setCurrentUserUnits
} = userSlice.actions

export default userSlice.reducer
