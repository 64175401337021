import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { type RootState } from '../../../../../store/store'
import { setScheduleFilters } from '../../../../../store/slices/modalSlice'






// case 'advertises':
//     statuses = [
//         'all',
//         'Approved',
//         'Confirmed',
//         'Completed',
//         'Canceled',
//         'Rejected',
//     ]
//     chooseFilterStatus = "byAdvertiseStatus"


export default function ScheduleSortViewingsByStatuses(): JSX.Element {
    const scheduleFilters = useAppSelector((state: RootState) => state.modal.scheduleFilters)
    const dispatch = useAppDispatch()
    const statuses = [
        'all',
        'Rescheduled by BSO',
        'Rescheduled by agent',
        'Rescheduled by other agent',
        'Approved',
        'Confirmed',
        'Completed',
        'Canceled',
        'Rejected',
        'Canceled by other agent',
        'Waiting for complain'
    ]

    return (
        <div className="d-flex flex-wrap">
            {statuses.map((status) => (
                <div key={status} className={`form-check form-check-custom ${scheduleFilters.byViewingStatus === status ? 'form-check-warning' : 'form-check-success'} form-check-solid my-2`}>
                    <input
                        className="ms-6 form-check-input h-25px w-25px border border-5 border-gray-300"
                        type="radio"
                        value={status}
                        checked={scheduleFilters.byViewingStatus === status}
                        onChange={() => dispatch(setScheduleFilters({ ...scheduleFilters, byViewingStatus: status }))}
                    />
                    <div className="ms-2 py-2 fw-semibold lh-1">
                        {status.toUpperCase()}
                    </div>
                </div>
            ))}
        </div>
    );
}
