import React from 'react';
import { SchedulerStatus } from '../../../../functions/agent/date/SchedulerStatus';
import { ShowReason } from '../timelineUtils/ShowReason';

interface StepComponentProps {
    title: React.ReactNode;
    description: string;
    addTitle?: string;
    process: number;
    currentProcess: number;
    statuses: string[];
    rejectReason?: string;
    negotiationReason?: string;
}

export const StepComponent: React.FC<StepComponentProps> = ({ title, addTitle, description, process, currentProcess, statuses, rejectReason, negotiationReason }) => {

    return (
        <div>
            <div className='lh-sm'>{title}</div>
            {addTitle?.trim() ? <div className='lh-sm mb-1'> {addTitle} </div> : null}
            <div className={'d-flex align-items-center my-1'}>
                {currentProcess === process &&
                    <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                <div className="">{currentProcess === process ? SchedulerStatus(statuses ?? [], 'fs-6 my-1') : undefined}</div>
            </div>
            {(statuses?.includes('Rejected') || statuses?.includes('Slip rejected')) && rejectReason &&
                <ShowReason statuses={statuses} reason={rejectReason} />
            }
            {(statuses?.[0]?.includes?.('Negotiated') || statuses?.includes('Waiting draft contract send')) && negotiationReason &&
                <ShowReason statuses={statuses} reason={negotiationReason} />
            }
        </div>
    );
};

export default StepComponent;