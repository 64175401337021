import React, { useEffect } from 'react'
import MenuItem from './MenuItem'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../../store/slices/authSlice'
import { swalSureLogout } from '../../../functions/swalOptions'

import Icon from '../../../app/icon/Icon'
import { store, type RootState } from '../../../store/store'
import { removeNotifyByDeviceToken } from '../../../functions/shared/notify-helpers/removeNotify'
import { BSO_DEVELOP_MODE_ON_FRONT } from '../../../urls'

export default function MenuPage(): JSX.Element {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const documentsLoaded = useAppSelector((state: RootState) => state.agentStates.documentsLoaded)
    const loadSts = documentsLoaded.emiratesLoaded || documentsLoaded.passportLoaded



    useEffect(() => {
        document.getElementById('menu_page')?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }, [])

    const logoutHandler = (): any => {
        void swalSureLogout().then(async (result: any) => {
            if (result.isDenied) {
                dispatch(logout())
                void removeNotifyByDeviceToken({
                    userID: store.getState().user.currentUser.id as number,
                    deviceToken: store.getState().user.currentUser.DeviceToken as string,
                    badges: 0
                })
                location.reload()
                navigate('/auth/login')
            }
        })
    }

    const isDevFront = window.localStorage.getItem(BSO_DEVELOP_MODE_ON_FRONT)

    return (
        <>
            <div
                className={`page d-flex flex-column flex-row-fluid fs-5 ${userDevice === 'IPhone' ? 'pt-8' : 'pt-0'}`} id='menu_page'
            // style={{ height: '100vh' }}
            >
                <div
                    className="content d-flex flex-column flex-fill p-0 m-4 " style={{ height: '100vh' }}
                >
                    <div className="w-100 mw-450px mx-auto ">

                        <div className={`card shadow-sm mb-4 ${userDevice === 'IPhone' ? 'mt-8' : 'mt-0'}`}>
                            <div className="card-body p-4">
                                <MenuItem
                                    label={'Profile'}
                                    description={'Profile, dashboard, BSO credit balance'}
                                    route={'/agent/profile/me'}
                                    bgColor={'primary'}
                                    icon={

                                        <Icon
                                            name={'person'}
                                            className={'fs-3x  fs-lg-2x mx-auto text-white'}
                                        />
                                    }
                                />
                                <MenuItem
                                    label={'Settings'}
                                    route={'/agent/settings'}
                                    description={'Update contact details, payment methods, notifications'}
                                    bgColor={'warning'}
                                    icon={
                                        <Icon
                                            name={'settings'}
                                            className={'fs-3x  fs-lg-2x mx-auto text-white'}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        {loadSts && <div className="card shadow-sm mb-4 ">
                            <div className="card-body p-4">
                                {isDevFront === 'true'
                                    ? <MenuItem
                                        label={'Business'}
                                        route={'/agent/menu/business'}
                                        description={'Manage units manage clients, Refer and earn'}
                                        bgColor={'info'}
                                        icon={
                                            <Icon
                                                name={'monetization_on'}
                                                className={'fs-3x  fs-lg-2x mx-auto text-white'}
                                            />
                                        }
                                    />
                                    : null}

                                <MenuItem
                                    description={'View payment history, get addons'}
                                    label={'Orders'}
                                    route={'/agent/menu/orders'}
                                    bgColor={'danger'}

                                    icon={

                                        <Icon
                                            name={'list'}
                                            className={'fs-3x  fs-lg-2x mx-auto text-white'}
                                        />
                                    }
                                />

                                <MenuItem
                                    description={'Current subscription, upgrade plan'}
                                    label={'My Subscription'}
                                    route={'/agent/settings/subscriptions'}
                                    bgColor={'warning'}
                                    icon={

                                        <Icon
                                            name={'bookmark'}
                                            className={'fs-3x  fs-lg-2x mx-auto text-white'}
                                        />
                                    }
                                />

                                {isDevFront === 'true'
                                    ? <MenuItem
                                        description={'Membership overview, view progress, Agent Leaderboard'}
                                        label={'Performance'}
                                        route={'/agent/menu/performance'}
                                        bgColor={'info'}
                                        icon={
                                            <></>
                                        }
                                    />
                                    : null}
                            </div>
                        </div>}

                        <div className="card shadow-sm mb-4 ">
                            <div className="card-body p-4">

                                <MenuItem
                                    description={'Learn more about BSO club features'}
                                    label={'Tutorials'}
                                    route={'/agent/settings/tutorials'}
                                    bgColor={'primary'}
                                    icon={
                                        <Icon
                                            name={'dvr'}
                                            className={'fs-3x  fs-lg-2x mx-auto text-white'}
                                        />

                                    }
                                />

                                <MenuItem
                                    description={'Privacy Policy and Terms & Conditions'}
                                    label={'Legal'}
                                    route={'/agent/menu/legal'}
                                    bgColor={'success'}
                                    icon={
                                        <Icon
                                            name={'policy'}
                                            className={'fs-3x  fs-lg-2x mx-auto text-white'}
                                        />

                                    }
                                />
                            </div>
                        </div>
                        <div className="card shadow-sm mb-4">
                            <div className="card-body p-4">

                                <MenuItem
                                    label={'Logout'}
                                    route={'#'}
                                    onClick={logoutHandler}
                                    description={''}
                                    bgColor={'danger'}
                                    icon={
                                        <Icon
                                            id={'lottie-error'}
                                            name={'door_open'}
                                            className={'fs-3x  fs-lg-2x mx-auto text-white'}
                                        />

                                    }
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="m-0" style={{ height: '195px' }}></div>
            </div>
        </>
    )
}
