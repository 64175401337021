import React from 'react';

export const iconProcess = (step: number, process: number, totalSteps: number): JSX.Element | undefined => {
    if (process + 1 > step || process === totalSteps) {

        return undefined
    }
    return (<div className="ant-steps-item-icon">
        <span className="ant-steps-icon" style={{ color: 'black' }}>
            {step}
        </span>
    </div>
    )
}
