import React from 'react'



interface IProps {
    localFilters: Record<string, any>
    setLocalFilters: React.Dispatch<React.SetStateAction<Record<string, any>>>
}
export default function ScheduleSort({ localFilters, setLocalFilters }: IProps): JSX.Element {


    const handleFilterChange = (sort: string): void => {
        setLocalFilters({ ...localFilters, sort })
    }


    return (
        <div className="fs-6 text-uppercase ">
            <div className="d-flex flex-row align-items-center"
                onClick={() => { handleFilterChange('all') }}

            >
                <div className={`form-check form-check-custom 
                                 ${localFilters.sort === 'all' ? 'form-check-warning' : 'form-check-sucsess '} form-check-solid`}

                >
                    <input
                        className="form-check-input h-25px w-25px border border-5 border-gray-300"
                        type="radio"
                        value="all"
                        checked={localFilters.sort === 'all'}
                        onChange={() => { handleFilterChange('all') }}

                    />
                    <div
                        className=" ms-4 py-2 fw-semibold"

                    >
                        All
                    </div>
                </div>

            </div>
            <div className="separator separator-dotted border-gray-300 my-2"></div>
            <div className="d-flex flex-row align-items-center"

                onClick={() => {
                    handleFilterChange('bso_units')
                }}
            >
                <div className={`form-check form-check-custom ${localFilters.sort === 'bso_units' ? 'form-check-warning' : 'form-check-success '} form-check-solid`}

                >

                    <input
                        className="form-check-input h-25px w-25px border border-5 border-gray-300"
                        type="radio"
                        value="bso_units"
                        checked={localFilters.sort === 'bso_units'}
                        onChange={() => { handleFilterChange('bso_units') }}

                    />
                    <div
                        className=" ms-4 py-2 fw-semibold"

                    >
                        BSO Units
                    </div>
                </div>
            </div>
            <div className="separator separator-dotted border-gray-300 my-2"></div>
            <div className="d-flex flex-row align-items-center " onClick={() => {
                handleFilterChange('other_units')
            }}>
                <div className={`form-check form-check-custom 
                                 ${localFilters.sort === 'other_units' ? 'form-check-warning' : 'form-check-sucsess '} form-check-solid`}

                >
                    <input
                        className="form-check-input h-25px w-25px border border-5 border-gray-300"
                        type="radio"
                        value="other_units"
                        checked={localFilters.sort === 'other_units'}
                        onChange={() => { handleFilterChange('other_units') }}

                    />
                    <div
                        className=" ms-4 py-2 fw-semibold"

                    >
                        Other Agents Units
                    </div>
                </div>
            </div> <div className="separator separator-dotted border-gray-300 my-2"></div>
            <div className="d-flex flex-row align-items-center" onClick={() => {
                handleFilterChange('my_units')
            }}>
                <div className={`form-check form-check-custom 
       
                                      ${localFilters.sort === 'my_units' ? 'form-check-warning' : 'form-check-sucsess '}
                                    form-check-solid`}

                >
                    <input
                        className="form-check-input h-25px w-25px border border-5 border-gray-300"
                        type="radio"
                        value="my_units"
                        checked={localFilters.sort === 'my_units'}
                        onChange={() => { handleFilterChange('my_units') }}

                    />
                    <div
                        className=" ms-4 py-2 fw-semibold"

                    >
                        My Units
                    </div>
                </div>
            </div>
        </div>
    )
}
