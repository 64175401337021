import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { setScheduleFilters } from '../../../../store/slices/modalSlice'

export default function ScheduleSortTypeUnits(): JSX.Element {
    const scheduleFilters = useAppSelector((state: RootState) => state.modal.scheduleFilters)
    const dispatch = useAppDispatch()
    return (
        <div className="d-flex flex-row align-items-center justify-content-between">
            <div className={`form-check form-check-custom ${scheduleFilters.sortByTypeUnit === 'all' ? 'form-check-warning' : 'form-check-sucsess '} form-check-solid`}>
                <input
                    className="form-check-input h-25px w-25px border border-5 border-gray-300"
                    type="radio"
                    value="all"
                    checked={scheduleFilters.sortByTypeUnit === 'all'}
                    onChange={() => dispatch(setScheduleFilters({ ...scheduleFilters, sortByTypeUnit: 'all' }))}
                />
                <div className=" ms-4 py-2 fw-semibold">
                    ALL
                </div>
            </div>
            <div className={`form-check form-check-custom ${scheduleFilters.sortByTypeUnit === 'sale' ? 'form-check-warning' : 'form-check-sucsess '} form-check-solid`}>
                <input
                    className="form-check-input h-25px w-25px border border-5 border-gray-300"
                    type="radio"
                    value="sale"
                    checked={scheduleFilters.sortByTypeUnit === 'sale'}
                    onChange={() => dispatch(setScheduleFilters({ ...scheduleFilters, sortByTypeUnit: 'sale' }))}
                />
                <div className=" ms-4 py-2 fw-semibold">
                    SALE
                </div>
            </div>
            <div className={`form-check form-check-custom ${scheduleFilters.sortByTypeUnit === 'rent' ? 'form-check-warning' : 'form-check-sucsess '} form-check-solid`}>
                <input
                    className="form-check-input h-25px w-25px border border-5 border-gray-300"
                    type="radio"
                    value="rent"
                    checked={scheduleFilters.sortByTypeUnit === 'rent'}
                    onChange={() => dispatch(setScheduleFilters({ ...scheduleFilters, sortByTypeUnit: 'rent' }))}
                />
                <div className=" ms-4 py-2 fw-semibold">
                    RENT
                </div>
            </div>
        </div>
    )
}
