
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDubaiDate'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { sendNotification } from '../../../functions/shared/notifications/sendNotification'
import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../../urls'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'
import { setViewings, viewingsFetchingError } from '../../slices/viewingsSlice'
import { type AppDispatch, type RootState } from '../../store'
import { viewingsQuery } from '../viewingsActions'

export const cancelViewing = (bodyClient: any) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
        const isOtherAgent = bodyClient.isOtherAgent

        dispatch(dataFetchingStart())
        try {
            const response = await fetch(`${urlAPI}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    body: JSON.stringify({
                        method: 'PUT',
                        collection: 'viewings',
                        id: Number(bodyClient.viewID),
                        query: viewingsQuery,
                        body: {
                            Statuses: ['Canceled'],
                            Notify: (isOtherAgent) ? 'Other Agent' : 'BSO',
                            RejectReason : null

                        }
                    })
                })
            const dataJSON = await response.json()
            if (dataJSON.success) {
                if (dataJSON.response.error != null) {
                    dispatch(viewingsFetchingError(getErrorss(dataJSON)))
                    dispatch(dataFetchingFinish())
                    return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
                } else {
                    const currentDubaiDate = getCurrentDubaiDate()
                    const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                    await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-rejected', `Attention admin, ${bodyClient?.nameUser} CANCELED viewing by ${bodyClient?.unitAdress}.`)
                    dispatch(setViewings(
                        dataJSON.response.data
                    ))
                    dispatch(dataFetchingFinish())
                    return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
                }
            } else {
                dispatch(dataFetchingFinish())
                dispatch(viewingsFetchingError(getErrorss(dataJSON)))
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            }
        } catch (e) {
            dispatch(dataFetchingFinish())
            console.log(e)
        }
    }
}
