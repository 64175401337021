import React from 'react'
import useUserType from '../../../../hooks/UseUserType'
import { Link } from 'react-router-dom'
import { openChatByUserID } from '../../../../store/actionsCreators/chatActions'
import { useLocation, useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import _ from 'lodash'
import { type RootState } from '../../../../store/store'
import { type User } from '../../../../store/slices/userSlice'
import { USER_ID_KEY } from '../../../../urls'
import { userUpdateClickCount } from '../../../../store/actionsCreators/userActions/userUpdateClickCount'

const BSOUnitButtons = ({ unit, sentBy }: any): JSX.Element => {
    const { userType } = useUserType()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser) as User
    let offerModalType = ''

    if (unit.attributes?.Marketing?.Use === 'For rent') {
        offerModalType = unit.attributes?.Type === 'Residential' ? 'residential' : 'commercial'
    } else {
        offerModalType = 'sale'
    }

    const { pathname } = useLocation()
    const isBSOBroadcast = _.includes(pathname, 'chats') && pathname.slice(-2) === '/4'
    const properties = useAppSelector((state: RootState) => state.property.properties)

    const propertyID = properties.filter(prop => {
        return prop?.attributes?.Units?.data?.some(_unit => _unit.id === unit.attributes.id)
    }
    ).at(0)?.id


    const lsUserId = window.localStorage.getItem(USER_ID_KEY)
    const userID = currentUser.id ?? lsUserId

    return (
        <div className="d-flex flex-row mb-0 px-4 gap-4 w-100 ">
            {
                sentBy === 'me'
                    ? <>
                        <div className="col w-100">
                            <Link
                                to={`/agent/modal/agent_edit_unit/${String(unit.attributes.id)}/${propertyID ?? '-'}`}
                                className="text-decoration-none">
                                <div className={`btn btn-bso btn-sm border border-dark m-0 fs-7 p-1 py-3  w-100`}>Edit</div>
                            </Link>
                        </div>
                        <div className="col w-100">
                            <Link
                                to={`/${userType.toLowerCase()}/my_schedule`}
                                className="text-decoration-none">
                                <div className={`btn btn-bso btn-sm border border-dark m-0 fs-7 p-1 py-3  w-100`}>   Go to schedule</div>
                            </Link>
                        </div>
                    </>

                    : <>

                        <div className="col w-100">
                            <div onClick={() => {
                                dispatch(userUpdateClickCount('viewing-agent'))
                                navigate(`/${userType.toLowerCase()}/modal/agent_set_viewing_modal/${String(unit.attributes.id)}/${String(userID)}`)
                            }} className={`btn btn-dark btn-sm border border-dark m-0 fs-7 p-1 py-3  w-100`}>Viewing</div>
                        </div>
                        {
                            isBSOBroadcast && process.env.REACT_APP_IS_DEV !== 'true'
                                ? <div className="col w-100">
                                    <div onClick={() => {
                                        dispatch(openChatByUserID(unit?.attributes?.User?.data?.id))
                                            .then(({ id }: { id: number }) => {
                                                navigate(`/${userType.toLowerCase()}/chats/${id}`)
                                            })
                                    }} className={`btn btn-white border border-dark m-0 fs-7 p-1 py-3  w-100`}
                                    >
                                        Chat
                                    </div>
                                </div>
                                : null
                        }

                        {
                            process.env.REACT_APP_IS_DEV === 'true' &&
                            <div className="col w-100">
                                <div onClick={() => {
                                    dispatch(userUpdateClickCount('offer-agent'))
                                    // navigate(`/agent/modal/agent_to_agent_make_offer/${unit.attributes.id}/${userID}`)
                                    navigate(`/agent/modal/agent_to_agent_make_offer_last/${unit.attributes.id}/${userID}`)
                                }} className={`btn btn-bso border border-bso m-0 fs-7 p-1 py-3 w-100 fs-7 `}>Offer</div>
                            </div>}
                    </>
            }

        </div>
    )
}

export default BSOUnitButtons
