import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import useUserType from '../../../hooks/UseUserType'
import AgentInfoForChildren from './contactInfo/AgentInfoForChildren'
import { removeOfferNotify } from '../../../store/actionsCreators/offerActions'
import OfferTimeline from './offerTimelines/OfferTimeline'
import { type Unit } from '../../../store/slices/unitsSlice'
import { type IOffer } from '../../../store/slices/offersSlice'
import OfferClientInfoForChildren from './contactInfo/OfferClientInfoForChildren'
import CopyClientInfo from './contactInfo/CopyClientInfo'
import { offerAdminViewStatuses } from '../../../constants'
import renderOfferStatus from "../../../functions/agent/offers/renderOfferStatus";
import Icon from "../../icon/Icon";
import { type RootState } from '../../../store/store'
import CardOffer from '../../../components/shared/schedule/offersList/CardOffer'
import OfferTaskForAdmin from '../../../components/shared/schedule/offersList/OfferTaskForAdmin'
import { nanoid } from 'nanoid'
import { useNavigate } from 'react-router-dom'
import Approved from '../../../functions/agent/statuses/Approved'
import { getOfferTypeName } from '../../../functions/agent/offers/getOfferTypeName'
import { cancelOffer } from '../../../store/actionsCreators/offerActions/cancelOffer'

interface IProps {
    currentUnit: Unit
    currentOffer: IOffer
}

export default function ChildOffer({ currentUnit, currentOffer }: IProps): JSX.Element {
    const { userType } = useUserType()
    const dispatch = useAppDispatch()
    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const unitOffers = offers.filter((offer: IOffer) => {
        return Boolean(offer?.attributes?.User.data?.id) && offer?.attributes?.Unit?.data?.id === Number(currentUnit.id) && offer?.attributes?.Statuses?.includes("Negotiation confirmed by agent")
    })


    useEffect(() => {
        const agentViewStatuses = ['Completed', 'Approved', 'Rejected', 'Canceled', 'Canceled by other agent', 'Confirmed', 'Signing F form', 'Signing other documents', 'Obtaining NOC', 'Downpayment', 'Transfer process', 'Waiting booking form sign']
        const otherAgentViewStatuses = ['Canceled', 'Canceled by other agent', 'Completed', 'Approved', 'Rejected']

        const landlordViewStatuses = offerAdminViewStatuses
        const agentNotifyBothNotViewStatuses = ['Negotiated by landlord']
        if (userType === 'Agent' && currentOffer?.attributes?.Notify === 'Agent' && agentViewStatuses.includes(currentOffer?.attributes?.Statuses?.[0])) {
            dispatch(removeOfferNotify(currentOffer.id))
        } else if (userType === 'Agent' && (currentOffer?.attributes?.Notify === 'Other Agent') && otherAgentViewStatuses.includes(currentOffer?.attributes?.Statuses?.[0])) {
            dispatch(removeOfferNotify(currentOffer.id, 'Agent'))
        }

        if (currentOffer.attributes?.Notify === 'Both' && !agentNotifyBothNotViewStatuses.includes(currentOffer?.attributes?.Statuses?.[0])) {
            if (userType === 'Agent') {
                dispatch(removeOfferNotify(currentOffer.id, 'BSO'))
            } else if (landlordViewStatuses?.includes(currentOffer?.attributes?.Statuses?.[0])) {
                dispatch(removeOfferNotify(currentOffer.id, 'Agent'))
            }
        } if (userType === 'Landlord' && currentOffer?.attributes?.Notify === 'BSO' && landlordViewStatuses?.includes(currentOffer?.attributes?.Statuses?.[0])) {
            dispatch(removeOfferNotify(currentOffer?.id))
        }
    }, []) // offer, userType, dispatch

    const [offerKey, setOfferKey] = useState(0)

    useEffect(() => {
        setOfferKey((prevKey) => prevKey + 1)
    }, [currentOffer])

    const navigate = useNavigate()
    const price = Number(currentOffer?.attributes?.Type?.[0]?.Offer) * 0.05

    return (
        <>
            <div className="fv-row mb-2 m-0 p-0 w-100">
                <div className="d-flex align-items-center">
                    <div className="fs-3 fw-bolder text-dark mb-4 lh-1">Offer Information</div>

                    <div className="fv-row mb-2 m-0 p-0 w-100 text-end">
                        <label className="form-label fs-5 fw-bold text-dark m-0">
                            Offer status
                        </label>
                        <div className="fw-normal text-dark mb-2">
                            <div className="badge badge-light-primary border border-primary fs-6 my-1">
                                {
                                    renderOfferStatus(currentOffer?.attributes?.Statuses?.[0] ?? '', currentOffer.id)
                                }
                            </div>
                        </div>

                        {currentOffer?.attributes?.RejectReason && (currentOffer?.attributes?.Statuses[0] === 'Rejected' || currentOffer.attributes?.Statuses[0] === 'Canceled by other agent')
                            ? <div className="fv-row mb-2 m-0 p-0 w-100">
                                <label className="form-label fs-5 fw-bold text-dark m-0">
                                    Reject reason
                                </label>
                                <div className="fw-normal text-dark mb-2">
                                    <div className="badge badge-light-primary border border-primary fs-6 my-1">
                                        {currentOffer?.attributes?.RejectReason}
                                    </div>
                                </div>
                            </div>
                            : <></>
                        }
                        {currentOffer?.attributes?.Type?.[0]?.DepositSlipLink != null
                            ? <div className="fv-row mb-2 m-0 p-0 w-100">
                                <label className="form-label fs-5 fw-bold text-dark m-0">
                                    Deposit confirmation
                                </label>
                                <div className="fw-normal text-dark mb-2">
                                    <a href={currentOffer?.attributes?.Type?.[0]?.DepositSlipLink} target={'_blank'} rel="noreferrer">
                                        <div className="badge badge-light-primary border border-primary fs-6 my-1">
                                            Link
                                        </div>
                                    </a>
                                </div>
                            </div>
                            : <></>
                        }
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="fs-6 fw-bolder text-dark  lh-1">Proposed amount</div>
                    <div className="fv-row mb-2 m-0 p-0 w-100 text-end">
                        <label className="form-label fs-4 fw-bold text-dark m-0">
                            {currentOffer?.attributes?.Type?.[0]?.Offer}
                            <span className="fs-7 fw-semibold text-dark"> AED</span>
                        </label>
                    </div>
                </div>
                {currentOffer?.attributes?.Type?.[0]?.__component?.split('.')[1] === "commercial" && < div className="d-flex align-items-center">
                    <div className="fs-6 fw-bolder text-dark  lh-1">Payments</div>
                    <div className="fv-row mb-2 m-0 p-0 w-100 text-end">
                        <label className="form-label fs-4 fw-bold text-dark m-0">
                            {currentOffer?.attributes?.Type?.[0]?.Payments}
                            <span className="fs-7 fw-semibold text-dark"> cheques</span>
                        </label>
                    </div>
                </div>}
                <div className="d-flex align-items-center">
                    <div className="fs-6 fw-bolder text-dark  lh-1">Type offer</div>
                    <div className="fv-row mb-2 m-0 p-0 w-100 text-end">
                        <label className="form-label fs-4 fw-bold text-dark m-0">
                            <Approved statusText={currentOffer?.attributes?.Type?.[0]?.__component?.split('.')[1]} fontsize='fs-7' />
                        </label>
                    </div>
                </div>
                <div className="fv-row my-2  p-2 w-100">
                    <div className="separator border border-bso border-3 px-2 my-2"></div>
                </div>
                <OfferTimeline key={offerKey} offer={currentOffer} unit={currentUnit} />
                {!['Canceled', 'Completed'].includes(currentOffer?.attributes?.Statuses?.[0]) && userType !== 'Landlord' &&
                    <div className="text-end">
                        <div className="text-end btn btn-bso w-40  fs-8 text-uppercase" key={nanoid()} onClick={() => { dispatch(cancelOffer(currentOffer?.id)) }}>
                            Cancel process
                        </div>
                    </div>}
                <div className="fv-row my-2  p-2 w-100">
                    <div className="separator border border-bso border-3 px-2 my-2"></div>
                </div>

                {userType === 'Landlord' &&
                    currentOffer?.attributes?.Statuses?.includes("Negotiation confirmed by agent")
                    &&
                    <>
                        <label className="text-center form-label fw-bold text-dark my-2 fs-1 lh-sm">
                            Admin! There are currently several offers for this unit:
                        </label>
                        <div className="flex-grow-1">
                            {unitOffers && unitOffers?.length > 0 && (
                                unitOffers.map((off: any) => (
                                    <OfferTaskForAdmin key={`offer-for-admin-${off.id}`} currentOffer={off} />
                                ))
                            )}
                        </div></>
                }


                {userType === 'Agent' && currentOffer?.attributes?.Statuses?.includes("Confirmed") && !getOfferTypeName(currentOffer).includes("sale") && currentUnit?.attributes?.ManagedBy?.includes("BSO") &&
                    <>
                        <div className="fv-row mb-2 m-0 p-0 w-100">
                            <label className="text-center form-label fw-bold text-dark my-2 fs-2 lh-sm">
                                Our team prepared all the documents for your offer, please follow the instructions:
                            </label>
                            <br />
                            <br />
                            <div className="fw-normal text-dark">
                                <strong>1. Inform your tenant that, he should to pay to BSO the booking deposit in the amount of    <strong className='fs-1x'> {price.toFixed(2)}  </strong> AED.
                                </strong>  <br />  <br />
                                <strong>
                                    2. Upload a screenshot of the deposit pay. Below is the bank details where you can send your payment. <br />
                                </strong>
                                <br />
                                <pre className="bg-light p-3 mt-2 rounded text-dark">
                                    Bank Name: First Abu Dhabi Bank
                                    <br />
                                    Account Name: BSO Real Estate Management
                                    <br />
                                    Account No.: <strong>1611323950980015</strong>
                                    <br />
                                    IBAN No.: <strong>AE270351611323950980015</strong>
                                    <br />
                                    Swift Code: <strong>NBADAEAAXXX</strong>
                                </pre>
                                <br />
                            </div>
                        </div>
                        <div className="fv-row my-2  p-2 w-100">
                            <div className="separator border border-bso border-3 px-2 my-2"></div>
                        </div>
                    </>
                }




            </div >


            {
                userType === 'Landlord'
                    ? <>
                        <OfferClientInfoForChildren offerID={currentOffer?.id} />
                        <CopyClientInfo offerID={currentOffer?.id} />
                        <AgentInfoForChildren userID={currentOffer?.attributes?.User?.data?.id ?? -1} />
                        <div className="fv-row my-2  p-2 w-100">
                            <div className="separator border border-bso border-3 px-2 my-2"></div>
                        </div>
                        {/* <ContactAgent userID={offer?.User?.data?.id ?? -1} /> */}
                    </>
                    : <></>
            }
        </>
    )
}
