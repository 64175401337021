import { getSingleMessageAdapter } from '../../../functions/adapters/chat/getMessageAdapter'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { sendNotification } from '../../../functions/shared/notifications/sendNotification'
import { type ApiResponse } from '../../../types/userTypes/TAuth'
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../urls'
import { setDebitMarketingCredits, setNotEnoughMarketingCredits } from '../../slices/advertisesSlice'
import {setChat, setCurrentMessage, setCurrentUnit, setMessages} from '../../slices/chatSlice'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'
import { type AppDispatch, type RootState } from '../../store'
import {getChat, messagePopulate, updateLastMessage} from '../chatActions'

import dayjs from 'dayjs'
import qs from 'qs'
import { getBroadcasts } from './getBroadcasts'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDubaiDate'

export const createBroadcastNew = (formValues:  Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const chatID = Number(process.env.REACT_APP_BSO_BROADCAST_ID ?? '4')

    try {
        dispatch(dataFetchingStart())
        const today = dayjs()
        const futureDate = today.add(Number(1), 'day').toDate()

        dispatch(setCurrentMessage('type:unit'))
        dispatch(setCurrentUnit(Number(formValues.unitID)))
        const message = getState().chat.currentMessage

        const bodyObject: Record<string, any> = {
            method: 'POST',
            collection: 'messages',
            body: {
                Text: message,
                Attachments: null,
                Sender: {
                    id: Number(userID)
                },
                Unit: {
                    id: Number(formValues.unitID)
                },
                ChatRoom: {
                    id: chatID
                },
                HideDate: futureDate,
                mode: 'unpublish',
                executeAt: futureDate
            },
            query: qs.stringify({
                populate: messagePopulate
            })
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json() as unknown as ApiResponse

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(setDebitMarketingCredits(0))
                dispatch(setNotEnoughMarketingCredits(0))
                const currentDubaiDate = getCurrentDubaiDate()
                const inMoment = toUTCWithOffset(currentDubaiDate, 1)
                const inFuture = toUTCWithOffset(futureDate, 1)

                await sendNotification(inMoment, Number(userID), Number(formValues.unitID), 'user-unit-broadcast', 'user unit broadcast notify')
                await sendNotification(inFuture, Number(userID), Number(formValues.unitID), 'user-unit-broadcast-end', 'user unit broadcast end notify')

                void (async () => {
                    // Get broadcast chat data
                    await dispatch(getChat(Number(process.env.REACT_APP_BSO_BROADCAST_ID), 1))
                    // Update broadcast chat last message and unread message
                    await dispatch(updateLastMessage(chatID, dataJSON.response.data.id))
                    // Clear broadcast chat data
                    dispatch(setChat({}))
                })()

                const message = getSingleMessageAdapter(dataJSON.response.data)
                dispatch(setCurrentUnit(null))
                dispatch(setCurrentMessage(''))
                dispatch(setMessages([message, ...getState().chat.Messages]))
                await dispatch(getBroadcasts())
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: `/agent/chats/${chatID}`  + '?from=create-broadcast', isSuccessful: true, textNotification: 'Congratulations, you have successfully broadcasted the unit.' }
            }
        } else {
            dispatch(dataFetchingFinish())
            return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
        dispatch(dataFetchingFinish())
    }
}
// async () => {
//     try {
//         const iconText = 'Info'
//         const result = await Swal.fire({
//             ...swalOptions('info'),
//             title: 'Broadcast Your Unit for Increased Visibility and Viewings',
//             html: `
//             When you broadcast your unit, it becomes visible to a broader audience of agents for 24 hours. This increased exposure allows other agents to see your unit and potentially respond with interest. As a result, more agents will be aware of your listing, which can lead to additional viewings and a higher chance of finding the right match for your property.

//             By broadcasting, you maximize the visibility of your unit, making it easier to attract attention and generate more engagement. The more agents who know about your unit, the better the chances of finding a suitable tenant or buyer quickly. Broadcasting is an effective way to create momentum and encourage more viewings, ultimately leading to faster and more successful transactions.
//         `,
//             preConfirm: async () => {
//                 console.log('createBroadcastNew')
//                 // dispatch(createBroadcastNew({ unitID: unit.id }));
//                 return true
//             }
//         })

//         if (result.isConfirmed) {
//             // Handle confirmed action
//         }
//     } catch (error) {
//         console.error('An error occurred:', error)
//     }
// }
