import { getErrorss, getMessageForError } from "../../../../../functions/errors/GetErrors"
import { getQueryOffers } from "../../../../../functions/shared/api/queries/getQueryOffers"
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from "../../../../../urls"
import { dataFetchingFinish, dataFetchingStart, setWhereAfterToGo } from "../../../../slices/modalSlice"
import {  setOffers } from "../../../../slices/offersSlice"
import { type AppDispatch, type RootState } from "../../../../store"
import { uploadMultipleForOffers } from "../../../documentsActions/upload/uploadMultipleForOffers"

export const addOfferSaleClientDetails = (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ isSuccessful: boolean, textNotification?: string, textNavigate?: string }> => {
    try {
        dispatch(dataFetchingStart())
        const { objectID , email , phone } = data
        const jwt = window.localStorage.getItem(ACCESS_KEY)  ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY)
        const offer = getState().offers.offers.find((offerItem) => offerItem.id === Number(objectID))
      
        let visaDoc = null
        if(data.visa != null ){
            visaDoc = await dispatch(uploadMultipleForOffers(data, 'agent_to_bso_offer'))
            
        }
        const bodyObject = {
            method: 'PUT',
            id: Number(objectID),
            collection: 'offers',
            query:  getQueryOffers(),
            body: {
                ...offer?.attributes,
                Type: [{
                    ...(offer?.attributes?.Type?.[0]),
                    Email: email,
                    Phone: phone
                }],
                Documents: [...(offer?.attributes?.Documents?.data?.map(doc => ({ id: doc.id })) ?? []), ...(visaDoc ? [{ id: visaDoc[0] }] : [])] , 
                Statuses: ['Waiting draft contract send'],
                RejectReason: null,
                Notify: 'BSO', 
            }
        }
      
        const responseOffer = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'BSO-Hash': '02ns30nsBSO!',
                Authorization: `Bearer ${String(jwt)}`
            },
            body: JSON.stringify(bodyObject)
        })
        const responseJSON = await responseOffer.json()
  
        const typeOffer = offer?.attributes?.Type?.[0]?.__component?.split('.')[1] ?? ''
        if (responseJSON.success === true && responseJSON?.response?.data?.id != null) {
            dispatch(setWhereAfterToGo(`/agent/schedule`))
            dispatch(setOffers(responseJSON.response.data))
            dispatch(dataFetchingFinish())
            return {
                textNotification: 'Client data has been successfully added',
                textNavigate:`/agent/modalView/offer_modal/${offer?.attributes?.Unit?.data?.id}/${objectID as string}/catalog?type=${typeOffer ?? 'not'}`,
                isSuccessful: true
            }
        } else {
            dispatch(dataFetchingFinish())
            return {
                textNotification: getErrorss(responseJSON),
                isSuccessful: false
            }}
    } catch (err) {
        console.error(err)
        dispatch(dataFetchingFinish())
        return {
            textNotification: getErrorss(getMessageForError("Something went wrong. Try again later")),
            isSuccessful: false
        }
    }
  
}
