import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDubaiDate'
import { timeStringInScheduler } from '../../../functions/agent/date/timeStringInScheduler'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { sendNotification } from '../../../functions/shared/notifications/sendNotification'
import { USER_ID_KEY } from '../../../urls'
import { type Unit } from '../../slices/unitsSlice'
import { type User } from '../../slices/userSlice'
import { type IViewing } from '../../slices/viewingsSlice'
import { store } from '../../store'

export const createNotifyViewing = async (viewing: IViewing, unit: [Unit], isAgentUnit?: boolean): Promise<void> => {
    const currentUser = store.getState().user.currentUser
    const nameUser = getNameUser(currentUser as User)

    const currentUnit = unit[0]
    const unitAdress = getUnitAddress(currentUnit)
    const currentDubaiDate = getCurrentDubaiDate()
    const userID = Number(window.localStorage.getItem(USER_ID_KEY))
    const viewingTime = new Date(viewing?.attributes?.Datetime)
    const twoHoursBeforeViewing = toUTCWithOffset(viewingTime, 120)
    const oneHourBeforeViewing = toUTCWithOffset(viewingTime, 60)
    const thirtyMinutesBeforeViewing = toUTCWithOffset(viewingTime, 30)
    if (currentDubaiDate.getTime() < thirtyMinutesBeforeViewing.getTime()) {
        await sendNotification(thirtyMinutesBeforeViewing, userID, viewing.id, 'user-viewing-before', `Attention, ${nameUser} , you are booked  a viewing  at ${timeStringInScheduler(viewing?.attributes?.Datetime)}, at ${unitAdress}. Be sure to show up on time please.`)
    } if (currentDubaiDate.getTime() < oneHourBeforeViewing.getTime()) {
        await sendNotification(oneHourBeforeViewing, userID, viewing.id, 'user-viewing-before', `Attention, ${nameUser} , you are booked  a viewing  at ${timeStringInScheduler(viewing?.attributes?.Datetime)}, at ${unitAdress}. Be sure to show up on time please.`)
    } if (currentDubaiDate.getTime() < twoHoursBeforeViewing.getTime()) {
        await sendNotification(twoHoursBeforeViewing, userID, viewing.id, 'user-viewing-before', `Attention, ${nameUser} , you are booked  a viewing  at ${unitAdress}. Be sure to show up on time please.`)
    }
    const inMomentViewing = toUTCWithOffset(viewingTime, 1)
    const thirtyMinutesAfterViewing = toUTCWithOffset(viewingTime, -30)
    await sendNotification(inMomentViewing, userID, viewing.id, 'user-viewing-before', `Attention, ${nameUser} , the viewing at ${unitAdress} has started. We wish you a good viewing experience!`)
    //  if (!isAgentUnit) {
    await sendNotification(thirtyMinutesAfterViewing, userID, viewing.id, 'user-viewing-feedback', `Hey, ${nameUser} how was the viewing at ${unitAdress} ?
    Please leave feedback.`)
    // }
}
