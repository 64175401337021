import { getCurrentDubaiDate } from "./getCurrentDubaiDate"

export function isLater (time1: string): boolean {
    const date = getCurrentDubaiDate()
    const formattedDate = date.toISOString().substring(0, 11)
    const date1 = new Date(formattedDate + time1)
    const date2 = getCurrentDubaiDate()
    //  console.log(date1, date2, 'date1 , date2')
    // console.log(date1 > date2, 'date1 > date2')
    date2.setMinutes(date2.getMinutes() + 30) // add 30 minutes to date2
    return date1 > date2
}
