import _ from "lodash"
import { getBelongsUnitForOffer } from "../../../functions/shared/schedule/getBelongsUnit"
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from "../../../urls"
import { dataFetchingFinish, dataFetchingStart } from "../../slices/modalSlice"
import { type AppDispatch, type RootState } from "../../store"
import { animationOptions, swalOptions } from "../../../functions/swalOptions"
import Swal from "sweetalert2"
import swalConfig from '../../../app/texts/swalTexts'
import { getQueryOffers } from "../../../functions/shared/api/queries/getQueryOffers"
import { getNameUser } from "../../../functions/agent/agentModuleComponent/getNameUser"
import { type User } from "../../slices/userSlice"
import { toUTCWithOffset } from "../../../functions/agent/date/toUTCWithOffset"
import { setOffers } from "../../slices/offersSlice"
import { getCurrentDubaiDate } from "../../../functions/agent/date/getCurrentDubaiDate"
import { sendNotification } from "../../../functions/shared/notifications/sendNotification"


export const cancelOffer = (offerID: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const userID = window.localStorage.getItem(USER_ID_KEY)

    try {
        dispatch(dataFetchingStart())
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const offers = getState().offers.offers ?? []
        const offer = _.find(offers, (offerItem: any) => offerItem.id === offerID) 
        const ratingAffected = !_.includes(offer?.attributes?.Statuses, 'In-process') && !_.includes(offer?.attributes?.Statuses, 'Rejected')
        const ownerUnit = getBelongsUnitForOffer(offer!)

        const iconText = 'Warning'
        const { isConfirmed } = await Swal.fire({
            ...swalOptions(iconText.toLowerCase()),
            title: swalConfig.attentionLeave.title,
            text: ratingAffected 
                ? swalConfig.offerCancelSwal.message.ratingAffected 
                : swalConfig.offerCancelSwal.message.ratingNotAffected,
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true ,
            confirmButtonText: swalConfig.offerCancelSwal.confirmButtonText,
            cancelButtonText: swalConfig.offerCancelSwal.denyButtonText,
            reverseButtons: false,
            iconHtml: '<div  id="lottie-icon-"${iconText}></div>',
            didOpen: () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: require(`../../../animations/${iconText}.json`) }
                    })
                }
            }
    
        })

        if (!isConfirmed) {
            dispatch(dataFetchingFinish())
            return
        }

        const bodyObject = {
            method: 'PUT',
            collection: 'offers',
            query:  getQueryOffers(),
            id: offerID,
            body: {
                Statuses: ['Canceled'],
                Notify: ownerUnit.type === 'BSO UNIT'  ? 'BSO' : 'Other Agent'
            }
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response.json()

        if (responseJSON.success === true) {
            if (responseJSON.response.error != null) {
                const currentUser = getState().user.currentUser
                const nameUser = getNameUser(currentUser as User)
                await sendNotification(toUTCWithOffset(getCurrentDubaiDate()), Number(userID), responseJSON?.response?.data?.id, 'admin-offer-canceled', `Attention , ${nameUser} canceled offer.`)
                dispatch(setOffers(responseJSON.response.data))
            } 
        } 
        dispatch(dataFetchingFinish())
        return responseJSON
    } catch (error) {
        dispatch(dataFetchingFinish())
        console.log(error)
    }
}