// StepNavigation.tsx
import React from 'react';
import { type TimelineItem } from '../viewingTimelines/ViewingTimeline';
import Icon from '../../../icon/Icon';

interface StepNavigationProps {
    process: number;
    line: string;
    setLine: (line: string) => void;
    setSliceSteps: (steps: TimelineItem[]) => void;
    group: TimelineItem[];
    offerStatuses: string[];
    totalSteps: number;
}

const StepNavigation: React.FC<StepNavigationProps> = ({ process, line, setLine, setSliceSteps, group, offerStatuses, totalSteps }) => {
    return (
        <div className={'d-flex'} style={{ justifyContent: process === 0 || process === totalSteps ? 'flex-end' : 'space-evenly' }}>
            <div className="d-flex justify-content-between align-items-center mx-4">
                {process !== 0 && (
                    <div className={'text-end'}>
                        <div
                            className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                            onClick={line === 'prew'
                                ? () => { setSliceSteps([group[process]]); setLine('middle'); }
                                : () => {
                                    setSliceSteps([...group].slice(0, process + 1));
                                    setLine('prew');
                                }
                            }
                        >
                            <div className="d-flex justify-content-around align-items-center">
                                <Icon name={line === 'prew' ? 'play_circle' : 'expand_circle_up'} className={'fs-5'} />
                                <span className="ms-1 fs-8">{line === 'prew' ? 'Current step' : 'Previous steps'}</span>
                            </div>
                        </div>
                    </div>
                )}
                <div className="d-flex justify-content-between align-items-center mx-2">
                    {process !== totalSteps - 1 && !offerStatuses.includes('Canceled') && (
                        <div className={'text-end'}>
                            <div
                                className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                                onClick={
                                    line === 'next'
                                        ? () => { setSliceSteps([group[process]]); setLine('middle'); }
                                        : () => { setSliceSteps([...group].slice(process)); setLine('next'); }
                                }
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    <Icon name={line === 'next' ? 'play_circle' : 'expand_circle_down'} className={'fs-5'} />
                                    <span className="ms-1 fs-8">{line === 'next' ? 'Current step' : 'Next steps'}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StepNavigation;