import { Drawer } from 'antd'
import React from 'react'

export interface AddOnServicesInfoDrawerProps {
    isOpen: boolean
    onClose: () => void

    children: React.ReactElement
}

export default function AddOnServicesInfoDrawer({ isOpen, onClose, children }: AddOnServicesInfoDrawerProps): React.ReactElement {
    return (
        <Drawer
            style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
            height={'88%'}
            styles={{
                body: {

                    maxHeight: '88vh', // maxHeight: '80vh',
                    marginBottom: '3px',
                    paddingTop: '3px',
                    marginTop: '6px',
                    paddingLeft: '2px',
                    paddingRight: '2px'
                }
            }}
            placement={'bottom'}
            closable={false}
            onClose={onClose}
            open={isOpen}
            rootStyle={{ zIndex: '1100' }}

            maskClosable={true}
        >
            <div
                className="content d-flex flex-column flex-fill p-0 m-1 px-2"
            >
                {
                    children
                }
            </div>
        </Drawer>
    )
}
