import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { type RootState } from '../../../store/store'
import { useAppSelector } from '../../../hooks/redux'
import { swalInProgress, swalNeedLoadDocs, swalRejectedDoc } from '../../../functions/swalOptions'
import swalConfig from '../../../app/texts/swalTexts'


export default function AlertsVerified(): JSX.Element {
    const navigate = useNavigate()

    const { statusesApproved, documentsExpires, documentsLoaded, documentsRejected, documentsInProgress } = useAppSelector((state: RootState) => state.agentStates)

    const rejSts = documentsRejected.passportReject || documentsRejected.emiratesReject
    const verifiedStatus = statusesApproved.emiratesApproved || statusesApproved.passportApproved
    const verifiedInProgress = documentsInProgress.emiratesInProgress || documentsInProgress.passportInProgress
    const loadSts = documentsLoaded.emiratesLoaded || documentsLoaded.passportLoaded
    const expSts = documentsExpires.emiratesExpire || documentsExpires.passportExpire
    useEffect(() => {
        const checkStatus = async () => {
            if (!loadSts) {
                const result = await swalNeedLoadDocs();
                if (result?.isConfirmed) {
                    navigate('/agent/modal/update_emirates_front_together/INagentMODULE/to_choose_subscription');
                } else if (result?.isDismissed) {
                    navigate('/agent/units/nav');
                }
            }
            if (loadSts && verifiedInProgress && !verifiedStatus) {
                const result = await swalInProgress(swalConfig.inProgress.message);
                if (result?.isConfirmed) {
                    navigate(-1);
                }
            }
            if (loadSts && rejSts && !verifiedStatus) {
                const result = await swalRejectedDoc(
                    swalConfig.rejectedDoc.documentMessageRej(
                        documentsRejected.emiratesReject ? 'EmiratesID' : 'Passport'
                    )
                );
                if (result?.isConfirmed) {
                    if (documentsRejected.emiratesReject) {
                        navigate('/agent/modal/update_emirates_front_together/not/not');
                    } else {
                        navigate('/agent/modal/update_passport_together/not/not');
                    }
                }
            }
            if (loadSts && expSts && !verifiedStatus) {
                const result = await swalRejectedDoc(
                    swalConfig.rejectedDoc.documentMessageExp(
                        documentsExpires.emiratesExpire ? 'EmiratesID' : 'Passport'
                    )
                );
                if (result?.isConfirmed) {
                    if (documentsExpires.emiratesExpire) {
                        navigate('/agent/modal/update_emirates_front_together/not/not');
                    } else {
                        navigate('/agent/modal/update_passport_together/not/not');
                    }
                }
            }
        };

        void checkStatus();
    }, [loadSts, verifiedInProgress, verifiedStatus, rejSts, expSts]);
    return (
        <></>
    )
}
