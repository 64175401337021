import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { Outlet, useNavigate } from 'react-router'
import Loader from '../../app/custom/loaders/Loader'
import { store, type AppDispatch, type RootState } from '../../store/store'

import {
    fetchUser,
    fetchUsers,
    updateUserTokenDevice
} from '../../store/actionsCreators/userActions'
import { fetchViewings } from '../../store/actionsCreators/viewingsActions'
import { fetchUnits } from '../../store/actionsCreators/unitsActions'
import { fetchProperties } from '../../store/actionsCreators/propertyActions'
import ViewingRescheduled from './componentsViewings/ViewingRescheduled'

import { useLocation } from 'react-router-dom'
import _ from 'lodash'

import { ACCESS_KEY, REACT_APP_BSO_SUPPORT_USER_ID, urlAPI, USER_ID_KEY } from '../../urls'
import { fetchDocuments } from '../../store/actionsCreators/documentActions'
import AlertsComponentVerify from '../../AlertsComponentVerify'
import LandscapePlaceholder from '../shared/landscape-placeholder'
import useOrientation from '../../hooks/UseOrientation'
import { getChatList } from '../../store/actionsCreators/chatListActions'
import SearchProvider from '../../app/SearchProvider'

import { fetchAdvertises } from '../../store/actionsCreators/advertiseActions/fetchAdvertiseAction'
import { fetchAppConfig } from '../../store/actionsCreators/appActions'
import AlertNeedCreateLinks from './advertise/AlertNeedCreateLinks'
import ViewingFeedback from './componentsViewings/ViewingFeedback'

import { AgentLoadProcessData } from './AgentLoadProcessData'
import { agentSocket } from '../../functions/socket/agentSocket'
import { getBroadcasts } from '../../store/actionsCreators/broadcastActions/getBroadcasts'
import { type NavigateFunction } from 'react-router-dom'
import { userUpdateCountLeaving } from '../../store/actionsCreators/userActions/userUpdateCountLeaving'
import { swalError } from '../../functions/swalOptions'
import Swal from 'sweetalert2'
import PrivacyPolicyDrawer from '../../app/custom/modalV2/Fields/TermsAndConditions/PrivacyPolicyDrawer'
import { dataFetchingFinish, dataFetchingStart } from '../../store/slices/modalSlice'
import { setSocketTimeout } from '../../store/slices/appSlice'
import { fetchOffers } from '../../store/actionsCreators/offerActions/fetchOffers'
import NewReliesDriver from '../shared/newReliseDriver/NewReliesDriver'

let webSocket: WebSocket | null = null;
let retryCount = 0;
let dataLoaded = false
export default function AgentModule(): JSX.Element {
    let userID = window.localStorage.getItem(USER_ID_KEY)
    // const [dataLoaded, setDataLoaded] = useState(false)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()


    const [showRelies, setShowRelies] = useState(false)
    // const isUserVerified = store.getState().modal.isUserVerified;
    // const { unitsLoading, units } = store.getState().units;
    // const currentUser = store.getState().user.currentUser;
    // const loading = store.getState().user.loading;
    // const { viewingsLoading, viewings } = store.getState().viewings;
    // const documentsLoading = store.getState().documents.documentsLoading;
    // const offersLoading = store.getState().offers.offersLoading;
    // const socketTimeout = store.getState().app.socketTimeout;
    // const oneCycleShowPopupsViewingReschedule = store.getState().swalsStates.oneCycleShowPopupsViewingReschedule;
    // const showPopupViewingFeedback = store.getState().swalsStates.showPopupViewingFeedback;
    // const showPopupViewingReschedule = store.getState().swalsStates.showPopupViewingReschedule;
    // const firstLengthSolidStackViewingsReschedule = store.getState().swalsStates.firstLengthSolidStackViewingsReschedule;
    const isUserVerified = useAppSelector((state: RootState) => state.modal.isUserVerified)
    const { unitsLoading, units } = useAppSelector((state: RootState) => state.units)
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const loading = useAppSelector((state: RootState) => state.user.loading)
    const { viewingsLoading, viewings } = useAppSelector((state: RootState) => state.viewings)
    const documentsLoading = useAppSelector((state: RootState) => state.documents.documentsLoading)
    const offersLoading = useAppSelector((state: RootState) => state.offers.offersLoading)
    const socketTimeout = useAppSelector((state: RootState) => state.app.socketTimeout)
    const oneCycleShowPopupsViewingReschedule = useAppSelector((state: RootState) => state.swalsStates.oneCycleShowPopupsViewingReschedule)
    const showPopupViewingFeedback = useAppSelector((state: RootState) => state.swalsStates.showPopupViewingFeedback)
    const showPopupViewingReschedule = useAppSelector((state: RootState) => state.swalsStates.showPopupViewingReschedule)
    const firstLengthSolidStackViewingsReschedule = useAppSelector((state: RootState) => state.swalsStates.firstLengthSolidStackViewingsReschedule)

    const { orientation } = useOrientation()


    const initializeWebSocket = (dispatch: AppDispatch, navigate: NavigateFunction): void => {
        //   console.log("CALL initializeWebSocket")


        if (webSocket != null && (webSocket.readyState === WebSocket.OPEN || webSocket.readyState === WebSocket.CONNECTING)) {
            //    console.log("WebSocket is already open or connecting. No need to create a new one.");
            return;
        } else {
            webSocket?.close()
        }
        setTimeout(() => {
            webSocket = new WebSocket('wss://bso.ae/ws');
            void agentSocket(webSocket, dispatch, navigate);

            webSocket.onclose = () => {
                //  console.log('WebSocket connection closed. Attempting to reconnect...');
                retryCount++;
                const retryDelay = Math.min(1000 * 2 ** retryCount, 30000);
                setTimeout(() => { initializeWebSocket(dispatch, navigate); }, retryDelay);
            }
            webSocket.onerror = (error) => {
                console.error('WebSocket error:', error);
                webSocket?.close();
            };
        }, 1)
    }







    useEffect(() => {
        const currentTime = new Date().getTime();
        const timestampDifference = currentTime - socketTimeout;


        if (timestampDifference > 40000 || timestampDifference < 40000) {
            //  console.log('Timestamp difference too large, reloading WebSocket connection...');
            initializeWebSocket(dispatch, navigate);
        }
    }, [socketTimeout, dispatch, navigate])

    useEffect(() => {
        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        }


        const checkToken = async (dispatch: AppDispatch, navigate: NavigateFunction): Promise<void> => {
            const maxAttempts = 16;
            let attemptCount = 0;
            let currentToken: string | null = null;

            while (attemptCount < maxAttempts && !currentToken) {
                try {
                    currentToken = (window as any).devicetoken || window.localStorage.getItem('devicetoken');
                    if (currentToken) {
                        await dispatch(updateUserTokenDevice(currentToken));
                        initializeWebSocket(dispatch, navigate);
                        break;
                    } else {
                        attemptCount++;
                        await new Promise(resolve => setTimeout(resolve, 500));
                    }
                } catch (error) {
                    console.error('Error updating token:', error);
                    break;
                }
            }

            //  токен так и не найден
            if (!currentToken) {
                const fallbackToken = `BrowserTokenUnfound${userID}`;
                await dispatch(updateUserTokenDevice(fallbackToken));
                initializeWebSocket(dispatch, navigate);
            }
        };

        const loadUserData = async (): Promise<void> => {
            const jwt = window.localStorage.getItem(ACCESS_KEY)
            userID = window.localStorage.getItem(USER_ID_KEY)

            if (jwt && userID) {
                // const loadAll = await Promise.all([
                //     dispatch(fetchUser()),
                //     dispatch(fetchViewings()),
                //     dispatch(fetchOffers()),
                //     dispatch(fetchUnits()),
                //     dispatch(fetchDocuments()),
                //     dispatch(fetchUsers()),
                //     dispatch(fetchProperties()),
                //     dispatch(getChatList()),
                //     dispatch(getBroadcasts()),
                //     dispatch(fetchAdvertises()),
                //     dispatch(fetchAppConfig())
                // ])
                const requests = [
                    dispatch(fetchUser()),
                    dispatch(fetchViewings()),
                    dispatch(fetchOffers()),
                    dispatch(fetchUnits()),
                    dispatch(fetchDocuments()),
                    dispatch(fetchUsers()),
                    dispatch(fetchProperties()),
                    dispatch(getChatList()),
                    dispatch(getBroadcasts()),
                    dispatch(fetchAdvertises()),
                    dispatch(fetchAppConfig()),
                ];

                // с дозировкой
                const results: any[] = [];
                for (const request of requests) {

                    try {
                        const result = await request;

                        if (!result.success || !result.isSuccessful) {

                            const swalResult = await swalError("Error while executing the request");
                            if (swalResult.isConfirmed) {
                                window.location.reload();
                            }
                        }
                        results.push(result);

                    } catch (error) {
                        console.error("Error while executing the request", error);
                        results.push(null);
                    }
                }
                //    console.log(results, "results")
                const isLoad = results.every((el: any) => el?.isSuccessful === true) //  || el?.success === true

                if (isLoad) {
                    await dispatch(userUpdateCountLeaving())
                    const userAgent = navigator.userAgent
                    const isIPhone = _.includes(userAgent.toLowerCase(), 'iphone')
                    const isAndroid = _.includes(userAgent.toLowerCase(), 'android')

                    if (isAndroid) {
                        try {
                            const { initializeApp } = await import('firebase/app')
                            const firebaseApp = initializeApp(firebaseConfig)
                            const { getMessaging, getToken, onMessage } = await import('firebase/messaging')
                            const messaging = getMessaging(firebaseApp)

                            if (Notification.permission === 'default') {
                                const permission = await Notification.requestPermission()
                                if (permission === 'granted') {
                                    const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
                                    if (currentToken != null) {
                                        console.log('FB Token:', currentToken.slice(0, 5) + '...')
                                        await dispatch(updateUserTokenDevice(currentToken))
                                        initializeWebSocket(dispatch, navigate)
                                    } else {
                                        await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                        initializeWebSocket(dispatch, navigate)
                                    }
                                } else if (permission === 'denied') {
                                    await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                    initializeWebSocket(dispatch, navigate)
                                }
                            } else if (Notification.permission === 'granted') {
                                const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
                                if (currentToken != null) {
                                    console.log('FB Token:', currentToken.slice(0, 5) + '...')
                                    await dispatch(updateUserTokenDevice(currentToken))
                                    initializeWebSocket(dispatch, navigate)
                                } else {
                                    await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                    initializeWebSocket(dispatch, navigate)
                                }
                            } else if (Notification.permission === 'denied') {
                                await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                initializeWebSocket(dispatch, navigate)
                            }
                            onMessage(messaging, (payload) => {
                                navigator.serviceWorker.ready.then((registration) => {
                                    registration.showNotification(payload.notification?.title as string, {
                                        body: payload.notification?.body
                                    })
                                }).catch((e) => { console.log(e) })
                            })
                        } catch (err) {
                            console.log(err)
                        }
                    } else if (isIPhone) {
                        await checkToken(dispatch, navigate)
                    } else {
                        dataLoaded = true
                        await dispatch(updateUserTokenDevice(`BrowserTokenUnknownToken${userID}`))
                        initializeWebSocket(dispatch, navigate)
                    }
                    dataLoaded = true
                    //  setDataLoaded(true)
                } else {
                    void swalError("Error while executing the request")

                    //  setDataLoaded(false)
                    // TO DO Убрал редирект
                    // navigate('/auth/login')
                }
            }
        }
        void loadUserData()
    }, [])

    useEffect(() => {
        if (dataLoaded) {
            void AgentLoadProcessData(navigate, dispatch, location)
        }
    }, [dataLoaded])

    useEffect(() => {
        if (!currentUser.TermsAndConditionsApply && dataLoaded && currentUser?.tutorialCompleted) {
            setShowRelies(true)
        }
    }, [currentUser.TermsAndConditionsApply, dataLoaded])

    if (!dataLoaded || loading || unitsLoading || viewingsLoading || documentsLoading || offersLoading) {
        return <Loader />
    }

    return (
        <>
            <SearchProvider>
                {
                    orientation === 'landscape'
                        ? <LandscapePlaceholder />
                        : <>
                            {isUserVerified || currentUser?.emailVerified ? null : location.pathname.includes('agent/modal/update_passport_together') || location.pathname.includes('/agent/modal/update_emirates_back_together') || location.pathname.includes('/agent/modal/update_emirates_front_together') || location.pathname.includes('/auth/register_scan_emirates') ? null : <AlertsComponentVerify />}
                            {dataLoaded &&
                                isUserVerified
                                ? showRelies ?
                                    <NewReliesDriver title='new-relies' /> :
                                    <>
                                        {location.pathname.includes('agent_update_viewing_modal') || showPopupViewingFeedback ? null : <ViewingRescheduled currentUser={currentUser} viewings={viewings} dispatch={dispatch} dataLoaded={dataLoaded} />}
                                        {oneCycleShowPopupsViewingReschedule || (firstLengthSolidStackViewingsReschedule === 0 && !oneCycleShowPopupsViewingReschedule)
                                            ? <ViewingFeedback currentUser={currentUser} viewings={viewings} dispatch={dispatch}
                                                dataLoaded={dataLoaded} units={units} />
                                            : null}
                                        {
                                            (oneCycleShowPopupsViewingReschedule && !showPopupViewingFeedback && !showPopupViewingReschedule || (firstLengthSolidStackViewingsReschedule === 0 && !oneCycleShowPopupsViewingReschedule && !showPopupViewingFeedback && !showPopupViewingReschedule))
                                                ? !location.pathname.includes('agent_advertise_create_start_date') ? <AlertNeedCreateLinks dataLoaded={dataLoaded} /> : null
                                                : null}
                                    </>
                                : null}
                            <Outlet />
                        </>
                }
            </SearchProvider>

        </>
    )
}
