import React, { FormEvent, type ReactElement, useState } from 'react'
import {
    type Control,
    Controller,
    type FieldErrors,
    type FieldValues,
    type UseFormRegister,
    type UseFormSetValue
} from 'react-hook-form'
import { Space, Switch } from 'antd'
import { getCountries, getCountryCodes } from '../../../../functions/getDataForSelects/getCountries'

import CustomSelect from '../../../../app/custom/selects/CustomSelect'
import _ from 'lodash'
import { regExps } from '../../../../constants'
import DefaultInput from '../../../../app/custom/inputs/DefaultInput'
import GroupInput from '../../../../app/custom/inputs/GroupInput'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import CustomSingleFileInput from '../../../../app/custom/inputs/CustomSingleFileInput'
import { scanDocument } from '../../../../store/actionsCreators/documentsActions/scaning/documentScan'
import { dataFetchingFinish, dataFetchingStart } from '../../../../store/slices/modalSlice'
import { type IFields } from '../../../../types/userTypes/documentTypes/TScan'
import CustomFormLable from '../../../../app/custom/customModalUtils/CustomFormLable'
import { setIsCommercial } from '../../../../store/slices/offersSlice'
import CustomToggleButtonGroup from '../CustomToggleButtonGroup'
import { type Document } from '../../../../store/slices/documentSlice'
import Icon from '../../../../app/icon/Icon'
import Markdown from 'react-markdown'

interface ScanningValidatorProps {
    errors: FieldErrors<any>
    register: UseFormRegister<any>
    id: string
    show: boolean
    validationMessage: string
}

function ScanningValidator({ errors, register, id, show, validationMessage }: ScanningValidatorProps): ReactElement {
    const name = `${id}-validation`
    return (
        show
            ? <div>
                <input
                    {...register(name, {
                        required: validationMessage
                    })}
                    name={name}
                    type="text"
                    hidden={true}
                />
                {
                    _.get(errors, name) != null && (
                        <div className="text-danger my-2">{String(_.get(errors, name)?.message)}</div>
                    )
                }
            </div>
            : <></>
    )
}

interface AgentToBSOOfferClientInfoProps {
    setValue: UseFormSetValue<FieldValues>
    errors: FieldErrors<any>
    register: UseFormRegister<any>
    control: Control<any>
    offerID: number
    unitID: number
}

export default function AgentToBSOOfferClientInfo({ setValue, errors, register, control, offerID, unitID }: AgentToBSOOfferClientInfoProps): ReactElement {
    const dispatch = useAppDispatch()
    const units = useAppSelector((state: RootState) => state.units.units)
    const unit = units.find((unitItem) => unitItem.id === unitID)
    const typeUnit = unit?.attributes?.Marketing?.Use

    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const offer = offers.find((offerItem) => offerItem.id === offerID)
    const documents = useAppSelector((state: RootState) => state.documents.documents)
    const offerDocs = documents.filter((docItem) => (
        offer?.attributes?.Documents?.data?.some((offerDocItem) => (
            docItem.id === offerDocItem.id
        ))
    ))

    const tradeLicenseDoc = offerDocs?.find((docItem) => docItem?.attributes?.Type?.[0]?.__component === 'documents.trade-license')
    const passportDoc = offerDocs?.find((docItem) => docItem?.attributes?.Type?.[0]?.__component === 'documents.passport')
    const emiratesIDDoc = offerDocs?.find((docItem) => docItem?.attributes?.Type?.[0]?.__component === 'documents.emirates-id')
    const chequeCopyDoc = offerDocs?.find((docItem) => docItem?.attributes?.Category === 'Deposit Cheque')


    const handleSwitchChangeOfferType = (checked: boolean): void => {
        setCheckedOfferType(checked)
    }
    const [switchCheckedOfferType, setCheckedOfferType] = useState(offer?.attributes?.Type[0]?.BusinessName != null)


    const [scanningErrors, setScanningErrors] = useState<string[]>([])
    const [uploadedFiles, setUploadedFiles] = useState<string[]>([])
    const [scannedFormData, setScannedFormData] = useState<Record<string, any> | null>(null)
    const [type, setType] = useState<string>((offer != null) ? (passportDoc != null && emiratesIDDoc != null) ? "both" : passportDoc != null ? 'passport' : 'emirates-id' : 'emirates-id')

    const captureFileUpload = (rowID: string) => (e: any): void => {
        const newUploadedFiles = Array.from(new Set([...uploadedFiles, rowID]))
        setUploadedFiles(newUploadedFiles)
    }

    const rowFirstName = {
        id: 'Type.FirstName',
        content: ['Client First Name', 'Thomas'],
        element: 'input',
        required: true,
        validateEquals: ['Scanned.FirstName', 'Must be equal to first name in the scanned document', 2],
        defaultValue: scannedFormData?.document?.name?.split(' ')?.[0] ?? offer?.attributes?.Type[0]?.FirstName ?? undefined
    }
    const rowLastName = {
        id: 'Type.LastName',
        content: ['Client Last Name', 'A. Anderson'],
        element: 'input',
        required: true,
        validateEquals: ['Scanned.LastName', 'Must be equal to last name in the scanned document', 2],
        defaultValue: scannedFormData?.document?.name.split(' ').slice(1).join(' ') ?? offer?.attributes?.Type[0]?.LastName ?? undefined
    }
    const rowBusinessName = {
        id: 'Type.BusinessName',
        content: ['Business Name', 'Input client business name'],
        element: 'input',
        required: true,
        defaultValue: offer?.attributes?.Type[0]?.BusinessName ?? undefined
    }
    const rowBusinessOwnerName = {
        id: 'Type.BusinessOwnerName',
        content: ['Business Owner Name', 'Input business owner name'],
        element: 'input',
        required: true,
        defaultValue: offer?.attributes?.Type[0]?.BusinessOwnerName ?? undefined
    }
    const rowBusinessActivity = {
        id: 'Type.BusinessActivity',
        content: ['Business Activity', 'Input business activity'],
        element: 'input',
        required: true,
        defaultValue: offer?.attributes?.Type[0]?.BusinessActivity ?? undefined
    }
    const rowEstablishmentDate = {
        id: 'Type.EstablishmentDate',
        content: ['Establishment Date', 'Year', 'Input establishment year'],
        element: 'input-group',
        regexp: regExps.isYear,
        required: true,
        dateBeforeToday: true,
        defaultValue: offer?.attributes?.Type?.[0]?.EstablishmentDate ?? undefined
    }
    // const rowCountry = {
    //     id: 'Type.Country',
    //     content: ['Client Nationality', 'Select country'],
    //     selectors: getCountries(),
    //     element: 'select',
    //     required: true,
    //     defaultValue: scannedFormData?.document?.nationality != null ? getCountryCodes(scannedFormData?.document?.nationality) : offer?.attributes?.Type[0]?.Country ?? undefined
    // }
    const rowTradeLicense = {
        id: 'trade_licence',
        content: ['Upload client\'s company trade license', 'Trade license'],
        element: 'input-file',
        required: tradeLicenseDoc == null,
        onInput: captureFileUpload('traded_licence'),
        uploadedBase64: !uploadedFiles.includes('trade_licence') ? tradeLicenseDoc?.attributes?.Link : undefined
    }
    const rowChequeCopy = {
        id: 'cheque_copy',
        content: ['Upload 10% client\'s cheque copy', 'Cheque copy'],
        element: 'input-file',
        description: 'First, you are required to make an initial payment or deposit, amounting to 10% of the purchase price. This payment secures the selected property under your client name.',
        required: chequeCopyDoc == null,
        onInput: captureFileUpload('cheque_copy'),
        uploadedBase64: !uploadedFiles.includes('cheque_copy') ? chequeCopyDoc?.attributes?.Link : undefined
    }

    const rowPurchaseReason = {
        id: 'Type.PurchaseReason',
        content: ['Purchase Reason', 'Select reason'],
        selectors: ['Investment', 'Personal use'],
        element: 'select',
        required: true,
        defaultValue: 'Personal use'
    }
    const rowIdFront = {
        id: 'id_front',
        content: ['Emirates ID Front Side'],
        element: 'input-file',
        required: emiratesIDDoc == null,
        onInput: captureFileUpload('id_front'),
        uploadedBase64: !uploadedFiles.includes('id_front') ? emiratesIDDoc?.attributes?.LinkFront : undefined
    }
    const rowIdBack = {
        id: 'id_back',
        content: ['Emirates ID back side'],
        element: 'input-file',
        required: emiratesIDDoc == null,
        onInput: captureFileUpload('id_back'),
        uploadedBase64: !uploadedFiles.includes('id_back') ? emiratesIDDoc?.attributes?.LinkBack : undefined
    }
    const rowVisa = {
        id: 'visa',
        content: ['Passport'],
        element: 'input-file',
        required: passportDoc == null,
        onInput: captureFileUpload('visa'),
        uploadedBase64: !uploadedFiles.includes('visa') ? passportDoc?.attributes?.Link : undefined
    }

    const docOptions = [
        { value: 'emirates-id', label: 'Emirates ID' },
        { value: 'passport', label: 'Passport' },
        { value: 'both', label: 'Both' }
    ];
    return (
        <>

            <div className="fv-row my-2 m-0 p-0 w-100">
                {offer?.attributes.Statuses.includes("Resubmit required") && offer?.attributes?.RejectReason &&
                    <div className={'rounded rounded-sm bg-gray-300 p-2 mb-6 '}>
                        <label className={'form-label fw-bold fs-5 text-dark m-0 d-flex align-items-center text-uppercase'}>
                            <Icon name={'info'} className={'fs-2x text-dark me-1'} />
                            Resubmit reason :
                        </label>
                        <div className='fw-semi fs-5  lh-1 mt-2'>
                            <Markdown>
                                {offer?.attributes?.RejectReason}
                            </Markdown></div>
                    </div>
                }
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Is the client a company ?</div>
                    </div>

                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Controller
                                control={control}
                                name={'offer_is_commercial'}
                                defaultValue={switchCheckedOfferType}
                                render={({ field }) => (
                                    <Switch unCheckedChildren="NO"
                                        {...field}
                                        // defaultChecked={switchCheckedOfferType}
                                        checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                        onChange={(e) => {
                                            handleSwitchChangeOfferType(e)
                                            dispatch(setIsCommercial(e))
                                            field.onChange(e)
                                        }}
                                    />
                                )}
                            />
                        </Space>
                    </div>
                </div>

                {
                    switchCheckedOfferType
                        ? <>
                            <div className={'d-flex flex-column gap-4'}>
                                <div className="separator separator-dashed my-4 mb-0"></div>
                                <CustomSingleFileInput row={rowTradeLicense} register={register} errors={errors} getValues={() => { }} />
                            </div>
                        </>
                        : null


                }
                <div className="separator separator-dashed my-4"></div>
            </div>
            <div >
                <div className="fs-6 fw-bold mb-1 text-uppercase">What is document type you want to load ?</div>
                <div className="fs-8 fw-semibold mb-2 text-uppercase text-center"> {switchCheckedOfferType ? "Company representative's" : "client's"} {type === 'both' ? 'both documents' : type} copy *</div>
                <CustomToggleButtonGroup
                    options={docOptions}
                    selectedValue={type}
                    onSelect={setType}
                />

            </div>





            <div className="fv-row  mt-8 p-0 w-100">
                {(type === 'passport' || type === 'both') &&
                    <>
                        <CustomSingleFileInput row={rowVisa} register={register} errors={errors} getValues={() => { }} />
                        <ScanningValidator id={rowVisa.id} register={register} errors={errors} validationMessage={'The passport is not valid'} show={scanningErrors.includes(rowVisa.id)} />
                        <div className="separator separator-dashed my-4"></div>
                    </>
                }
            </div>
            <div className="fv-row p-0 w-100">

                {(type === 'emirates-id' || type === 'both') &&
                    <>
                        <CustomSingleFileInput row={rowIdFront} register={register} errors={errors} getValues={() => { }} />
                        <CustomSingleFileInput row={rowIdBack} register={register} errors={errors} getValues={() => { }} />
                        <ScanningValidator id={rowIdFront.id} register={register} errors={errors} validationMessage={'The emirates id is not valid'} show={scanningErrors.includes(rowIdFront.id) && scanningErrors.includes(rowIdBack.id)} />
                        <div className="separator separator-dashed my-4"></div>
                    </>
                }

                {
                    typeUnit === 'For sale' &&
                    <div className="fv-row my-2 p-0 w-100">
                        <CustomSingleFileInput row={rowChequeCopy} register={register} errors={errors} getValues={() => { }} />
                        <CustomSelect control={control} register={register} data={rowPurchaseReason} errors={errors} />
                        <div className="separator separator-dashed my-4"></div>
                    </div>
                }


                {
                    !switchCheckedOfferType
                        ?
                        <>
                            <DefaultInput row={rowFirstName} register={register} errors={errors} />
                            <DefaultInput row={rowLastName} register={register} errors={errors} />
                        </>
                        : <>
                            <DefaultInput row={rowBusinessName} register={register} errors={errors} />
                            <DefaultInput row={rowBusinessActivity} register={register} errors={errors} />
                            <DefaultInput row={rowBusinessOwnerName} register={register} errors={errors} />
                            <GroupInput register={register} errors={errors} row={rowEstablishmentDate} />
                        </>
                }

            </div >
        </>
    )
}
