
import { getCurrentDubaiDate } from "../../../functions/agent/date/getCurrentDubaiDate"
import { toUTCWithOffset } from "../../../functions/agent/date/toUTCWithOffset"
import { getErrorss } from "../../../functions/errors/GetErrors"
import { getQueryUnits } from "../../../functions/shared/api/queries/getQueryUnits"
import { sendNotification } from "../../../functions/shared/notifications/sendNotification"
import { swalError, swalSuccess } from "../../../functions/swalOptions"
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from "../../../urls"
import { dataFetchingFinish, dataFetchingStart } from "../../slices/modalSlice"
import { type AppDispatch, type RootState } from "../../store"



interface WaitingItem {
    adId: number;
    userID: number;
    hideDate: string;
    startDate: string;
    status: 'Reserve' | 'Reserved';
}

export const cancelAwaitingAdvertise = (unitID: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {

    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? '';
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? '';
    try {
        dispatch(dataFetchingStart());
        const units: any = getState().units.units;
        const unit = units?.find((el: any) => el.id === Number(unitID));
        // console.log(unit, "unit");

        const awaitingList: WaitingItem[] = unit?.attributes?.WaitingList || [];
        const userReserveIndex = awaitingList.findIndex((reserve) => Number(reserve.userID) === Number(userID));

        if (userReserveIndex === -1) {
            dispatch(dataFetchingFinish());
            return await swalError( "No reservation found for the current user.");
        }

        
        if (userReserveIndex < awaitingList.length - 1) {
            const nextReserve = awaitingList[userReserveIndex + 1];
            await sendNotification(
                toUTCWithOffset(getCurrentDubaiDate()),
                Number(nextReserve.userID),
                Number(unitID),
                'user-waiting-list-date-set',
                `Hey, its stay relevant.`
            );
        }

     
        const updatedWaitingList = awaitingList.filter((_, index) => index !== userReserveIndex);

        const bodyObject = {
            method: 'PUT',
            collection: 'units',
            id: Number(unitID),
            body: {
                WaitingList: updatedWaitingList
            },
            query: getQueryUnits()
        };

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        });

        const responseJSON = await response?.json();
        //     console.log(responseJSON, "responseJSON");

        if (responseJSON.success) {
            if (responseJSON.response.error != null) {
                dispatch(dataFetchingFinish());
                return await swalError(getErrorss(responseJSON));
            } else {
                await swalSuccess("You have successfully deleted reservation an advertisement");
                dispatch(dataFetchingFinish());
                
            }
        } else {
            dispatch(dataFetchingFinish());
            return await swalError(getErrorss(responseJSON));
        }
    } catch (error) {
        dispatch(dataFetchingFinish());
        return await swalError(getErrorss({}));
    }
};

