
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDubaiDate'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { getQueryAdvertises } from '../../../functions/shared/api/queries/getQueryAdvertises'
import { getQueryDocuments } from '../../../functions/shared/api/queries/getQueryDocuments'
import { sendNotification } from '../../../functions/shared/notifications/sendNotification'
import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../../urls'
import { advertisesSlice } from '../../slices/advertisesSlice'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'
import { type AppDispatch, type RootState } from '../../store'
import { uploadSingleFile } from '../documentsActions/upload/uploadSingleFile'

export const sendAdvertiseForm = (formValues: any) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
        try {
            const currentAdvertise = getState().advertises.advertises.filter((adv) => adv.id === Number(formValues.viewID))
            dispatch(dataFetchingStart())

            const url = await uploadSingleFile(formValues?.a_form?.[0])

            if (url) {
                const sendBodyDocument = {
                    method: 'POST',
                    collection: 'documents',
                    query: getQueryDocuments(),
                    body: {
                        RefID: `REF_DOC<->a_form<->${'document_number'}<->${new Date().getTime()}`,
                        Link: url,
                        Advertise: Number(formValues.viewID),
                        Category: 'A Form'
                    }
                }

                const responseDocument = await fetch(`${urlAPI}`, {
                    method: 'POST',
                    body: JSON.stringify(sendBodyDocument),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                })

                if (responseDocument.status === 200) {
                    const responseDocumentJSON = await responseDocument.json()

                    if (responseDocumentJSON.success) {
                        if (responseDocumentJSON.response.error != null) {
                            return { textNavigate: -1, isSuccessful: false, textNotification: 'Error loading the advertise form, please try again.' }
                        } else {
                            const response = await fetch(`${urlAPI}`,
                                {
                                    method: 'POST',
                                    headers: {
                                        'Content-type': 'application/json',
                                        Authorization: `Bearer ${jwt}`
                                    },
                                    body: JSON.stringify({
                                        method: 'PUT',
                                        collection: 'unit-advertises',
                                        id: Number(formValues.viewID),
                                        query: getQueryAdvertises(), 
                                        body: {
                                            Notify: 'BSO',
                                            Statuses: ['A Form added']
                                        }
                                    })
                                })
                            const responseJSON = await response.json()

                            if (responseJSON.success) {
                                if (responseJSON.response.error != null) {
                                    dispatch(dataFetchingFinish())
                                    dispatch(advertisesSlice.actions.advertisesFetchingError(getErrorss(responseJSON)))
                                    return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
                                } else {
                                    const inMomentViewingForAdmin = toUTCWithOffset(getCurrentDubaiDate(), 1)
                                    await sendNotification(inMomentViewingForAdmin, Number(userID), responseJSON.response.data.id, 'admin-advertise-a-form', 'Attention admin, new a-form')

                                    dispatch(advertisesSlice.actions.setAdvertises(responseJSON.response.data))
                                    dispatch(dataFetchingFinish())
                                    return { ...responseJSON, textNavigate: `/agent/modalView/advertise_modal/${currentAdvertise?.[0]?.attributes.Unit.data?.id}/${currentAdvertise?.[0].id}/not`, isSuccessful: true, textNotification: 'You have successfully uploaded the advertising form' }
                                }
                            } else {
                                dispatch(dataFetchingFinish())
                                dispatch(advertisesSlice.actions.advertisesFetchingError(getErrorss(responseJSON)))
                                return { textNavigate: '', isSuccessful: false, textNotification: '' }
                            }
                        }
                    } else {
                        dispatch(dataFetchingFinish())
                        return { textNavigate: -1, isSuccessful: false, textNotification: 'Error loading the advertise form, please try again.' }
                    }
                }
            } else {
                dispatch(dataFetchingFinish())
                return { textNavigate: -1, isSuccessful: false, textNotification: 'Error loading the advertise form, please try again.' }
            }
        } catch (e) {
            dispatch(dataFetchingFinish())
            console.log(e)
        }
    }
}
