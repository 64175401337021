import React from 'react'
import Lottie from 'react-lottie'
// import animationData from '../animations/loading_screen_animation/LOADING SCREEN 2.json'
import animationData from '../../../animations/loading_screen_animation/Icon.json'
import { getDynamicHeight } from '../../../functions/utils/getDynamicHeight';
export default function Loader(): JSX.Element {

    return (
        <div className="d-flex flex-column flex-row-fluid flex-column-fluid flex-center  my-n8" style={{ height: `${getDynamicHeight()}px` }}>
            <Lottie
                options={{
                    animationData,
                    loop: true,
                    autoplay: true
                }}
                height={220}
                width={220}
            />
            <h1 className="pt-n12 mt-n12">{'App is loading'}</h1>
        </div>
    )
}
