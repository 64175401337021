import React, { useState, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import UnitSh from './UnitSh'
import UnitsHeaderSh from './UnitsHeaderSh'
import UnitsNavigateSh from './UnitsNavigateSh'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import MainUnitFilters from './filters/MainUnitFilters'
import AgentFooter from '../../agentModule/AgentFooter'
import { setUnitsCatalogScrollPosition, setUnitsCategory } from '../../../store/slices/unitsSlice'
import { Drawer, Spin } from 'antd'
import { nanoid } from 'nanoid'
import { type ISettings } from '../../../store/slices/userSlice'

import useUnitsCatalog from '../../../hooks/useUnitsCatalog'
import useUserType from '../../../hooks/UseUserType'
import LandlordFooter from '../../landlordModule/LandlordFooter'
import UnitsScrollSaver from './UnitsScrollSaver'

import UnitsNotFoundMessageSh from './UnitsNotFoundMessageSh'
import { setButtonDriverAddOnsParent, setButtonDriverExtendLocation } from '../../../store/slices/modalSlice'
import { setIsExtend, setNotEnoughMarketingCredits, setUnitIDnotEnoughAreaForAdvertise } from '../../../store/slices/advertisesSlice'
import AddOnsServiceBricks from '../../agentModule/addOnsServices/AddOnsServicesCards/AddOnsServiceBricks'
import AddOnsServiceArea from '../../agentModule/addOnsServices/AddOnsServicesCards/AddOnServicesArea'
import { USER_AREAS_EXTEND_COUNT, USER_CHOOSE_EXTEND_AREA } from '../../../urls'
import { setWhatIsNotThereArea } from '../../../store/slices/areasSlice'
import { getDynamicHeight } from '../../../functions/utils/getDynamicHeight'

export interface IUnitsFiltersProps {
    filters: Array<{
        label: string
        value: string
        variants: string[]
    }>
    slice: 'units'
}

export type UnitsCategory = 'all' | 'rent' | 'sell' | 'agents'

interface AgentUnitsProps {
    hideHeader?: boolean
}

export default function UnitsSh({ hideHeader = false }: AgentUnitsProps): JSX.Element {
    const dispatch = useAppDispatch()

    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const settings = currentUser.Settings as ISettings[] | undefined
    const mainUnitFiltersOpen = useAppSelector((state: RootState) => state.modal.mainUnitFiltersOpen)
    const paginationLoading = useAppSelector((state: RootState) => state.units.catalogLoading)
    const unitsCategory = useAppSelector((state: RootState) => state.units.unitsCategory) as UnitsCategory

    const { from } = useParams()
    const [filterUse, setFilterUse] = useState('rent')
    const scrollRef = useRef<HTMLDivElement>(null)
    const userDevice = useAppSelector((state: RootState) => state.app.userDevice)

    const { userType } = useUserType()
    const filteredCatalog = useUnitsCatalog()


    const onClickCategory = (val: UnitsCategory): any => {
        dispatch(setUnitsCatalogScrollPosition(0))
        dispatch(setUnitsCategory(val))
    }


    const buttonDriverAddOnsParent = useAppSelector((state: RootState) => state.modal.buttonDriverAddOnsParent)
    const buttonDriverExtendLocation = useAppSelector((state: RootState) => state.modal.buttonDriverExtendLocation)
    const myFavoritesUnitsOpen = useAppSelector((state) => state.units).myFavoritesUnitsOpen

    return (
        <>
            <UnitsScrollSaver scrollRef={scrollRef} />
            <div className="page d-flex flex-column flex-row-fluid mw-450px mx-auto " style={{ minHeight: `${getDynamicHeight()}px` }}>
                {/* style={{ height: '100vh' }} */}
                {!hideHeader
                    ? <>
                        <div className="header position-fixed w-100 bg-white h-auto fw-bold d-flex flex-column shadow-sm mw-450px mx-auto "
                            style={{
                                paddingTop: userDevice === 'IPhone' ? '2.5rem' : 0,
                                zIndex: 101
                            }}
                        >
                            <div className="d-flex flex-column p-4 py-0 w-100 position-relative" style={{

                                zIndex: 101
                            }}>
                                <UnitsHeaderSh />
                            </div>
                            {!myFavoritesUnitsOpen && <UnitsNavigateSh filter={{ unitsCategory, filterUse }} onClickCategory={onClickCategory} />}
                            <MainUnitFilters />
                        </div>
                    </>
                    : null
                }

                <div className="m-0" style={{ height: '135px' }}></div>
                <div className="content d-flex flex-column flex-fill pt-4 pb-4 mx-4 overflow-auto " style={{ height: '100%' }} ref={scrollRef}>
                    {
                        filteredCatalog?.length > 0
                            ? (
                                <>
                                    {
                                        filteredCatalog.map((unit: any) => {
                                            return (
                                                <UnitSh
                                                    key={`${String(unit.id)}-${nanoid()}`}
                                                    hide={true}
                                                    unit={unit}
                                                    fromEntry={unitsCategory === "agents" ? 'other_agent_s_unit' : from}
                                                    settings={settings}
                                                />
                                            )
                                        })
                                    }
                                </>
                            )
                            : (!paginationLoading
                                ? <UnitsNotFoundMessageSh />
                                : <></>
                            )
                    }
                    {
                        paginationLoading
                            ? <div className={'w-100 d-flex justify-content-center p-4'}>
                                <Spin size={'large'} />
                            </div>
                            : <></>
                    }
                    <div className="d-none pb-14"></div>
                </div>
                <div className="m-0" style={{ height: '170px' }}></div>

                {mainUnitFiltersOpen
                    ? null
                    : userType === 'Agent'
                        ? <AgentFooter />
                        : <LandlordFooter />
                }
            </div>
            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'auto'}
                styles={{
                    body: {
                        maxHeight: '88vh',
                        marginBottom: '3px',
                        paddingTop: '3px',
                        marginTop: '6px',
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }
                }}
                placement={'bottom'}
                closable={false}
                onClose={() => {
                    dispatch(setButtonDriverExtendLocation(false))
                    window.localStorage.removeItem(USER_AREAS_EXTEND_COUNT)
                    window.localStorage.removeItem(USER_CHOOSE_EXTEND_AREA)

                    dispatch(setIsExtend(false))
                    dispatch(setUnitIDnotEnoughAreaForAdvertise(undefined))
                    dispatch(setWhatIsNotThereArea(null))
                    dispatch(setButtonDriverExtendLocation(false))
                }}
                open={buttonDriverExtendLocation}
                rootStyle={{ zIndex: '1100' }}
                key={'parent-driver-areas'}
                maskClosable={true}
            >
                <div key={'child-driver-areas'}
                    className="content d-flex flex-column flex-fill p-0 m-1 px-2"
                >
                    <div className="w-100 mw-450px  mx-auto">
                        <AddOnsServiceArea />
                    </div>
                </div>
            </Drawer>
            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'auto'}
                styles={{
                    body: {
                        maxHeight: '88vh', // maxHeight: '80vh',
                        marginBottom: '3px',
                        paddingTop: '3px',
                        marginTop: '6px',
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }
                }}
                placement={'bottom'}
                closable={false}
                onClose={() => { dispatch(setButtonDriverAddOnsParent(false)); dispatch(setNotEnoughMarketingCredits(0)) }}
                open={buttonDriverAddOnsParent}
                rootStyle={{ zIndex: '1100' }}
                key={'parent-driver-bricks'}
                maskClosable={true}
            >
                <div key={'child-driver-bricks'} className="content d-flex flex-column flex-fill p-0 m-1 px-2">
                    <div className="w-100 mw-450px mx-auto mh-75">
                        <AddOnsServiceBricks />
                    </div>
                </div>
            </Drawer>


        </>
    )
}
