
import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY, USER_ADVERTISE_DAYS, USER_AREAS_EXTEND_COUNT, USER_CHOOSE_EXTEND_AREA, USER_ID_KEY, urlAPI } from '../../../urls'
import { dataFetchingFinish, dataFetchingStart, modalSlice } from '../../slices/modalSlice'
import { type AppDispatch, type RootState } from '../../store'
import { addAdvertise, setDebitMarketingCredits, setIsExtend, setNotEnoughMarketingCredits, setUnitIDnotEnoughAreaForAdvertise } from '../../slices/advertisesSlice'
import { makePositiveOrUnchanged } from '../../../functions/agent/date/makePositiveOrUnchanged'

import { type User } from '../../slices/userSlice'
import { setFilterNavigate } from '../../slices/scheduleSlice'

import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { sendNotification } from '../../../functions/shared/notifications/sendNotification'
import { getQueryAdvertises } from '../../../functions/shared/api/queries/getQueryAdvertises'
import { getQueryUnits } from '../../../functions/shared/api/queries/getQueryUnits'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDubaiDate'

export const createAdvertise = (formValues: Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const userEMAIL = getState().user.currentUser.email
    const debitMarketingCredits = getState().advertises.debitMarketingCredits
    try {
        const units: any = getState().units.units
        const unit = units?.filter((el: any) => el.id === Number(formValues.unitID))
        const name = unit[0]?.attributes?.Property?.data?.attributes?.Name ?? ''
        const location = unit[0]?.attributes?.Property?.data?.attributes?.Area ?? ''
        const unitNumber = unit[0].attributes?.Number ?? ''
        dispatch(dataFetchingStart())
        const bodyObject = {
            method: 'POST',
            collection: 'unit-advertises',
            query: getQueryAdvertises(),  
            body: {
                Name: `${unitNumber} <-> ${name} <-> ${location}`,
                Duration: formValues.advertise_create_duration,
                User: Number(userID),
                Unit: Number(formValues.unitID),
                CreditsAmount: makePositiveOrUnchanged(debitMarketingCredits),
                Notify: 'Both',
                Statuses: ['In-process']
            }
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })
        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                const currentUnit = getState().units?.units?.filter((el: any) => el.id === Number(formValues.unitID))[0]
                const currentUser = getState().user.currentUser
                const nameUser = getNameUser(currentUser as User)
                const unitAddress = getUnitAddress(currentUnit)
                // логика удаления резерва с waitingList
                if(currentUnit.attributes.WaitingList != null && currentUnit.attributes.WaitingList.length > 0) {
                    const isUserInWaitingList =  currentUnit.attributes.WaitingList.some((waitingItem: any) => waitingItem.userID === Number(userID));
                    if (isUserInWaitingList) {
                        
                        const updatedWaitingList = currentUnit.attributes.WaitingList.filter((waitingItem: any) => Number(waitingItem.userID) !== Number(userID));
                        const responseUnitUpdateWaitingList = await fetch(`${urlAPI}`, {
                            method: 'POST',
                            body: JSON.stringify({
                                method: 'PUT',
                                collection: 'units',
                                id: Number(currentUnit.id),
                                body: {
                                    WaitingList: updatedWaitingList
                                },
                                query: getQueryUnits()
                            }),
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${jwt}`
                            }
                        });
                        await responseUnitUpdateWaitingList?.json();
                    }
                }
                
                dispatch(setIsExtend(false))
                dispatch(setUnitIDnotEnoughAreaForAdvertise(undefined))
                dispatch(setNotEnoughMarketingCredits(0))
                dispatch(addAdvertise(dataJSON.response.data))
                const currentDubaiDate = getCurrentDubaiDate()
                const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)

                await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON.response.data.id, 'admin-advertise-in-process', `Attention admin, ${nameUser} bought an ad for ${unitAddress}, for ${formValues?.advertise_create_duration} days period.`)
                const expirationDate = new Date(currentDubaiDate)
                expirationDate.setDate(expirationDate.getDate() + formValues?.advertise_create_duration)
                expirationDate.setDate(expirationDate.getDate() + 7)

                const responseGenerateLink = await fetch('https://bso.ae/api/app/generateDocumentsLink', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    body: JSON.stringify({
                        id: Number(dataJSON.response.data.id), 
                        expiryDate: expirationDate.getTime()
                    })
                })

                const responseGenerateLinkJSON = await responseGenerateLink.json()
                const responseSendMailAdvertise = await fetch('https://bso.ae/api/site/sendMail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    body: JSON.stringify({
                        email: userEMAIL,
                        templateId: 'd-e749cc0a5dfd499cad0d8d15932e8129',
                        dynamicTemplateData: {
                            unit_title: currentUnit?.attributes?.Marketing?.Headline,
                            documents: responseGenerateLinkJSON?.response?.link // `${URL_MAIN_API_APP}/action?type=GetAdvertiseDocuments&id=${dataJSON?.response?.data?.id}`
                        }
                    })
                })

                const responseSendMailAdvertiseJSON = await responseSendMailAdvertise.json()
                if (responseSendMailAdvertiseJSON.success && responseSendMailAdvertiseJSON.response.status === 202) {
                    dispatch(setFilterNavigate('adverts'))
                    dispatch(setDebitMarketingCredits(0))
                    // TODO Add main key 
                    window.localStorage.removeItem('firstDateAdvertise')
                    window.localStorage.removeItem('secondDateAdvertise')
                    window.localStorage.removeItem(USER_CHOOSE_EXTEND_AREA)
                    window.localStorage.removeItem(USER_ADVERTISE_DAYS)
                    window.localStorage.removeItem(USER_AREAS_EXTEND_COUNT)

 
                    const areThereMoreThanFiveAdvertises = getState().advertises.areThereMoreThanFiveAvertises
                    if (!areThereMoreThanFiveAdvertises) {
                        dispatch(setFilterNavigate('adverts'))
                    }

                    dispatch(dataFetchingFinish())
                    return {
                        ...dataJSON,
                        textNavigate:
                         areThereMoreThanFiveAdvertises
                             ? `/agent/modalView/advertise_modal/${currentUnit.id}/${dataJSON?.response?.data?.id}/create`
                             : '/agent/schedule',

                        isSuccessful: true,
                        textNotification: `Congratulations, you have successfully purchased the marketing right to advertising the unit.
                        The unit documents with the Marketing Authorization Form have been sent to your registered email.`

                    }
                } else {
                    dispatch(dataFetchingFinish())
                    return { ...dataJSON, textNavigate: responseSendMailAdvertiseJSON.response.status === 202 ? -1 : '', isSuccessful: false, textNotification: 'Something\'s gone wrong, try again.' }
                }
            }
        } else {
            dispatch(dataFetchingFinish())
            return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
        dispatch(dataFetchingFinish())
    }
}

export const generateAdvertiseLink = (advID: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(dataFetchingStart())
        const jwt = window.localStorage.getItem(ACCESS_KEY)
        const responseGenerateLink = await fetch('https://bso.ae/api/app/generateDocumentsLink', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                id: advID, // Number(formValues.unitID),
                expiryDate: new Date()
            })
        })
    
        dispatch(dataFetchingFinish())  
        const resultJSON = await responseGenerateLink.json()
        return resultJSON
    } catch (error) {
        console.log(error)
        dispatch(dataFetchingFinish())
    }
}
