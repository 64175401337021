
import { getQueryDocuments } from '../../../../functions/shared/api/queries/getQueryDocuments'
import { ACCESS_KEY,  urlAPI, USER_ID_KEY } from '../../../../urls'
import { authSlice } from '../../../slices/authSlice'
import { documentAddSuccess } from '../../../slices/documentSlice'
import { store, type AppDispatch } from '../../../store'

import { uploadSingleFile } from './uploadSingleFile'


export const uploadMultipleForOffers = (formValues: Record<string, any>, offerType: 'agent_to_bso_offer' | 'agent_to_agent_offer' = 'agent_to_agent_offer') => async (dispatch: AppDispatch) => {

    try {
        dispatch(authSlice.actions.authFetching(true))
        const fileTypesToUpload = []
        if ('visa' in formValues && formValues.visa.length > 0) {
            fileTypesToUpload.push('visa')
        } if ('id_front' in formValues) {
            fileTypesToUpload.push('id_front')
        } if ('id_back' in formValues && formValues.id_back.length > 0) {
            fileTypesToUpload.push('id_back')
        } if ('trade_licence' in formValues && formValues.trade_licence.length > 0) {
            fileTypesToUpload.push('trade_licence')
        } if ('cheque_copy' in formValues && formValues.cheque_copy.length > 0) {
            fileTypesToUpload.push('cheque_copy')
        }
        const result = await uploadFiles(formValues, fileTypesToUpload, offerType)
        let filteredResults = []
        if (result && result.length > 0) {
            filteredResults = result.filter((item: any) => item?.success && item?.response && item?.response?.data && item?.response?.data?.attributes).map((el: any) => {
                store.dispatch(documentAddSuccess(el?.response.data))    
                return el.response.data.id
            })
        }
        dispatch(authSlice.actions.authFetching(false))
        return filteredResults
    } catch (error) {
        console.error(error)
        dispatch(authSlice.actions.authFetching(false))
        return { textNavigate: '', isSuccessful: false, textNotification: 'Error loaded file' }
    } 
}

const uploadFiles = async (formValues: Record<string, any>, fileTypesToUpload: string[], offerType: 'agent_to_bso_offer' | 'agent_to_agent_offer'): Promise<any> => {
    try {

        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
        const offerID = formValues.offerID
        const promises = fileTypesToUpload.map(async (fileKey) => {
            const file = formValues[fileKey]?.[0]
            if (!file) return null
            const url = await uploadSingleFile(file)
            return { key: fileKey, url }
        })
        const uploadedFiles = (await Promise.all(promises)).filter(Boolean)
    
        const sendBodies =  uploadedFiles.map((file, i) => {
            let  type , category
            if (file != null) {
                const { key, url } = file
                if (key === 'visa') {
                    type =[ {   __component: 'documents.passport'} ] 
                    category = 'Visa'
                    return {
                        method: 'POST',
                        collection: 'documents',
                        query: getQueryDocuments(),
                        body: {
                            RefID: `REF_DOC<->${key}<->${offerType === 'agent_to_agent_offer' ? 'agent_offer' : 'bso_offer'}<->${new Date().getTime()}`,
                            Type: type,
                            Link: url,
                            Category: category, 
                            Offer: offerID
                        }
                    }
                }
                if (key === 'trade_licence') {
                    type =[ {  __component: 'documents.trade-license'  }]
                    category = 'Trade License'
                    return {
                        method: 'POST',
                        collection: 'documents',
                        query: getQueryDocuments(),
                        body: {
                            RefID: `REF_DOC<->${key}<->${offerType === 'agent_to_agent_offer' ? 'agent_offer' : 'bso_offer'}<->${new Date().getTime()}`,
                            Type: type,
                            Link: url,
                            Category: category, 
                            Offer: offerID
                        }
                    }
                } 
                if (key === 'cheque_copy') { 
                    category = 'Deposit Cheque'
                    return {
                        method: 'POST',
                        collection: 'documents',
                        query: getQueryDocuments(),
                        body: {
                            RefID: `REF_DOC<->${key}<->${offerType === 'agent_to_agent_offer' ? 'agent_offer' : 'bso_offer'}<->${new Date().getTime()}`,
                            Link: url,
                            Category: category,
                            Offer: offerID
                        }
                    }
                } 
                if (key === 'id_front') {
                    type =[ {  __component: 'documents.emirates-id'}]
                    category = 'Emirates ID'
                    return {
                        method: 'POST',
                        collection: 'documents',
                        query: getQueryDocuments(),
                        body: {
                            RefID: `REF_DOC<->emirates_id<->${offerType === 'agent_to_agent_offer' ? 'agent_offer' : 'bso_offer'}<->${new Date().getTime()}`,
                            Type: type,
                            LinkFront: url,
                            Offer: offerID
                        }
                    }
                } 
            }
             
        })
        const createLinks = await Promise.all(
            sendBodies.filter(body => Boolean(body)).map(async (sendBody) => {
                if(sendBody != null){
                    const response = await fetch(`${urlAPI}`, {
                        method: 'POST',
                        body: JSON.stringify(sendBody),
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwt}`,
                            'BSO-Hash': '02ns30nsBSO!'
                        }
                    })
                    const json = await response.json()
                    if (json.success && json.response && json.response.data && json.response.data.attributes) {
                        if (json?.response?.data?.attributes?.LinkFront != null) {
                            const responseBackID = await fetch(`${urlAPI}`, {
                                method: 'POST',
                                body: JSON.stringify(   {
                                    method: 'PUT',
                                    collection: 'documents',
                                    id: json?.response?.data?.id,
                                    query: getQueryDocuments(),
                                    body: {
                                        LinkBack: uploadedFiles.find((objLink) => objLink?.key ===  "id_back")?.url,
                                    }
                                }),
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${jwt}`,
                                    'BSO-Hash': '02ns30nsBSO!'
                                }
                            })
                            const jsonBack = await responseBackID.json()
                        }
                        return json
                    }
                }
            })
        )
        return createLinks
    } catch (error) {
        console.error(error)
    }
}
