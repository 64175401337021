
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { getQueryAdvertises } from '../../../functions/shared/api/queries/getQueryAdvertises'
import { sendNotification } from '../../../functions/shared/notifications/sendNotification'
import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../../urls'
import { advertisesSlice } from '../../slices/advertisesSlice'
import { modalSlice } from '../../slices/modalSlice'

import { type AppDispatch, type RootState } from '../../store'

export const createStartDateAdvertise = (bodyClient: any) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
        try {
            const currentAdvertise = getState().advertises.advertises.filter((adv) => adv.id === Number(bodyClient.viewID))
            const durationAdv = currentAdvertise?.[0]?.attributes.Duration ?? 0
            const startDate = new Date(bodyClient.advertise_create_start_date)
            const endDate = new Date(startDate)
            endDate.setDate(endDate.getDate() + durationAdv)
            dispatch(modalSlice.actions.dataFetchingStart())
            const response = await fetch(`${urlAPI}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    body: JSON.stringify({

                        method: 'PUT',
                        collection: 'unit-advertises',
                        id: Number(bodyClient.viewID),
                        query:getQueryAdvertises(), // `filters[User][id][$eq]=${userID}&populate[User][fields][0]=id&populate[Unit][fields][0]=id`, // [id]
                        body: {
                            StartDate: startDate,
                            EndDate: endDate,
                            Notify: 'BSO'
                        }
                    })
                })
            const responseJSON = await response.json()
            if (responseJSON.success) {
                if (responseJSON.response.error != null) {
                    dispatch(modalSlice.actions.dataFetchingFinish())
                    dispatch(advertisesSlice.actions.advertisesFetchingError(getErrorss(responseJSON)))
                    return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
                } else {
                    const inMomentViewingForAdmin = toUTCWithOffset(startDate, 0)
                    const advertiseDateEndNotify = toUTCWithOffset(new Date(endDate), 0)
                    const advertiseDateEndNotifyAfterDay = new Date(advertiseDateEndNotify.getTime() + 1 * 24 * 60 * 60 * 1000)
                    const advertiseDateEndNotifyBeforeDay = new Date(advertiseDateEndNotify.getTime() - 1 * 24 * 60 * 60 * 1000)

                    dispatch(advertisesSlice.actions.setAdvertises(responseJSON.response.data))
                    await sendNotification(inMomentViewingForAdmin, Number(userID), responseJSON.response.data.id, 'user-advertise-start', 'Hi , it\'s new')
                    await sendNotification(advertiseDateEndNotifyAfterDay, Number(userID), responseJSON.response.data.id, 'user-advertise-end', 'Hi , it\'s new')
                    await sendNotification(advertiseDateEndNotifyBeforeDay, Number(userID), responseJSON.response.data.id, 'user-advertise-heads-up', 'Hi , it\'s new')

                    dispatch(modalSlice.actions.dataFetchingFinish())
                    return { ...responseJSON, textNavigate: `/agent/modal/agent_advertise_create_links/${currentAdvertise?.[0].attributes.Unit.data?.id}/${currentAdvertise?.[0].id}`, isSuccessful: true, textNotification: 'The advertising date has been set' }
                }
            } else {
                dispatch(modalSlice.actions.dataFetchingFinish())
                dispatch(advertisesSlice.actions.advertisesFetchingError(getErrorss(responseJSON)))
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } catch (e) {
            dispatch(modalSlice.actions.dataFetchingFinish())
            console.log(e)
        }
    }
}
