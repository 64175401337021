import { type AppDispatch, type RootState } from '../../../store'
import { dataFetchingFinish, dataFetchingStart} from '../../../slices/modalSlice'
import { ACCESS_KEY, urlAPI } from '../../../../urls'
import { offersSlice, setOffers } from '../../../slices/offersSlice'
import { getErrorss } from '../../../../functions/errors/GetErrors'
import { getQueryOffers } from '../../../../functions/shared/api/queries/getQueryOffers'

export const makeOfferAgentToBSOOfferInfo = (unitID: number, offerID?: number) => (data: Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState) => {

    dispatch(dataFetchingStart())
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY)
        const unit = getState().units.units.find((unitItem) => unitItem.id === unitID)
        const offer = getState().offers?.offers?.find((offer) => offer.id === Number(offerID))
        if (unit != null ) { // && offer != null && offer?.attributes?.Type?.[0]?.__component != null
            const isSale = unit.attributes?.Marketing?.Use === 'For sale'
            const isCommercial = unit.attributes?.Type  === 'Commercial'




            const offerType = {
                __component: isCommercial 
                    ? (isSale ? 'offer.sale-commercial' : 'offer.commercial') 
                    : (isSale ? 'offer.sale' : 'offer.residential'),
                ...offer?.attributes?.Type?.[0],
                Offer: data.Type.Offer,
                Payments: data.Type.Payments,
                AdditionalNotes: data.Type.AdditionalNotes,
                ...(
                    isSale
                        ? {
                            BankName: data.Type.BankName,
                            BankPreApproval: data.Type.PreApprovedAmount != null,
                            Type: data.Type.Type === 'cash' ? 'Cash Buyer' : 'Mortgage Buyer',
                            ...(data.Type.PreApprovedAmount ? {PreApprovedAmount: data.Type.PreApprovedAmount} : {}),
                        }
                        : {
                            Duration: data.Type.Duration,
                            ContractStartDate: data.Type.ContractStartDate
                        }
                )
            }

            const property = unit.attributes?.Property?.data
            const name = property?.attributes?.Name ?? ''
            const location = property?.attributes?.Area ?? ''
            const unitNumber = unit?.attributes?.Number ?? 'Offer'
            const bodyObject = {
                method: offerID == null ? 'POST' : 'PUT',
                id: offerID ?? undefined,
                query: getQueryOffers(),
                collection: 'offers',
                body: {
                    ...(offer?.attributes ?? {}),
                    Type: [offerType],
                    Notify: 'Both',
                    // new spred 
                    Statuses: ['Client docs required'], 
                    Name: `${unitNumber} <-> ${name} <-> ${location}`,
                    Unit: unit.id,
                    User: getState().user.currentUser.id,
                }
            }
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'BSO-Hash': '02ns30nsBSO!',
                    Authorization: `Bearer ${String(jwt)}`
                },
                body: JSON.stringify(bodyObject)
            })
            const responseJSON = await response.json()
            if (responseJSON.success === true) {
                if (responseJSON.response.error != null) {
                    dispatch(offersSlice.actions.offersFetchingError(getErrorss(responseJSON)))
                    dispatch(dataFetchingFinish())
                    return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
                } else {
                   
                    dispatch(offersSlice.actions.addOffer(responseJSON.response.data))
                    //  void sendNotification(new Date(new Date().getTime() + 10000), Number(getState().user.currentUser.id), responseJSON.response.data.id , 'admin-offer-in-process', 'Offer - in process')
                    //   deleteAgentToBSOOfferCommunicationStorageInfo()
                 
                    dispatch(dataFetchingFinish())
                    return {
                        textNavigate: `/agent/modalView/offer_modal/${unitID}/${responseJSON?.response?.data?.id}/create?type=${String(responseJSON?.response?.data?.attributes?.Type?.[0]?.__component ?? 'not').slice(6)}`,
                        isSuccessful: true,
                        textNotification: 'Offer has been created',
                        title: 'Success'
                    }
                  
                }
            }
        }
    } catch (err: any) {
        console.error(`Error in makeOfferAgentToBSOOfferInfo ${String(err.message)}`)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false,
        textNotification: 'Something went wrong. Try again later'
    }
}
