import React, { useEffect, useRef } from 'react'
import { Select } from 'antd'
import { Controller } from 'react-hook-form'
import _ from 'lodash'
import { useAppSelector } from '../../../hooks/redux'
import Icon from '../../icon/Icon'
import softlyIncludes from '../../../functions/search/softlyIncludes'
import CustomFormLable from '../customModalUtils/CustomFormLable'

export default function CustomSelect({ register, control, data, setValue, errors }: any): JSX.Element {

    const [open, setOpen] = React.useState(false)
    const triggerValue = useAppSelector((store) => _.get(store.modal, data.trigger ?? ''))
    useEffect(() => {
        if (triggerValue != null && data.trigger != null) {
            setTimeout(() => { setValue(data.id, triggerValue) })
        }
    }, [triggerValue, data.id])

    const ref = useRef<any>()
    let optionsMapped: Array<{ value: string, label: string }>
    /**
     * Check if the selectors array is already mapped
     */
    if (_.isObject(data.selectors?.[0]) && 'label' in (data.selectors?.[0])) {
        optionsMapped = data.selectors
    } else {
        optionsMapped = (data.selectors ?? []).map((el: string) => {
            return {
                value: el,
                label: el
            }
        })
    }

    return (
        (

            <div className="fv-row  py-2 w-100 fv-plugins-icon-container">
                <CustomFormLable row={data} />
                <Controller
                    key={`key-${String(triggerValue)}`}
                    name={data.id}
                    control={control}
                    defaultValue={Boolean(data.defaultValue) || data.defaultValue === 'undefined' ? data.defaultValue : undefined}
                    rules={{
                        required: data.required ? `Field "${data.content?.[0]}" is required` : false,
                        validate: value => {
                            if (data.required && !value) {
                                return `Field "${data.content?.[0]}" is required`;
                            }
                            return true;
                        }
                    }}
                    // rules={data.required ? { required: `Field "${data.content?.[0]}" is required` } : {}}
                    render={({ field }) => {
                        return <Select
                            open={open}
                            onDropdownVisibleChange={(visible) => {
                                setOpen(visible)
                                // onDropdownToggle()
                            }}
                            dropdownRender={
                                data.multiple === true
                                    ? (menu: React.ReactElement) => (
                                        <>
                                            <div className={'position-relative'}>
                                                <div className={'position-absolute end-0 p-2 bg-white rounded'}
                                                    style={{ zIndex: '1500' }}>
                                                    <Icon name={'close'} className={'fs-4x text-muted'} onClick={() => {
                                                        setOpen(false)
                                                    }} />
                                                </div>
                                            </div>
                                            {
                                                menu
                                            }
                                        </>
                                    )
                                    : undefined
                            }
                            popupClassName="drp-style"
                            listHeight={325}
                            placeholder={data.content && data.content[1]}
                            className="d-block"
                            {...field}
                            size={'large'}
                            options={optionsMapped}
                            optionFilterProp="children"
                            // showSearch={data.selectors?.length > 5}
                            showSearch={data?.showSearch === true}
                            filterOption={(value, option) => softlyIncludes({
                                target: String(option?.label).toLowerCase(),
                                value: value.toLowerCase(),
                                accuracy: 0.3
                            })}
                            mode={data.multiple ? 'multiple' : undefined}
                            onDeselect={data.onDeselect != null
                                ? data.onDeselect
                                : () => { }
                            }
                            onSelect={data.onChange != null
                                ? data.onChange
                                : () => {
                                }}
                            ref={ref}
                            disabled={data.disabled === true}
                        >
                            {field.value === '' && (
                                <Select.Option value="">{data.content[1]}</Select.Option>
                            )}
                        </Select>
                    }}
                />
                {
                    _.get(errors, data.id) != null && (
                        <div className="text-danger my-2">{String(_.get(errors, data.id)?.message)}</div>
                    )
                }
            </div>
        )

    )
}
