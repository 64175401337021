import Swal, { type SweetAlertResult } from 'sweetalert2'
import { type User } from '../../../store/slices/userSlice'

import { animationOptions, swalNegatiateOffer, swalOptions, swalSuccess } from '../../swalOptions'
import { removeOfferNotify, setOfferStatus } from '../../../store/actionsCreators/offerActions'
import { type AppDispatch, store } from '../../../store/store'
import { type NavigateFunction } from 'react-router-dom'
import _ from 'lodash'
import { removeDataAttributes } from '../../adapters/universal/removeDataAttributes'
import swalConfig from '../../../app/texts/swalTexts'
import {
    agentToBSOOfferCommunicationCheckBrokenPipeline
} from '../../../store/actionsCreators/offerActions/agentToBSOOfferCommunication/agentToBSOOfferCommunicationStorage'
import { type IOffer } from '../../../store/slices/offersSlice'
import { USER_ID_KEY } from '../../../urls'
import { type Unit } from '../../../store/slices/unitsSlice'
import { textsOfferTimelineRent } from '../../../app/texts/timelinesTexts'
import { getOfferTypeName } from './getOfferTypeName'
import { type Document } from '../../../store/slices/documentSlice'

export const showNegotiateSwals = async (currentUser: User, dispatch: AppDispatch, navigate: NavigateFunction): Promise<void> => {
    const userID = currentUser.id ?? window.localStorage.getItem(USER_ID_KEY) as string
    const offers =  store.getState().offers.offers.filter((el) => el?.attributes?.User?.data?.id === Number(userID))
  
    try {
        for (const offer of offers) {
            await processOfferSwal(offer, dispatch, navigate)
        }
        // dispatch(agentToBSOOfferCommunicationCheckBrokenPipeline(navigate))
    } catch (err) {
        console.log(err)
    }
}

export const offerReactRequireStatuses = ['Resubmit required', 'Negotiated by landlord', 'Negotiated by BSO',  'Waiting deposit pay slip', 'Slip rejected']
export const offerReactRequireActions = {
    'Resubmit required': 'Resubmit',
    'Negotiated by landlord': 'Negotiate',
    'Negotiated by BSO': 'Negotiate',
    Confirmed: 'Send client info',
    'Waiting deposit pay slip': 'Upload pay slip',
    'Slip rejected': 'Upload pay slip'
}

export const processOfferSwal = async (offer: IOffer, dispatch: AppDispatch, navigate: NavigateFunction): Promise<void> => {
    let units = store.getState().units.units
    units = [...units]?.filter((el: Unit) => el.id === Number(offer?.attributes?.Unit?.data?.id))
    const processEndDate = offer?.attributes?.ProcessEndDate ? new Date(offer.attributes.ProcessEndDate).getTime() : null;
    const unit = units[0] 
    if(unit == null || unit.attributes.ManagedBy !== "BSO") return
    if (
        processEndDate !== null && 
        new Date().getTime() > processEndDate && offer?.attributes?.ProcessEndDate != null && _.includes(['Negotiated by landlord', 'Negotiated by BSO', 'Confirmed', 'Waiting deposit pay slip', 'Slip approved', 'Slip rejected'], offer?.attributes?.Statuses[0])) {
        await Swal.fire({
            ...swalOptions('error'),
            ...{
                html: `${getOfferInfo(offer)} ${swalConfig.offerExpiredSwal.message(unit?.attributes?.Marketing?.Headline ?? " - ")}`,
                showConfirmButton: true,
                confirmButtonText: swalConfig.offerExpiredSwal.confirmButtonText
            }
        })
    } else {
        const status = offer?.attributes?.Statuses?.[0]
        // console.log(offer,"offer","status",status)
        const storeOffer = store.getState().offers.offers.find((offerItem) => offerItem.id === offer.id)
        switch (status) {
            case 'Resubmit required':
                await Swal.fire({
                    ...swalOptions('info'),
                    ...{
                        html: getOfferInfo(offer, 'Our specialist requires action from you: Please resubmit this offer', 'resubmit'),
                        icon : "info",
                        iconHtml: '<div  id="lottie-icon-info"></div>',
                        didOpen: () => {
                            const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                            if (lottieContainer != null) {
                                lottie.loadAnimation({
                                    container: lottieContainer,
                                    renderer: 'svg',
                                    ...{ ...animationOptions, animationData: require(`../../../animations/Info.json`) }
                                })
                            }
                        },
                      
                        showConfirmButton: true,
                        confirmButtonText: 'Resubmit offer',
                        showDenyButton: true,
                        denyButtonText: 'Cancel offer',
                        showCancelButton: true,
                        cancelButtonText: 'Decide later',
                        allowOutsideClick: true
                    }
                }).then((promptResult) => {
                    if (promptResult.isConfirmed) {
                        navigate(`/agent/modal/agent_to_bso_resubmit_offer_documents/${String(offer.attributes?.Unit?.data?.id ?? storeOffer?.attributes?.Unit?.data?.id ?? '-')}/${String(offer.id)}`)
                        // switch (offer.Type[0].__component) {
                        //     case 'offer.residential':
                        //         navigate(`/agent/modal/agent_set_offer_modal_documents_individual/${String(offer.Unit.id ?? storeOffer?.attributes?.Unit?.data?.id ?? '-')}/${String(offer.id)}`)
                        //         break
                        //     case 'offer.commercial':
                        //         navigate(`/agent/modal/agent_set_offer_modal_documents_company/${String(offer.Unit.id ?? storeOffer?.attributes?.Unit?.data?.id ?? '-')}/${String(offer.id)}`)
                        //         break
                        //     case 'offer.sale':
                        //         navigate(`/agent/modal/agent_set_offer_modal_documents_individual_sale/${String(offer.Unit.id ?? storeOffer?.attributes?.Unit?.data?.id ?? '-')}/${String(offer.id)}`)
                        //         break
                        //     case 'offer.sale-commercial':
                        //         navigate(`/agent/modal/agent_set_offer_modal_documents_company_sale/${String(offer.Unit.id ?? storeOffer?.attributes?.Unit?.data?.id ?? '-')}/${String(offer.id)}`)
                        //         break
                        //     default:
                        //         break
                        // }
                    } else if (promptResult.isDenied) {
                        void dispatch(setOfferStatus(offer.id, 'Canceled', undefined, {
                            receiver: 'BSO',
                            rule: 'admin-offer-canceled'
                        }))
                    }
                })
                break
            case 'Negotiated by landlord':
            case 'Negotiated by BSO':
         
                await swalNegatiateOffer(
                    status === 'Negotiated by landlord'
                        ? `${getOfferInfo(offer, swalConfig.negotiateOfferSwal.title.byLandlord, 'negotiate')}`
                        : `${getOfferInfo(offer, swalConfig.negotiateOfferSwal.title.byBSO, 'negotiate')}`,
                    async (promptResult: SweetAlertResult<any>) => {
                        if (promptResult.isDismissed) {
                        } else if (!promptResult.isConfirmed) {
                            navigate(`/agent/modal/negotiate_offer_modal/${String(offer.attributes?.Unit?.data?.id)}/${String(offer?.id)}`)
                        } else {
                            if(status === 'Negotiated by landlord')   {
                                void dispatch(setOfferStatus(offer.id, 'Confirmed', undefined, {
                                    receiver: 'Agent',
                                    rule: 'admin-offer-confirmed'
                                }))
                                await swalSuccess(swalConfig.negotiateOfferSwal.negotiateConfirmed.message)
                            } else if (status === 'Negotiated by BSO') {   
                                void dispatch(setOfferStatus(offer.id, 'Negotiation confirmed by agent', undefined, {
                                    receiver: 'Agent',
                                    rule: 'admin-offer-confirmed'
                                })).then(() => {
                                    void swalSuccess(swalConfig.negotiateOfferSwal.negotiateConfirmed.message)
                                })
                          
                            }
                        }
                    },
                    new Date().getTime() - new Date(offer?.attributes.updatedAt).getTime() < 24 * 60 * 60 * 1000
                )
                break

                // TO DO REMOVE   add status negotiotions confirmd  
                // case 'Confirmed': {
                //     if (offer?.attributes.AgentToAgentID == null && offer?.attributes.AgentToLandlordID == null && !unit?.attributes?.Statuses?.includes('Door close')) {
                //         await Swal.fire({
                //             ...swalOptions('info'),
                //             ...{
                //                 html: `${getOfferInfo(offer, swalConfig.confirmedOfferSwal.title)} ${swalConfig.confirmedOfferSwal.message(removeDataAttributes(_.cloneDeep(offer))?.Type?.[0]?.Offer * 0.05)}`,
                //                 showConfirmButton: true,
                //                 showCancelButton: true,
                //                 confirmButtonText: swalConfig.confirmedOfferSwal.confirmButtonText,
                //                 cancelButtonText: swalConfig.confirmedOfferSwal.cancelButtonText,
                //                 showLoaderOnConfirm: true,
                //                 allowOutsideClick: true,
                //                 icon : "info",
                //                 iconHtml: '<div id="lottie-icon-info"></div>',
                //                 didOpen: () => {
                //                     const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                                
            //                     if (lottieContainer != null) {
            //                         lottie.loadAnimation({
            //                             container: lottieContainer,
            //                             renderer: 'svg',
            //                             ...{ ...animationOptions, animationData: require(`../../../animations/Info.json`) }
            //                         })
            //                     }
            //                 },
            //                 preConfirm: async () => {
            //                     navigate(`/agent/modal/agent_offer_submit_client_info_modal/${String(offer.attributes?.Unit?.data?.id)}/${String(offer?.id)}`)
            //                     return true
            //                     // const cancelButton = Swal.getCancelButton()
            //                     // if (cancelButton != null) {
            //                     //     cancelButton.style.display = 'none'
            //                     // }
            //                     //
            //                     // const form: HTMLFormElement | null = document.querySelector('#swal-form')
            //                     // if (form != null) {
            //                     //     const email = form.Email.value
            //                     //     const phone = form.Phone.value
            //                     //     const { isSuccessful } = await setClientContactInfo(offer.id, email, phone)
            //                     //     if (isSuccessful) {
            //                     //         return true
            //                     //     }
            //                     // }
            //                     // if (cancelButton != null) {
            //                     //     cancelButton.style.display = 'block'
            //                     // }
            //                     // Swal.showValidationMessage('Client\'s email or phone is not valid')
            //                     //
            //                     // return false
            //                 }
            //             }
            //         })
            //     }
            //     break
            // }
            // case 'Completed':
            //     if (offer.attributes?.Notify === 'Agent' && !unit?.attributes?.Statuses?.includes('Door close')) {
            //         await Swal.fire({
            //             ...swalOptions('success'),
            //             icon : "success",
            //             iconHtml: '<div  id="lottie-icon-success"></div>',
            //             didOpen: () => {
            //                 const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
            //                 if (lottieContainer != null) {
            //                     lottie.loadAnimation({
            //                         container: lottieContainer,
            //                         renderer: 'svg',
            //                         ...{ ...animationOptions, animationData: require(`../../../animations/Success.json`) }
            //                     })
            //                 }
            //             },
            //             html: getOfferInfo(offer, swalConfig.offerCompletedSwal.title)
            //         })
            //         await dispatch(removeOfferNotify(offer.id))
            //     }
            //     break
            case 'Confirmed' : // 'Waiting deposit pay slip':
                await swalWaitingForDepositPaySlip(dispatch, offer, false, navigate)
                break
            case 'Slip rejected':
                await swalWaitingForDepositPaySlip(dispatch, offer, true, navigate)
                break
            default:
                break
        }
    }
}

export const swalWaitingForDepositPaySlip = async (dispatch: AppDispatch, offer: any, wasRejected: boolean, navigate: NavigateFunction): Promise<SweetAlertResult<any>> => {
  
    const isSale  = getOfferTypeName(offer).includes('sale')

    return await Swal.fire({
        ...swalOptions(wasRejected ? 'error' : 'info'),
        icon : wasRejected ? 'error' : 'info',
        iconHtml: `<div  id="lottie-icon-${wasRejected ? 'error' : 'info'}"></div>`,
        didOpen: () => {
            const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
            if (lottieContainer != null) {
                lottie.loadAnimation({
                    container: lottieContainer,
                    renderer: 'svg',
                    ...{ ...animationOptions, animationData: require(`../../../animations/${wasRejected ? 'Error' : 'Info'}.json`) }
                })
            }
        },
        ...{
            html: `${getOfferInfo(offer, wasRejected ?    isSale ?  'Offer confirmation pay has been denied: Resubmit cheque copy 10%'   : 'Offer confirmation pay has been denied: Resubmit deposit pay slip and contact info' :  isSale ? 'Please send us your client\'s contact info: email address and phone number.' : textsOfferTimelineRent.descriptionConfirmed)}`, // !wasRejected ? 'Proceed to uploading' : 'Resubmit offer confirmation',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Upload later',
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: true
        },
        preConfirm: async () => {
            !isSale 
                ?   navigate(`/agent/modal/submit_deposit_slip_offer_modal/-/${String(offer?.id)}`) 
                :  navigate(`/agent/modal/agent_offer_submit_client_info_modal/${String(offer.attributes?.Unit?.data?.id)}/${String(offer?.id)}`) 
        }
    })
   
}

export const getOfferInfo = (offer: IOffer, title?: string, mode: 'negotiate' | 'resubmit' | null = null): string => {
    const offerInfo = removeDataAttributes(_.cloneDeep(offer))
    const units = store.getState().units.units
    const unit = units?.filter((el: any) => el.id === Number(offer?.attributes.Unit?.data?.id))[0]

    const htmlContent = `
    <div class="w-100 d-flex flex-column">
        <div class="d-flex justify-content-center mb-2">
            <h3>${title !== null ? String(title) : 'Notification for offer'}</h3>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <div class="text-start">Offer Unit:</div>
            <strong  class="text-end">${String(unit?.attributes?.Marketing?.Headline ?? unit?.attributes?.Property?.data?.attributes?.Name)}</strong>
        </div>
        <div class="d-flex justify-content-between mb-2 lh-sm">
            <div class="text-start ">Offer Client:</div>
            <strong  class="text-end">${String(offerInfo?.Type?.[0]?.FirstName ?? offerInfo?.Type?.[0]?.BusinessName)} ${String(offerInfo?.Type?.[0]?.LastName ?? '')}</strong>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <div>Offer Price:</div>
            <strong>${String(offerInfo?.Type?.[0]?.Offer)} AED</strong>
        </div>
        ${(mode === 'negotiate') ? `
            ${offerInfo?.Type?.[0]?.Payments != null ? `<div class="d-flex justify-content-between mb-2"><div>Offer Payments:</div> <strong>${String(offerInfo?.Type?.[0]?.Payments)}</strong></div>` : ''}
            ${offerInfo?.Type?.[0]?.Duration != null ? `<div class="d-flex justify-content-between mb-2"><div>Contract Duration:</div> <strong>${String(offerInfo?.Type?.[0]?.Duration)} months</strong></div>` : ''}
            ${offerInfo?.Type?.[0]?.ContractStartDate != null ? `<div class="d-flex justify-content-between mb-2"><div>Contract Start Date:</div> <strong>${String(offerInfo?.Type?.[0]?.ContractStartDate)}</strong></div>` : ''}
        ` : ''}
        ${(mode === 'resubmit') ? `
            ${offerInfo?.RejectReason != null ? `<div class="d-flex justify-content-between mb-2"><div>Reject Reason:</div> <strong>${String(offerInfo?.RejectReason)}</strong></div>` : ''}
        ` : ''}
    </div><br>
    `;
    return htmlContent
}
