import React from 'react'
import { untitledImage } from '../../../../urls'

export interface UnitImageCardProps {
    image: string
    inMessage?: boolean
}
const UnitImageCard = ({ image, inMessage = false }: UnitImageCardProps): JSX.Element => {

    return (
        <div className="text-center">
            <div
                className={`w-100 ${inMessage ? 'h-150px' : 'h-200px'} overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center`}
                style={{ backgroundImage: `url(${image})` }}
            >
                <div className={`${inMessage ? 'h-150px' : 'h-200px'} w-100`}></div>
            </div>
        </div>
    )
}

export default UnitImageCard
