import React from 'react';

interface ToggleButtonGroupProps {
    options: Array<{ value: string, label: string }>;
    selectedValue: string;
    onSelect: (value: string) => void;
}

export default function CustomToggleButtonGroup({ options, selectedValue, onSelect }: ToggleButtonGroupProps): JSX.Element {

    return (
        <div className="d-flex justify-content-between gap-4 w-100 px-4 my-2">
            {options.map(option => (
                <div key={option.value} className={'col'} onClick={() => { onSelect(option.value); }}>
                    <div className={`d-flex flex-column align-items-center rounded-4 ${selectedValue === option.value ? 'bg-bso-semi-back' : 'bg-light-secondary'}`}>
                        <div className={'d-flex align-items-center justify-content-center w-100 px-auto py-3'}>
                            <span className={`fs-7 text-dark text-uppercase my-2 ${selectedValue === option.value ? 'fw-bold' : 'fw-semibold'} lh-1 text-center`}>
                                {option.label}
                            </span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

