import { getErrorss } from "../../../functions/errors/GetErrors"
import { getQueryOffers } from "../../../functions/shared/api/queries/getQueryOffers"
import { ACCESS_KEY, urlAPI } from "../../../urls"
import { offersSlice } from "../../slices/offersSlice"
import { type AppDispatch } from "../../store"

export const fetchOffers = (noLoading: boolean = false) => async (dispatch: AppDispatch) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    try {
        if (!noLoading) {
            dispatch(offersSlice.actions.offersFetching())
        }
        const bodyObject = {
            method: 'GET',
            collection: 'offers',
            query:  getQueryOffers()
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(bodyObject)
        })
        const dataJSON = await response.json()
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(offersSlice.actions.offersFetchingError(getErrorss(dataJSON)))
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(offersSlice.actions.offersFetchingSuccess(dataJSON.response.data))
                return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(offersSlice.actions.offersFetchingError(getErrorss(dataJSON)))
            return {...dataJSON, textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(offersSlice.actions.offersFetchingError(JSON.stringify(error)))
    }
}
