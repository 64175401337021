import React, { useEffect, useState } from 'react'
import CloseIconBasic from '../../elements/CloseIconBasic'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { setScheduleFilters, setShowPopupScheduleFilters } from '../../../../store/slices/modalSlice'
import { type RootState } from '../../../../store/store'
import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../../urls'
import UseUserType from '../../../../hooks/UseUserType'
import ScheduleAdminHeader from './ScheduleAdminHeader'
import ScheduleAdminUnitsList from './ScheduleAdminUnitsList'
import ScheduleSortTypeUnits from './ScheduleSortTypeUnits'
import ScheduleSort from './ScheduleSort'

import ScheduleSortByDates from './ScheduleSortByDates'
import ScheduleSortOffersByStatuses from './status/ScheduleSortOffersByStatuses'
import ScheduleSortViewingsByStatuses from './status/ScheduleSortViewingsByStatuses'
import ScheduleSortByNotices from './ScheduleSortByNotices'
interface IProps {
    scheduleFiltersApply: boolean
    setScheduleFiltersApply: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ScheduleFiltersList({ scheduleFiltersApply, setScheduleFiltersApply }: IProps): JSX.Element {
    const dispatch = useAppDispatch()
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const scheduleFilters = useAppSelector((state: RootState) => state.modal.scheduleFilters)
    const loading = useAppSelector(state => state.modal.loading)
    const isDev = process.env.REACT_APP_IS_DEV === 'true'
    const { userType } = UseUserType()
    const [localFilters, setLocalFilters] = useState(scheduleFilters)
    const userID = window.localStorage.getItem(USER_ID_KEY)

    const filterNavigate = useAppSelector((state: RootState) => state.schedule.filterNavigate)



    const units = useAppSelector((state: RootState) => state.units.units)
    const unitsByBSO = units
        .filter((unit) => unit.attributes?.User?.data?.id && unit.attributes?.User?.data?.id === Number(process.env.REACT_APP_BSO_USER_ID ?? '57') && unit.attributes?.ManagedBy === 'BSO' && Boolean(unit?.attributes?.Marketing))
        .sort((a, b) => {
            const dateA = new Date(a.attributes?.createdAt).getTime()
            const dateB = new Date(b.attributes?.createdAt).getTime()
            return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
        })
    //  !unit?.attributes?.Statuses?.includes('Sold') && !unit?.attributes?.Statuses?.includes('Drafted') && !unit?.attributes?.Statuses?.includes('Archived') &&


    useEffect(() => {
        setLocalFilters(scheduleFilters)
    }, [scheduleFilters])


    const applyFilters = () => {
        dispatch(setScheduleFilters(localFilters))
        dispatch(setShowPopupScheduleFilters(false))
        if (!scheduleFiltersApply) {
            setScheduleFiltersApply(true)
        }
    }

    const resetFilters = () => {
        setLocalFilters({ ...scheduleFilters, sort: 'all' })
        dispatch(setShowPopupScheduleFilters(false))
        if (!scheduleFiltersApply) {
            setScheduleFiltersApply(true)
        }
    }

    return (
        <div className="page  fs-5 w-100 mx-auto">
            <div className="content w-100 mw-450px mx-auto ">
                {/* <div className="card d-flex  w-100 mx-auto "> */}
                <div
                    className="position-fixed w-100 bg-white"
                    style={{
                        paddingTop:
                            userDevice === 'IPhone' ? '45px' : '14px',
                        paddingBottom: '14px',
                        zIndex: "9999!important"
                    }}
                >
                    <div className="h-100  w-100vw" >
                        <h3 className="text-uppercase ">
                            Schedule Filters
                        </h3>

                        {
                            <CloseIconBasic
                                onCancel={() => {
                                    dispatch(
                                        setShowPopupScheduleFilters(false)
                                    )
                                }}
                                cls={'15px'}
                            />
                        }
                    </div>

                </div>
                <div className="h-100px"></div>
                <div className="px-4 my-2 ">
                    {/* {
                        userType === 'Agent' &&
                        <div className="card-body card-scroll h-50">
                            <h5 className="text-uppercase mb-4">
                                Notices :
                            </h5>
                            <ScheduleSortByNotices />
                        </div>
                    } */}

                    {
                        userType === 'Agent' &&
                        <div className="card-body card-scroll h-50">
                            <h5 className="text-uppercase mb-4">
                                Sort tasks by :
                            </h5>
                            <ScheduleSort localFilters={localFilters} setLocalFilters={setLocalFilters} />
                        </div>
                    }

                    <div className="my-8">
                        <div className="card-body card-scroll h-50">
                            <h5 className="text-uppercase mb-2">
                                Sort tasks by type units :
                            </h5>
                            <ScheduleSortTypeUnits />
                        </div>
                    </div>
                    <div className="separator separator-dotted border-gray-300 my-2"></div>
                    {

                        <>
                            <div className="card-body card-scroll h-50">
                                <h5 className="text-uppercase mb-4">
                                    Sort OFFERS by Statuses :
                                </h5>
                                <ScheduleSortOffersByStatuses />
                            </div>
                        </>
                    }
                    {

                        <>
                            <div className="card-body card-scroll h-50">
                                <h5 className="text-uppercase mb-4">
                                    Sort VIEWINGS by Statuses :
                                </h5>
                                <ScheduleSortViewingsByStatuses />
                            </div>
                            <div className="card-body card-scroll h-50">
                                <h5 className="text-uppercase mb-4">
                                    Sort tasks by Period :
                                </h5>
                                <ScheduleSortByDates />
                            </div>
                        </>
                    }
                </div>
                <div style={{ height: '70px' }}></div>
                <div className="footer fixed-bottom bg-white shadow-sm w-100  mx-auto ">
                    <div className="d-flex flex-row w-100 mw-450px h-100 p-4   mx-auto">
                        {!loading && (
                            <button
                                onClick={resetFilters}

                                className={`btn btn-white mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} ${loading ? ' disabled' : ''} border-bso2px p-4 fs-4 col-4 me-2 ms-n1 ls-1`}>
                                <span className="indicator-label">RESET</span>
                            </button>
                        )}
                        {!loading && (
                            <button

                                className={`btn btn-bso mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} ${loading ? ' disabled' : ''} border-bso2px p-4 fs-4 col-8  ls-1`}
                                onClick={applyFilters}

                            >
                                {loading
                                    ? (
                                        <span className="indicator-progress d-block">
                                            Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-4"></span>
                                        </span>
                                    )
                                    : (
                                        <span className="indicator-label">APPLY</span>
                                    )}
                            </button>
                        )}
                    </div>
                </div>


            </div>
        </div>

    )
}
