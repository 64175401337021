import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { AgentModals } from '../../functions/agent/AgentModals'
import { LandlordModals } from '../../functions/landlord/data/LandlordModals'

import CustomFieldsHook from './CustomFieldsHook'
import CustomValidatorHook from './CustomValidatorHook'

import { setDop, setPlan, setService } from '../../store/slices/serviceSlice'
import { getServices } from '../../functions/landlord/data/servicesSubm'
import useEditUnit from '../../hooks/formModals/useEditUnit'
import _, { forEach } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { addProperty, editProperty } from '../../store/actionsCreators/propertyActions'
import { applyUnitModalFilters, setUnitsFilters, setUnitSold, updateUnit } from '../../store/actionsCreators/unitsActions'

import useEditFilters from '../../hooks/formModals/useEditFilters'
import TutorialLoadDocuments from '../../components/agentModule/tutorials/TutorialLoadDocuments'
import { addAgentSettings, addClient } from '../../store/actionsCreators/userActions'

import { saveAgentOfferNegotiate } from '../../store/actionsCreators/offerActions'

import fillAddOfferModal from '../../functions/agent/modals/filAddOfferModal'
import {
    resetPasswordCheckMailOTP,
    resetPasswordCheckMobileOTP,
    resetPasswordSendMailOTP,
    resetPasswordSendMobileOTP,
    resetPasswordWithEmail,
    resetPasswordWithPhone
} from '../../store/actionsCreators/userActions/resetPasswordActions'
import {
    changeNumber,
    changeNumberChangePhoneConfirm,
    changeNumberVerifyPhoneConfirm
} from '../../store/actionsCreators/userActions/changePhoneActions'
import { changeEmail, changeEmailVerifyPhoneConfirm } from '../../store/actionsCreators/userActions/changeEmailActions'
import { type AppDispatch, type RootState, store } from '../../store/store'
import useUserType from '../../hooks/UseUserType'

import {
    dropModalSlice,
    setDateChooseViewing,
    setImages,
    setRescheduleReason,
    setShowButtonBuyCoinsOnAdvertise
} from '../../store/slices/modalSlice'
import fillEditPropertyModal from '../../functions/agent/modals/fillEditPropertyModal'
import { updateAvatar } from '../../store/actionsCreators/userActions/avatarActions'
import { createAdvertise } from '../../store/actionsCreators/advertiseActions/createAdvertiseAction'
import { scanQrCodeForPickUpKeys } from '../../store/actionsCreators/viewingActions/scanQrCodeForPickUpKeys'
import { addCardAction } from '../../store/actionsCreators/userActions/paymendActions/addCardAction'
import { uploadFile } from '../../store/actionsCreators/documentsActions/upload/uploadFile'
import {
    createKeyBookingByMagic,
    updateViewingByMagic
} from '../../store/actionsCreators/viewingActions/magicViewingActions'
import { updateOfferByMagic, uploadOfferDocument } from '../../store/actionsCreators/offerActions/magicOfferActions'
import { swalAttentionLeave, swalSuccess } from '../../functions/swalOptions'
import magicFillCheckLinks from '../../functions/shared/modals/magicFillCheckLinks'
import { setAdvertiseLinksStatus } from '../../store/actionsCreators/advertiseActions/setAdvertiseLinksStatus'
import { setModalDateChooseViewing } from '../../store/actionsCreators/viewingActions/setModalDateChooseViewing'
import { addOfferTenantType } from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferTenantType'
import {
    addOfferIndividualDocument
} from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferIndividual/addOfferIndividualDocument'
import {
    addOfferIndividualClientInfo
} from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferIndividual/addOfferIndividualClientInfo'
import {
    addOfferIndividualOfferDetails
} from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferIndividual/addOfferIndividualOfferDetails'
import {
    addOfferCompanyDocument
} from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferCompany/addOfferCompanyDocument'
import {
    addOfferCompanyClientInfo
} from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferCompany/addOfferCompanyClientInfo'
import {
    addOfferCompanyOfferDetails
} from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferCompany/addOfferCompanyOfferDetails'
import { addOfferSaleTenantType } from '../../store/actionsCreators/offerActions/addOffer/sell/addOfferTenantType'
import {
    addOfferSaleIndividualDocument
} from '../../store/actionsCreators/offerActions/addOffer/sell/addOfferIndividual/addOfferIndividualDocument'
import {
    addOfferSaleIndividualOfferDetails
} from '../../store/actionsCreators/offerActions/addOffer/sell/addOfferIndividual/addOfferIndividualOfferDetails'
import { magicCheckBookingForm } from '../../store/actionsCreators/offerActions/magicCheckBookingForm'
import {
    addOfferCompanySaleDocument
} from '../../store/actionsCreators/offerActions/addOffer/sell/addOfferCompany/addOfferCompanyDocument'
import {
    addOfferCompanySaleOfferDetails
} from '../../store/actionsCreators/offerActions/addOffer/sell/addOfferCompany/addOfferCompanyOfferDetails'
import swalConfig from '../texts/swalTexts'
import { setDebitMarketingCredits, setNotEnoughMarketingCredits } from '../../store/slices/advertisesSlice'
import fillFaqModal from '../../functions/shared/modals/fillFaqModal'
import CustomModalHeader from './CustomModalHeader'
import { USER_ADVERTISE_DAYS } from '../../urls'
import { createStartDateAdvertise } from '../../store/actionsCreators/advertiseActions/createStartDateAdvertise'
import { sendAdvertiseForm } from '../../store/actionsCreators/advertiseActions/sendAdvertiseForm'
import { extendAdvertise } from '../../store/actionsCreators/advertiseActions/extendAdvertise'
import fillAdminOfferDocumentModal from '../../functions/shared/modals/fillAdminOfferDocumentModal'
import { checkAFormSwitchAction } from '../../store/actionsCreators/advertiseActions/checkAFormSwitchAction'
import { checkAForm } from '../../store/actionsCreators/advertiseActions/checkAForm'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { createPropertyForUnit } from '../../store/actionsCreators/propertyActions/createPropertyAction'
import { createAdditionalOptionsForUnit } from '../../store/actionsCreators/unitActions/createAdditionalOptionsForUnit'
import { createMarketingForUnit } from '../../store/actionsCreators/unitActions/createMarketingForUnit'
import { editAgentUnit } from '../../store/actionsCreators/unitActions/editAgentUnit'

import {
    fillSetClientContactInfoModal
} from '../../store/actionsCreators/offerActions/setContactInfo/fillSetClientContactInfoModal'
import { createBroadcast } from '../../store/actionsCreators/broadcastActions/createBroadcast'
import fillDepositSlipOfferModal from '../../functions/agent/modals/fillDepositSlipOfferModal'
import {
    addOfferPaymentConfirmation
} from '../../store/actionsCreators/offerActions/addPaymentConfirmation/addOfferPaymentConfirmation'
import {
    makeOfferAgentToAgent
} from '../../store/actionsCreators/offerActions/agentToAgentOfferCommunication/makeOfferAgentToAgent'
import {
    makeOfferAgentToAgentLastPart
} from '../../store/actionsCreators/offerActions/agentToAgentOfferCommunication/makeOfferAgentToAgentLastPart'
import {
    negotiateOfferAgentToAgent
} from '../../store/actionsCreators/offerActions/agentToAgentOfferCommunication/negotiateOfferAgentToAgent'
import {
    makeOfferAgentToBSOClientInfo
} from '../../store/actionsCreators/offerActions/agentToBSOOfferCommunication/makeOfferAgentToBSOClientInfo'
import nanToNull from '../../functions/adapters/universal/nanToNull'
import {
    makeOfferAgentToBSOOfferInfo
} from '../../store/actionsCreators/offerActions/agentToBSOOfferCommunication/makeOfferAgentToBSOOfferInfo'
import { createPeriodBisyKeys } from '../../store/actionsCreators/viewingActions/createPeriodBisyKeys'
import { createViewing } from '../../store/actionsCreators/viewingActions/createViewing'
import { updateViewing } from '../../store/actionsCreators/viewingActions/updateViewing'
import { updateViewingByOtherAgent } from '../../store/actionsCreators/viewingActions/updateViewingByOtherAgent'
import { createBroadcastNew } from '../../store/actionsCreators/broadcastActions/createBroadcastNew'
import { updateAreasForBricksPay } from '../../store/actionsCreators/userActions/updateAreasForBricksPay'

import { updateProfile } from '../../store/actionsCreators/userActions/updateProfile'
import { type Document, setTemporaryTitleDeed } from '../../store/slices/documentSlice'
import { editAgentDraftUnit } from '../../store/actionsCreators/unitActions/editAgentDraftUnit'
import { deleteData } from '../../store/actionsCreators/userActions/deleteUserAction'

import {
    scanUnitTitleDeed,
    skipScanUnitTitleDeed
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/scanUnitTitleDeed'
import {
    fillValuesForAddUnitModal
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/modalFillers/fillValuesForAddUnitModal'
import {
    addUnitInfo,
    skipAddUnitInfo
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/addUnitInfo'
import {
    scanUnitOwnersPassport,
    skipScanUnitOwnersPassport
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/scanUnitOwnersPassport'
import {
    scanUnitPowerOfAttorney,
    skipScanUnitPowerOfAttorney
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/scanUnitPowerOfAttorney'
import { editMarketing } from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/editMarketing'
import {
    scanUnitUserPassport,
    skipScanUnitUserPassport
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/scanUserPassport'
import {
    requestValidation
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/requestValidation'
import {
    addAdditionalUnitInfo,
    skipAddAdditionalUnitInfo
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/addUnitAdditionalInfo'
import {
    fillValuesForCorrectLandlordInfoModal
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/modalFillers/fillValuesForCorrectLandlordInfoModal'
import {
    correctLandlordInfo
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/correctLandlordInfo'
import { addServiceFindingTenant } from '../../store/actionsCreators/serviceActions/callbacks/addServiceFindingTenant'
import getUserType from '../../functions/shared/user/getUserType'
import {
    updateViewingByLandlord
} from '../../store/actionsCreators/viewingActions/landlordViewingActions/updateViewingByLandlord'
import {
    makeOfferAgentToLandlordClientInfo
} from '../../store/actionsCreators/offerActions/agentToLandlordOfferCommunication/makeOfferAgentToLandlordClientInfo'
import {
    makeOfferAgentToLandlordOfferInfo
} from '../../store/actionsCreators/offerActions/agentToLandlordOfferCommunication/makeOfferAgentToLandlordOfferInfo'
import {
    addMainInfoFirst, skipAddMainInfoFirst
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/addUnitMainInfoFirst'
import {
    addMainInfoSecond, skipAddMainInfoSecond
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/addUnitMainInfoSecond'
import {
    addAdditionalUnitInfoOutdoor, skipAddAdditionalUnitInfoOutdoor
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/addUnitAdditionalInfoOutdoor'
import {
    addAdditionalUnitInfoFiles, skipAddAdditionalUnitInfoFiles
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/addUnitAdditionalInfoFiles'
import {
    addUnitMarketingInfo, skipAddUnitMarketingInfo
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/addUnitMarketing'
import {
    addUnitMarketingSpecialRequests, skipAddUnitMarketingSpecialRequests
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/callbacks/addUnitMarketingSpecialRequests'
import { getDynamicHeight } from '../../functions/utils/getDynamicHeight'
import { addOfferSaleClientDetails } from '../../store/actionsCreators/offerActions/addOffer/sell/addOfferSaleClientDetails'
import { addOfferSaleContractType } from '../../store/actionsCreators/offerActions/addOffer/sell/addOfferSaleContractType'
import { addDraftContractChanges } from '../../store/actionsCreators/offerActions/addOffer/sell/addDraftContractChanges'
import { addOfferCustomDocument } from '../../store/actionsCreators/offerActions/addOffer/sell/addOfferCustomDocument'
import { cancelOffer } from '../../store/actionsCreators/offerActions/cancelOffer'
import { getCurrentDubaiDate } from '../../functions/agent/date/getCurrentDubaiDate'
import { addOffer } from '../../store/actionsCreators/offerActions/addOffer'
dayjs.extend(utc)

interface CustomModalProps {
    modalID?: string
    unitID?: string
    objectID?: string
    close?: () => void
}

export default function CustomModal(props: CustomModalProps): JSX.Element {
    let { modalID, unitID, objectID } = useParams()
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const dispatch = useAppDispatch()
    if (_.some([props.modalID, props.unitID, props.objectID], prop => prop !== undefined)) {
        modalID = props.modalID
        unitID = props.unitID
        objectID = props.objectID
    }

    const units = useAppSelector((state) => state.units.units)
    const offers = useAppSelector((state) => state.offers.offers)
    const properties = useAppSelector((state) => state.property.properties)
    const newProperty = useAppSelector((state) => state.property.newPropertyFields)
    const temporaryTitleDeed = useAppSelector((state) => state.documents.temporaryTitleDeed)

    // const [defaultFields, setDefaultFields] = useState(initializeFields())
    // const [modals, setModals] = useState<any[]>([...LandlordModals, ...AgentModals(defaultFields)])

    const unitFields = units.filter((unit) => unit.id === Number(unitID)) ?? [] as any
    const propertyFields = {
        ...unitFields?.[0]?.attributes?.Property?.data?.attributes
    }

    const [modals, setModals] = useState<any[]>([...LandlordModals, ...AgentModals({
        unitFields,
        propertyFields,
        newProperty,
        offer: offers.find((offer) => offer.id === Number(objectID))
    })])

    useEffect(() => {
        const unitsFields = store.getState().units.units.filter((unit) => unit.id === Number(unitID)) ?? []
        const newProperty = store.getState().property.newPropertyFields
        const propertyFields = {
            ...unitsFields?.[0]?.attributes?.Property?.data?.attributes,
            Name: newProperty?.name,
            // Type: newProperty?.type,
            Area: newProperty?.area,
            MAKANI: newProperty?.makani
        }

        const updatedUnitFields = unitsFields.length > 0
            ? [{
                ...unitsFields[0],
                attributes: {
                    ...unitsFields[0].attributes,
                    Number: (newProperty?.number != null) ? Number(newProperty?.number) : unitsFields[0].attributes.Number ?? null
                }
            }]
            : []

        const newModals = [...LandlordModals, ...AgentModals({
            unitFields: updatedUnitFields,
            newProperty,
            propertyFields
        })]
        setModals(newModals)
    }, [temporaryTitleDeed, newProperty, modalID, properties]) // , ?.name, newProperty?.number, newProperty?.area
    let modal = modals.filter((modal: any) => modal.id === modalID)

    if (props.close !== undefined) {
        const closeButton = modal[0]?.footer?.find((button: any) => button.id === 'close') ?? {}
        closeButton.close = props.close
    }

    const key = modalID
    let callback
    let handleReset

    const navigate = useNavigate()
    const { userType } = useUserType()

    const state: RootState = store.getState()// useAppSelector((state: RootState) => state)
    const modalInitialData = useAppSelector((state: RootState) => state.modal.initialData)

    let initialData = JSON.parse(JSON.stringify(modalInitialData))
    useEffect(() => {
        const pageServices = getServices(modalID ?? '')
        dispatch(setService(pageServices))
        const initialPlan = ((pageServices?.plans?.[0]) != null) || {}
        dispatch(setPlan(initialPlan))
        return () => {
            dispatch(setService(undefined))
            dispatch(setPlan(undefined))
            dispatch(setDop([]))
            dispatch(setImages([]))
            if (key === 'agent_set_offer_modal_residential') {
                dispatch(dropModalSlice())
            }
        }
    }, [])

    switch (key) {
        case 'add_card_modal':
            callback = addCardAction
            break
        case 'scan_qrcode':
            callback = scanQrCodeForPickUpKeys
            break
        case 'agent_advertise_create_duration':
            callback = createAdvertise
            break

        case 'agent_advertise_send_form':
            callback = sendAdvertiseForm
            break

        case 'agent_advertise_create_start_date':
            callback = createStartDateAdvertise
            break

        case 'agent_advertise_create_links':
            callback = null
            break
        case 'set_time_for_take_keys_modal':
            callback = createPeriodBisyKeys
            break
        case 'agent_avatar':
            callback = updateAvatar
            break
        case 'my_profile_reset_password_way_email':
            callback = resetPasswordSendMailOTP
            break
        case 'my_profile_reset_password_way_phone':
            callback = resetPasswordSendMobileOTP
            break
        case 'my_profile_password_verify_phone':
            callback = resetPasswordCheckMobileOTP
            break
        case 'my_profile_password_verify_email':
            callback = resetPasswordCheckMailOTP
            break
        case 'my_profile_reset_password_final_phone':
            callback = resetPasswordWithPhone
            break
        case 'my_profile_reset_password_final_email':
            callback = resetPasswordWithEmail
            break
        case 'change_phone_for_contacts_my_phone_verify':
            callback = changeNumberChangePhoneConfirm
            break
        case 'change_phone_for_contacts_my_check_otp':
            callback = changeNumberVerifyPhoneConfirm
            break
        case 'change_phone_for_contacts_my':
            callback = changeNumber
            break
        case 'change_email_for_contacts_my_check_otp':
            callback = changeEmailVerifyPhoneConfirm
            break
        case 'change_email_for_contacts_my':
            callback = changeEmail
            break
        case 'update_emirates_back_together':
            callback = uploadFile
            break
        case 'update_emirates_front_together':
            callback = uploadFile
            break
        case 'update_passport_together':
            callback = uploadFile
            break
        case 'update_passport':
            callback = uploadFile
            break
        case 'update_emirates_front':
            callback = uploadFile
            break
        case 'update_emirates_back':
            callback = uploadFile
            break
        case 'update_rera':
            callback = uploadFile
            break
        case 'update_rera_deal':
            callback = uploadFile
            break
        case 'agent_settings':
            callback = addAgentSettings
            break
        case 'agent_settings_for_subscriptions':
            callback = addAgentSettings
            break
        case 'agent_settings_for_areas_extension':
            // if (unitID === 'addons') {
            callback = updateAreasForBricksPay
            // } else {
            //     callback = addAgentSettings
            // }

            break
        case 'agent_set_viewing_modal':
            callback = createViewing
            break
        case 'agent_update_viewing_modal':
            callback = updateViewing
            break
        case 'agent_update_viewing_modal_close_btn':
            callback = updateViewing
            break

        case 'set_property_modal':
            callback = addProperty
            break
        case 'edit_property_modal':
            callback = editProperty
            initialData = fillEditPropertyModal(state.property.properties, _.find(state.setUnit.staticForm, (row) => row.id === 'Property')?.value ?? -1)
            break
        case 'set_unit_marketing_modal': //
            initialData = useEditUnit(modal, +(props.unitID ?? -1), true)
            modal = initialData.modal
            callback = (data: any) => async (dispatch: AppDispatch, getState: (state: RootState) => RootState) => {
                const response = await dispatch(updateUnit(data, +(unitID ?? props.unitID ?? -1), true))
                if (response?.isSuccessful === true && response?.response?.error == null) {
                    response.textNavigate = '/landlord/my_units'
                    response.textNotification = ''
                } else {
                    response.textNavigate = ''
                }
                return response
            }
            break
        case 'edit_unit_marketing_modal': //
            initialData = useEditUnit(modal, +(props.unitID ?? -1))
            modal = initialData.modal
            callback = (data: any) => updateUnit(data, +(unitID ?? props.unitID ?? -1), true)
            break
        case 'agent_unit_filters_modal':
            initialData = useEditFilters(modal)
            modal = initialData.modal
            callback = applyUnitModalFilters
            handleReset = () => {
                dispatch(setUnitsFilters({}))
                navigate(-1)
            }
            break
        case 'agent_set_offer_modal_residential': //
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = (data: any) => addOffer(data, 'residential', +(unitID ?? -1), objectID !== '-' && objectID != null ? +objectID : undefined)
            break
        case 'agent_set_offer_modal_commercial': //
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = (data: any) => addOffer(data, 'commercial', +(unitID ?? -1), objectID !== '-' && objectID != null ? +objectID : undefined)
            break
        case 'agent_set_offer_modal_sale': //
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = (data: any) => addOffer(data, 'sale', +(unitID ?? -1), objectID !== '-' && objectID != null ? +objectID : undefined)
            break
        case 'agent_edit_offer_modal_residential':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState)
            modal = initialData.modal
            callback = (data: any) => addOffer(data, 'residential', +(unitID ?? -1), objectID !== '-' && objectID != null ? +objectID : undefined, true)
            break
        case 'agent_set_offer_modal_tenant_type':
            callback = addOfferTenantType(unitID, objectID)
            break
        case 'agent_set_offer_modal_documents_individual':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferIndividualDocument(unitID, objectID)
            break
        case 'agent_set_offer_modal_client_info_individual':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferIndividualClientInfo(unitID, objectID)
            break
        case 'agent_set_offer_modal_offer_details_individual':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferIndividualOfferDetails(unitID, objectID)
            break
        case 'agent_set_offer_modal_documents_company':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferCompanyDocument(unitID, objectID)
            break
        case 'agent_set_offer_modal_client_info_company':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferCompanyClientInfo(unitID, objectID)
            break
        case 'agent_set_offer_modal_offer_details_company':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferCompanyOfferDetails(unitID, objectID)
            break
        case 'agent_set_offer_modal_tenant_type_sale':
            callback = addOfferSaleTenantType(unitID, objectID)
            break
        case 'agent_set_offer_modal_documents_individual_sale':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferSaleIndividualDocument(unitID, objectID)
            break
        case 'agent_set_offer_modal_offer_details_individual_sale':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferSaleIndividualOfferDetails(unitID, objectID)
            break
        case 'agent_set_offer_modal_documents_company_sale':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferCompanySaleDocument(unitID, objectID)
            break
        case 'agent_set_offer_modal_offer_details_company_sale':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = addOfferCompanySaleOfferDetails(unitID, objectID)
            break
        case 'offer_modal': //
            // modal = fillOfferModal(modal, +(objectID ?? '-1'), searchParams, state.user.currentUser)
            handleReset = () => {
                dispatch(cancelOffer(+(objectID ?? '-1')))
                    .finally(() => {
                        navigate(-1)
                    })
            }
            break
        case 'checkout_modal':
            callback = (data: any) => (dispatch: AppDispatch, getState: (state: RootState) => RootState) => {
                // alert(1)
                navigate(-1)
            }
            break
        case 'agent_add_client_modal':
            callback = addClient
            break
        case 'reject_viewing_modal':
            if (getUserType() === 'Admin') {
                callback = (data: Record<string, unknown>) => updateViewingByMagic(Number(objectID), {
                    ...data,
                    Statuses: ['Rejected']
                }, 'The viewing is successfully rejected', 'user-viewing-rejected', 'ATTENTION viewing is rejected')
            } else {
                callback = (data: Record<string, unknown>) => updateViewingByLandlord({
                    id: Number(objectID),
                    data: {
                        ...data,
                        Statuses: ['Rejected']
                    },
                    successAlert: '',
                    notifyRule: 'user-viewing-rejected'
                })
            }
            break
        case 'reschedule_viewing_modal':
            if (getUserType() === 'Admin') {
                callback = (data: Record<string, unknown>) => updateViewingByMagic(Number(objectID), {
                    ...data,
                    Statuses: ['Rescheduled by BSO'],
                    Datetime: new Date(`${data.Datetime as string}+04:00`)
                }, 'The viewing is successfully rescheduled', 'user-viewing-newtime', 'ATTENTION viewing is rescheduled')
            } else {
                callback = (data: Record<string, unknown>) => updateViewingByLandlord({
                    id: Number(objectID),
                    data: {
                        ...data,
                        Statuses: ['Rescheduled by landlord'],
                        Datetime: new Date(`${data.Datetime as string}+04:00`)
                    },
                    successAlert: '',
                    notifyRule: 'user-viewing-newtime'
                })
            }
            break
        case 'reschedule_viewing_modal_lock':
            callback = (data: Record<string, unknown>) => (dispatch: AppDispatch, getState: () => RootState) => {
                // dispatch(setDateChooseViewing(new Date(`${data.Datetime as string}+04:00`)))
                dispatch(setDateChooseViewing(new Date(`${data.Datetime as string}+04:00`).toISOString()))
                dispatch(setRescheduleReason(data.RejectReason))
                return {
                    isSuccessful: true,
                    textNotification: '',
                    textNavigate: `/landlord/modal/lock_pass_viewing_modal_reschedule/${String(unitID)}/${String(objectID)}`
                }
            }
            // (data: Record<string, unknown>) => updateViewingByMagic(Number(objectID), { ...data, Statuses: ['Rescheduled by BSO'], Datetime: new Date(`${data.Datetime as string}+04:00`) }, 'The viewing is successfully rescheduled', 'user-viewing-newtime', 'ATTENTION viewing is rescheduled', `/landlord/modal/lock_pass_viewing_modal_reschedule/${String(unitID)}/${String(objectID)}`)
            // callback = (data: Record<string, unknown>) => {
            //     // dispatch(setDateChooseViewing(new Date(`${data.Datetime as string}+04:00`)))
            //     dispatch(setDateChooseViewing(new Date(`${data.Datetime as string}+04:00`).toISOString()))
            //     return {
            //         isSuccessful: true,
            //         textNotification: '',
            //         textNavigate: `/landlord/modal/lock_pass_viewing_modal_reschedule/${String(unitID)}/${String(objectID)}`
            //     }
            // }
            break
        case 'reschedule_viewing_modal_keys':
            callback = (data: Record<string, unknown>) => (dispatch: AppDispatch, getState: () => RootState) => {
                //  dispatch(setDateChooseViewing(new Date(`${data.Datetime as string}+04:00`)))
                dispatch(setDateChooseViewing(new Date(`${data.Datetime as string}+04:00`).toISOString()))
                dispatch(setRescheduleReason(data.RejectReason))
                return {
                    isSuccessful: true,
                    textNotification: '',
                    textNavigate: `/landlord/modal/set_key_bookings_modal_reschedule/${String(unitID)}/${String(objectID)}`
                }
            }// updateViewingByMagic(Number(objectID), { ...data, Statuses: ['Rescheduled by BSO'], Datetime: new Date(`${data.Datetime as string}+04:00`) }, 'The viewing is successfully rescheduled', 'user-viewing-newtime', 'ATTENTION viewing is rescheduled', `/landlord/modal/set_key_bookings_modal_reschedule/${String(unitID)}/${String(objectID)}`)
            break

        case 'complain_viewing_modal':
            callback = (data: Record<string, unknown>) => updateViewingByMagic(Number(objectID), { ...data, Statuses: ['Completed'] }, 'The complain is successfully saved', 'user-viewing-complaint', 'ATTENTION viewing is waiting for complain')
            break
        case 'lock_pass_viewing_modal':
            callback = (data: Record<string, unknown>) => updateViewingByMagic(Number(objectID), { ...data, Statuses: ['Approved'] }, 'The lock pass is successfully set', 'user-viewing-approved', `ATTENTION viewing is approved. Lock pass is ${data.DigitalLockPass as string}`)
            break
        case 'lock_pass_viewing_modal_reschedule':
            callback = (data: Record<string, unknown>) => updateViewingByMagic(Number(objectID), { ...data, Statuses: ['Rescheduled by BSO'], Datetime: store.getState().modal.dateChooseViewing, RejectReason: store.getState().modal.rescheduleReason }, 'The lock pass is successfully set', 'user-viewing-newtime', `ATTENTION viewing is approved. Lock pass is ${data.DigitalLockPass as string}`, -2)
            break
        case 'set_key_bookings_modal':
            dispatch(setModalDateChooseViewing(Number(objectID)))
            // TO DO
            modal[0].form[0].content[1] = dayjs(getCurrentDubaiDate()).format('YYYY-MM-DD HH:mm')  // dayjs(store.getState().modal.dateChooseViewing).format('YYYY-MM-DD HH:mm') 
            callback = (data: Record<string, unknown>) => createKeyBookingByMagic(Number(unitID), Number(objectID), data)
            break
        case 'set_key_bookings_modal_reschedule':
            // dispatch(setModalDateChooseViewing(Number(objectID)))
            modal[0].form[0].content[1] = dayjs(getCurrentDubaiDate()).format('YYYY-MM-DD HH:mm')  //  dayjs(store.getState().modal.dateChooseViewing).format('YYYY-MM-DD HH:mm')
            callback = (data: Record<string, unknown>) => createKeyBookingByMagic(Number(unitID), Number(objectID), data, -2, 'Rescheduled by BSO')
            break
        case 'reject_offer_modal':
            // TODO set actual notify data for this change
            callback = (data: Record<string, unknown>) => updateOfferByMagic(Number(objectID), { ...data, Statuses: ['Rejected'] }, 'Offer has been rejected', 'user-offer-rejected', 'ATTENTION offer has been rejected')
            break
        case 'require_resubmit_offer_modal':
            callback = (data: Record<string, unknown>) => updateOfferByMagic(Number(objectID), { ...data, Statuses: ['Resubmit required'] }, 'Offer has been rejected', 'user-offer-resubmit', 'ATTENTION offer has been resubmitted')
            break
        case 'negotiate_offer_modal':
            initialData = fillAddOfferModal(modal, +(unitID ?? -1), state.units.units, state.user.currentUser, objectID ?? '-', initialData.initialState, dispatch)
            modal = initialData.modal
            callback = userType === 'Landlord'
                ? (data: Record<string, any>) => {
                    // console.log(data)
                    if (data?.Type?.[0]?.ContractStartDate != null) {
                        //  console.log('CONTRACT START DATE')
                        data.Type[0].ContractStartDate = (data.Type[0].ContractStartDate).add(dayjs(data.Type[0].ContractStartDate).utcOffset(), 'minutes').toISOString()
                    }
                    //  console.log(data.Type[0].ContractStartDate)
                    return updateOfferByMagic(
                        Number(objectID),
                        {
                            ...data,
                            // ...(data?.Type?.[0]?.ContractStartDate != null ? {} : {})//(data.Type[0].ContractStartDate).add(dayjs(data.Type[0].ContractStartDate).utcOffset(), 'minutes').toISOString()),
                            Statuses: [
                                state.user.currentUser.id === Number(process.env.REACT_APP_BSO_USER_ID)
                                    ? 'Negotiated by BSO'
                                    : 'Negotiated by landlord'
                            ]
                        }, 'Your price is successfully saved', 'user-offer-negotiate', 'ATTENTION price on offer has been negotiated'
                    )
                }
                : saveAgentOfferNegotiate(unitID, objectID)
            break


        case 'change_status_offer_modal':
            callback = (data: Record<string, unknown>) => updateOfferByMagic(Number(objectID), data, 'You have successfully updated status', 'user-offer-change', 'ATTENTION the offer status has changed')
            break
        case 'check_advertisement_links_modal':
        case 'check_advertisement_links_removed_modal':
            initialData = magicFillCheckLinks(+(objectID ?? -1), 'check_advertisement_links_modal')
            modal = [initialData.modal]
            callback = (data: Record<string, unknown>) => setAdvertiseLinksStatus(data, Number(objectID), key)
            break
        case 'check_a_form_modal':
            (() => {
                const advertise = _.find(state.advertises.advertises, (row) => row.id === Number(objectID))

                //  console.log(_.cloneDeep(state.documents.documents.filter(doc => ((doc.attributes as any)?.Offer?.data?.id === Number(objectID) && doc.attributes as any)?.Category === 'Deposit Cheque')))
                const document = _.cloneDeep(state.documents.documents)
                    .filter(doc => (doc.attributes as any)?.Advertise?.data?.id === Number(objectID) && (doc.attributes as any)?.Category === 'A Form')
                    .sort((a, b) => b?.id - a?.id)?.[0]
                if (advertise != null && document != null) {
                    modal[0].form[0].content.push(document.attributes?.Link)
                    modal[0].form[1].content = [document.attributes?.Link]
                }
            })()
            callback = (data: Record<string, unknown>) => checkAFormSwitchAction(data, Number(objectID), Number(unitID), true)
            handleReset = () => {
                navigate(`/landlord/modal/reject_a_form/${Number(unitID)}/${Number(objectID)}`)
            }
            break
        case 'approve_a_form':
            (() => {
                const advertise = _.find(state.advertises.advertises, (row) => row.id === Number(objectID))
                //  console.log(_.cloneDeep(state.documents.documents.filter(doc => ((doc.attributes as any)?.Offer?.data?.id === Number(objectID) && doc.attributes as any)?.Category === 'Deposit Cheque')))
                const document = _.cloneDeep(state.documents.documents)
                    .filter(doc => (doc.attributes as any)?.Advertise?.data?.id === Number(objectID) && (doc.attributes as any)?.Category === 'A Form')
                    .sort((a, b) => b?.id - a?.id)?.[0]
                if (advertise != null && document != null) {
                    modal[0].form[0].content.push(document.attributes?.Link)
                    modal[0].form[1].content = [document.attributes?.Link]
                }
            })()
            callback = (data: Record<string, unknown>) => checkAForm(data, Number(objectID), true)
            break
        case 'reject_a_form':
            (() => {
                const advertise = _.find(state.advertises.advertises, (row) => row.id === Number(objectID))
                //  console.log(_.cloneDeep(state.documents.documents.filter(doc => ((doc.attributes as any)?.Offer?.data?.id === Number(objectID) && doc.attributes as any)?.Category === 'Deposit Cheque')))
                const document = _.cloneDeep(state.documents.documents)
                    .filter(doc => (doc.attributes as any)?.Advertise?.data?.id === Number(objectID) && (doc.attributes as any)?.Category === 'A Form')
                    .sort((a, b) => b?.id - a?.id)?.[0]
                if (advertise != null && document != null) {
                    modal[0].form[0].content.push(document.attributes?.Link)
                    modal[0].form[1].content = [document.attributes?.Link]
                }
            })()
            callback = (data: Record<string, unknown>) => checkAForm(data, Number(objectID), false)
            break
        // 
        case 'check_booking_form_modal':
            callback = magicCheckBookingForm(unitID, objectID)
            break
        case 'faq_modal':
            initialData = fillFaqModal(key, objectID ?? '')
            modal = [initialData.modal]
            break
        case 'admin_upload_offer_document_modal':
            initialData = fillAdminOfferDocumentModal(Number(objectID), key)
            modal = [initialData.modal]
            callback = (data: Record<string, unknown>) => uploadOfferDocument(Number(objectID), data, 'You have successfully updated status', 'user-offer-change', 'ATTENTION the offer status has changed')
            break

        // case 'agent_create_unit':
        //     callback = uploadFile
        //     break
        case 'agent_create_unit_property':
            callback = createPropertyForUnit
            break
        case 'agent_create_unit_options':
            callback = createAdditionalOptionsForUnit
            break
        case 'agent_create_unit_marketing':
            callback = createMarketingForUnit
            break
        case 'agent_edit_unit':
            callback = editAgentUnit
            break
        case 'save_after_draft_agent_unit':
            callback = editAgentDraftUnit
            break

        case 'agent_set_broadcast':
            // callback = createBroadcast
            callback = createBroadcastNew
            break


        case 'submit_deposit_slip_offer_modal':
            callback = addOfferPaymentConfirmation
            break
        case 'agent_to_bso_make_offer_upload_custom_documents':
            callback = addOfferCustomDocument
            break


        case 'agent_offer_submit_client_info_modal':
            callback = addOfferSaleClientDetails
            break

        case 'agent_offer_choose_contract_type':
            callback = addOfferSaleContractType
            break
        case 'agent_offer_submit_draft_contract_changes_modal':
            callback = addDraftContractChanges
            break
        case 'viewing_actions_from_agent':
            callback = null
            break
        case 'offer_actions_from_agent':
            callback = null
            break
        case 'agent_create_unit_with_title_deed':
            callback = null
            break
        case 'reschedule_viewing_modal_from_agent':
            callback = updateViewingByOtherAgent
            break
        case 'agent_to_agent_make_offer':
            callback = makeOfferAgentToAgent
            break
        case 'agent_to_agent_make_offer_last':
            callback = makeOfferAgentToAgentLastPart
            break
        case 'agent_to_agent_negotiate_offer':
            callback = negotiateOfferAgentToAgent
            break
        case 'agent_to_bso_make_offer_documents':
        case 'agent_to_bso_resubmit_offer_documents':
            callback = makeOfferAgentToBSOClientInfo(Number(unitID), nanToNull(objectID) ?? undefined)
            break
        case 'agent_to_bso_offer_offer_info':
            //  case 'agent_to_bso_resubmit_offer_info':
            callback = makeOfferAgentToBSOOfferInfo(Number(unitID), nanToNull(objectID) ?? undefined)
            break
        case 'agent_to_landlord_make_offer_documents':
        case 'agent_to_landlord_resubmit_offer_documents':
            callback = makeOfferAgentToLandlordClientInfo(Number(unitID), nanToNull(objectID) ?? undefined)
            break
        case 'agent_to_landlord_offer_offer_info':
        case 'agent_to_landlord_resubmit_offer_info':
            callback = makeOfferAgentToLandlordOfferInfo(Number(unitID), Number(objectID))
            break
        case 'landlord_create_unit':
            initialData = dispatch(fillValuesForAddUnitModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = scanUnitTitleDeed(Number(unitID))
            handleReset = skipScanUnitTitleDeed(navigate, Number(unitID))
            break
        case 'landlord_create_unit_info':
            initialData = dispatch(fillValuesForAddUnitModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = addUnitInfo(Number(unitID))
            handleReset = skipAddUnitInfo(navigate, Number(unitID))
            break
        case 'landlord_create_main_info_first':
            initialData = dispatch(fillValuesForAddUnitModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = addMainInfoFirst(Number(unitID))
            handleReset = skipAddMainInfoFirst(navigate, Number(unitID))
            break
        case 'landlord_create_main_info_second':
            initialData = dispatch(fillValuesForAddUnitModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = addMainInfoSecond(Number(unitID))
            handleReset = skipAddMainInfoSecond(navigate, Number(unitID))
            break
        case 'landlord_create_unit_additional_info':
            initialData = dispatch(fillValuesForAddUnitModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = addAdditionalUnitInfo(Number(unitID))
            handleReset = skipAddAdditionalUnitInfo(navigate, Number(unitID))
            break
        case 'landlord_create_unit_additional_info_outdoor':
            initialData = dispatch(fillValuesForAddUnitModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = addAdditionalUnitInfoOutdoor(Number(unitID))
            handleReset = skipAddAdditionalUnitInfoOutdoor(navigate, Number(unitID))
            break
        case 'landlord_create_unit_additional_info_files':
            initialData = dispatch(fillValuesForAddUnitModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = addAdditionalUnitInfoFiles(Number(unitID))
            handleReset = skipAddAdditionalUnitInfoFiles(navigate, Number(unitID))
            break
        case 'landlord_create_unit_owners_passport':
            initialData = dispatch(fillValuesForAddUnitModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = scanUnitOwnersPassport(Number(unitID))
            handleReset = skipScanUnitOwnersPassport(navigate, Number(unitID))
            break
        case 'landlord_create_unit_poa':
            initialData = dispatch(fillValuesForAddUnitModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = scanUnitPowerOfAttorney(Number(unitID))
            handleReset = skipScanUnitPowerOfAttorney(navigate, Number(unitID))
            break
        case 'landlord_create_unit_users_passport':
            initialData = dispatch(fillValuesForAddUnitModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = scanUnitUserPassport(Number(unitID))
            handleReset = skipScanUnitUserPassport(navigate, Number(unitID))
            break
        case 'landlord_create_unit_special_requests':
            initialData = dispatch(fillValuesForAddUnitModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = addUnitMarketingSpecialRequests(Number(unitID))
            handleReset = skipAddUnitMarketingSpecialRequests(navigate, Number(unitID))
            break
        case 'landlord_create_unit_marketing':
            initialData = dispatch(fillValuesForAddUnitModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = addUnitMarketingInfo(Number(unitID))
            handleReset = skipAddUnitMarketingInfo(navigate, Number(unitID))
            break
        case 'landlord_create_unit_request_validation':
            initialData = dispatch(fillValuesForAddUnitModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = requestValidation
            break
        case 'landlord_create_unit_correct_owner_info':
            initialData = dispatch(fillValuesForCorrectLandlordInfoModal(key, Number(unitID), navigate))
            modal = initialData.modal
            callback = correctLandlordInfo(Number(unitID))
            break
        case 'finding_tenant':
            callback = addServiceFindingTenant(Number(unitID))
            break

        case 'profile_modal':
            callback = updateProfile
            break

        default:
            break
    }

    const onLeave = (str?: string): void => {
        return void swalAttentionLeave(
            swalConfig.attentionLeave.messageModalFooterClose
        ).then(async (result: any) => {
            if (result.isConfirmed) {
                str && str !== '' ? navigate(str) : navigate(-1)
                if (key === "agent_create_unit_with_title_deed") {
                    dispatch(setTemporaryTitleDeed(null))
                } else if (key === "save_after_draft_agent_unit") {
                    const docID = units.filter((unit) => unit.id === Number(unitID))?.[0]?.attributes?.Documents?.data?.[0]?.id
                    dispatch(setTemporaryTitleDeed(null))
                    await deleteData('properties', Number(objectID))
                    await deleteData('units', Number(unitID))
                    await deleteData('documents', docID)
                }
                // else if (key === "agent_to_bso_offer_offer_info") {
                //     await deleteData('offers', Number(objectID))
                //     const offersClientsDocuments = offers.filter((offer) => offer.id === Number(objectID))?.[0]?.attributes?.Documents?.data

                //     if (offersClientsDocuments && offersClientsDocuments.length > 0) {
                //         (offersClientsDocuments as Document[]).forEach(async (doc: Document) => await deleteData('documents', Number(doc.id)))
                //     }

                // }
            }
        })
    }




    const onCloseForAdvertiseModal = () => {
        localStorage.removeItem(USER_ADVERTISE_DAYS)
        dispatch(setDebitMarketingCredits(0))
        dispatch(setNotEnoughMarketingCredits(0))
        dispatch(setShowButtonBuyCoinsOnAdvertise(false))
        navigate('/agent/units/nav')
    }






    if (key === 'update_emirates_front_together' && objectID === 'first') return <TutorialLoadDocuments />
    return (
        <>
            {/* w-100 mx-auto */}
            <div className="page d-flex fs-5 " style={{ minHeight: `${getDynamicHeight()}px` }}>

                <div className=" content w-100   mw-450px  mx-auto ">

                    <div className="card d-flex mh-100  w-100  mx-auto">
                        <div className="card-header shadow-sm " style={{ paddingTop: userDevice === 'IPhone' ? '45px' : '14px', paddingBottom: '14px' }}>
                            <CustomModalHeader modal={modal} onLeave={onLeave} onCloseForAdvertiseModal={onCloseForAdvertiseModal} />
                        </div>
                        <div className="card-body  h-50  mw-450px" style={
                            modal?.[0]?.overflowScroll === true
                                ? { maxHeight: `calc(100vh - ${userDevice === 'IPhone' ? '101' : '70'}px)` }
                                : {}
                        }>
                            <CustomValidatorHook key={`${modalID}-validator`} id={modalID ?? ''} fromPage={unitID} unitID={unitID} objectID={objectID} callback={callback} handleReset={handleReset} initialState={initialData.initialState} modals={modals}>
                                <CustomFieldsHook key={`${modalID}-validator-fields`} modal={modal} isAuth={false} unitID={unitID} objectID={objectID} />
                            </CustomValidatorHook>
                            <div style={{ height: '100px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
