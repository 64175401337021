import React from 'react'
const LandscapePlaceholder = (): JSX.Element => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center vh-100 vw-100">
            <a href="/">
                <img alt="Logo" src="/assets/media/logo192.png" className="rounded rounded-lg h-100px mb-10" />
            </a>
            <h1 className="fs-3x fw-bold text-center text-dark mw-900px mx-10 mb-10">Sorry, the app is not available in landscape mode</h1>
            <h1 className="fs-1 fw-bold text-center text-dark mx-5 mb-2">
                <i className="ki-duotone ki-arrows-circle fs-1 me-2 text-gray-900">
                    <i className="path1"></i>
                    <i className="path2"></i>
                </i>
                Turn your device back to continue
            </h1>
        </div>
    )
}

export default LandscapePlaceholder
