import React from 'react'
import LandlordHeader from '../../LandlordHeader'
import ActionCard from '../actionCard'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { useParams } from 'react-router'
import { type RootState } from '../../../../store/store'
import _ from 'lodash'
import { updateOfferByMagic } from '../../../../store/actionsCreators/offerActions/magicOfferActions'
import { showFileInputAlert, showInputAlert, swalConfirm, swalError, swalSuccess } from '../../../../functions/swalOptions'
import { useNavigate } from 'react-router-dom'
import { setUnitSold } from '../../../../store/actionsCreators/unitsActions'
import confirmAction from '../../../../functions/swals/confirmAction'
import { setWhereAfterToGo } from '../../../../store/slices/modalSlice'
import notifyDelay from '../../../../functions/shared/notify-helpers/notifyDelay'
import { sendNotification } from '../../../../functions/shared/notifications/sendNotification'
import { downloadPaySlip } from './uploadOfferPaySlip'
import { getOfferTypeName } from '../../../../functions/agent/offers/getOfferTypeName'
import { addOfferSaleContractDocuments } from '../../../../store/actionsCreators/offerActions/addOffer/sell/addOfferSaleContractDocuments'
import { updateOfferDetailsFromAgent } from '../../../../store/actionsCreators/offerActions/updateOfferDetailsFromAgent'
import { toUTCWithOffset } from '../../../../functions/agent/date/toUTCWithOffset'
import { getCurrentDubaiDate } from '../../../../functions/agent/date/getCurrentDubaiDate'

const OffersActions = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { unitID, offerID } = useParams()
    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const units = useAppSelector((state: RootState) => state.units.units)
    const offer = _.find(offers, (offer) => offer.id === Number(offerID))
    const unit = _.find(units, (unit) => unit.id === Number(unitID))
    const status = offer?.attributes?.Statuses[offer?.attributes?.Statuses?.length - 1]
    const marketingUse = unit?.attributes?.Marketing?.Use
    const navigate = useNavigate()
    const isSale = getOfferTypeName(offer)?.includes('sale')

    return <>
        <LandlordHeader active={'Offer actions'} />
        <div className={'d-flex flex-column gap-4 p-4 mt-8'}>
            {_.includes(['Negotiated by agent', "Negotiation confirmed by agent"], status) && // 'Approved',
                <ActionCard
                    title={'Confirm agents\'s offer' + (status === 'Negotiated by agent' ? ' - Negotiated' : ' - consent')}
                    description={'Confirm the offer\'s price'}
                    onClick={() => {
                        confirmAction({
                            callback: async () => {
                                const { textNotification, textNavigate, isSuccessful } = await dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Confirmed'] }, 'Offer price has been confirmed', 'user-offer-confirmed', 'ATTENTION price on offer has been confirmed'))
                                if (isSuccessful) {
                                    // TO DO combine 'user-offer-deposit' notify to confirm action
                                    // void sendNotification(notifyDelay(), offer?.attributes?.Unit?.data?.id as number, Number(offerID as string), 'user-offer-deposit', 'ATTENTION upload a deposit pay slip for offer')
                                    const swalResult = await swalSuccess('Offer price has been confirmed')
                                    if (swalResult.isConfirmed) {
                                        navigate(textNavigate);
                                    }
                                } else {
                                    void swalError(textNotification);
                                }
                            }
                        })
                    }}
                    icon={'check_circle'}
                />
            }
            {(status === 'In-process' || status === 'Rejected') &&
                <ActionCard
                    title={'Approve'}
                    description={'Check offer - Approve offer - and inform landlord'}
                    onClick={() => {
                        confirmAction({
                            callback: async () => {
                                const { textNotification, textNavigate, isSuccessful } = await dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Approved'] }, 'Offer has been approved', 'user-offer-approved', 'Great, offer has been approved', false, true))
                                if (isSuccessful) {
                                    const swalResult = await swalSuccess('Offer has been approved')
                                    if (swalResult.isConfirmed) {
                                        navigate(textNavigate);
                                    }
                                } else {
                                    void swalError(textNotification);
                                }
                            }
                        })
                    }}
                    icon={'check_circle'}
                />
            }
            {_.includes(['Negotiated by landlord', 'Negotiated by BSO', 'Negotiated by agent', "Approved"], status) && // Comfirmed , 
                <ActionCard
                    title={'Negotiate'}
                    description={'Negotiate on offer'}
                    onClick={() => {
                        navigate(`/landlord/modal/negotiate_offer_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                    }}
                    icon={'monetization_on'}
                />
            }
            {status === 'In-process' &&
                <ActionCard
                    title={'Require resubmit'}
                    description={'Require agent to resubmit offer'}
                    onClick={() => {
                        navigate(`/landlord/modal/require_resubmit_offer_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                    }}
                    icon={'cancel'}
                />
            }
            {status === "Approved" && // && isSale
                <ActionCard
                    title={'Confirm from landlord - Go to next steps'}
                    description={'Confirm from landlord - Go to next steps'}
                    onClick={() => {
                        confirmAction({
                            callback: async () => {
                                const { textNotification, textNavigate, isSuccessful } = await dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Confirmed'] }, 'Offer price has been confirmed', 'user-offer-confirmed', 'ATTENTION price on offer has been confirmed'))
                                if (isSuccessful) {
                                    // TO DO combine 'user-offer-deposit' notify to confirm action
                                    // void sendNotification(notifyDelay(), offer?.attributes?.Unit?.data?.id as number, Number(offerID as string), 'user-offer-deposit', 'ATTENTION upload a deposit pay slip for offer')
                                    const swalResult = await swalSuccess('Offer price has been confirmed')
                                    if (swalResult.isConfirmed) {
                                        navigate(textNavigate);
                                    }
                                } else {
                                    void swalError(textNotification);
                                }
                            }
                        })
                    }}
                    icon={'check_circle'}
                />
            }
            {status === "Slip approved" &&
                !isSale &&
                <ActionCard
                    title={'Confirm booking form sending'}
                    description={'Confirm that booking form is sent to client'}
                    onClick={() => {
                        void (async () => {
                            const response = await swalConfirm('Booking form', 'Send a booking form to client')
                            if (response.isConfirmed) {
                                const { textNotification, textNavigate, isSuccessful } = await dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Waiting booking form sign'] }, 'Booking form is send', 'user-offer-booking-form', 'Booking form is sent', false, false, true))
                                if (isSuccessful) {
                                    const swalResult = await swalSuccess('Booking form is send')
                                    if (swalResult.isConfirmed) {
                                        navigate(textNavigate);
                                    }
                                } else {
                                    await swalError(textNotification);
                                }
                            }
                        })()
                    }}
                    icon={'description'}
                />
            }
            {status === 'Waiting for slip approval' &&
                <ActionCard
                    title={'Upload check slip'}
                    description={'Upload and check the deposit pay slip'}
                    onClick={() => {
                        downloadPaySlip(Number(offerID))
                    }}
                    icon={'description'}
                />
            }
            {status === 'Waiting for slip approval' &&
                <ActionCard
                    title={isSale ? "Check cheque 10% -  Inform landlord - Send to Landlord" : 'Approve pay slip'}
                    description={isSale ? 'Check and approve the cheque deposit 10%' : 'Check and approve the deposit pay slip'}
                    onClick={() => {
                        void (async () => {
                            const { textNotification, textNavigate, isSuccessful } = await dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Slip approved'] }, 'Slip is successfully approved', 'user-offer-deposit-approved', 'ATTENTION deposit on offer has been approved', false, true))
                            if (isSuccessful) {
                                const swalResult = await swalSuccess('Slip has been successfully approved')
                                if (swalResult.isConfirmed) {
                                    navigate(textNavigate);
                                }
                            } else {
                                await swalError(textNotification);
                            }
                        })()
                    }}
                    icon={'description'}
                />
            }
            {status === 'Waiting for slip approval' &&
                <ActionCard
                    title={isSale ? 'Reject the cheque deposit 10%' : 'Reject pay slip'}
                    description={isSale ? "Check and reject the cheque deposit 10%" : 'Check and reject the deposit pay slip'}
                    onClick={() => {
                        void (async () => {
                            const result = await showInputAlert('Reject pay slip - Write the reason for rejecting the deposit pay slip');
                            if (result.isConfirmed) {
                                const { input1 } = result.value;
                                const { textNotification, textNavigate, isSuccessful } = await dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Slip rejected'], RejectReason: input1 }, 'Slip has been successfully rejected', 'user-offer-deposit-rejected', 'ATTENTION deposit on offer has been rejected'))
                                if (isSuccessful) {
                                    const swalResult = await swalSuccess('Slip has been successfully rejected');
                                    if (swalResult.isConfirmed) {
                                        navigate(textNavigate);
                                    }
                                } else {
                                    const swalResult = await swalError(textNotification);
                                    if (swalResult.isConfirmed) {
                                        navigate(-1);
                                    }
                                }
                            }
                        })()
                    }}
                    icon={'description'}
                />
            }

            {status === 'Waiting draft contract send' &&
                <ActionCard
                    title={'Upload draft contract file'}
                    description={'Upload draft contract file to Agent'}
                    onClick={() => {
                        void (async () => {
                            const result = await showFileInputAlert('Upload draft contract file to Agent');
                            if (result.isConfirmed) {
                                const file = result.value;
                                const { textNotification, textNavigate, isSuccessful } = await dispatch(addOfferSaleContractDocuments({ offerID: Number(offerID), file, status: 'Waiting draft contract approve', categoryDoc: "Draft Contract" }));
                                if (isSuccessful) {
                                    const swalResult = await swalSuccess(textNotification);
                                    if (swalResult.isConfirmed) {
                                        navigate(textNavigate);
                                    }
                                } else {
                                    const swalResult = await swalError(textNotification);
                                    if (swalResult.isConfirmed) {
                                        navigate(-1);
                                    }
                                }
                            }
                        })();
                    }}
                    icon={'description'}
                />
            }



            {status === 'Waiting booking form sign' &&
                <ActionCard
                    title={'Check booking form sign'}
                    description={'Check booking form sign for offer'}
                    onClick={() => {
                        void (async () => {
                            const response = await swalConfirm('Booking form', 'Has the client confirmed the booking form?')
                            if (response.isConfirmed) {
                                // if (offer?.attributes?.Type?.[0]?.__component === 'offer.sale') {
                                // await dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Completed'] }, 'Booking form is successfully signed', 'admin-offer-completed', 'Offer is completed'))
                                // void dispatch(setUnitSold(offer?.attributes?.Unit?.data?.id ?? -1))
                                // } else {
                                //    await dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Waiting deposit pay slip'] }, 'Booking form is successfully signed', 'user-offer-deposit', 'ATTENTION upload a deposit pay slip for offer'))
                                // }
                                // void swalSuccess('Booking form is successfully signed')
                                navigate(`/landlord/modal/check_booking_form_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                            }
                        })()
                    }}
                    icon={'menu'}
                />
            }

            {(status === 'F Form requested' || status === 'Manual contract requested') &&
                <ActionCard
                    title={status === 'F Form requested' ? 'Prepared - Sent F Form to client ' : 'Prepared - Sent manual contract to client'}
                    description={'The Agent will be know about it'}
                    onClick={() => {
                        confirmAction({
                            callback: async () => {
                                const { textNotification, isSuccessful } = await dispatch(updateOfferDetailsFromAgent(Number(offer?.id), { Statuses: [status === 'F Form requested' ? 'F Form sent' : 'Manual contract sent'], notifyInjection: 'Agent' }));
                                if (isSuccessful === true) {
                                    const swalResult = await swalSuccess('Draft contract has been approved');
                                    if (swalResult.isConfirmed) {
                                        navigate(`/landlord/modalView/magic_offer_modal/${String(offer?.attributes?.Unit?.data?.id)}/${offer?.id}/catalog?type=${String(offer?.attributes?.Type?.[0]?.__component ?? 'not').slice(6)}`)
                                    }
                                } else {
                                    const swalResult = await swalError(textNotification);
                                    if (swalResult.isConfirmed) {
                                        navigate(-1);
                                    }
                                }
                            },
                            title: 'Are you sure the contract is sent to client?',
                            description: "",
                            confirmText: "Yes , main contract is sent"
                        })

                        // void (async () => {
                        // const result = await showFileInputAlert(status === 'F Form requested' ? 'Upload prepared  F Form file to Agent' : 'Upload prepared manual contract file to Agent');
                        // if (result.isConfirmed) {
                        //     const file = result.value;
                        //     const { textNotification, textNavigate, isSuccessful } = await dispatch(addOfferSaleContractDocuments({ offerID: Number(offerID), file, status: status === 'F Form requested' ? 'F Form sent' : 'Manual contract sent', categoryDoc: "MOU" }));
                        //     if (isSuccessful) {
                        //         const swalResult = await swalSuccess(textNotification);
                        //         if (swalResult.isConfirmed) {
                        //             navigate(textNavigate);
                        //         }
                        //     } else {
                        //         const swalResult = await swalError(textNotification);
                        //         if (swalResult.isConfirmed) {
                        //             navigate(-1);
                        //         }
                        //     }
                        // }
                        // })();
                    }}
                    icon={'description'}
                />
            }
            {(status === 'F Form sent' || status === 'Manual contract sent') &&
                <ActionCard
                    title={status === 'F Form sent' ? 'Client has F Form signet - send notice to agent' : 'Client has Manual contract signet - send notice to agent'}
                    description={'Upload draft contract file to Agent'}
                    onClick={() => {
                        confirmAction({
                            callback: async () => {
                                const { textNotification, isSuccessful } = await dispatch(updateOfferDetailsFromAgent(Number(offer?.id), { Statuses: [status === 'F Form sent' ? 'F Form signed' : 'Manual contract signed'], notifyInjection: 'Agent' }))
                                if (isSuccessful === true) {
                                    const swalResult = await swalSuccess('Draft contract has been approved');
                                    if (swalResult.isConfirmed) {
                                        navigate(`/landlord/modalView/magic_offer_modal/${String(offer?.attributes?.Unit?.data?.id)}/${offer?.id}/catalog?type=${String(offer?.attributes?.Type?.[0]?.__component ?? 'not').slice(6)}`)
                                    }
                                } else {
                                    const swalResult = await swalError(textNotification);
                                    if (swalResult.isConfirmed) {
                                        navigate(-1);
                                    }
                                }
                            },
                            title: 'Are you sure the contract is signet - send notice to agent with contract details?',
                            description: "Please confirm",
                            confirmText: "Document is signet"
                        })
                    }}
                    icon={'description'}
                />
            }


            {/* } else if (offerStatuses?.includes('F Form signed') || offerStatuses?.includes('Manual contract signed')) {  */}
            {marketingUse === 'For sale'
                ? <>
                    {/* {
                        status === 'Waiting for updates' && (
                            <ActionCard
                                title={'Start MOU process'}
                                description={'Start MOU process for the offer'}
                                onClick={() => {
                                    confirmAction({
                                        callback: () => {
                                            void dispatch(updateOfferByMagic(Number(offerID), { Statuses: ['Under MOU'] }, 'Offer is under MOU', 'user-offer-change', '', false, true))
                                                .then(() => {
                                                    dispatch(setWhereAfterToGo('/landlord/schedule'))
                                                    void swalSuccess('Offer is under MOU')
                                                })
                                        }
                                    })
                                }}
                                icon={'check_circle'}
                            />
                        )
                    } */}
                    {/* {
                        status === 'Under MOU' && offer?.attributes?.Type?.[0]?.Type === 'Mortgage Buyer' && (
                            <ActionCard
                                title={'Start mortgage process'}
                                description={'Start mortgage process for the offer'}
                                onClick={() => {
                                    navigate(`/landlord/modal/admin_upload_offer_document_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                                }}
                                icon={'check_circle'}
                            />
                        )
                    }
                    {
                        (status === 'Mortgage process' || (status === 'Under MOU' && offer?.attributes?.Type?.[0]?.Type !== 'Mortgage Buyer')) && (
                            <ActionCard
                                title={'Start obtaining NOC'}
                                description={'Start obtaining NOC for the offer'}
                                onClick={() => {
                                    navigate(`/landlord/modal/admin_upload_offer_document_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                                }}
                                icon={'check_circle'}
                            />
                        )
                    }
                    {
                        status === 'Obtaining NOC' && (
                            <ActionCard
                                title={'Start transfer process'}
                                description={'Start transfer process for the offer'}
                                onClick={() => {
                                    navigate(`/landlord/modal/admin_upload_offer_document_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                                }}
                                icon={'check_circle'}
                            />
                        )
                    }

                        await sendNotification(toUTCWithOffset(getCurrentDubaiDate(), 1), Number(ownerUserId), request?.response?.data?.id, 'user-offer-confirmed', `Attention kindy, ${nameUser} did offer on ${unitAddress} , please respond.`)

                    {
                        status === 'Transfer process' && (
                            <ActionCard
                                title={'Commit transaction'}
                                description={'Commit transaction for the offer'}
                                onClick={() => {
                                    navigate(`/landlord/modal/admin_upload_offer_document_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                                }}
                                icon={'check_circle'}
                            />
                        )
                    } */}

                    {status && !['In-process', 'Sent to landlord', 'Negotiated by agent',
                        'Negotiated by yourself', 'Negotiated by BSO', 'Pending BSO admin confirmation',
                        'Waiting for slip approval', 'Completed'
                    ]?.includes(status) &&
                        <ActionCard
                            title={'Change offer dynamic status - add extra details'}
                            description={'It\'ll be change updates and notices to agent'}
                            onClick={() => {
                                void (async () => {
                                    const result = await showInputAlert('Change offer status', 'Please provide description here...');
                                    if (result.isConfirmed) {
                                        const { input1, input2, loadDocument } = result.value;

                                        const { textNotification, isSuccessful } = await dispatch(updateOfferDetailsFromAgent(Number(offer?.id), { Statuses: [input1], StatusUpdateDescription: loadDocument ? "UPLOAD-URGENT " + input2 : input2, notifyInjection: 'Agent' }))
                                        if (isSuccessful === true) {
                                            const swalResult = await swalSuccess('Status has been changed successfully');
                                            void sendNotification(toUTCWithOffset(getCurrentDubaiDate(), 1), Number(offer?.attributes?.User?.data?.id), Number(offerID), 'user-offer-status-updated', input1)
                                            if (swalResult.isConfirmed) {
                                                navigate(`/landlord/modalView/magic_offer_modal/${String(offer?.attributes?.Unit?.data?.id)}/${offer?.id}/catalog?type=${String(offer?.attributes?.Type?.[0]?.__component ?? 'not').slice(6)}`)
                                            }
                                        } else {
                                            const swalResult = await swalError(textNotification);
                                            if (swalResult.isConfirmed) {
                                                navigate(-1);
                                            }
                                        }
                                    }
                                })();
                            }}
                            icon={'edit'}
                        />
                    }

                </>
                :
                <>
                </>
            }
            {status && !['In-process', 'Sent to landlord', 'Negotiated by agent', 'Negotiated by yourself', 'Negotiated by BSO', 'Pending BSO admin confirmation', 'Waiting for slip approval', 'Completed']?.includes(status) &&
                (
                    <ActionCard
                        title={'Complete offer'}
                        description={'The deal is done'}
                        onClick={() => {
                            confirmAction({
                                callback: async () => {
                                    const { textNotification, isSuccessful } = await dispatch(updateOfferDetailsFromAgent(Number(offer?.id), { Statuses: ['Completed'], notifyInjection: 'Agent' }))
                                    if (isSuccessful === true) {
                                        const swalResult = await swalSuccess('Status has been changed successfully');
                                        if (offer?.attributes?.Unit?.data?.id && offer?.attributes?.User?.data?.id) {
                                            void dispatch(setUnitSold(offer.attributes.Unit.data.id, false, offer.attributes.User.data.id))
                                        } else {
                                            void swalError('Unit ID or User ID is missing');
                                        }
                                        if (swalResult.isConfirmed) {
                                            navigate(`/landlord/modalView/magic_offer_modal/${String(offer?.attributes?.Unit?.data?.id)}/${offer?.id}/catalog?type=${String(offer?.attributes?.Type?.[0]?.__component ?? 'not').slice(6)}`)
                                        }
                                    } else {
                                        const swalResult = await swalError(textNotification);
                                        if (swalResult.isConfirmed) {
                                            navigate(-1);
                                        }
                                    }
                                }
                            })
                        }}
                        icon={'check_circle'}
                    />
                )
            }
            {status && !['In-process', 'Sent to landlord', 'Negotiated by agent', 'Negotiated by yourself', 'Negotiated by BSO', 'Pending BSO admin confirmation'].includes(status) &&
                <ActionCard
                    title={'Reject'}
                    description={'Reject offer'}
                    onClick={() => {
                        navigate(`/landlord/modal/reject_offer_modal/${unitID ?? '-'}/${offerID ?? '-'}`)
                    }}
                    icon={'cancel'}
                />
            }


        </div>
    </>
}

export default OffersActions
