import React from 'react'

export default function Layout({ children }: any): JSX.Element {
    // console.log(children, "children")
    return (
        <>
            {children}
        </>
    )
}
