import { type AppDispatch, type RootState } from '../../../store'
import { ACCESS_KEY, urlAPI } from '../../../../urls'
import { viewingsQuery } from '../../viewingsActions'
import { modalSlice } from '../../../slices/modalSlice'
import { viewingsSlice } from '../../../slices/viewingsSlice'
import { getErrorss } from '../../../../functions/errors/GetErrors'
import { toUTCWithOffset } from '../../../../functions/agent/date/toUTCWithOffset'
import { sendNotification } from '../../../../functions/shared/notifications/sendNotification'
import { getCurrentDubaiDate } from '../../../../functions/agent/date/getCurrentDubaiDate'

interface UpdateViewingByLandlordDTO {
    id: number
    data: Record<string, unknown>
    successAlert: string
    notifyRule?: string
    notifyMessage?: string
    textNavigate?: string | number
    sendNoNotify?: boolean
}

export const updateViewingByLandlord = ({ id, data, successAlert, notifyRule, notifyMessage = '', textNavigate = -1, sendNoNotify = false }: UpdateViewingByLandlordDTO) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    // const userID = window.localStorage.getItem(USER_ID_KEY)
    const viewing = getState().viewings?.viewings?.find((viewing) => viewing.id === id)
    const userID = viewing?.attributes?.User?.data?.id
    const currentDubaiDate = getCurrentDubaiDate()
    const inMomentViewingForLandlord = toUTCWithOffset(currentDubaiDate, 0)
    try {
        // const dateViewing = new Date(`${bodyClient.time_viewing}+04:00`)
        dispatch(modalSlice.actions.dataFetchingStart())
        const bodyObject = {
            method: 'PUT',
            collection: 'viewings',
            query: viewingsQuery,
            //  query: 'populate[User][fields][0]=id&populate[Unit][fields][0]=id&populate[KeyBooking]=*',
            id,
            body: { ...data, Notify: 'Agent' }
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${String(jwt)}`
            },
            body: JSON.stringify(bodyObject)
        })
        const dataJSON = await response.json()

        // console.log(dataJSON, 'dataJSON<<<<<<<<<updateViewing<<<<<<<<!!!!!!__________')

        if (dataJSON.success === true) {
            if (dataJSON.response.error != null) {
                dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(viewingsSlice.actions.setViewings(
                    dataJSON.response.data
                ))
                if (!sendNoNotify) {
                    // TODO CHECK ARSENII WE WERE USE BEFORE FROM VIEWINGS ACTIONS
                    await sendNotification(inMomentViewingForLandlord, Number(userID), id, notifyRule ?? '', notifyMessage)
                }
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate, isSuccessful: true, textNotification: successAlert ?? 'Great, unit time view has been changed' }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            dispatch(viewingsSlice.actions.viewingsFetchingError(getErrorss(dataJSON)))
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (err) {
        console.error()
    }
    return { textNavigate: '', isSuccessful: false, textNotification: '' }
}
