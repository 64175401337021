import { type IAdvertise } from '../../../store/slices/advertisesSlice'

export const reduceAdvertise = (entry: Record<any, any>, isMy: IAdvertise) => {
    const reduceAdvertise = {
        id: entry.id,
        attributes: {
            ...entry,
            // createdAt: entry.createdAt,
            // updatedAt: entry.updatedAt,
            // Statuses: entry.Statuses,
            // DigitalLockPass: entry.DigitalLockPass,
            // StartDate: entry.StartDate,
            // EndDate: entry.EndDate,
            // CreditsAmount: entry.CreditsAmount,
            // Status: entry.Status,
            // Links: entry.Links,
            // Name: entry.Name,
            // RejectReason: entry.RejectReason,
            // Notify: entry.Notify,
            // Duration: entry.Duration,
            User: {
                data: {
                    id: isMy?.attributes?.User?.data?.id ?? entry?.User?.id,
                    attributes: {}
                }
            },
            Unit: {
                data: {
                    id: isMy?.attributes?.Unit?.data?.id ?? entry?.Unit?.id,
                    attributes: {}
                }
            }

        }
    }
    return reduceAdvertise
}
