import _ from 'lodash'
import { type IViewing } from '../../../store/slices/viewingsSlice'

export const reduceViewing = (entry: Record<any, any>, isMy: IViewing) => {
    const actualKeyBooking = _.isEmpty(entry?.KeyBooking) || entry?.KeyBooking === null
        ? { data: null }
        : {
            data: {
                id: entry?.KeyBooking?.id,
                attributes: {
                    DropOutDate: entry?.KeyBooking?.DropOutDate,
                    PickUpDate: entry?.KeyBooking?.PickUpDate,
                    Status: entry?.KeyBooking?.Status,
                    createdAt: entry?.KeyBooking?.createdAt,
                    updatedAt: entry?.KeyBooking?.updatedAt
                }
            }
        }

    const reducedViewing = {
        id: entry.id,
        attributes: {
            ...(entry),
            User: {
                data: {
                    id: isMy?.attributes?.User?.data?.id ?? entry?.User?.id,
                    attributes: {}
                }
            },
            Unit : {
                data: {
                    id: entry?.Unit?.id,
                    attributes: {
                        Marketing : entry?.Unit?.Marketing ?? isMy?.attributes?.Unit?.data?.attributes?.Marketing ,
                        User : {
                            data: {
                                id: entry?.Unit?.User?.id ?? isMy?.attributes?.Unit?.data?.attributes?.User?.data?.id ,
                                attributes: {
                                    Category : entry?.Unit?.User?.Category ?? isMy?.attributes?.Unit?.data?.attributes?.User?.data?.attributes?.Category
                                }
                            }
                        }
                    }
                }
            },
            KeyBooking: actualKeyBooking
        }
    }
    console.log(reducedViewing,"reducedViewing")
    return reducedViewing
}

export const reduceViewingAfterCreate = (entry: Record<any, any>) => {
    const actualKeyBooking = _.isEmpty(entry?.KeyBooking) || entry?.KeyBooking === null
        ? { data: null }
        : {
            data: {
                id: entry?.KeyBooking?.id,
                attributes: {
                    DropOutDate: entry?.KeyBooking?.DropOutDate,
                    PickUpDate: entry?.KeyBooking?.PickUpDate,
                    Status: entry?.KeyBooking?.Status,
                    createdAt: entry?.KeyBooking?.createdAt,
                    updatedAt: entry?.KeyBooking?.updatedAt
                }
            }
        }
    const reducedViewing = {
        id: entry.id,
        attributes: {
            ...(entry),
            User: entry?.User?.id
                ? {
                    data: {
                        id: entry?.User?.id,
                        attributes: {}
                    }
                }
                : null,
            Unit : entry?.Unit?.id ? {
                data: {
                    id: entry?.Unit?.id,
                    attributes: {
                        Marketing : entry?.Unit?.Marketing ,
                        User : {
                            data: {
                                id: entry?.Unit?.User?.id  ,
                                attributes: {
                                    Category : entry?.Unit?.User?.Category 
                                }
                            }
                        }
                    }
                }
            }
                : null,
         
            Documents : {
                data: entry.Documents.map((doc:any) => ({ id: doc.id, attributes: { ...doc } }))
            },
            KeyBooking: actualKeyBooking
        }
    }
    return reducedViewing
}
