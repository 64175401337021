import _ from "lodash"
import { ACCESS_KEY, urlAPI } from "../../../urls"
import { dataFetchingFinish, dataFetchingStart, dropModalSlice } from "../../slices/modalSlice"
import { setFilterNavigate } from "../../slices/scheduleSlice"
import { type Unit } from "../../slices/unitsSlice"
import dayjs from 'dayjs'
import { removeDataAttributes } from "../../../functions/adapters/universal/removeDataAttributes"
import { getQueryOffers } from "../../../functions/shared/api/queries/getQueryOffers"
import { sendNotification } from "../../../functions/shared/notifications/sendNotification"
import { offersSlice } from "../../slices/offersSlice"
import { type AppDispatch, type RootState } from "../../store"
import { fetchSingleUnit } from "../unitsActions"

export const addOffer = (data: any, type: 'residential' | 'commercial' | 'sale' | 'sale-commercial', unitID: number, offerID?: number, isEditDocument: boolean = false) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    try {
        dispatch(dataFetchingStart())
        const unit = await dispatch(fetchSingleUnit(unitID))
   
        const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
        data.Type.__component = `offer.${type}`

        if ('ContractStartDate' in data.Type) {
            data.Type.ContractStartDate = dayjs(data.Type.ContractStartDate).add(dayjs(data.Type.ContractStartDate).utcOffset(), 'minutes').toISOString()
        }

        if (type === 'residential') {
            data.Type.Smoking = data.Type.Smoking === 'Yes'
            data.Type.Pets = data.Type.Pets === 'Yes'
        }
        if (type === 'sale' || type === 'sale-commercial') {
            data.Type.BankPreApproval = data.Type.BankPreApproval === 'Yes'
        }
        data.User = {
            id: getState().user.currentUser.id
        }
        data.Unit = {
            id: unitID
        }
        if ((type === 'residential' || type === 'commercial') && getState().modal.scannedData['Scanned.DepositSlipLink'] != null) {
            data.Type.DepositSlipLink = getState().modal.scannedData['Scanned.DepositSlipLink']
        }
        const areThereMoreThanFiveOffers = getState().offers.areThereMoreThanFiveOffers
        if (!areThereMoreThanFiveOffers) {
            dispatch(setFilterNavigate('offers'))
        }

        if (offerID == null) { // if not edit
            data.Statuses = ['In-process']
            data.Type = [{ ...data.Type }]
            data.Name = `Offer - ${String(unit?.attributes?.Property?.data?.attributes?.Name)} - ${String(unit?.attributes?.Marketing?.Headline)}`
            data.ProcessStartDate = new Date()
            data.Notify = 'Both'
            data.Documents = getState().modal.documents
            if (getState()?.modal?.scannedData?.['Scanned.TradeLicenceID'] != null) {
                data.Documents = [...data.Documents, getState()?.modal?.scannedData?.['Scanned.TradeLicenceID']]
            }
        } else {
            const offer = removeDataAttributes(
                _.cloneDeep(
                    getState().offers.offers.find((offer : any) => offer.id === offerID) ?? {}
                )
            )
            data.Statuses = ['In-process']
            if ((type === 'residential' || type === 'commercial') && data.Type.DepositSlipLink == null) {
                data.Type.DepositSlipLink = offer.Type[0].DepositSlipLink
            }
            data.Type = [{ ...data.Type }]
            data.Notify = 'Both'
            data.Documents = [...offer.Documents, ...getState().modal.documents]
            if (getState()?.modal?.scannedData?.['Scanned.TradeLicenceID'] != null) {
                data.Documents = [...data.Documents, getState()?.modal?.scannedData?.['Scanned.TradeLicenceID']]
            }
        }/* if (isEditDocument) {
            const offer = removeDataAttributes(
                _.cloneDeep(
                    _.find(getState().offers.offers, { id: offerID }) ?? {}
                )
            )
            if (offer != null) {
                offer.Type[0].FirstName = data.Type.FirstName
                offer.Type[0].FamilyName = data.Type.FamilyName
                offer.Type[0].Country = data.Type.Country
                offer.Type[0].EmiratesID = data.Type.EmiratesID

                data = offer
            }
        } */

        const bodyObject = {
            method: offerID != null ? 'PUT' : 'POST',
            collection: 'offers',
            id: offerID,
            body: _.omitBy(data, (value, key) => {
                //   console.log('KEY', key, 'VALUE', value)
                if (key?.includes != null) {
                    return key?.includes('Scanned.') || key?.includes('Type.') || ['state', 'Type.Country', 'Type.EmiratesID', 'Type.FirstName', 'Type.LastName', 'client_birthday', 'Scanned'].includes(key)
                }
                return ['state', 'Type.Country', 'Type.EmiratesID', 'Type.FirstName', 'Type.LastName', 'client_birthday', 'Scanned'].includes(key)
            }),
            query: getQueryOffers()
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        // void dispatch(fetchOffers(true))
       
  



        dispatch(dataFetchingFinish())
        const responseJSON = await response.json()
        // if (offerID == null) {
        void sendNotification(new Date(new Date().getTime() + 10000), Number(getState().user.currentUser.id), responseJSON.response.data ?? {}, 'admin-offer-in-process', 'Offer - in process')
        // }
        if (responseJSON.success === true) {
            if (responseJSON.response.error != null) {
                dispatch(offersSlice.actions.addOffer(responseJSON.response.data))
            }
        }
        responseJSON.isSuccessful = responseJSON.success
        responseJSON.textNotification = offerID == null ? 'Offer has been successfully submitted' : 'Offer has been successfully resubmitted'
        responseJSON.textNavigate = areThereMoreThanFiveOffers
            ? `/agent/modalView/offer_modal/${unitID}/${responseJSON?.response?.data?.id}/create?type=${type}`
            : '/agent/schedule'
        // '/agent/units/nav'
        dispatch(dropModalSlice())
        return responseJSON
        
    } catch (error) {
        console.log(error)
        return {
            isSuccessful: false
        }
    }
}