
import React from 'react'
import { type RootState } from '../../../../store/store'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { Drawer } from 'antd'
import { nanoid } from 'nanoid'
import { setIsBricksOpen, setShowButtonBuyCoinsOnAdvertise, setShowPopupAddOnsBricks, setShowPopupScheduleFilters } from '../../../../store/slices/modalSlice'
interface IProps {
    children: any
    title: string

}
export default function ScheduleFilters({ children, title }: IProps) {
    const showPopupScheduleFilters = useAppSelector((state: RootState) => state.modal.showPopupScheduleFilters)

    // console.log(showPopupAddOnsBricks, 'showPopupAddOnsBricks')
    const dispatch = useAppDispatch()

    const onClose = (): void => {
        dispatch(setShowPopupScheduleFilters(false))
    }
    return (
        <>
            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'80%'}
                styles={{
                    body: {
                        maxHeight: '88vh',
                        marginBottom: '3px',
                        paddingTop: '0px',
                        marginTop: '0px',
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }
                }}
                placement={'bottom'}
                closable={false}
                onClose={onClose}
                open={showPopupScheduleFilters}
                rootStyle={{ zIndex: '1100' }}
                key={title}
                maskClosable={true}
            >
                <div key={'child-driver-filters'}
                    className="content d-flex flex-column px-2"
                >
                    <div className="w-100 mw-450px  mx-auto">
                        {children}
                    </div>
                </div>
                {/* <div key={nanoid()} className="content d-flex flex-column flex-fill p-0 w-100"
                >
                    <div className="w-100 mw-450px  mx-auto">
                        {children}
                    </div>
                </div> */}
            </Drawer>
        </>
    )
}
