import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { setValue } from '../../../store/slices/setUnitSlice'
import { Space, Switch } from 'antd'
import { getCountries, getCountryCodes } from '../../../functions/getDataForSelects/getCountries'
import DefaultInput from '../../../app/custom/inputs/DefaultInput'
import CustomFormLable from '../../../app/custom/customModalUtils/CustomFormLable'
import MyDataPicker from '../../../app/MyDataPicker'
import _ from 'lodash'
import CustomSelect from '../../../app/custom/selects/CustomSelect'
import { scanDocument } from '../../../store/actionsCreators/documentsActions/scaning/documentScan'
import { useAppDispatch } from '../../../hooks/redux'
import { type IFields } from '../../../types/userTypes/documentTypes/TScan'
import Icon from '../../../app/icon/Icon'
import { ReturnStsScan } from './ReturnStsScan'
import { generateDefaultValueFormField } from '../../../functions/agent/AgentModals'
import { getCorrectExpDate } from '../../../functions/agent/date/getCorrectExpDateForScaning'
import { regExps } from '../../../constants'
import GroupInput from '../../../app/custom/inputs/GroupInput'

export default function StateTypeOffer({
    setValue,
    errors,
    register,
    control
}: any): JSX.Element {
    const currentPath = useLocation().pathname
    const { modalID, unitID, objectID } = useParams()
    const isIndividual = currentPath.includes('individual')
    const isCompany = currentPath.includes('company')

    const [switchCheckedDocType, setSwitchCheckedDocType] = useState(true)
    const [switchCheckedOfferType, setCheckedOfferType] = useState(false)
    const [isScanetDoc, setIsScanetDoc] = useState(false)
    const [isScanetFront, setIsScanetFront] = useState(false)
    const [isScanetVisa, setIsScanetVisa] = useState(false)
    const [isScanetBack, setIsScanetBack] = useState(false)
    const [selectedFile, setSelectedFile] = useState<any>(null)
    const [loadingLocal, setLoadingLocal] = useState(false)
    const [dataScan, setDataScan] = useState<any | null>(null)
    const handleSwitchChangeDocType = (checked: boolean) => {
        setSwitchCheckedDocType(checked)
    }
    const handleSwitchChangeOfferType = (checked: boolean) => {
        setCheckedOfferType(checked)
    }
    const dispatch = useAppDispatch()
    const [isHaveID, setIsHaveID] = useState(false)
    const [isHavePassport, setIsHavePassport] = useState(false)

    // Scanned.TradeLicence
    const rowFirstName = { id: 'Type.FirstName', content: ['Client First Name', 'Thomas'], element: 'input', required: true, validateEquals: ['Scanned.FirstName', 'Must be equal to first name in the scanned document', 2], ...(generateDefaultValueFormField(dataScan != null && dataScan?.name ? dataScan?.name.split(' ')[0] : undefined)) }
    const rowLastName = { id: 'Type.LastName', content: ['Client Last Name', 'A. Anderson'], element: 'input', required: true, validateEquals: ['Scanned.LastName', 'Must be equal to last name in the scanned document', 2], ...(generateDefaultValueFormField(dataScan != null && dataScan?.name ? dataScan?.name.split(' ').slice(1).join(' ') : undefined)) }

    const rowCountry = { id: 'Type.Country', content: ['Client Nationality', 'Select country'], selectors: getCountries(), element: 'select', required: true, ...(generateDefaultValueFormField(dataScan != null && dataScan?.nationality ? getCountryCodes(dataScan?.nationality) : undefined)) }
    // const rowClientBirthday = { id: 'client_birthday', content: ['Client Birth Day', 'Choose client\'s date of birth'], element: 'input-date', yearsBeforeToday: 18, required: true, ...(generateDefaultValueFormField(dataScan != null && dataScan?.dob ? getCorrectExpDate(dataScan?.dob) : undefined)) }
    //   const rowClientBirthday = { id: 'dob', content: ['Client Birth Day', 'Choose client\'s date of birth'], element: 'input-date', required: true, ...(generateDefaultValueFormField(dataScan != null && dataScan?.dob ? getCorrectExpDate(dataScan?.dob) : undefined)) }
    const rowTradeLicence = { id: 'trade_licence', content: ['Upload client\'s company trade licence', 'Trade licence'], element: 'input-file', required: true }
    const rowIdFront = { id: 'id_front', content: ['Emirates ID Front Side'], element: 'input-file', required: true }
    const rowIdBack = { id: 'id_back', content: ['Emirates ID back side'], element: 'input-file', required: true }
    const rowVisa = { id: 'visa', content: ['Passport'], element: 'input-file', required: true }

    const rowBusinessName = { id: 'Type.BusinessName', content: ['Business Name', 'Input client business name'], element: 'input', required: true }
    const rowBusinessOwnerName = { id: 'Type.BusinessOwnerName', content: ['Business Owner Name', 'Input business owner name'], element: 'input', required: true/*  validateEquals: ['Scanned.BusinessOwnerName', 'Must be equal to the business owner name in scanned document', 0.2] */ }
    const rowBusinessActivity = { id: 'Type.BusinessActivity', content: ['Business Activity', 'Input business activity'], element: 'input', required: true }
    const rowEstablishmentDate = { id: 'Type.EstablishmentDate', content: ['Establishment Date', 'Year', 'Input establishment year'], element: 'input-group', regexp: regExps.isYear, required: true, dateBeforeToday: true }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files

        if (file != null) {
            const formValues: IFields = { [e.target.name]: file }
            const scanDoc = await dispatch(scanDocument(formValues))
            if (scanDoc.document.valid) {
                setIsScanetDoc(true)
            }
        }
    }
    const scanHandler = async (): Promise<any> => {
        if (selectedFile == null) return
        setLoadingLocal(true)

        try {
            const file = selectedFile.target.files

            if (file != null) {
                const formValues: IFields = { [selectedFile.target.name]: file }
                const scanDoc = await dispatch(scanDocument(formValues))
                if (scanDoc.document.valid) {
                    setDataScan(scanDoc.document)
                    if (selectedFile.target.name === 'id_front') {
                        setIsScanetFront(true)
                    } else if (selectedFile.target.name === 'id_back') {
                        setIsScanetBack(true)
                    } else if (selectedFile.target.name === 'visa') {
                        setIsScanetVisa(true)
                    }

                    setIsScanetDoc(true)
                }
            }
        } catch (error) {
            console.error('Error while scanning title deed:', error)
        } finally {
            setLoadingLocal(false)
        }
    }

    return (
        <>

            <div className="fv-row my-2 m-0 p-0 w-100">
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase">Is the client a company ?</div>
                    </div>

                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Switch unCheckedChildren="NO" defaultChecked={switchCheckedOfferType}
                                checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                onChange={handleSwitchChangeOfferType}
                            />
                        </Space>
                    </div>
                </div>

                {
                    switchCheckedOfferType
                        ? <>
                            <div className={'d-flex flex-column gap-4'}>
                                <div className="separator separator-dashed my-4 mb-0"></div>
                                <div>
                                    <label className={'form-label fw-bold mb-2 text-dark text-uppercase '}>{rowTradeLicence.content[1]}</label>
                                    <input
                                        {...register(rowTradeLicence.id)}
                                        name={rowTradeLicence.id}
                                        className="form-control form-control-solid form-control-lg"
                                        accept={undefined}
                                        type="file"

                                        placeholder="Choose File"
                                        multiple={false}
                                    //       onChange={handleFileChange}
                                    />

                                </div>
                            </div>

                            {errors?.[rowTradeLicence.id] && (
                                <div className="text-danger text-start">{errors?.[rowTradeLicence.id].message}</div>
                            )}
                        </>
                        : null

                }
                <div className="separator separator-dashed my-4"></div>
            </div>

            <div className="fv-row  mt-8 p-0 w-100">
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase"> Do you have a {switchCheckedOfferType ? "company representative's" : "client's"} Passport ?</div>
                    </div>
                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Switch unCheckedChildren="NO" defaultChecked={isHavePassport}
                                checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                onChange={(ch) => { setIsHavePassport(ch) }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-4"></div>
                {isHavePassport &&
                    <>
                        <div className={'w-100 d-flex align-items-center justify-content-between'}>
                            <label className={'form-label fw-bold mb-2 text-dark text-uppercase required'}>Passport</label>
                            {/* {ReturnStsScan(isScanetVisa)} */}
                        </div>
                        <input
                            {...register(rowVisa.id, { required: `Field "${rowVisa.content?.[0]}" is required` })}
                            name={rowVisa.id}
                            className="form-control form-control-solid form-control-lg"
                            accept={undefined}
                            type="file"
                            placeholder="Choose File"
                            multiple={false}
                        //    required={rowVisa.required}
                        //   onChange={  handleFileChange }
                        //   onChange={(event) => {
                        // const files = event.target.files
                        // if ((files != null) && files.length > 0) {
                        //     setSelectedFile(files)
                        // }
                        //     setSelectedFile(event)
                        // }}
                        />
                        {errors?.[rowVisa.id] && (
                            <div className="text-danger text-start">{errors?.[rowVisa.id].message}</div>
                        )}
                        <div className="separator separator-dashed my-4"></div>
                    </>
                }
            </div>
            <div className="fv-row  mt-8 p-0 w-100">
                <div className="d-flex flex-wrap align-items-center">
                    <div >
                        <div className="fs-6 fw-bold mb-1 text-uppercase"> Do you have a {switchCheckedOfferType ? "company representative's" : "client's"} Emirates ID ?</div>
                    </div>
                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Switch unCheckedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>NO</span>} defaultChecked={isHaveID}
                                checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                onChange={(ch) => { setIsHaveID(ch) }}
                            />
                        </Space>
                    </div>
                </div>
                <div className="separator separator-dashed my-4"></div>
                {isHaveID &&

                    <>
                        <div className={'w-100 d-flex align-items-center justify-content-between'}>
                            <label className={'form-label fw-bold mb-2 text-dark text-uppercase required'}>Front side</label>
                            {/* {ReturnStsScan(isScanetFront)} */}

                        </div>
                        <input
                            {...register(rowIdFront.id, { required: `Field "${rowIdFront.content?.[0]}" is required` })}
                            name={rowIdFront.id}
                            className="form-control form-control-solid form-control-lg mb-2"
                            accept={undefined}
                            type="file"
                            placeholder="Choose File"
                            multiple={false}

                        />
                        {errors?.[rowIdFront.id] && (
                            <div className="text-danger text-start">{errors?.[rowIdFront.id].message}</div>
                        )}

                        <div className={'w-100 d-flex align-items-center justify-content-between'}>
                            <label className={'form-label fw-bold mb-2 text-dark text-uppercase required'}>Back side</label>
                        </div>
                        <input
                            {...register(rowIdBack.id, { required: `Field "${rowIdBack.content?.[0]}" is required` })}
                            name={rowIdBack.id}
                            className="form-control form-control-solid form-control-lg"
                            accept={undefined}
                            type="file"
                            placeholder="Choose File"
                            multiple={false}

                        />
                        {errors?.[rowIdBack.id] && (
                            <div className="text-danger text-start">{errors?.[rowIdBack.id].message}</div>
                        )}

                        <div className="separator separator-dashed my-4"></div></>
                }

                {
                    !switchCheckedOfferType
                        ? <>
                            <DefaultInput row={rowFirstName} register={register} errors={errors} />
                            <DefaultInput row={rowLastName} register={register} errors={errors} />
                            <CustomSelect control={control} register={register} data={rowCountry} setValue={setValue} errors={errors} />


                            {/* <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                <CustomFormLable row={rowClientBirthday} />
                                <MyDataPicker control={control} register={register} row={rowClientBirthday} setValue={setValue} />
                                {_.get(errors, rowClientBirthday.id) != null && (
                                    <div className="text-danger">{_.get(errors, rowClientBirthday.id).message}</div>
                                )}
                            </div>   */}
                        </>
                        : <>
                            <DefaultInput row={rowBusinessName} register={register} errors={errors} />
                            <DefaultInput row={rowBusinessActivity} register={register} errors={errors} />
                            <DefaultInput row={rowBusinessOwnerName} register={register} errors={errors} />

                            {/* <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                <CustomFormLable row={rowEstablishmentDate} />
                                <MyDataPicker control={control} register={register} row={rowEstablishmentDate} setValue={setValue} />
                                {_.get(errors, rowEstablishmentDate.id) != null && (
                                    <div className="text-danger">{_.get(errors, rowEstablishmentDate.id).message}</div>
                                )}
                            </div> */}

                            <GroupInput register={register} errors={errors} row={rowEstablishmentDate} />
                        </>
                }

            </div >

        </>

    )
}

