import React, { useEffect, useState } from 'react'
import { type IOffer } from '../../../../store/slices/offersSlice'
import { type Unit } from '../../../../store/slices/unitsSlice'
import { Steps } from 'antd'
import { type TimelineItem } from '../viewingTimelines/ViewingTimeline'
import renderOfferStatus from "../../../../functions/agent/offers/renderOfferStatus";
import StepComponent from './StepComponent'
import StepNavigation from './StepNavigation'
import { textsOfferTimelineRent } from '../../../texts/timelinesTexts'
import { iconProcess } from './iconProcess'
interface IProps {
    offer: IOffer
    unit: Unit
}


function TimelineRent({ offer, unit }: IProps): JSX.Element {
    let process = 0
    const offerStatuses = [renderOfferStatus(offer?.attributes?.Statuses[0], offer.id)]
    const price = Number(offer?.attributes?.Type?.[0]?.Offer) * 0.05


    if (offerStatuses?.includes('Client docs required')) {
        process = 0
    }
    else if (offerStatuses?.includes('Resubmit required')) {
        process = 0
        textsOfferTimelineRent.stepClientData = 'Resubmit and wait sent offer.'
        textsOfferTimelineRent.descriptionStepClientData = 'Our specialist will be sent your offer soon after you resubmit.'
    } else if (offerStatuses?.includes('Canceled') || offerStatuses?.includes('Canceled by yourself') || offerStatuses?.includes('Canceled by agent')) {
        process = 0
        textsOfferTimelineRent.stepClientData = 'You have canceled Offer!'
        textsOfferTimelineRent.descriptionStepClientData = ''
    } else if (offerStatuses?.includes('In-process')) {
        process = 1
    } else if (offerStatuses?.includes('Rejected')) {
        process = 1
        textsOfferTimelineRent.stepApprovedOrRejected = 'Your offer was rejected by BSO.'
        textsOfferTimelineRent.descriptionApprovedOrRejected = 'Unfortunately, our specialist has decided to reject your offer because of the reason stated above'
    } else if (offerStatuses?.includes('Sent to landlord')) {
        process = 2
    } else if (offerStatuses?.includes('Negotiated by agent') || offerStatuses?.includes('Negotiated by yourself')) {
        process = 2
        textsOfferTimelineRent.stepConfirmed = 'You requested the offer negotiation.'
        textsOfferTimelineRent.descriptionConfirmed = 'Please wait until our specialist or the landlord takes action about your negotiation of this offer.'
    } else if (offerStatuses?.includes('Negotiated by BSO')) {
        process = 2
        textsOfferTimelineRent.stepConfirmed = 'The BSO offered another option from landlord.'
        textsOfferTimelineRent.descriptionConfirmed = 'If you are satisfied, send the customer data, or provide updated offer details.'
    } else if (offerStatuses?.includes('Negotiated by landlord')) {
        process = 2
        textsOfferTimelineRent.stepConfirmed = 'The landlord offered another option.'
        textsOfferTimelineRent.descriptionConfirmed = 'If you are satisfied, send the customer data, or provide updated offer details.'
    } else if (offerStatuses?.includes('Pending BSO admin confirmation')) {
        process = 2
        textsOfferTimelineRent.stepConfirmed = 'In the process of reviewing your consent.'
        textsOfferTimelineRent.descriptionConfirmed = 'Please wait until our specialist will be reviewing your consent.'
    } else if (offerStatuses?.includes('Confirmed')) {
        process = 3
        textsOfferTimelineRent.stepConfirmed = textsOfferTimelineRent.stepPayUploading
        textsOfferTimelineRent.descriptionConfirmed = textsOfferTimelineRent.descriptionPayUploading
    } else if (offerStatuses?.includes('Waiting for slip approval')) {
        process = 4
        textsOfferTimelineRent.descriptionConfirmed = ''
    } else if (offerStatuses?.includes('Slip approved')) {
        process = 5
    } else if (offerStatuses?.includes('Slip rejected')) {
        process = 5
    }
    else if (offerStatuses?.includes('Waiting booking form send')) {
        process = 6
    }
    else if (offerStatuses?.includes('Waiting booking form sign')) {
        process = 6
    } else if (offerStatuses?.includes('Completed')) {
        process = 7
    }
    const [line, setLine] = useState('middle')


    const groupRent: TimelineItem[] = [

        {
            title: <StepComponent
                title={textsOfferTimelineRent.stepClientData}
                description={textsOfferTimelineRent.descriptionStepClientData}
                process={0}
                currentProcess={process}
                statuses={offerStatuses}
                rejectReason={process === 0 ? offer?.attributes?.RejectReason ?? undefined : undefined}
            />,
            status: process === 0 && (offerStatuses?.[0]?.includes('Canceled') || offerStatuses?.includes('Rejected')) ? 'finish' : undefined,
            description: textsOfferTimelineRent.descriptionStepClientData
        },
        ...(offerStatuses?.includes('Canceled') ? [] : [
            ...offerStatuses?.includes('Rejected') ? [{
                title: <StepComponent
                    title={textsOfferTimelineRent.stepApprovedOrRejected}
                    description={textsOfferTimelineRent.descriptionApprovedOrRejected}
                    process={1}
                    currentProcess={process}
                    statuses={offerStatuses}
                    rejectReason={offer?.attributes?.RejectReason ?? undefined}
                />,
                status: 'finish',
                description: textsOfferTimelineRent.descriptionApprovedOrRejected
            }] : [
                {
                    title: <StepComponent
                        title={textsOfferTimelineRent.stepInProcess}
                        description={textsOfferTimelineRent.descriptionInProcess}
                        process={1}
                        currentProcess={process}
                        statuses={offerStatuses}
                        rejectReason={process === 1 ? offer?.attributes?.RejectReason ?? undefined : undefined}

                    />,
                    status: process === 1 && (offerStatuses?.includes('Canceled') || offerStatuses?.includes('Rejected')) ? 'finish' : undefined,
                    description: textsOfferTimelineRent.descriptionInProcess
                },
                {
                    title: <StepComponent
                        title={textsOfferTimelineRent.stepApprovedOrRejected}
                        description={textsOfferTimelineRent.descriptionApprovedOrRejected}
                        process={2}
                        currentProcess={process}
                        statuses={offerStatuses}

                    />,
                    description: textsOfferTimelineRent.descriptionApprovedOrRejected
                },
                {
                    title: <StepComponent
                        title={textsOfferTimelineRent.stepConfirmed}
                        description={textsOfferTimelineRent.descriptionConfirmed}
                        addTitle={textsOfferTimelineRent.addTitle}
                        process={3}
                        currentProcess={process}
                        statuses={offerStatuses}
                    />,
                    description: textsOfferTimelineRent.descriptionConfirmed
                },
                {
                    title: <StepComponent
                        title={textsOfferTimelineRent.stepPaySlip}
                        description={textsOfferTimelineRent.descriptionPaySlip}
                        process={4}
                        currentProcess={process}
                        statuses={offerStatuses}
                    />,
                    description: textsOfferTimelineRent.descriptionPaySlip
                },
                {
                    title: <StepComponent
                        title={textsOfferTimelineRent.stepBFSending}
                        description={textsOfferTimelineRent.descriptionBFSending}
                        process={5}
                        currentProcess={process}
                        statuses={offerStatuses}
                    />,
                    description: textsOfferTimelineRent.descriptionBFSending
                }, {
                    title: <StepComponent
                        title={textsOfferTimelineRent.stepBFSinging}
                        description={textsOfferTimelineRent.descriptionBFSinging}
                        process={6}
                        currentProcess={process}
                        statuses={offerStatuses}
                    />,
                    description: textsOfferTimelineRent.descriptionBFSinging
                },
                {
                    title: <StepComponent
                        title={textsOfferTimelineRent.stepCompleted}
                        description={textsOfferTimelineRent.descriptionCompleted}
                        process={7}
                        currentProcess={process}
                        statuses={offerStatuses}
                    />,
                    description: textsOfferTimelineRent.descriptionCompleted,
                    status: process === 7 ? 'finish' : undefined
                }]
        ])
    ];
    const [sliceSteps, setSliceSteps] = useState([groupRent[process]])
    const totalSteps = groupRent.length;

    useEffect(() => {
        groupRent.forEach((el, i) => { el.icon = iconProcess(i + 1, process, totalSteps) })
    }, [sliceSteps, process])



    return (
        <div className='my-8 pt-8' style={{ maxWidth: '450px', width: '100%' }}>

            <Steps
                current={line === 'next' ? 0 : line === 'middle' ? 0 : process}
                percent={60}
                items={sliceSteps}
                direction='vertical'
            />

            <StepNavigation
                process={process}
                line={line}
                setLine={setLine}
                setSliceSteps={setSliceSteps}
                group={groupRent}
                offerStatuses={offerStatuses}
                totalSteps={totalSteps}
            />
        </div>
    )
}

export default TimelineRent
