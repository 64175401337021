import React from 'react';

export const ShowReason = ({ statuses, reason }: any): JSX.Element => {
    const typeReason = Array.isArray(statuses) && typeof statuses[0] === 'string' && statuses[0].includes('Canceled') ? 'Cancel ' : Array.isArray(statuses) && typeof statuses[0] === 'string' && statuses[0].toLocaleLowerCase().includes('rejected') ? 'Rejected ' : statuses?.[0]?.includes('Negotiated') ? 'Negotiated ' : statuses?.includes('Waiting draft contract send') ? "Need to change - " : 'Reschedule '
    return (
        <div>
            <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">
                {typeReason} Reason:
            </div>
            <div className="d-block w-100">
                <div className={`badge badge-light-${Array.isArray(statuses) && typeof statuses[0] === 'string' && (statuses[0].includes('Canceled') || statuses[0].toLocaleLowerCase().includes('rejected')) ? "danger" : "info"} border  fs-7 text-wrap w-100`}>
                    {/* border-${statuses?.[0]?.includes('Canceled') || statuses?.[0]?.includes('Rejected') ? "danger" : "info"} */}
                    {reason}
                </div>
            </div>
        </div>
    );
};