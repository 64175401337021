
import { type User } from '../../store/slices/userSlice'
import { store } from '../../store/store'
import { USER_GENDER_KEY, USER_NAME_KEY, USER_NATIONALITY_KEY, USER_DOB_KEY } from '../../urls'
import { getAreas, getBanks, getCountries, getPropertyType } from '../getDataForSelects/dataFunctions'

import { AddOfferTenantTypes } from '../../store/actionsCreators/offerActions/addOffer/rent/addOfferTenantType'
import _ from 'lodash'
import { regExps } from '../../constants'
import { redirect } from 'react-router-dom'
import { ServicesConfig } from '../landlord/data/serivces/servicesConfig'
import getUserType from '../shared/user/getUserType'

// interface Modal {
//     id: string
//     header?: boolean | string
//     form?: Array<{
//         id?: string
//         content?: string[]
//         element?: string
//         selectors?: string[]
//         subrow?: Array<{
//             id: string
//             content: string[]
//             element: string
//             selectors?: string[]
//         }>
//         required?: boolean // добавлено свойство "required"
//     }>
//     btnTitle?: string
//     footer?: boolean | any[]
// }
export const generateDefaultValueFormField = (defaultValue: any) => {
    const field: any = {}
    if (defaultValue !== undefined && defaultValue !== null && defaultValue !== '') {
        field.defaultValue = defaultValue
    }
    return field
}

export function AgentModals (defaultFields?: any): any {
    const userCur = (store.getState().user?.currentUser as User)
    const AgentSettings = userCur.Settings
    const configSelectors = store.getState().app.config?.units
    const newProperty = store.getState().property.newPropertyFields //   defaultFields?.newProperty
    let unitsFields = defaultFields?.unitFields?.[0]
    let propertyFields = defaultFields?.propertyFields
    let marketingFields = unitsFields?.attributes?.Marketing
    if (defaultFields && defaultFields?.unitFields && defaultFields?.unitFields?.length > 0) {
        unitsFields = defaultFields?.unitFields?.[0]
        propertyFields = defaultFields?.propertyFields
        marketingFields = unitsFields?.attributes?.Marketing
    }

    // TODO CHECK ARSENII
    const allAreas = store.getState().app.config?.all.Area
        .filter((area: string) => area !== 'All areas')
        .sort((a: string, b: string) => a.localeCompare(b));




    const modals: any = [
        {
            id: 'update_emirates_front_together',
            header: 'Get verify status of your account',
            form: [
                { content: ['Upload your Emirates ID front side'], element: 'label-primary' },
                { id: 'progress', element: 'progress-emirates-front' },
                { id: 'to_emirates_id', content: ['Doesn\'t have Emirates ID?', 'Press here for upload passport', `/${getUserType({ checkForAdmin: false }).toLowerCase()}/modal/update_passport_together/not/not`], element: 'header-link' },
                { id: 'id_front', content: ['Emirates ID Front Side'], element: 'input-file', required: true },
                { id: 'imagePaceholder', element: 'id_front' }

            ],
            footer: [
                { id: 'submit', content: ['Save and go to next step'], element: 'button-primary', swal: true },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: getUserType({ checkForAdmin: false }) === 'Landlord' ? '/landlord/my_units' : '/agent/units/nav' }
            ]
        },
        {
            id: 'update_emirates_back_together',
            header: 'Get verify status of your account',
            form: [
                { content: ['Upload your Emirates ID back side'], element: 'label-primary' },
                { id: 'progress', element: 'progress-emirates-back' },
                //   { id: 'to_emirates_id', content: ['Doesn\'t have Emirates ID?', 'Press here for upload passport', '/agent/modal/update_passport_together/not/not'], element: 'header-link' },
                { id: 'id_back', content: ['Emirates ID back side'], element: 'input-file', required: true },
                // { id: 'id_expiry', content: ['Emirates ID expiry date', 'Select date'], element: 'input-date', required: true },
                { id: 'separator', element: 'separator' },
                { id: 'imagePaceholder', element: 'id_back' }

            ],
            footer: [
                { id: 'submit', content: ['Save changes'], element: 'button-primary', swal: true }
                //  { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav' }
            ]
        },

        {
            id: 'update_passport_together',
            header: 'Get verify status of your account',
            form: [
                { content: ['Upload your Passport front side'], element: 'label-primary' },
                { id: 'to_emirates_id', content: ['For load Emirates ID', 'Click here', `/${getUserType({ checkForAdmin: false }).toLowerCase()}/modal/update_emirates_front_together/not/not`], element: 'header-link' },
                { id: 'visa', content: ['Passport'], element: 'input-file', required: true },
                // { id: 'visa_expiry', content: ['Passport expiry date', 'Select date'], element: 'input-date', required: true },
                // { id: 'separator', element: 'separator' },
                { id: 'imagePaceholder', element: 'visa' }
            ],
            footer: [
                { id: 'submit', content: ['Save changes'], element: 'button-primary', swal: true },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: getUserType({ checkForAdmin: false }) === 'Landlord' ? '/landlord/my_units' : '/agent/units/nav' }
            ]
        },
        {
            id: 'update_emirates_front',
            header: 'Get your account verified', //  in one click
            form: [
                // { content: ['Emirates ID'], element: 'header' },
                { content: ['Upload your Emirates ID front side'], element: 'label-primary' },
                { id: 'progress', element: 'progress-emirates-front' },
                { id: 'id_front', content: ['Emirates ID front side'], element: 'input-file', required: true },

                // { id: 'id_expiry', content: ['Emirates ID expiry date', 'Select date'], element: 'input-date', required: true },
                //  { id: 'separator', element: 'separator' },
                { id: 'imagePaceholder', element: 'id_front' }
            ],
            footer: [
                { id: 'submit', content: ['Save and go to next step'], element: 'button-primary', swal: true },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },

        {
            id: 'update_emirates_back',
            header: 'Get your account verified', //  in one click
            form: [
                // { content: ['Emirates ID'], element: 'header' },
                { content: ['Upload your Emirates ID back side'], element: 'label-primary' },
                { id: 'progress', element: 'progress-emirates-back' },
                { id: 'id_back', content: ['Emirates ID back side'], element: 'input-file', required: true },
                // { id: 'id_expiry', content: ['Emirates ID expiry date', 'Select date'], element: 'input-date', required: true },
                // { id: 'separator', element: 'separator' },
                { id: 'imagePaceholder', element: 'id_back' }
            ],
            footer: [
                { id: 'submit', content: ['Save changes'], element: 'button-primary', swal: true }
                // { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'update_passport',
            header: 'Get your account verified', //  in one click
            form: [
                // { content: ['Passport'], element: 'header' },
                { content: ['Upload your Passport front side'], element: 'label-primary' },

                { id: 'visa', content: ['Passport'], element: 'input-file', required: true },
                // { id: 'visa_expiry', content: ['Passport expiry date', 'Select date'], element: 'input-date', required: true },
                // { id: 'separator', element: 'separator' },
                { id: 'imagePaceholder', element: 'visa' }

            ],
            footer: [
                { id: 'submit', content: ['Save changes'], element: 'button-primary', swal: true },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'update_rera',
            header: 'Get your account licensed', // in one click'
            form: [

                { content: ['Upload your RERA License to get Licensed Status in one click'], element: 'label-primary' },
                { id: 'rera', content: ['RERA Card'], element: 'input-file', required: true },
                { id: 'separator', element: 'separator' },
                { id: 'imagePaceholder', element: 'rera' }

            ],
            footer: [
                { id: 'submit', content: ['Save changes'], element: 'button-primary', swal: true },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'update_rera_deal',
            header: 'Get your account licensed ', // in one click
            form: [

                { content: ['Upload your RERA License to get Licensed Status in one click'], element: 'label-primary' },
                { id: 'rera', content: ['RERA Card'], element: 'input-file', required: true },
                { id: 'separator', element: 'separator' },
                { id: 'imagePaceholder', element: 'rera' }

            ],
            footer: [
                { id: 'submit', content: ['Save changes'], element: 'button-primary', swal: true },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav' } // redirect: '/agent/settings',
            ]
        },
        {
            id: 'loader',
            header: false,
            form: [
                { id: 'loader', content: ['App is loading'], element: 'loader' }
            ],
            footer: false
        },

        /// for auth
        {
            id: 'login',
            header: true,
            form: [
                { content: ['Sign in to BSO Club'], element: 'header' },
                { id: 'to_register', content: ['New here?', 'Create an account', '/auth/register1'], element: 'header-link' },
                { id: 'email', content: ['Email', 'whiterabbit@follow.me'], element: 'input-email', required: true, autocomplete: 'username' },
                { id: 'password', content: ['Password', 'Pa5$w0rd'], element: 'input-password', required: true, autocomplete: true, passwordType: 'current-password' }

            ],
            footer: [
                { id: 'submit', content: ['Login'], element: 'button-primary', for: 'auth' },
                { id: 'to_forgot_pass', content: ['Forgot your password?', 'Reset here', '/auth/reset_password_way_email'], element: 'header-link' }
            ]
        },
        {
            id: 'register_phone',
            header: 'Create Account',
            progress: 15,
            form: [
                // { content: 'Create Account', element: 'header' },
                { content: 'Enter your mobile number', element: 'modal-title' },
                { content: ['We will send you confirmation code'], element: 'modal-description'},
                { id: 'phone', element: 'phone-keypad', required: true }
            ],
            footer: [
                { id: 'submit', content: ['NEXT'], element: 'modal-submit-button' },
                { id: 'back', element: 'modal-back' }
            ]
        },
        {
            id: 'register_phone_verify',
            header: 'Create Account',
            progress: 30,
            form: [
                { content: 'Enter OTP Code we sent to you', element: 'modal-title' },
                { content: ['Did you enter the wrong number?', 'Reset here', '/auth/v2/register_phone'], element: 'modal-description' },
                { id: 'code', element: 'code-keypad', required: true }
            ],
            footer: [
                { id: 'reset', content: ['Resend OTP'], timeout: 30, element: 'modal-reset-button' },
                { id: 'submit', content: ['NEXT'], element: 'modal-submit-button' },
                { id: 'back', element: 'modal-back' }
            ]
        },
        {
            id: 'register_user_type',
            header: 'Create Account',
            progress: 40,
            form: [
                { content: 'Tell us about yourself', element: 'modal-title' },
                { content: ['Help us personalize your experience by picking options below'], element: 'modal-description'},
                {
                    id: 'user_type',
                    selectors: [
                        {
                            value: 'Landlord',
                            title: 'Landlord',
                            description: 'Ligula aptent conubia molestie suspendisse ut rhoncus. Hendrerit quis vehicula integer natoque consectetur dictumst enim primis ante. ',
                            icon: {
                                type: 'image',
                                src: '/assets/media/auth/user_type/landlord.svg'
                            },
                            color: '#2168F2'
                        },
                        {
                            value: 'Broker',
                            title: 'Broker',
                            description: 'Ligula aptent conubia molestie suspendisse ut rhoncus. Hendrerit quis vehicula integer natoque consectetur dictumst enim primis ante. ',
                            icon: {
                                type: 'image',
                                src: '/assets/media/auth/user_type/broker.svg'
                            },
                            color: '#9E74F7'
                        }
                    ],
                    element: 'cards-select'
                }
            ],
            footer: [
                { id: 'submit', content: ['NEXT'], element: 'modal-submit-button' },
                { id: 'back', element: 'modal-back' }
            ]
        },
        {
            id: 'register_setup_details',
            header: 'Create Account',
            progress: 60,
            form: [
                { content: 'Set up your details', element: 'modal-title' },
                { content: ['Please complete the form with valid information'], element: 'modal-description'},
                { id: 'email', content: ['Email', 'whiterabbit@follow.me'], type: 'email', required: true, errorMessage: 'Please enter a valid email address', regexp: regExps.isAuthEmail, element: 'input', autocomplete: 'username' },
                { id: 'password', content: ['Password', '*********'], type: 'password', required: true, showPassSecurity: true, regexp: regExps.isAuthPassword, element: 'input', autocomplete: 'new-password' },
                { id: 'password-confirm', content: ['Confirm Password', '*********'], type: 'password', required: true, errorMessage: 'Passwords do not match', regexp: regExps.isAuthPassword, element: 'input', autocomplete: 'new-password' },
                { id: 'terms-and-conditions', element: 'terms-and-conditions', height: '100%' }
            ],
            footer: [
                { id: 'submit', content: ['NEXT'], element: 'modal-submit-button' },
                { id: 'back', element: 'modal-back' }
            ]
        },
        {
            id: 'register_verify_identity_emirates_id',
            header: 'Create Account',
            progress: 80,
            form: [
                { content: 'Verify your Identity', element: 'modal-title' },
                { content: ['Choose an ID and prepare for the scanning process'], paddingBottom: 'small', paddingX: 'small', element: 'modal-description'},
                {
                    links: [
                        { text: 'Emirates ID', href: '/auth/v2/register_verify_identity_emirates_id' },
                        { text: 'Passport', href: '/auth/v2/register_verify_identity_passport' }
                    ],
                    element: 'tab-links'
                },
                { src: '/assets/media/auth/documents/eid.png', imageHeight: '388px', animateTransition: true, element: 'modal-image'},
                { id: 'id_front', content: [undefined, 'Upload a photo (front side)'], required: true, submitOnInput: true, animateTransition: true, element: 'input-file' },
                { content: ['Scan your ID'], href: '#', element: 'button-link', animateTransition: true, },
                { content: ['', 'I’ll input my details manually'], href: '/auth/v2/register_id_verification', element: 'text-link' }
            ],
            footer: [
                // { id: 'submit', content: ['NEXT'], element: 'modal-submit-button' },
                { id: 'back', element: 'modal-back' }
            ]
        }, // register_verify_identity_emirates_id_back
        {
            id: 'register_verify_identity_emirates_id_back',
            header: 'Create Account',
            progress: 85,
            form: [
                { content: 'Verify your Identity', element: 'modal-title' },
                { content: ['Scan the back side of the ID'], paddingBottom: 'small', paddingX: 'small', element: 'modal-description'},
                { src: '/assets/media/auth/documents/eid.png', imageHeight: '388px', element: 'modal-image'},
                { id: 'id_back', content: [undefined, 'Upload a photo (back side)'], required: true, submitOnInput: true, element: 'input-file' },
                { content: ['Scan your ID'], href: '#', element: 'button-link' },
                { content: ['', 'I’ll input my details manually'], href: '/auth/v2/register_id_verification', element: 'text-link' }
            ],
            footer: [
                // { id: 'submit', content: ['NEXT'], element: 'modal-submit-button' },
                { id: 'back', element: 'modal-back' }
            ]
        },
        {
            id: 'register_verify_identity_passport',
            header: 'Create Account',
            progress: 80,
            form: [
                { content: 'Verify your Identity', element: 'modal-title' },
                { content: ['Choose an ID and prepare for the scanning process'], paddingBottom: 'small', paddingX: 'small', element: 'modal-description'},
                {
                    links: [
                        { text: 'Emirates ID', href: '/auth/v2/register_verify_identity_emirates_id' },
                        { text: 'Passport', href: '/auth/v2/register_verify_identity_passport' }
                    ],
                    element: 'tab-links'
                },
                { src: '/assets/media/auth/documents/passport.png', imageHeight: '388px', animateTransition: true, element: 'modal-image'},
                { id: 'visa', content: [undefined, 'Upload a photo'], required: true, submitOnInput: true, animateTransition: true, element: 'input-file' },
                { content: ['Scan your ID'], href: '#', element: 'button-link', animateTransition: true, },
                { content: ['', 'I’ll input my details manually'], href: '/auth/v2/register_id_verification', element: 'text-link' }
            ],
            footer: [
                // { id: 'submit', content: ['NEXT'], element: 'modal-submit-button' },
                { id: 'back', element: 'modal-back' }
            ]
        },
        {
            id: 'register_id_verification',
            header: 'Create Account',
            progress: 100,
            form: [
                { content: 'ID Verification', element: 'modal-title' },
                { content: ['Complete and verify the following information'], element: 'modal-description'},
                { id: 'first_name', content: ['First Name', 'Thomas'], element: 'input', required: true, defaultValue: window.localStorage.getItem(USER_NAME_KEY) ? window.localStorage.getItem(USER_NAME_KEY)?.split(' ')[1] : '' },
                { id: 'last_name', content: ['Last Name', 'A. Anderson'], element: 'input', required: true, defaultValue: window.localStorage.getItem(USER_NAME_KEY) ? `${window.localStorage.getItem(USER_NAME_KEY)?.split(' ')[0]}` : '' },
                { id: 'gender', content: ['Gender', 'Choose your gender'], selectors: ['Male', 'Female'], element: 'select', required: true, ...(window.localStorage.getItem(USER_GENDER_KEY) ? { defaultValue: window.localStorage.getItem(USER_GENDER_KEY) } : {}) },
                { id: 'dob', content: ['Date of birth', 'Choose your date of birth'], element: 'input-date', required: true, ...(window.localStorage.getItem(USER_DOB_KEY) != null ? { defaultValue: window.localStorage.getItem(USER_DOB_KEY) } : {}) },
                { id: 'country', content: ['Country of birth', 'Choose your country of birth'], selectors: getCountries(), element: 'select', required: true, ...(window.localStorage.getItem(USER_NATIONALITY_KEY) ? { defaultValue: window.localStorage.getItem(USER_NATIONALITY_KEY) } : {}) },
                { content: ['Wrong info?', 'Click here to rescan ID'], href: '/auth/v2/register_verify_identity_emirates_id', stickToBottom: true, element: 'text-link', height: '100%' }
            ],
            footer: [
                { id: 'submit', content: ['REGISTER'], style: 'bso', element: 'modal-submit-button' },
                { id: 'back', element: 'modal-back' }
            ]
        },
        {
            id: 'register_end_landlord',
            form: [
                {
                    content: [
                        'Congratulations!',
                        'You have successfully created an account. Start adding your unit to unlock seamless management with just a few clicks!'
                    ],
                    src: '/assets/media/auth/final/party.png',
                    height: '100%',
                    element: 'info-block'
                }
            ],
            footer: [
                { id: 'submit', content: ['ADD UNIT'], style: 'bso', element: 'modal-submit-button' },
                { id: 'reset', content: ['Maybe later'], style: 'text', element: 'modal-reset-button' },
                { id: 'close', element: 'modal-close' }
            ]
        },
        {
            id: 'register_end_broker',
            form: [

            ],
            footer: [
                { id: 'close', element: 'modal-close' }
            ]
        },
        {
            id: 'register1',
            header: true,
            form: [
                { content: ['Create new account'], element: 'header' },
                { element: 'steps', title: 'Step 1', description: 'Register your mobile number', step: 1 },
                { id: 'phone', content: ['Mobile', '+971501001110'], element: 'input-phone', required: true }

            ],

            footer: [
                { id: 'submit', content: ['Get OTP'], element: 'button-primary', for: 'auth' },
                { id: 'to_login', content: ['Already have an account?', ' Log in', '/auth/login'], element: 'header-link', for: 'auth' }
            ]
        },
        {
            id: 'phone_verify',
            header: true,
            form: [
                { content: ['Create new account'], element: 'header' },
                { element: 'steps', title: 'Step 2', description: 'OTP verification', step: 2, isShowHint: true },
                { id: 'description_phone' , element : "header-phone" },
                { id: 'to_register', content: ['Did you enter the wrong number?', 'Update now', '/auth/register1'], element: 'header-link' },
                { id: 'code', content: ['Type your 6 digit OTP', '123456'], element: 'input-code', required: true }
                // { id: 'custom_input_code', content: ['Type your 6 digit OTP', '123456'], required: true },

            ],
            footer: [
                { id: 'submit', content: ['Submit'], element: 'button-primary', for: 'auth' },
                { id: 'recent', content: ['Resend OTP', 'phone'], element: 'button-primary', for: 'auth', renew: false },
                { id: 'to_login', content: ['Already have an account?', ' Log in', '/auth/login'], element: 'header-link', for: 'auth' }
            ]
        },
        {
            id: 'register2',
            header: true,
            form: [
                { content: ['Create new account'], element: 'header' },
                { element: 'steps', title: 'Step 3', description: 'Complete the form', step: 3 },
                //  { id: 'to_login', content: ['Already have an account?', 'Sign in here', '/auth/login'], element: 'header-link' },
                //  { content: ['Step 2: Complete the form'], element: 'label-primary' },
                { id: 'type', content: ['I\'m a', 'Broker'], selectors: ['Broker'], defaultValue: 'Broker', element: 'select', required: true },
                // , 'Landlord'
                { id: 'email', content: ['Email', 'whiterabbit@follow.me'], element: 'input-email', required: true, autocomplete: 'username' }, // autocomplete: 'email'
                { id: 'password', content: ['Password', 'Pa5$w0rd'], element: 'input-password', required: true, autocomplete: true, passwordType: 'new-password' },
                { id: 'password-confirm', content: ['Confirm Password', 'Pa5$w0rd'], element: 'input-password-confirm', required: true, autocomplete: true, passwordType: 'new-password' },
                { id: 'toc', content: ['I Accept the', 'Terms & Conditions', 'Privacy Policy'], element: 'input-checkbox', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Register'], element: 'button-primary', for: 'auth' },
                { id: 'to_login', content: ['Already have an account?', ' Log in', '/auth/login'], element: 'header-link', for: 'auth' }
            ]
        },
        {
            id: 'register_scan_passport',
            header: true,
            form: [
                { content: ['Create new account'], element: 'header' },
                { element: 'steps', title: 'Final step', description: 'Complete your registration by uploading your Passport', step: 4 },
                { id: 'segment_doc_type', type: 'Passport', redirect: '/auth/register_scan_emirates_front' },
                { id: 'visa', content: ['Passport'], element: 'input-file', required: true },
                { id: 'imagePaceholder', element: 'visa' },
                { id: 'write_fields_way', content: ['To fill in manually or upload later', 'Click here', '/auth/write_yourself_fields'], element: 'header-link' }
            ],
            footer: [
                { id: 'submit', content: ['Get Started'], element: 'button-primary', for: 'auth' },
                { id: 'to_login', content: ['Already have an account?', ' Log in', '/auth/login'], element: 'header-link', for: 'auth' }
            ]
        },
        {
            id: 'register_upload_poa',
            header: true,
            form: [
                { content: ['Create new account'], element: 'header' },
                // { id: 'to_login', content: ['Already have an account?', ' Log in', '/auth/login'], element: 'header-link' },
                //  { content: ['Final step: Complete your registration by uploading your Passport'], element: 'label-primary' },
                { element: 'steps', title: 'As the landlord\'s representative', description: 'Upload POA document', step: 7, countSteps: 7 },
                // { id: 'first_scan_way', content: ['Upload Emirates ID', 'Click here', '/auth/register_scan_emirates_front'], element: 'header-link' },
                { id: 'poa', content: ['Power Of Attorney'], element: 'input-file', required: true },
                { id: 'imagePaceholder', element: 'visa' },
                // { id: 'write_fields_way', content: ['To fill in manually or upload later', 'Click here', '/auth/write_yourself_fields'], element: 'header-link' }
            ],

            footer: [
                { id: 'submit', content: ['Next step'], element: 'button-primary', for: 'auth' },
                { id: 'to_login', content: ['Already have an account?', ' Log in', '/auth/login'], element: 'header-link', for: 'auth' }

            ]
        },
        {
            id: 'register_choose_landlord_type',
            header: true,
            form: [
                { content: ['Create new account'], element: 'header' },
                { element: 'steps', title: 'Select', description: 'Select whether you are a landlord or landlord\'s representative', step: 6, countSteps: 7 },
                { id: 'landlord_type', content: ['I\'m a', 'Landlord'], element: 'select', selectors: ['Landlord', 'Representative'], required: true },
                { id: 'imagePaceholder', element: 'visa' },
                // { id: 'write_fields_way', content: ['To fill in manually or upload later', 'Click here', '/auth/write_yourself_fields'], element: 'header-link' }
            ],
            footer: [
                { id: 'submit', content: ['Next step'], element: 'button-primary', for: 'auth' },
                { id: 'to_login', content: ['Already have an account?', ' Log in', '/auth/login'], element: 'header-link', for: 'auth' }

            ]
        },
        {
            id: 'register_scan_emirates_front',
            header: true,
            form: [
                { content: ['Create new account'], element: 'header' },
                { element: 'steps', title: 'Final step', description: 'Complete your registration by uploading your Emirates ID front side', step: 4, countSteps: 6 },
                { id: 'segment_doc_type', type: 'Emirates ID', redirect: '/auth/register_scan_passport' },
                { id: 'id_front', content: ['Emirates ID Front'], element: 'input-file', required: true },
                { id: 'imagePaceholder', element: 'id_front' },
                { id: 'write_fields_way', content: ['To fill in manually or upload later', 'Click here', '/auth/write_yourself_fields'], element: 'header-link' } ,
            ],

            footer: [
                { id: 'submit', content: ['Scan Front Side'], element: 'button-primary', for: 'auth' },
                { id: 'to_login', content: ['Already have an account?', ' Log in', '/auth/login'], element: 'header-link', for: 'auth' }
            ]
        },

        {
            id: 'register_scan_emirates',
            header: true,
            form: [
                { content: ['Create new account'], element: 'header' },
                { element: 'steps', title: 'Final step', description: 'Complete your registration by uploading your Emirates ID back side', step: 5, countSteps: 6 },
                { id: 'segment_doc_type', type: 'Emirates ID', redirect: '/auth/register_scan_passport' },
                { id: 'id_back', content: ['Emirates ID Back'], element: 'input-file', required: true },
                { id: 'imagePaceholder', element: 'id_back' },
                { id: 'write_fields_way', content: ['To fill in manually or upload later', 'Click here', '/auth/write_yourself_fields'], element: 'header-link' } 
            ],

            footer: [
                { id: 'submit', content: ['Get Started'], element: 'button-primary', for: 'auth' },
                { id: 'to_login', content: ['Already have an account?', ' Log in', '/auth/login'], element: 'header-link', for: 'auth' }
            ]
        },
        {
            id: 'write_yourself_fields',
            header: true,
            form: [
                { content: ['Create new account'], element: 'header' },
                // { content: ['Complete and verify the following information'], element: 'label-primary' },
                { element: 'steps', title: 'Final step', description: 'Complete and verify the following information', step: 5 },
                { id: 'first_name', content: ['First Name', 'Thomas'], element: 'input', required: true, defaultValue: window.localStorage.getItem(USER_NAME_KEY) ? window.localStorage.getItem(USER_NAME_KEY)?.split(' ')[1] : '' },
                { id: 'last_name', content: ['Last Name', 'A. Anderson'], element: 'input', required: true, defaultValue: window.localStorage.getItem(USER_NAME_KEY) ? `${window.localStorage.getItem(USER_NAME_KEY)?.split(' ')[0]}` : '' },
                { id: 'company_name', content: ['Company Name', 'Meta Cortex'], element: 'input', required: false },
                { id: 'gender', content: ['Gender', 'Choose your gender'], selectors: ['Male', 'Female'], element: 'select', required: true, ...(window.localStorage.getItem(USER_GENDER_KEY) ? { defaultValue: window.localStorage.getItem(USER_GENDER_KEY) } : {}) },
                { id: 'dob', content: ['Date of birth', 'Choose your date of birth'], element: 'input-date', required: true, ...(window.localStorage.getItem(USER_DOB_KEY) ? { defaultValue: window.localStorage.getItem(USER_DOB_KEY) } : {}) },
                { id: 'country', content: ['Country of birth', 'Choose your country of birth'], selectors: getCountries(), element: 'select', required: true, ...(window.localStorage.getItem(USER_NATIONALITY_KEY) ? { defaultValue: window.localStorage.getItem(USER_NATIONALITY_KEY) } : {}) },
                // { id: 'languages', content: ['Languages', 'Choose your languages'], selectors: getLanguages(), element: 'select', multiple: true, required: false , ...(generateDefaultValueFormField(["English"])) },
                { id: 'first_scan_way', content: ['To rescan/resubmit the document', ' Click here', '/auth/register_scan_emirates_front'], element: 'header-link' }
            ],
            footer: [{ id: 'submit', content: ['Save'], element: 'button-primary', for: 'auth' }]
        },
        {
            id: 'reset_password_way_email',
            header: true,
            form: [
                { content: ['First step password reset'], element: 'header' },
                // { id: 'to_login', content: ['Already got your password?', 'Sign in here', '/auth/login'], element: 'header-link' },
                { id: 'to_reset_way', content: ['Verify Method', 'by phone', '/auth/reset_password_way_phone'], element: 'header-link' },
                // { id: 'reset_way', content: ['Verify Method', 'Choose verify by email/phone'], selectors: ['Email', 'Phone'], element: 'select' },
                { id: 'email', content: ['Email', 'whiterabbit@follow.me'], element: 'input-email', required: true }

            ],
            footer: [
                { id: 'submit', content: ['Get OTP'], element: 'button-primary', for: 'auth' },
                { id: 'to_login', content: ['Already remember old password?', 'Sign in here', '/auth/login'], element: 'header-link', for: 'auth' }
            ]
        },

        {
            id: 'reset_password_way_phone',
            header: true,
            form: [
                { content: ['First step password reset'], element: 'header' },
                { id: 'reset_way', content: ['Verify Method', 'by email', '/auth/reset_password_way_email'], element: 'header-link' },
                { id: 'phone', content: ['Mobile', '+971501001110'], element: 'input-phone', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Get OTP'], element: 'button-primary', for: 'auth' },
                { id: 'to_login', content: ['Already remember old password?', 'Sign in here', '/auth/login'], element: 'header-link', for: 'auth' }
            ]
        },
        {
            id: 'password_verify',
            header: true,
            form: [
                { content: ['Second step password reset by phone'], element: 'header' },
                { id: 'description_phone' , element : "header-phone"},
                { id: 'code', content: ['Type your 6 digit OTP', '123456'], element: 'input-code', required: true },
                { id: 'to_register', content: ["Haven't received the code?", 'Resend it', 'phone'], element: 'header-onclick' }
               
            ],
            footer: [
                { id: 'submit', content: ['Submit'], element: 'button-primary', for: 'auth' },
                { id: 'to_login', content: ['Already remember old password?', 'Sign in here', '/auth/login'], element: 'header-link', for: 'auth' }
            ]
        },
        {
            id: 'password_verify_email',
            header: true,
            form: [
                { content: ['Second step password reset by email'], element: 'header' },
                { id: 'mail', element: 'header-mail' },
                { id: 'code', content: ['Type your 6 digit OTP', '123456'], element: 'input-code', required: true },
                { id: 'to_register', content: ["Haven't received the code?", 'Resend it', 'email'], element: 'header-onclick' }
            
            ],
            footer: [
                { id: 'submit', content: ['Submit'], element: 'button-primary', for: 'auth' },
                { id: 'to_login', content: ['Already remember old password?', 'Sign in here', '/auth/login'], element: 'header-link', for: 'auth' }
            ]
        },
        {
            id: 'reset_password_final',
            header: true,
            form: [
                { content: ['Final step password reset by mobile'], element: 'header' },
                { id: 'password', content: ['Password', 'Pa5$w0rd'], element: 'input-password', required: true },
                { id: 'password-confirm', content: ['Confirm Password', 'Pa5$w0rd'], element: 'input-password-confirm', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Reset password'], element: 'button-primary', for: 'auth' },
                { id: 'to_login', content: ['Come to login?', 'Click  here', '/auth/login'], element: 'header-link', for: 'auth' }
            ]
        },

        {
            id: 'reset_password_final_email',
            header: true,
            form: [
                { content: ['Final step password reset by email'], element: 'header' },
                { id: 'password', content: ['Password', 'Pa5$w0rd'], element: 'input-password', required: true },
                { id: 'password-confirm', content: ['Confirm Password', 'Pa5$w0rd'], element: 'input-password-confirm', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Reset password'], element: 'button-primary', for: 'auth' },
                { id: 'to_login', content: ['Come to login?', 'Click  here', '/auth/login'], element: 'header-link', for: 'auth' }
            ]
        },
        // email change
        {
            id: 'change_email_for_contacts_my',
            header: 'Change email address',
            form: [
                { content: ['Enter a new email address'], element: 'label-primary' },
                { id: 'email', content: ['Email', 'whiterabbit@follow.me'], element: 'input-email', required: true }
            ],
            footer: [
                { id: 'close', content: ['Cancel'], element: 'button-secondary', redirect: '/agent/settings', attention: true },
                { id: 'submit', content: ['Change email'], element: 'button-primary', for: 'auth' }
            ]
        },
        {
            id: '',
            header: 'Upload required document',
            form: [
                { content: ['Enter a new email address'], element: 'label-primary' },
                { id: 'email', content: ['Email', 'whiterabbit@follow.me'], element: 'input-file', required: true }
            ],
            footer: [
                { id: 'close', content: ['Cancel'], element: 'button-secondary', redirect: '/agent/settings', attention: true },
                { id: 'submit', content: ['Change email'], element: 'button-primary', for: 'auth' }
            ]
        },
        {
            id: 'agent_to_bso_make_offer_upload_custom_documents',
            header: 'Upload required document',
            form: [
                { id: 'custom_document', content: ['Upload document', 'Upload file'], element: 'input-file', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        
        {
            id: 'change_email_for_contacts_my_check_otp',
            header: 'Change email address',
            form: [
                { content: ['Enter the verification code we sent to phone'], element: 'header' },
                { id: 'description_phone', element : "header-phone" },
                { id: 'code', content: ['Type your 6 digit OTP', '123456'], element: 'input-code', required: true },
                { id: 'to_register', content: ["Haven't received the code?", 'Resend it', 'phone'], element: 'header-onclick', renew: true }
            ],
            footer: [
                { id: 'close', content: ['Back'], element: 'button-secondary', attention: true },
                { id: 'submit', content: ['Submit'], element: 'button-primary', for: 'auth' }
            ]
        },

        // number change

        {
            id: 'change_phone_for_contacts_my',
            header: 'Change phone number',
            form: [
                { content: ['Enter a new phone number'], element: 'label-primary' },
                { id: 'phone', content: ['Mobile', '+971501001110'], element: 'input-phone', required: true }
            ],
            footer: [
                { id: 'close', content: ['Cancel'], element: 'button-secondary', redirect: '/agent/settings', attention: true },
                { id: 'submit', content: ['Change phone'], element: 'button-primary', for: 'auth' }
            ]
        },

        {
            id: 'change_phone_for_contacts_my_check_otp',
            header: 'Change phone number',
            form: [
                // { content: ['Enter the verification code we sent to email'], element: 'header' },
                { id: 'mail', element: 'header-mail' },
                { id: 'code', content: ['Type your 6 digit OTP', '123456'], element: 'input-code', required: true },
                { id: 'to_register', content: ["Haven't received the code?", 'Resend it', 'email'], element: 'header-onclick', renew: true }

            ],
            footer: [
                { id: 'close', content: ['Back'], element: 'button-secondary', attention: true },
                { id: 'submit', content: ['Submit'], element: 'button-primary', for: 'auth' }
            ]
        },
        {
            id: 'change_phone_for_contacts_my_confirm',
            header: 'Change phone number',
            form: [
                { content: ['Enter the verification code we sent to phone'], element: 'header' },
                // { id: 'phone', element: 'header-phone' },
                { id: 'description_phone', element : "header-phone" },
                { id: 'code', content: ['Type your 6 digit OTP', '123456'], element: 'input-code', required: true },
                { id: 'to_register', content: ["Haven't received the code?", 'Resend it', 'phone'], element: 'header-onclick', renew: false }
            ],
            footer: [
                { id: 'close', content: ['Back'], element: 'button-secondary', attention: true },
                { id: 'submit', content: ['Submit'], element: 'button-primary', for: 'auth' }
            ]
        },
        {
            id: 'change_phone_for_contacts_my_phone_verify',
            header: 'Change phone number',
            form: [
                { content: ['Confirm your new phone'], element: 'header' },
                { id: 'code', content: ['Type your 6 digit OTP', '123456'], element: 'input-code', required: true }

                // '/auth/reset_password_way_phone' element: 'header-link'
            ],
            footer: [
                { id: 'close', content: ['Cancel'], element: 'button-secondary', redirect: '/agent/settings', attention: true },
                { id: 'submit', content: ['Confirm phone'], element: 'button-primary', for: 'auth' }
            ]
        },
        /// PASSWORD CHANGE /////////////////////////////////////////
        {
            id: 'my_profile_reset_password_way_email',
            header: 'Change password',
            form: [
                { content: ['First step: Please choose the way to verify your actions'], element: 'header' },
                { id: 'to_reset_way', content: ['To get verification OTP by phone number', 'Click here', `/${(store.getState().user.currentUser as User)?.Category?.toLowerCase()}/modal/my_profile_reset_password_way_phone/not/not`], element: 'header-link' },
                { id: 'email', content: ['Email', 'whiterabbit@follow.me'], element: 'input-email', required: true }

            ],
            footer: [
                { id: 'close', content: ['Back'], element: 'button-secondary', redirect: '/agent/settings', attention: true },
                { id: 'submit', content: ['Get OTP'], element: 'button-primary', for: 'auth' }
            ]
        },

        {
            id: 'my_profile_reset_password_way_phone',
            header: 'Change password',
            form: [
                { content: ['First step: Please choose the way to verify your actions'], element: 'header' },
                { id: 'reset_way', content: ['To get verification OTP by email', 'Click here', `/${(store.getState().user.currentUser as User)?.Category?.toLowerCase()}/modal/my_profile_reset_password_way_email/not/not`], element: 'header-link' },
                { id: 'phone', content: ['Mobile', '+971501001110'], element: 'input-phone', required: true }
            ],
            footer: [
                { id: 'close', content: ['Back'], element: 'button-secondary', redirect: '/agent/settings', attention: true },
                { id: 'submit', content: ['Get OTP'], element: 'button-primary', for: 'auth' }
            ]
        },
        {
            id: 'my_profile_password_verify_phone',
            header: 'Change password',
            form: [
                { content: ['Second step: Please verify your actions'], element: 'header' },
                { id: 'description_phone', element : "header-phone" },
                { id: 'code', content: ['Type your 6 digit OTP', '123456'], element: 'input-code', required: true },
                { id: 'to_register', content: ["Haven't received the code?", 'Resend it', 'phone'], element: 'header-onclick' }
            ],
            footer: [
                { id: 'close', content: ['Back'], element: 'button-secondary', attention: true },
                { id: 'submit', content: ['Submit'], element: 'button-primary', for: 'auth' }
            ]
        },
        {
            id: 'my_profile_password_verify_email',
            header: 'Change password',
            form: [
                { content: ['Second step: Please verify your actions'], element: 'header' },
                { id: 'mail', element: 'header-mail' },
                { id: 'code', content: ['Type your 6 digit OTP', '123456'], element: 'input-code', required: true },
                { id: 'to_register', content: ["Haven't received the code?", 'Resend it', 'email'], element: 'header-onclick' }
            ],
            footer: [
                { id: 'close', content: ['Back'], element: 'button-secondary', attention: true },
                { id: 'submit', content: ['Submit'], element: 'button-primary', for: 'auth' }
            ]
        },
        {
            id: 'my_profile_reset_password_final_phone',
            header: 'Change password',
            form: [
                { content: ['Final step: Set the new password'], element: 'header' },
                { id: 'password', content: ['Password', 'Pa5$w0rd'], element: 'input-password', required: true },
                { id: 'password-confirm', content: ['Confirm Password', 'Pa5$w0rd'], element: 'input-password-confirm', required: true }
            ],
            footer: [
                { id: 'close', content: ['Back'], element: 'button-secondary', redirect: '/agent/settings', attention: true },
                { id: 'submit', content: ['Submit'], element: 'button-primary', for: 'auth' }
            ]
        },
        {
            id: 'my_profile_reset_password_final_email',
            header: 'Change password',
            form: [
                { content: ['Final step: Set the new password'], element: 'header' },
                { id: 'password', content: ['Password', 'Pa5$w0rd'], element: 'input-password', required: true },
                { id: 'password-confirm', content: ['Confirm Password', 'Pa5$w0rd'], element: 'input-password-confirm', required: true }
            ],
            footer: [
                { id: 'close', content: ['Back'], element: 'button-secondary', redirect: '/agent/settings', attention: true },
                { id: 'submit', content: ['Submit'], element: 'button-primary', for: 'auth' }
            ]
        },
        ///

        {
            id: 'agent_avatar',
            header: 'Change your profile picture',
            form: [

                { id: 'avatar', content: ['Avatar'], element: 'input-file', required: true  }

            ],
            footer: [
                { id: 'delete', content: ['Delete'], objectDelet: 'avatar' },
                { id: 'submit', content: ['Save changes'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },

        /// ///// for viewings//////////////////////////////////////////////////////////////////////////////
        {
            id: 'scan_qrcode',
            header: 'Scan QR Code',
            form: [
                { content: ['Scan the QR-code provided at reception'], element: 'label-primary' },
                { id: 'img_scan', content: ['QR Code'], element: 'input-file', required: true },
                { id: 'separator', element: 'separator' },
                { id: 'imagePaceholder', element: 'scan_qr' }
            ],
            footer: [
                { id: 'submit', content: ['Save changes'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },

        {
            id: 'set_time_for_take_keys_modal',
            header: 'Change time for viewing',
            form: [
                { id: 'pick-up-date', content: ['Pick up date keys', 'Choose time'], element: 'input-date-time-range', required: true },
                { id: 'pick-out-date', content: ['Pick up out keys', 'Choose time'], element: 'input-date-time-range', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save time picks'], element: 'button-primary' }
                // { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },

        {
            id: 'set_newtime_viewing_modal',
            header: 'Change time for viewing',
            form: [
                { id: 'date', content: ['Date of viewing', 'Choose date of viewing'], element: 'input-date' },
                { id: 'time', content: ['Time of viewing'], element: 'input-time' },
                { id: 'datetime', content: ['Custom datetime of viewing'], element: 'input-date' },
                { element: 'separator' },
                { id: 'marketing_headline', element: 'label-primary' },
                { id: 'images_slider', element: 'slider-images' }
            ],
            footer: [
                { id: 'submit', content: ['Suggest new time'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'residential_offer_process_modal',
            header: 'Offer process',
            form: [
                { id: 'date_end', content: ['Date to end this process', 'Choose date'], element: 'input-date' },
                { id: 'deposit', content: ['Offer deposit', 'AED', 'Input amount'], element: 'input-group' },
                { id: 'check', content: ['I already sent Booking Form to tenant'], element: 'check' }
            ],
            footer: [
                { id: 'submit', content: ['Send to agent'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'commercial_offer_process_modal',
            header: 'Offer process',
            form: [
                { id: 'date_end', content: ['Date to end this process', 'Choose date'], element: 'input-date' },
                { id: 'deposit', content: ['Offer deposit', 'AED', 'Input amount'], element: 'input-group' },
                { id: 'check', content: ['I already sent Booking Form to tenant'], element: 'check' }
            ],
            footer: [
                { id: 'submit', content: ['Send to agent'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'sale_offer_process_modal',
            header: 'Offer process',
            form: [
                { id: 'date_end', content: ['Date to end this process', 'Choose date'], element: 'input-date' }
            ],
            footer: [
                { id: 'submit', content: ['Send to agent'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'agent_unit_filters_modal',
            header: 'Search filters',

            form: [
                //  required: true
                { id: '>Property_Area', content: ['Areas', 'Choose areas'], selectors: getAreas(), multiple: true, element: 'select' },
                { id: '>Type', content: ['Property Type', 'Choose property type'], selectors: getPropertyType(), multiple: true, element: 'select' },
                { id: 'FROM-Marketing_Price', content: ['Property price from'], element: 'input' },
                { id: 'UPTO-Marketing_Price', content: ['Property price up to'], element: 'input' },
                { id: 'FROM-SizeArea', content: ['Size from (Sq.Ft)'], element: 'input' },
                { id: 'UPTO-SizeArea', content: ['Size to (Sq.Ft)'], element: 'input' },
                /* { id: 'price_min', content: ['Property Price Min', 'Input minimum property price'], element: 'input' },
                // { id: 'price_max', content: ['Property Price Max', 'Input maximum property price'], element: 'input' }, */
                // { id: 'Bedrooms', content: ['Bedrooms'], element: 'range', min: 1, max: 10 },
                { id: 'Bedrooms', content: ['Bedrooms'], element: 'inline-select', selectors: [{ label: 'Studio', value: 0 }, { label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }, { label: '6+', value: 'MORE-5' }] },
                /* { id: 'beds_min', content: ['Bedrooms Min', 'Input minimum property price'], element: 'input' },
                // { id: 'beds_max', content: ['Bedrooms Max', 'Input maximum property price'], element: 'input' }, */
                // { id: 'Bathrooms', content: ['Baths'], element: 'range', min: 1, max: 10 },
                { id: 'Bathrooms', content: ['Baths'], element: 'inline-select', selectors: [{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }, { label: '6+', value: 'MORE-5' }] }
                /* { id: 'baths_min', content: ['Bathrooms Min', 'Input minimum property price'], element: 'input' },
                // { id: 'baths_max', content: ['Bathrooms Max', 'Input maximum property price'], element: 'input' }, */
            ],
            footer: [
                { id: 'reset', content: ['Clear'], element: 'button-primary' },
                { id: 'submit', content: ['Search'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },

        {
            id: 'add_card_modal',
            header: 'Add new card',
            form: [
                { id: 'name_card', content: ['Name on card', 'Ahmed Al Aziz'], element: 'input', required: true }, // defaultValue: window.localStorage.getItem(USER_NAME_KEY) ? window.localStorage.getItem(USER_NAME_KEY)?.split(' ')[1] : ''
                { id: 'namber_card', content: ['Card number', '2132 1232 1232 ****'], element: 'input', required: true },
                { id: 'cvv_card', content: ['CVV', '***'], element: 'input', required: true },
                { id: 'expiration_day_card', content: ['Expiration Date', 'Choose expiry date card'], selectors: ['1', '2', '3', '4', '5', '6'], element: 'select', required: true },
                { id: 'expiration_mounth_card', content: ['Expiration Year', 'Choose expiry date card'], selectors: ['2023', '2024', '2025', '2026', '2027'], element: 'select', required: true } // ...(window.localStorage.getItem(USER_NATIONALITY_KEY) ? { defaultValue: window.localStorage.getItem(USER_NATIONALITY_KEY) } : {})
            ],
            footer: [
                { id: 'submit', content: ['Add'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },

        // =============>>>>>>>>>>>>>>>> BROADCAST MODALS <<<<<<<<<<<<===========
        

        {
            id: 'broadcast_modal',
            header: 'Set Broadcast',
            form: [
                // { content: ['Choose Broadcast period'], element: 'label-primary' },
                { id: 'broadcast_create_instruction' }

                //  { id: 'broadcast_create_duration', content: ['Duration of broadcast', 'Choose duration of broadcast'], element: 'broadcast_create_duration', required: true }

            ],
            footer: [
             
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },

        {
            id: 'agent_set_broadcast',
            header: 'Set Broadcast',
            form: [
                // { content: ['Choose Broadcast period'], element: 'label-primary' },
                { id: 'broadcast_create_instruction' }

                //  { id: 'broadcast_create_duration', content: ['Duration of broadcast', 'Choose duration of broadcast'], element: 'broadcast_create_duration', required: true }

            ],
            footer: [
                { id: 'open_list', content: ['Buy bricks'], element: 'button-primary' },
                { id: 'submit', content: ['Create Broadcast'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },

        // =============>>>>>>>>>>>>>>>> ADVERTISE MODALS <<<<<<<<<<<<===========

        {
            id: 'agent_advertise_create_duration',
            header: 'Create Advertisement',
            form: [
                { id: 'advertise_create_duration', content: ['Duration of advertisement', 'Choose duration of advertisement'], element: 'advertise_create_duration', required: true }
            ],
            footer: [
                { id: 'open_list', content: ['Buy bricks'], element: 'button-primary' },
                // { id: 'submit', content: ['Create'], element: 'button-primary' },
                { id: 'submit', content: ['Set Duration'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        // {
        //     id: 'agent_advertise_extend_duration',
        //     header: 'Extend Advertisement',
        //     form: [
        //         { id: 'advertise_create_duration', content: ['Duration of advertisement', 'Choose duration of advertisement'], element: 'advertise_create_duration', required: true }
        //     ],
        //     footer: [
        //         { id: 'open_list', content: ['Buy bricks'], element: 'button-primary' },
        //         // { id: 'submit', content: ['Create'], element: 'button-primary' },
        //         { id: 'submit', content: ['Set Duration'], element: 'button-primary' },
        //         { id: 'close', content: ['Close'], element: 'button-secondary' }
        //     ]
        // },
        {
            id: 'agent_advertise_create_links',
            header: 'Set Trakheesi IDs to your published advertisements',
            form: [

                { id: 'advertise_create_links', content: ['ADVERTISEMENTS Trakheesi IDs'], element: 'advertise_create_links', required: true }

            ],
            footer: [
                // { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'submit_antd', content: ['Save'] },
                { id: 'close', content: ['Close'], element: 'button-secondary' } // , attention: true, redirect: -2
                // redirect: -2
            ]
        },
        {
            id: 'agent_advertise_create_start_date',
            header: 'Create Advertisement - Add start date',
            form: [
                { id: 'advertise_create_start_date', content: ['Start Date', 'Choose Start Date of advertisement'], required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },

        {
            id: 'agent_advertise_send_form',
            header: 'Send A-Form',
            form: [
                { id: 'a_form', content: ['A-Form'], element: 'input-file', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Send'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },

        // =============>>>>>>>>>>>>>>>> MAIN MODALS <<<<<<<<<<<<===========
        {
            id: 'unit_modal',
            header: 'Property Details',
            form: [
                // { id: 'marketing_headline', element: 'label-primary' },
                // { id: 'images_slider', element: 'slider-images' },
                { id: 'Marketing.Use', content: ['Marketing type'], element: 'label-secondary', format: 'uppercase', icon: 'real_estate_agent' },
                { id: 'Property.Area', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                { id: 'Property.Type', content: ['Property type'], element: 'label-secondary', icon: 'list' },
                { id: 'unit_type', content: ['Unit type'], element: 'label-secondary', icon: 'list' },
                { id: 'Type', content: ['Unit category'], element: 'label-secondary', icon: 'list' },
                // { id: 'Property.Community', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                { id: 'Property.Name', content: ['Property name'], element: 'label-secondary', icon: 'home' },
                { id: 'Number', content: ['Unit number'], element: 'label-secondary', icon: 'door_front' },
                { id: 'Bedrooms', content: ['Number of Bedrooms'], element: 'label-secondary', icon: 'bed' },
                { id: 'Bathrooms', content: ['Number of Bathrooms'], element: 'label-secondary', icon: 'bathtub' },
                { id: 'GuestBathroom', content: ['Unit with guest bathroom'], element: 'label-secondary', icon: 'bathtub' },
                { id: 'MaidsRoom', content: ['Unit with maids room'], element: 'label-secondary', icon: 'bed' },
                { id: 'Utilities.ACCount', content: ['Number of A/c units installed'], element: 'label-secondary', icon: 'ac_unit' },
                { id: 'SizeArea', content: ['Unit Area', 'Sqr. Ft.'], element: 'label-secondary', format: 'number', icon: 'square_foot' },
                { id: 'Fit', content: ['Interior finishing'], element: 'features-list', icon: 'chair' },
                { id: 'View', content: ['View from the property'], element: 'label-secondary', icon: 'landscape' },
                { id: 'Features', content: ['Outdoor Features'], element: 'features-list', icon: 'check_circle' },
                { id: 'Amenities', content: ['Amenities Available'], element: 'features-list', icon: 'fitness_center' },
                { id: 'Appliances', content: ['Appliances Included'], element: 'features-list', icon: 'kitchen' },
                { id: 'Marketing.Price', content: ['Asking Price'], element: 'label-secondary', format: 'price', icon: 'monetization_on' },
                { id: 'Marketing.Checks', content: ['Preferable number of payments'], element: 'label-secondary', icon: 'monetization_on' },
                { id: 'Statuses:Vacant,Occupied', content: ['Availability status'], element: 'label-secondary', icon: 'door_open' },
                { id: 'OccupiedUntil', content: ['Occupied until'], element: 'label-secondary' },
                { id: 'CurrentRent', content: ['Current rent'], element: 'label-secondary', format: 'price', icon: 'monetization_on' },
                { id: 'AnnualServiceCharge', content: ['Annual service charge'], element: 'label-secondary', format: 'price', icon: 'build_circle' },
                { id: 'LegalNoticeServed', content: ['Legal notice served'], element: 'label-secondary', icon: 'description' },
                { id: 'DateServed', content: ['Date Served'], element: 'label-secondary', icon: 'description' },
                // { id: 'AdvertisePrice', content: ['Advertisement price', 'Bricks'], element: 'label-secondary', icon: 'license' },
                { id: 'Statuses>Mortgage', content: ['Mortgaged Property'], element: 'label-secondary', icon: 'credit_card' },
                { id: 'promotion_info', content: ['Promotion Info'], element: 'label-secondary', icon: 'sell' }
                // { id: 'ImportantNotice', content: ['Important Notice'], element: 'label-secondary', icon: 'info' }
                // { id: 'door_open', contsent: ['Door open/close'], element: 'label-secondary' },
                // { id: 'Street', content: ['Property Street'], element: 'label-secondary', icon: 'location_on' },
            ],
            footer: [
                // element: 'button-primary'
                { id: 'advertise', content: ['Advertise'], element: 'button-dark' },
                { id: 'book_viewing', content: ['Book Viewing'], element: 'button-white' },
                { id: 'make_offer', content: ['Make Offer'], element: 'button-primary' },
                // { id: 'landlord_geo', content: ['Get location'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', withIcon: true }
            ]

        },
        {
            id: 'viewing_modal',
            header: 'Event Details',
            viewContent: [
                { content: ['Viewing'], element: 'label-primary' },
                { id: 'status', content: ['Viewing status'], element: 'label-secondary' },
                { id: 'date', content: ['Viewing date'], element: 'label-secondary' },
                { id: 'landlord_geo', content: ['Get unit location', 'Open link in Google Maps'], element: 'label-button-geo' },
                { element: 'separator' }
            ],
            form: [
                { id: 'Property.Area', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                // { id: 'Property.Community', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                { id: 'Property.Name', content: ['Property name'], element: 'label-secondary', icon: 'home' },
                { id: 'Number', content: ['Unit number'], element: 'label-secondary', icon: 'door_front' },
                { id: 'Property.Type', content: ['Property type'], element: 'label-secondary', icon: 'list' },
                { id: 'Marketing.Use', content: ['Marketing type'], element: 'label-secondary', icon: 'real_estate_agent' },
                { id: 'Bedrooms', content: ['Bedrooms'], element: 'label-secondary', icon: 'bed' },
                { id: 'Bathrooms', content: ['Bathrooms'], element: 'label-secondary', icon: 'bathtub' },
                { id: 'Utilities.ACCount', content: ['AC Count'], element: 'label-secondary', icon: 'ac_unit' },
                { id: 'SizeArea', content: ['Size Area', 'Sqr. Ft.'], element: 'label-secondary', icon: 'square_foot' },
                { id: 'Fit', content: ['Property Fit'], element: 'features-list', icon: 'chair' },
                { id: 'View', content: ['Property View'], element: 'label-secondary', icon: 'check_circle' },
                { id: 'Features', content: ['Property Features'], element: 'features-list', icon: 'check_circle' },
                { id: 'Amenities', content: ['Property Amenities'], element: 'features-list', icon: 'fitness_center' },
                { id: 'Appliances', content: ['Property Appliances'], element: 'features-list', icon: 'kitchen' },
                { id: 'Marketing.Price', content: ['Price', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                { id: 'Marketing.DiscountPrice', content: ['Promotion Info', 'AED'], element: 'label-secondary', icon: 'sell' },
                { id: 'Statuses:Vacant,Occupied', content: ['Availability status'], element: 'label-secondary', icon: 'door_open' },
                { id: 'OccupiedUntil', content: ['Occupied until'], element: 'label-secondary' },
                { id: 'Statuses>Mortgage', content: ['Mortgage available'], element: 'label-secondary', icon: 'credit_card' },
                { id: 'ImportantNotice', content: ['Important Notice'], element: 'label-secondary', icon: 'info' }
            ],
            footer: [
                // { id: 'edit', content: ['Edit'], element: 'button-primary' },
                // { id: 'delete', content: ['Cancel'], element: 'button-primary' },
                { id: 'landlord_view_approve', content: ['Approve'], element: 'button-primary' },
                { id: 'landlord_view_reject', content: ['Reject'], element: 'button-primary' },
                { id: 'landlord_view_resch', content: ['Reschedule'], element: 'button-primary' },
                { id: 'make_offer', content: ['Make Offer'], element: 'button-primary' },
                { id: 'view_scan_qr', content: ['Scan QR'], element: 'button-primary' },
                { id: 'view_approve', content: ['Approve'], element: 'button-primary' },
                { id: 'view_feedback', content: ['Add Feedback'], element: 'button-primary' },
                { id: 'view_resch', content: ['Reschedule'], element: 'button-primary' },
                { id: 'view_cancel', content: ['Cancel Viewing'], element: 'button-primary' },
                { id: 'view_message', content: ['Discuss'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', withIcon: true }
            ]
        },
        {
            id: 'magic_viewing_modal',
            header: 'Event Details',
            viewContent: [
                { content: ['Viewing'], element: 'label-primary' },
                { id: 'status', content: ['Viewing status'], element: 'label-secondary' },
                { id: 'date', content: ['Viewing date'], element: 'label-secondary' },
                { id: 'landlord_geo', content: ['Get unit location', 'Open link in Google Maps'], element: 'label-button-geo' },
                { element: 'separator' }
            ],
            form: [
                { id: 'Property.Area', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                // { id: 'Property.Community', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                { id: 'Property.Name', content: ['Property name'], element: 'label-secondary', icon: 'home' },
                { id: 'Number', content: ['Unit number'], element: 'label-secondary', icon: 'door_front' },
                { id: 'Property.Type', content: ['Property type'], element: 'label-secondary', icon: 'list' },
                { id: 'Marketing.Use', content: ['Marketing type'], element: 'label-secondary', icon: 'real_estate_agent' },
                { id: 'Bedrooms', content: ['Bedrooms'], element: 'label-secondary', icon: 'bed' },
                { id: 'Bathrooms', content: ['Bathrooms'], element: 'label-secondary', icon: 'bathtub' },
                { id: 'Utilities.ACCount', content: ['AC Count'], element: 'label-secondary', icon: 'ac_unit' },
                { id: 'SizeArea', content: ['Size Area', 'Sqr. Ft.'], element: 'label-secondary', icon: 'square_foot' },
                { id: 'Fit', content: ['Property Fit'], element: 'features-list', icon: 'chair' },
                { id: 'View', content: ['Property View'], element: 'label-secondary', icon: 'check_circle' },
                { id: 'Features', content: ['Property Features'], element: 'features-list', icon: 'check_circle' },
                { id: 'Amenities', content: ['Property Amenities'], element: 'features-list', icon: 'fitness_center' },
                { id: 'Appliances', content: ['Property Appliances'], element: 'features-list', icon: 'kitchen' },
                { id: 'Marketing.Price', content: ['Price', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                { id: 'Marketing.DiscountPrice', content: ['Promotion Info', 'AED'], element: 'label-secondary', icon: 'sell' },
                { id: 'Statuses:Vacant,Occupied', content: ['Availability status'], element: 'label-secondary', icon: 'door_open' },
                { id: 'OccupiedUntil', content: ['Occupied until'], element: 'label-secondary' },
                { id: 'Statuses>Mortgage', content: ['Mortgage available'], element: 'label-secondary', icon: 'credit_card' },
                { id: 'ImportantNotice', content: ['Important Notice'], element: 'label-secondary', icon: 'info' }
            ],
            footer: [
                // { id: 'edit', content: ['Edit'], element: 'button-primary' },
                // { id: 'delete', content: ['Cancel'], element: 'button-primary' },
                // { id: 'view_resch', content: ['Reschedule'], element: 'button-primary' },

                { id: 'actions', content: ['Quick actions'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'advertise_modal',
            header: 'Event Details',
            form: [
                { id: 'Property.Area', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                // { id: 'Property.Community', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                { id: 'Property.Name', content: ['Property name'], element: 'label-secondary', icon: 'home' },
                { id: 'Number', content: ['Unit number'], element: 'label-secondary', icon: 'door_front' },
                { id: 'Property.Type', content: ['Property type'], element: 'label-secondary', icon: 'list' },
                { id: 'Marketing.Use', content: ['Marketing type'], element: 'label-secondary', icon: 'real_estate_agent' },
                { id: 'Bedrooms', content: ['Bedrooms'], element: 'label-secondary', icon: 'bed' },
                { id: 'Bathrooms', content: ['Bathrooms'], element: 'label-secondary', icon: 'bathtub' },
                { id: 'Utilities.ACCount', content: ['AC Count'], element: 'label-secondary', icon: 'ac_unit' },
                { id: 'SizeArea', content: ['Size Area', 'Sqr. Ft.'], element: 'label-secondary', icon: 'square_foot' },
                { id: 'Fit', content: ['Property Fit'], element: 'features-list', icon: 'chair' },
                { id: 'View', content: ['Property View'], element: 'label-secondary', icon: 'check_circle' },
                { id: 'Features', content: ['Property Features'], element: 'features-list', icon: 'check_circle' },
                { id: 'Amenities', content: ['Property Amenities'], element: 'features-list', icon: 'fitness_center' },
                { id: 'Appliances', content: ['Property Appliances'], element: 'features-list', icon: 'kitchen' },
                { id: 'Marketing.Price', content: ['Price', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                { id: 'Marketing.DiscountPrice', content: ['Promotion Info', 'AED'], element: 'label-secondary', icon: 'sell' },
                { id: 'Statuses:Vacant,Occupied', content: ['Availability status'], element: 'label-secondary', icon: 'door_open' },
                { id: 'OccupiedUntil', content: ['Occupied until'], element: 'label-secondary' },
                { id: 'Statuses>Mortgage', content: ['Mortgage available'], element: 'label-secondary', icon: 'credit_card' },
                { id: 'ImportantNotice', content: ['Important Notice'], element: 'label-secondary', icon: 'info' }
            ],
            footer: [
                // { id: 'adv_extend', content: ['Extend Advertisement'] },
                { id: 'delete_add_links', content: ['Yes, I removed the ads'], element: 'button-primary' },
                { id: 'edit_links', content: ['Edit Trakheesi IDs'], element: 'button-primary' },
                { id: 'add_links', content: ['Add Trakheesi IDs'], element: 'button-primary' },
                { id: 'send_a_form', content: ['Send A-Form'], element: 'button-primary' },
                { id: 'download_docs', content: ['Download unit docs'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: -1 } // redirect: '/agent/units/nav'
            ]
        },
        {
            id: 'magic_advertise_modal',
            header: 'Event Details',
            form: [
                { id: 'Property.Area', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                // { id: 'Property.Community', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                { id: 'Property.Name', content: ['Property name'], element: 'label-secondary', icon: 'home' },
                { id: 'Number', content: ['Unit number'], element: 'label-secondary', icon: 'door_front' },
                { id: 'Property.Type', content: ['Property type'], element: 'label-secondary', icon: 'list' },
                { id: 'Marketing.Use', content: ['Marketing type'], element: 'label-secondary', icon: 'real_estate_agent' },
                { id: 'Bedrooms', content: ['Bedrooms'], element: 'label-secondary', icon: 'bed' },
                { id: 'Bathrooms', content: ['Bathrooms'], element: 'label-secondary', icon: 'bathtub' },
                { id: 'Utilities.ACCount', content: ['AC Count'], element: 'label-secondary', icon: 'ac_unit' },
                { id: 'SizeArea', content: ['Size Area'], element: 'label-secondary', icon: 'square_foot' },
                { id: 'Fit', content: ['Property Fit'], element: 'features-list', icon: 'chair' },
                { id: 'View', content: ['Property View'], element: 'label-secondary', icon: 'check_circle' },
                { id: 'Features', content: ['Property Features'], element: 'features-list', icon: 'check_circle' },
                { id: 'Amenities', content: ['Property Amenities'], element: 'features-list', icon: 'fitness_center' },
                { id: 'Appliances', content: ['Property Appliances'], element: 'features-list', icon: 'kitchen' },
                { id: 'Marketing.Price', content: ['Price'], element: 'label-secondary', icon: 'monetization_on' },
                { id: 'Marketing.DiscountPrice', content: ['Promotion Info'], element: 'label-secondary', icon: 'sell' },
                { id: 'Statuses:Vacant,Occupied', content: ['Availability status'], element: 'label-secondary', icon: 'door_open' },
                { id: 'OccupiedUntil', content: ['Occupied until'], element: 'label-secondary' },
                { id: 'Statuses>Mortgage', content: ['Mortgage available'], element: 'label-secondary', icon: 'credit_card' },
                { id: 'ImportantNotice', content: ['Important Notice'], element: 'label-secondary', icon: 'info' }
            ],
            footer: [
                { id: 'actions', content: ['Quick actions'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'offer_modal',
            header: 'Event Details',
            form: [
                {
                    id: 'residential',
                    subrow: [
                        // { content: ['Residential Offer'], element: 'label-primary' },
                        // { id: 'Statuses', content: ['Offer status'], element: 'label-secondary', icon: 'la la-flash' },
                        { id: 'FirstName', content: ['Client First Name'], element: 'label-secondary', icon: 'person' },
                        { id: 'LastName', content: ['Client Last Name'], element: 'label-secondary', icon: 'person' },
                        { id: 'Country', content: ['Client Nationality'], element: 'label-secondary', icon: 'location_on' },
                        { id: 'EmiratesID', content: ['Client Emirates ID'], element: 'label-secondary', icon: 'badge' },
                        { id: 'Occupation', content: ['Client Occupation'], element: 'label-secondary', icon: 'person' },
                        { id: 'Employer', content: ['Client Employer'], element: 'label-secondary', icon: 'work' },
                        { id: 'Occupants', content: ['Client Occupants'], element: 'label-secondary', icon: 'person' },
                        { id: 'Smoking', content: ['Client is smoker'], element: 'label-secondary', icon: 'smoking_rooms' },
                        { id: 'Pets', content: ['Client has pets'], element: 'label-secondary', icon: 'pets' },
                        { id: 'InUAESince', content: ['Client in UAE since'], element: 'label-secondary', icon: 'event_available' },
                        { id: 'UAEBank', content: ['Client Bank in UAE'], element: 'label-secondary', icon: 'credit_card' },
                        { id: 'Offer', content: ['Client Offer', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'Payments', content: ['Client Payments'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'ContractStartDate', content: ['Contract Start Date'], element: 'label-secondary', icon: 'event_available' },
                        { id: 'Duration', content: ['Contract Duration', 'Month'], element: 'label-secondary', icon: 'schedule' },
                        { id: 'AdditionalNotes', content: ['Client Additional Notes'], element: 'label-secondary', icon: 'info' },
                        { id: 'OfferConfirmation', content: ['Download the photo of deposit cheque'], element: 'label-secondary', icon: 'description' }
                        // { id: 'ContactInfo.FirstName', content: ['Agent first name'], element: 'label-secondary', icon: 'la la-user-o' },
                        // { id: 'ContactInfo.LastName', content: ['Agent last name'], element: 'label-secondary', icon: 'la la-user-o' },
                        // { id: 'Type[0].Number', content: ['Agent RERA number'], element: 'label-secondary', icon: 'las la-id-card' }
                    ],
                    element: 'display'
                },
                {
                    id: 'commercial',
                    subrow: [
                        // { content: ['Commercial Offer'], element: 'label-primary' },
                        // { id: 'Statuses', content: ['Offer status'], element: 'label-secondary', icon: 'la la-flash' },
                        { id: 'BusinessName', content: ['Business Name'], element: 'label-secondary', icon: 'work' },
                        { id: 'BusinessOwnerName', content: ['Business Owner Name'], element: 'label-secondary', icon: 'work' },
                        { id: 'BusinessActivity', content: ['Business Activity'], element: 'label-secondary', icon: 'work' },
                        { id: 'EstablishmentDate', content: ['Establishment Date'], element: 'label-secondary', icon: 'event_available' },
                        // { id: 'client_uae_since', content: ['Client in UAE since'], element: 'label-secondary' },
                        { id: 'UAEBank', content: ['Client Bank in UAE'], element: 'label-secondary', icon: 'credit_card' },
                        { id: 'Offer', content: ['Client Offer', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'Payments', content: ['Client Payments'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'ContractStartDate', content: ['Contract Start Date'], element: 'label-secondary', icon: 'event_available' },
                        { id: 'Duration', content: ['Contract Duration', 'Month'], element: 'label-secondary', icon: 'schedule' },
                        { id: 'AdditionalNotes', content: ['Client Additional Notes'], element: 'label-secondary', icon: 'info' },
                        { id: 'OfferConfirmation', content: ['Download the photo of deposit cheque'], element: 'label-secondary', icon: 'description' }
                    ],
                    element: 'display'
                },
                {
                    id: 'sale',
                    subrow: [
                        // { content: ['Sale Offer'], element: 'label-primary' },
                        // { id: 'Statuses', content: ['Offer status'], element: 'label-secondary', icon: 'la la-flash' },
                        { id: 'FirstName', content: ['Client First Name'], element: 'label-secondary', icon: 'person' },
                        { id: 'LastName', content: ['Client Last Name'], element: 'label-secondary', icon: 'person' },
                        { id: 'Offer', content: ['Client Offer', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'Type', content: ['Offer Type'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'BankName', content: ['Bank Name'], element: 'label-secondary', icon: 'credit_card' },
                        { id: 'BankPreApproval', content: ['Bank Pre-approval'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'PreApprovedAmount', content: ['Pre-Approved Amount', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'AdditionalNotes', content: ['Client Additional Notes'], element: 'label-secondary', icon: 'info' },
                        { id: 'OfferConfirmation', content: ['Download the photo of deposit cheque'], element: 'label-secondary', icon: 'description' }
                        // { id: 'mortgage', content: ['Mortgage'], element: 'label-secondary' }
                    ],
                    element: 'display'
                },
                {
                    id: 'sale-commercial',
                    subrow: [
                        { id: 'BusinessName', content: ['Business Name'], element: 'label-secondary', icon: 'work' },
                        { id: 'BusinessOwnerName', content: ['Business Owner Name'], element: 'label-secondary', icon: 'work' },
                        { id: 'BusinessActivity', content: ['Business Activity'], element: 'label-secondary', icon: 'work' },
                        { id: 'Type', content: ['Offer Type'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'EstablishmentDate', content: ['Establishment Date'], element: 'label-secondary', icon: 'event_available' },
                        { id: 'Offer', content: ['Client Offer', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'BankName', content: ['Bank Name'], element: 'label-secondary', icon: 'credit_card' },
                        { id: 'BankPreApproval', content: ['Bank Pre-approval'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'PreApprovedAmount', content: ['Pre-Approved Amount', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'AdditionalNotes', content: ['Client Additional Notes'], element: 'label-secondary', icon: 'info' },
                        { id: 'OfferConfirmation', content: ['Download the photo of deposit cheque'], element: 'label-secondary', icon: 'description' }
                    ],
                    element: 'display'
                },
                { id: 'Marketing.Headline', content: ['Marketing headline'], element: 'label-primary' },
                { id: 'Property.Area', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                // { id: 'Property.Community', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                { id: 'Property.Name', content: ['Property name'], element: 'label-secondary', icon: 'home' },
                { id: 'Number', content: ['Unit number'], element: 'label-secondary', icon: 'door_front' },
                { id: 'Property.Type', content: ['Property type'], element: 'label-secondary', icon: 'list' },
                { id: 'Marketing.Use', content: ['Marketing type'], element: 'label-secondary', icon: 'real_estate_agent' },
                { id: 'Bedrooms', content: ['Bedrooms'], element: 'label-secondary', icon: 'bed' },
                { id: 'Bathrooms', content: ['Bathrooms'], element: 'label-secondary', icon: 'bathtub' },
                { id: 'Utilities.ACCount', content: ['AC Count'], element: 'label-secondary', icon: 'ac_unit' },
                { id: 'SizeArea', content: ['Size Area', 'Sqr. Ft.'], element: 'label-secondary', icon: 'square_foot' },
                { id: 'Fit', content: ['Property Fit'], element: 'features-list', icon: 'chair' },
                { id: 'View', content: ['Property View'], element: 'label-secondary', icon: 'check_circle' },
                { id: 'Features', content: ['Property Features'], element: 'features-list', icon: 'check_circle' },
                { id: 'Amenities', content: ['Property Amenities'], element: 'features-list', icon: 'fitness_center' },
                { id: 'Appliances', content: ['Property Appliances'], element: 'features-list', icon: 'kitchen' },
                { id: 'Marketing.Price', content: ['Price', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                { id: 'Marketing.DiscountPrice', content: ['Promotion Info', 'AED'], element: 'label-secondary', icon: 'sell' },
                { id: 'Statuses:Vacant,Occupied', content: ['Availability status'], element: 'label-secondary', icon: 'door_open' },
                { id: 'OccupiedUntil', content: ['Occupied until'], element: 'label-secondary' },
                { id: 'Statuses>Mortgage', content: ['Mortgage available'], element: 'label-secondary', icon: 'credit_card' },
                { id: 'ImportantNotice', content: ['Important Notice'], element: 'label-secondary', icon: 'info' }
            ],
            footer: [
                /* { id: 'landlord_docs', content: ['Docs'], element: 'button-primary' },
                { id: 'landlord_geo', content: ['Locate'], element: 'button-primary' }, */
                { id: 'link', content: ['Edit'], element: 'button-primary' },
                { id: 'offer_action', content: ['Quick action'], element: 'button-primary' },
                // { id: 'cancel', content: ['Cancel Offer'], element: 'button-primary' },
                { id: 'offer_neg_approve', content: ['Approve'], element: 'button-primary' },
                { id: 'offer_neg', content: ['Negotiate'], element: 'button-primary' },
                // TO DO NEED TO CHECK UNIT NOT ACTUAL OR NOT < AND ALSO CHANGE FULL CONDITION
                //  { id: 'revise', content: ['Revise offer'], element: 'button-primary' },
                { id: 'upload_deposit', content: ['Upload deposit cheque copy'], element: 'button-primary' },
                { id: 'upload_client_info', content: ['Add clients\'s primary data'], element: 'button-primary' },
                { id: 'upload_custom_document', content: ['Upload'], element: 'button-primary' },
                { id: 'draft_contract_agree', content: ['Agree'], element: 'button-primary' },
                { id: 'draft_contract_change', content: ['Send changes'], element: 'button-primary' },
                { id: 'draft_contract_load', content: ['Download draft'], element: 'button-primary' },
                
                { id: 'chose_contract_type', content: ['Choose'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'magic_offer_modal',
            header: 'Event Details',
            form: [
                {
                    id: 'residential',
                    subrow: [
                        { id: 'Type', content: ['Offer Type'], element: 'label-secondary', icon: 'monetization_on' },
                        // { content: ['Residential Offer'], element: 'label-primary' },
                        // { id: 'Statuses', content: ['Offer status'], element: 'label-secondary', icon: 'la la-flash' },
                        { id: 'FirstName', content: ['Client First Name'], element: 'label-secondary', icon: 'person' },
                        { id: 'LastName', content: ['Client Last Name'], element: 'label-secondary', icon: 'person' },
                        { id: 'Country', content: ['Client Nationality'], element: 'label-secondary', icon: 'location_on' },
                        { id: 'EmiratesID', content: ['Client Emirates ID'], element: 'label-secondary', icon: 'badge' },
                        { id: 'Occupation', content: ['Client Occupation'], element: 'label-secondary', icon: 'person' },
                        { id: 'Employer', content: ['Client Employer'], element: 'label-secondary', icon: 'work' },
                        { id: 'Occupants', content: ['Client Occupants'], element: 'label-secondary', icon: 'person' },
                        { id: 'Smoking', content: ['Client is smoker'], element: 'label-secondary', icon: 'smoking_rooms' },
                        { id: 'Pets', content: ['Client has pets'], element: 'label-secondary', icon: 'pets' },
                        { id: 'InUAESince', content: ['Client in UAE since'], element: 'label-secondary', icon: 'event_available' },
                        { id: 'UAEBank', content: ['Client Bank in UAE'], element: 'label-secondary', icon: 'credit_card' },
                        { id: 'Offer', content: ['Client Offer', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'Payments', content: ['Client Payments'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'ContractStartDate', content: ['Contract Start Date'], element: 'label-secondary', icon: 'event_available' },
                        { id: 'Duration', content: ['Contract Duration', 'Month'], element: 'label-secondary', icon: 'schedule' },
                        { id: 'AdditionalNotes', content: ['Client Additional Notes'], element: 'label-secondary', icon: 'description' }
                        // { id: 'ContactInfo.FirstName', content: ['Agent first name'], element: 'label-secondary', icon: 'la la-user-o' },
                        // { id: 'ContactInfo.LastName', content: ['Agent last name'], element: 'label-secondary', icon: 'la la-user-o' },
                        // { id: 'Type[0].Number', content: ['Agent RERA number'], element: 'label-secondary', icon: 'las la-id-card' }
                    ],
                    element: 'display'
                },
                {
                    id: 'commercial',
                    subrow: [
                        { id: 'Type', content: ['Offer Type'], element: 'label-secondary', icon: 'monetization_on' },
                        // { content: ['Commercial Offer'], element: 'label-primary' },
                        // { id: 'Statuses', content: ['Offer status'], element: 'label-secondary', icon: 'la la-flash' },
                        { id: 'BusinessName', content: ['Business Name'], element: 'label-secondary', icon: 'work' },
                        { id: 'BusinessOwnerName', content: ['Business Owner Name'], element: 'label-secondary', icon: 'work' },
                        { id: 'BusinessActivity', content: ['Business Activity'], element: 'label-secondary', icon: 'work' },
                        { id: 'EstablishmentDate', content: ['Establishment Date'], element: 'label-secondary', icon: 'event_available' },
                        // { id: 'client_uae_since', content: ['Client in UAE since'], element: 'label-secondary' },
                        { id: 'UAEBank', content: ['Client Bank in UAE'], element: 'label-secondary', icon: 'credit_card' },
                        { id: 'Offer', content: ['Client Offer', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'Payments', content: ['Client Payments'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'ContractStartDate', content: ['Contract Start Date'], element: 'label-secondary', icon: 'event_available' },
                        { id: 'Duration', content: ['Contract Duration', 'Month'], element: 'label-secondary', icon: 'schedule' },
                        { id: 'AdditionalNotes', content: ['Client Additional Notes'], element: 'label-secondary', icon: 'description' }
                    ],
                    element: 'display'
                },
                {
                    id: 'sale',
                    subrow: [
                        { id: 'Type', content: ['Offer Type'], element: 'label-secondary', icon: 'monetization_on' },
                        // { content: ['Sale Offer'], element: 'label-primary' },
                        // { id: 'Statuses', content: ['Offer status'], element: 'label-secondary', icon: 'la la-flash' },
                        { id: 'FirstName', content: ['Client First Name'], element: 'label-secondary', icon: 'person' },
                        { id: 'LastName', content: ['Client Last Name'], element: 'label-secondary', icon: 'person' },
                        { id: 'Offer', content: ['Client Offer', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'BankName', content: ['Bank Name'], element: 'label-secondary', icon: 'credit_card' },
                        { id: 'BankPreApproval', content: ['Bank Pre-approval'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'PreApprovedAmount', content: ['Pre-Approved Amount', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'AdditionalNotes', content: ['Client Additional Notes'], element: 'label-secondary', icon: 'description' }
                        // { id: 'mortgage', content: ['Mortgage'], element: 'label-secondary' }
                    ],
                    element: 'display'
                },
                {
                    id: 'sale-commercial',
                    subrow: [
                        { id: 'Type', content: ['Offer Type'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'BusinessName', content: ['Business Name'], element: 'label-secondary', icon: 'work' },
                        { id: 'BusinessOwnerName', content: ['Business Owner Name'], element: 'label-secondary', icon: 'work' },
                        { id: 'BusinessActivity', content: ['Business Activity'], element: 'label-secondary', icon: 'work' },
                        { id: 'EstablishmentDate', content: ['Establishment Date'], element: 'label-secondary', icon: 'event_available' },
                        { id: 'Offer', content: ['Client Offer', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'BankName', content: ['Bank Name'], element: 'label-secondary', icon: 'credit_card' },
                        { id: 'BankPreApproval', content: ['Bank Pre-approval'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'PreApprovedAmount', content: ['Pre-Approved Amount', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                        { id: 'AdditionalNotes', content: ['Client Additional Notes'], element: 'label-secondary', icon: 'info' },
                        { id: 'OfferConfirmation', content: ['Download the photo of deposit cheque'], element: 'label-secondary', icon: 'description' }
                    ],
                    element: 'display'
                },
                // { element: 'separator' },
                { id: 'Marketing.Headline', content: ['Marketing headline'], element: 'label-primary' },
                { id: 'Property.Area', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                // { id: 'Property.Community', content: ['Location'], element: 'label-secondary', icon: 'location_on' },
                { id: 'Property.Name', content: ['Property name'], element: 'label-secondary', icon: 'home' },
                { id: 'Number', content: ['Unit number'], element: 'label-secondary', icon: 'door_front' },
                { id: 'Property.Type', content: ['Property type'], element: 'label-secondary', icon: 'list' },
                { id: 'Marketing.Use', content: ['Marketing type'], element: 'label-secondary', icon: 'real_estate_agent' },
                { id: 'Bedrooms', content: ['Bedrooms'], element: 'label-secondary', icon: 'bed' },
                { id: 'Bathrooms', content: ['Bathrooms'], element: 'label-secondary', icon: 'bathtub' },
                { id: 'Utilities.ACCount', content: ['AC Count'], element: 'label-secondary', icon: 'ac_unit' },
                { id: 'SizeArea', content: ['Size Area', 'Sqr. Ft.'], element: 'label-secondary', icon: 'square_foot' },
                { id: 'Fit', content: ['Property Fit'], element: 'features-list', icon: 'chair' },
                { id: 'View', content: ['Property View'], element: 'label-secondary', icon: 'check_circle' },
                { id: 'Features', content: ['Property Features'], element: 'features-list', icon: 'check_circle' },
                { id: 'Amenities', content: ['Property Amenities'], element: 'features-list', icon: 'fitness_center' },
                { id: 'Appliances', content: ['Property Appliances'], element: 'features-list', icon: 'kitchen' },
                { id: 'Marketing.Price', content: ['Price', 'AED'], element: 'label-secondary', icon: 'monetization_on' },
                { id: 'Marketing.DiscountPrice', content: ['Promotion Info', 'AED'], element: 'label-secondary', icon: 'sell' },
                { id: 'Statuses:Vacant,Occupied', content: ['Availability status'], element: 'label-secondary', icon: 'door_open' },
                { id: 'OccupiedUntil', content: ['Occupied until'], element: 'label-secondary' },
                { id: 'Statuses>Mortgage', content: ['Mortgage available'], element: 'label-secondary', icon: 'credit_card' },
                { id: 'ImportantNotice', content: ['Important Notice'], element: 'label-secondary', icon: 'info' }
            ],
            footer: [
                /* { id: 'landlord_docs', content: ['Docs'], element: 'button-primary' },
                { id: 'landlord_geo', content: ['Locate'], element: 'button-primary' }, */
                { id: 'actions', content: ['Quick actions'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary'/* withIcon: true */ }
            ]
        },

        {
            id: 'profile_modal',
            header: 'Profile Details',
            form: [
                { id: 'company_name', content: ['Company Name', 'Meta Cortex'], element: 'input', required: false , ...(generateDefaultValueFormField( AgentSettings?.[0]?.Company)) },
                { id: 'about_me', content: ['About Me', 'Hey there.I am using BSO Club!'],  element: 'textarea', typeArea : "forLinks", required: false ,maxLength: 300,...(generateDefaultValueFormField(userCur.About)) , regexp: regExps.checkLinks },
                // { id: 'languages', content: ['Languages', 'Choose your languages'], selectors: getLanguages(), element: 'select', multiple: true, required: false , ...(generateDefaultValueFormField( AgentSettings?.[0]?.Languages)) },
                //  { id: 'show_profile_details'},

            ], 
            footer: [
                { id: 'submit', content: ['Save changes'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
    
        {
            id: 'show_details_modal',
            header: 'Show Profile Details',
            form: [
           
                { id: 'show_profile_details'},

            ], 
            footer: [
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
    
        
      
        

        {
            id: 'agent_settings',
            header: 'Professional Preferences',
            form: [
                // { id: 'emirate', content: ['Emirate of work', 'Choose your work emirate'], selectors: ['Dubai'], element: 'select', required: false, defaultValue: AgentSettings?.at(0)?.Emirate },
                {
                    id: 'locations',
                    content: ['Preferred locations', 'Choose your preferred locations (up to 5)'],
                    selectors: allAreas,
                    element: 'select',
                    multiple: true,
                    required: false,
                    defaultValue: AgentSettings?.[0]?.Areas != null && AgentSettings[0].Areas.length > 0 ? AgentSettings?.[0]?.Areas : undefined
                }
                // { id: 'activity', content: ['Preferred activity', 'Choose your preferred activity (rent/sale)'], selectors: ['Rent', 'Sale', 'Rent & Sale'], element: 'select', required: false, defaultValue: AgentSettings?.at(0)?.Activity },
                //  { id: 'prorepty_type', content: ['Preferred prorepty type', 'You can select up to 2 property types'], selectors: getPropertyType(), multiple: true, element: 'select', required: false, defaultValue: AgentSettings?.at(0)?.Type }
            ],
            footer: [
                { id: 'submit', content: ['Save changes'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ],
            btnTitle: 'Set your preferences'
        },

        {
            id: 'agent_settings_for_subscriptions',
            header: 'Professional Preferences',
            form: [
                // { id: 'emirate', content: ['Emirate of work', 'Choose your work emirate'], selectors: ['Dubai'], element: 'select', required: false, defaultValue: AgentSettings?.at(0)?.Emirate },
                {
                    id: 'locations',
                    content: ['Preferred locations', 'Choose your preferred locations '],
                    selectors: allAreas,
                    element: 'select',
                    multiple: true,
                    required: true,
                    defaultValue: undefined
                }
                // { id: 'activity', content: ['Preferred activity', 'Choose your preferred activity (rent/sale)'], selectors: ['Rent', 'Sale', 'Rent & Sale'], element: 'select', required: false, defaultValue: AgentSettings?.at(0)?.Activity },
            //    { id: 'prorepty_type', content: ['Preferred prorepty type', 'You can select up to 2 property types'], selectors: getPropertyType(), multiple: true, element: 'select', required: true, defaultValue: AgentSettings?.at(0)?.Type }
            ],
            footer: [
                { id: 'submit', content: ['Save changes'], element: 'button-primary' }
            ]

        },
        {
            id: 'agent_settings_for_areas_extension',
            header: 'Professional Preferences',
            form: [
                // { id: 'emirate', content: ['Emirate of work', 'Choose your work emirate'], selectors: ['Dubai'], element: 'select', required: false, defaultValue: AgentSettings?.at(0)?.Emirate },
                {
                    id: 'locations_extension',
                    content: ['Preferred locations', 'Please,choose your preferred locations '],
                    selectors: allAreas,
                    element: 'select',
                    multiple: true,
                    required: true,
                    defaultValue: AgentSettings?.at(0)?.Areas // undefined
                }

            ],
            footer: [
                { id: 'submit', content: ['Purchase'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]

        },
        {
            id: 'agent_set_viewing_modal',
            header: 'Add viewing',
            form: [
                { id: 'time_viewing', content: ['Time of viewing'], element: 'input-date-time', required: true }

            ],
            footer: [
                { id: 'submit', content: ['Add viewing'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'agent_update_viewing_modal',
            header: 'Unit viewing time rewind',
            form: [
                // { id: 'date_viewing', content: ['Date of viewing', 'Choose date of viewing'], element: 'input-date', required: true },
                // { id: 'time_viewing', content: ['Time of viewing'], element: 'input-time', required: true },

                { id: 'time_viewing', content: ['Time of viewing'], element: 'input-date-time', required: true },
                { element: 'separator' },
                { id: 'marketing_headline', element: 'label-primary' },
                { id: 'images_slider', element: 'slider-images' }
            ],
            footer: [
                { id: 'submit', content: ['Set new viewing time'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ],
            btnTitle: 'Book viewing'
        },

        {
            id: 'agent_update_viewing_modal_close_btn',
            header: 'Unit viewing time rewind',
            form: [
                { id: 'time_viewing', content: ['Time of viewing'], element: 'input-date-time', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Set new viewing time'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },

        {
            id: 'settings_modal',
            header: 'Professional Preferences',
            form: [
                { id: 'emirate', content: ['Emirate of work', 'Choose your work emirate'], selectors: ['Dubai'], element: 'select' },
                { id: 'locations', content: ['Preferred locations', 'Choose your preferred locations (up to 5)'], selectors: allAreas, element: 'select', multiple: true },
                { id: 'activity', content: ['Preferred activity', 'Choose your preferred activity (rent/sale)'], selectors: ['Only Rent', 'Mostly Rent than Sale', 'Sale & Rent', 'Mostly Sale than Rent', 'Only Sale'], element: 'select' },
                { id: 'prorepty_type', content: ['Preferred prorepty type', 'Choose your preferred prorepty type'], selectors: getPropertyType(), multiple: true, element: 'select' }
            ],
            footer: [
                { id: 'submit', content: ['Save changes'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'docs_modal',
            header: 'Upload your docs',
            form: [
                { content: ['Upload your ID or Passport to get Verified Status'], element: 'label-primary' },
                { id: 'id_front', content: ['Emirates ID Front'], element: 'input-file' },
                { id: 'id_back', content: ['Emirates ID Back'], element: 'input-file' },
                { id: 'id_back_expiry', content: ['Emirates ID Expiry Date', 'Select date'], element: 'input-date' },
                { id: 'visa', content: ['Passport'], element: 'input-file' },
                { id: 'visa_expiry', content: ['Passport Expiry Date', 'Select date'], element: 'input-date' },
                { element: 'separator' },
                { content: ['Upload your RERA License to get Licensed Status'], element: 'label-primary' },
                { id: 'rera', content: ['RERA Card'], element: 'input-file' },
                { id: 'rera_expiry', content: ['RERA Card Expiry Date', 'Select date'], element: 'input-date' }
            ],
            footer: [
                { id: 'submit', content: ['Save changes'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'btn-light' }
            ],
            btnTitle: 'Submit your documents'
        },
        {
            id: 'set_property_modal',
            header: 'Add property',
            form: [
                // { id: 'Images', content: ['Set property images'], element: 'input-file-multiple' },
                { id: 'Type', content: ['Property type', 'Select type'], selectors: ['Building', 'Community'], element: 'select' },
                // { id: 'Area', content: ['Property area', 'Sq.Ft.', 'Input property area'], element: 'input-group' },
                { id: 'Area', content: ['Location', 'Select location'], selectors: allAreas, element: 'select' },
                { id: 'GoogleMapsLink', content: ['Google Maps Link', 'Input goo.gl/maps link'], element: 'input' },
                { id: 'Name', content: ['Property name', 'Input property name'], element: 'input' },
                { id: 'MAKANI', content: ['MAKANI', 'Input MAKANI'], element: 'input' },
                { id: 'Developer', content: ['Property Developer', 'Input Property Developer'], element: 'input' },
                { id: 'PlotNumber', content: ['Plot Number', 'Input Plot Number'], element: 'input' }
            ],
            footer: [
                { id: 'submit', content: ['Add property'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'edit_property_modal',
            header: 'Edit property',
            form: [
                // { id: 'Images', content: ['Set property images'], element: 'input-file-multiple' },
                { id: 'Type', content: ['Property type', 'Select type'], selectors: ['Building', 'Community'], element: 'select' },
                // { id: 'Area', content: ['Property area', 'Sq.Ft.', 'Input property area'], element: 'input-group' },
                { id: 'Area', content: ['Location', 'Select location'], selectors: allAreas, element: 'select' },
                { id: 'GoogleMapsLink', content: ['Google Maps Link', 'Input goo.gl/maps link'], element: 'input' },
                { id: 'Name', content: ['Property name', 'Input property name'], element: 'input' },
                { id: 'MAKANI', content: ['MAKANI', 'Input MAKANI'], element: 'input' },
                { id: 'Developer', content: ['Property Developer', 'Input Property Developer'], element: 'input' },
                { id: 'PlotNumber', content: ['Plot Number', 'Input Plot Number'], element: 'input' }

            ],
            footer: [
                { id: 'submit', content: ['Save changes'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },

        // <==== agent logic unit modal

        // {
        //     id: 'agent_create_unit_property',
        //     header: 'Set unit - step 1 of 3 - Add property',
        //     form: [
        //         { content: ['Please , create property'], element: 'label-primary' },
        //         { id: 'Type', content: ['Property type', 'Select type'], selectors: ['Building', 'Community'], element: 'select', required: true, ...(generateDefaultValueFormField(newProperty?.type === 'Unit' ? 'Community' : newProperty?.type === 'Flat' ? 'Building' : '')) },
        //         { id: 'Area', content: ['Location', 'Select location'], selectors: getAreas().filter((area) => area !== 'All areas'), element: 'select', ...(((newProperty != null) && newProperty?.area && newProperty?.area !== null) ? { defaultValue: newProperty?.area } : {}), required: true },
        //         // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        //         { id: 'Name', content: ['Property name', 'Input property name'], element: 'input', ...((newProperty && newProperty?.name && newProperty?.name !== null) ? { defaultValue: newProperty?.name } : {}), required: true },
        //         { id: 'GoogleMapsLink', content: ['Google Maps Link', 'Input goo.gl/maps link'], element: 'input', required: true, maxLength: 700 },
        //         { id: 'AreaSqFt', content: ['Property area', 'Sq.Ft.', 'Input property area'], element: 'input-group' },
        //         // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        //         { id: 'MAKANI', content: ['MAKANI', 'Input MAKANI'], element: 'input', ...((newProperty && newProperty?.makani && newProperty?.makani !== null) ? { defaultValue: newProperty?.makani } : {}), required: false },
        //         { id: 'Developer', content: ['Property Developer', 'Input Property Developer'], element: 'input', required: false },
        //         { id: 'PlotNumber', content: ['Plot Number', 'Input Plot Number'], element: 'input', required: false }
        //     ],
        //     footer: [
        //         { id: 'submit', content: ['Add Property'], element: 'button-primary' },
        //         { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/my_units', attention: true }
        //     ]
        // },


        // <==== agent logic unit modal
        {
            id: 'agent_create_unit_with_title_deed',
            header: 'Set unit',
            form: [
                { content: ['Please , choose type of create unit'], element: 'label-primary' },
                { id: 'CreateUnitType'}, // , idTitleDeed: unitsFields?.attributes?.Documents?.data?.[0]?.id 
            ],
            footer: [
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/my_units', attention: true }
            ]
        },
        {
            id: 'agent_create_unit_property',
            header: 'Set unit - step 1 of 3',

            form: [
                { content: ['Please , choose type unit'], element: 'label-primary' },
                { id: 'UnitType' },
                { id: 'show_add_property_options' }

            ],
            footer: [
                { id: 'submit', content: ['Add Property'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/my_units', attention: true }
            ]
        },
        {
            id: 'agent_create_unit_options',
            header: 'Set unit - step 2 of 3 - Add additional options',
            form: [
                { content: ['Please , Add additional options'], element: 'label-primary' },
                { id: 'dynamicStatuses', content: ['Occupied until', 'Select date'], required: true },
                { id: 'Bedrooms', content: ['Bedrooms', 'Input count of beds or "Studio"'], minLength: 1, element: 'input', required: true },
                { id: 'Bathrooms', content: ['Bathrooms', 'Input count of baths'], minLength: 1, element: 'input', required: true, regexp: regExps.isNumber },
                { id: 'Features', content: ['Property Features', 'Select features'], selectors: configSelectors?.Features, element: 'select', multiple: true, required: false },
                { id: 'GuestBathroom', content: ['Guest Bathroom', 'Unit with guest bathroom ?'], selectors: ['YES', 'NO'], element: 'input-switch', defaultValue: false, required: true },
                { id: 'MaidsRoom', content: ['Maids Room', 'Unit with maids room ?'], selectors: ['YES', 'NO'], element: 'input-switch', defaultValue: false, required: true },
                // switch show
                { id: 'show_add_unit_options' }
            ],
            footer: [
                { id: 'submit', content: ['Save and go next'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/my_units', attention: true }
            ]
        },

        {
            id: 'agent_create_unit_marketing',
            header: 'Set unit - step 3 of 3 - Add Marketing',
            form: [
                { content: ['Please , add marketing info'], element: 'label-primary' },
                { id: 'Images', content: ['Set unit images'], element: 'input-images-multiple' },
                { id: 'Marketing.Headline', content: ['Marketing headline', 'This title will be seen by app users'], element: 'input', required: true, maxLength: 160 },
                { id: 'Marketing.Description', content: ['Marketing description', 'This unit\'s description will be seen by app users'], element: 'textarea', required: false, maxLength: 5000 },
                { id: 'Marketing.Price', content: ['Price', 'AED', 'Input property price'], element: 'input-group', required: true, regexp: regExps.isNumber },
                // switch show
                { id: 'marketing_type_unit' }
                //  { id: 'verification_unit_by_title_deed' }

            ],
            footer: [
                { id: 'submit', content: ['Add Marketing and save unit'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/my_units', attention: true }
            ]
        },

        {
            id: 'agent_edit_unit',
            header: 'Edit unit',
            form: [
                // { content: ['Unit\'s Title Deed'], element: 'label-primary' },
                // { id: 'agent_edit_unit_title_deed', idTitleDeed: unitsFields?.attributes?.Documents?.data?.[0]?.id },
                { content: ['Property fields'], element: 'label-primary' },
                { id: 'Type', content: ['Property type', 'Select type'], selectors: ['Building', 'Community'], element: 'select', required: true, ...(generateDefaultValueFormField(propertyFields?.Type)) },
                { id: 'Area', content: ['Location', 'Select location'], selectors: allAreas, element: 'select', required: true, ...(generateDefaultValueFormField(propertyFields?.Area)) },
                { id: 'Name', content: ['Property name', 'Input property name'], element: 'input', required: true, ...(generateDefaultValueFormField(propertyFields?.Name)) },
                { id: 'GoogleMapsLink', content: ['Google Maps Link', 'Input goo.gl/maps link'], element: 'input', required: true, maxLength: 300, ...(generateDefaultValueFormField(propertyFields?.GoogleMapsLink)) , regexp: regExps.checkLinks},
                { id: 'AreaSqFt', content: ['Property area', 'Sq.Ft.', 'Input property area'], element: 'input-group', ...(generateDefaultValueFormField(unitsFields?.attributes?.SizeArea)) },
                { id: 'MAKANI', content: ['MAKANI', 'Input MAKANI'], element: 'input', required: false, ...(generateDefaultValueFormField(propertyFields?.MAKANI)) },
                { id: 'Developer', content: ['Property Developer', 'Input Property Developer'], element: 'input', required: false, ...(generateDefaultValueFormField(propertyFields?.Developer)) },
                { id: 'PlotNumber', content: ['Plot Number', 'Input Plot Number'], element: 'input', required: false, ...(generateDefaultValueFormField(propertyFields?.PlotNumber)) },
                { content: ['Additional options fields'], element: 'label-primary' },
                { id: 'dynamicStatuses', content: ['Occupied until', 'Select date'], required: true, ...(generateDefaultValueFormField(unitsFields?.attributes?.OccupiedUntil)), isEdit: true },
                { id: 'Number', content: ['Unit number', 'Input unit number'], minLength: 1, element: 'input', required: false, description: 'This field is optional, but completing it will enhance the credibility of your listing.', ...(generateDefaultValueFormField(unitsFields?.attributes?.Number))},
                { id: 'Bedrooms', content: ['Bedrooms', 'Input count of beds or "Studio"'], minLength: 1, element: 'input', required: true, ...(generateDefaultValueFormField(unitsFields?.attributes?.Bedrooms)), regexp: regExps.forBedrooms },
                { id: 'Bathrooms', content: ['Bathrooms', 'Input count of baths'], minLength: 1, element: 'input', required: true, ...(generateDefaultValueFormField(unitsFields?.attributes?.Bathrooms)), regexp: regExps.isNumber },
                { id: 'GuestBathroom', content: ['Guest Bathroom', 'Unit with guest bathroom ?'], selectors: ['YES', 'NO'], defaultValue: unitsFields?.attributes?.GuestBathroom, element: 'input-switch', required: true },
                { id: 'MaidsRoom', content: ['Maids Room', 'Unit with maids room ?'], selectors: ['YES', 'NO'], element: 'input-switch', defaultValue: unitsFields?.attributes?.MaidsRoom, required: true },

                // {
                //     id: 'StatusesMortgage',
                //     content: ['Mortgage available', 'Select option'],
                //     selectors: [{ label: 'Available', value: 'Mortgage' }, { label: 'Unavailable', value: 'Mortgage unavailable' }],
                //     element: 'select',
                //     required: true,
                //     ...(generateDefaultValueFormField(
                //         Array.isArray(unitsFields?.attributes?.Statuses)
                //             ? unitsFields.attributes.Statuses.includes('Mortgage')
                //                 ? 'Mortgage'
                //                 : 'Mortgage unavailable'
                //             : ''
                //     ))
                // },
                { id: 'Features', content: ['Property Features', 'Select features'], selectors: configSelectors?.Features, element: 'select', multiple: true, required: false, ...(generateDefaultValueFormField(unitsFields?.attributes?.Features)) },
                { id: 'Amenities', content: ['Property Amenities', 'Select amenities'], element: 'select', multiple: true, selectors: configSelectors?.Amenities, ...(generateDefaultValueFormField(unitsFields?.attributes?.Amenities)) },
                { id: 'Appliances', content: ['Property Appliances', 'Select appliances'], element: 'select', multiple: true, selectors: configSelectors?.Appliances, ...(generateDefaultValueFormField(unitsFields?.attributes?.Appliances)) },
                { id: 'Fit', content: ['Property Fit', 'Select fit'], element: 'select', selectors: configSelectors?.Fit, ...(generateDefaultValueFormField(unitsFields?.attributes?.Fit)) },
                { id: 'View', content: ['Property view', 'Select view'], element: 'select', selectors: configSelectors?.View, ...(generateDefaultValueFormField(unitsFields?.attributes?.View)) },
                { content: ['Marketing info fields'], element: 'label-primary' },
                { id: 'Images', content: ['Set unit images'], element: 'input-images-multiple', ...(generateDefaultValueFormField(unitsFields?.attributes?.Images)) },
                { id: 'Marketing.Headline', content: ['Marketing headline', 'This title will be seen by app users'], element: 'input', required: true, ...(generateDefaultValueFormField(marketingFields?.Headline)), maxLength: 160 },
                { id: 'Marketing.Description', content: ['Marketing description', 'This unit\'s description will be seen by app users'], element: 'textarea', required: false, ...(generateDefaultValueFormField(marketingFields?.Description)), maxLength: 5000 },
                { id: 'Marketing.Price', content: ['Price', 'AED', 'Input property price'], element: 'input-group', required: true, ...(generateDefaultValueFormField(marketingFields?.Price)), regexp: regExps.isNumber },
                { id: 'marketing_type_unit' }
            ],
            footer: [
                { id: 'delete_agent_unit', content: ['Delete'], element: 'button-primary' },
                { id: 'submit', content: ['Save changes'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/my_units', attention: true }
            ]
        },
        {
            id: 'save_after_draft_agent_unit',
            header: 'Add additional info',
            form: [
                { content: ['Property fields'], element: 'label-primary' },
                { id: 'Area', content: ['Location', 'Select location'], selectors: allAreas, element: 'select', required: true, ...(generateDefaultValueFormField(propertyFields?.Area)) },
                { id: 'Name', content: ['Property name', 'Input property name'], element: 'input', required: true, ...(generateDefaultValueFormField(propertyFields?.Name)) },
                { id: 'GoogleMapsLink', content: ['Google Maps Link', 'Input goo.gl/maps link'], element: 'input', required: true, maxLength: 300, ...(generateDefaultValueFormField(propertyFields?.GoogleMapsLink)) },
                { id: 'AreaSqFt', content: ['Property area', 'Sq.Ft.', 'Input property area'], element: 'input-group', ...(generateDefaultValueFormField(unitsFields?.attributes?.SizeArea)) },
                
                { content: ['Additional options fields'], element: 'label-primary' },
                { id: 'dynamicStatuses', content: ['Occupied until', 'Select date'], required: true, ...(generateDefaultValueFormField(unitsFields?.attributes?.OccupiedUntil)), isEdit: true },
                { id: 'Number', content: ['Unit number', 'Input unit number'], minLength: 1, element: 'input', required: false, description: 'This field is optional, but completing it will enhance the credibility of your listing.', ...(generateDefaultValueFormField(unitsFields?.attributes?.Number)), regexp: regExps.isNumber },
                { id: 'Bedrooms', content: ['Bedrooms', 'Input count of beds or "Studio"'], minLength: 1, element: 'input', required: true, ...(generateDefaultValueFormField(unitsFields?.attributes?.Bedrooms)), regexp: regExps.forBedrooms },
                { id: 'Bathrooms', content: ['Bathrooms', 'Input count of baths'], minLength: 1, element: 'input', required: true, ...(generateDefaultValueFormField(unitsFields?.attributes?.Bathrooms)), regexp: regExps.isNumber },
                { id: 'GuestBathroom', content: ['Guest Bathroom', 'Unit with guest bathroom ?'], selectors: ['YES', 'NO'], defaultValue: unitsFields?.attributes?.GuestBathroom, element: 'input-switch', required: true },
                { id: 'MaidsRoom', content: ['Maids Room', 'Unit with maids room ?'], selectors: ['YES', 'NO'], element: 'input-switch', defaultValue: unitsFields?.attributes?.MaidsRoom, required: true },
                { id: 'Features', content: ['Property Features', 'Select features'], selectors: configSelectors?.Features, element: 'select', multiple: true, required: false, ...(generateDefaultValueFormField(unitsFields?.attributes?.Features)) },
                { id: 'Amenities', content: ['Property Amenities', 'Select amenities'], element: 'select', multiple: true, selectors: configSelectors?.Amenities, ...(generateDefaultValueFormField(unitsFields?.attributes?.Amenities)) },
                { id: 'Appliances', content: ['Property Appliances', 'Select appliances'], element: 'select', multiple: true, selectors: configSelectors?.Appliances, ...(generateDefaultValueFormField(unitsFields?.attributes?.Appliances)) },
                { id: 'Fit', content: ['Property Fit', 'Select fit'], element: 'select', selectors: configSelectors?.Fit, ...(generateDefaultValueFormField(unitsFields?.attributes?.Fit)) },
                { id: 'View', content: ['Property view', 'Select view'], element: 'select', selectors: configSelectors?.View, ...(generateDefaultValueFormField(unitsFields?.attributes?.View)) },
                { content: ['Marketing info fields'], element: 'label-primary' },
                { id: 'Images', content: ['Set unit images'], element: 'input-images-multiple', ...(generateDefaultValueFormField(unitsFields?.attributes?.Images)) },
                { id: 'Marketing.Headline', content: ['Marketing headline', 'This title will be seen by app users'], element: 'input', required: true, ...(generateDefaultValueFormField(marketingFields?.Headline)), maxLength: 160 },
                { id: 'Marketing.Description', content: ['Marketing description', 'This unit\'s description will be seen by app users'], element: 'textarea', required: false, ...(generateDefaultValueFormField(marketingFields?.Description)), maxLength: 5000 },
                { id: 'Marketing.Price', content: ['Price', 'AED', 'Input property price'], element: 'input-group', required: true, ...(generateDefaultValueFormField(marketingFields?.Price)), regexp: regExps.isNumber },
                { id: 'marketing_type_unit' }
            ],
            footer: [
                { id: 'submit', content: ['Save and publish'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/my_units', attention: true }
            ]
        },
        /// ////////////////////  make offer agent to agent
        {
            id: 'agent_to_agent_make_offer',
            header: 'Add offer - step 1 of 2',
            form: [
                { id: 'state_offer_type_for_agent_to_agent' },
                { id: 'description_unit_modal' }
            ],
            footer: [
                { id: 'submit', content: ['Next step'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/schedule', attention: true }
            ]
        }, 
        {
            id: 'agent_to_agent_make_offer_last',
            header: 'Add offer to agent', //  - step 2 of 2
            form: [
                { id: 'state_offer_type_for_agent_to_agent_last' },
                { id: 'description_unit_modal' }
            ],
            footer: [
                { id: 'submit', content: ['Create Offer'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/schedule', attention: true }
            ]
        },
        {
            id: 'agent_to_agent_negotiate_offer',
            header: 'Make your deal to agent',
            form: [
                { id: 'state_offer_type_for_agent_to_agent_last' },
                { id: 'description_unit_modal' }
            ],
            footer: [
                { id: 'submit', content: ['Negotiate new conditions'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: -1, attention: true }
            ]
        },
        /// //////////////////// Make offer agent to BSO
        {
            id: 'agent_to_bso_make_offer_documents',
            header: 'Add offer - Additional info',
            form: [
                { id: 'agent_to_bso_offer_client_info' },
                { id: 'agent_to_bso_offer_additional_info' }, // TO CHECK
                { id: 'description_unit_modal' }
            ],
            footer: [
                { id: 'submit', content: ['Submit'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav', attention: true }
            ]
        },
        {
            id: 'agent_to_bso_offer_offer_info',
            header: 'Add offer', // 'Add offer - step 2 of 2',
            form: [
                { id: 'agent_to_bso_offer_offer_info' },
                { id: 'description_unit_modal' }
            ],
            footer: [
                { id: 'submit', content: ['Submit'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav', attention: true }
            ]
        },
  
        {
            id: 'agent_to_bso_resubmit_offer_documents',
            header: 'Resubmit offer -  Additional info',
            form: [
                { id: 'agent_to_bso_offer_client_info' },
                { id: 'agent_to_bso_offer_additional_info' }, // TO CHECK
                { id: 'description_unit_modal' }
            ],
            footer: [
                { id: 'submit', content: ['Submit'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav', attention: true } // -1
            ]
        },
        // {
        //     id: 'agent_to_bso_resubmit_offer_info',
        //     header: 'Resubmit offer', //  - step 2 of 2
        //     form: [
        //         { id: 'agent_to_bso_offer_offer_info' },
        //         { id: 'description_unit_modal' }
        //     ],
        //     footer: [
        //         { id: 'submit', content: ['Next step'], element: 'button-primary' },
        //         { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav', attention: true } // -1
        //     ]
        // },
  
        /// ////////////////////
        {
            id: 'set_unit_modal',
            header: 'Set unit',
            stored_modal: true,
            form: [
                { content: ['Main info'], element: 'label-primary' },
                { id: 'title_deed', content: ['Scan Title Deed'], element: 'input-file', multiple: true, required: true },
                { id: 'Property', content: ['Property', 'Choose property'], modal: 'set_property_modal', element: 'select-add' },
                { id: 'Images', content: ['Set unit images'], element: 'input-file-multiple' },
                { id: 'Statuses[0]', content: ['Availability status', 'Select status'], selectors: ['Available', 'Unavailable', 'In-process', 'Waiting updates'], element: 'select' },
                // { id: 'occupied_until', content: ['Occupied until', 'Select date'], element: 'input-date' },
                { id: 'Number', content: ['Unit number', 'Input unit number'], minLength: 1, element: 'input' },
                { id: 'Bedrooms', content: ['Bedrooms', 'Input count of beds or "Studio"'], minLength: 1, element: 'input' },
                { id: 'Bathrooms', content: ['Bathrooms', 'Input count of baths'], minLength: 1, element: 'input' },
                { id: 'Statuses[1]', content: ['Mortgage available', 'Select option'], selectors: [{ label: 'Available', value: 'Mortgage' }, { label: 'Unavailable', value: 'Mortgage unavailable' }], element: 'select' },
                // статус Door close только если юнит создается агентом , не BSO
                { id: 'Statuses[2]', content: ['Door open/close', 'Select option'], selectors: ['Door open'], element: 'select' }, // , 'Door close'
                { id: 'Features', content: ['Property Features', 'Select features'], selectors: ['Close to metro', 'Close to mall', 'Close to clinic']/* getFeatures() */, element: 'select', multiple: true },
                { element: 'separator' },
                { content: ['Owners'], element: 'label-primary' },
                { id: 'add_owner_button', content: ['Add owner'], element: 'button' }
            ],
            form_templates: [
                { id: 'title_deed', content: ['Title deed'], element: 'label-secondary' }
            ],
            footer: [
                { id: 'submit', content: ['Add unit'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'set_unit_marketing_modal',
            header: 'Set unit marketing',
            form: [
                { content: ['Main info'], element: 'label-primary' },
                { id: 'Images', content: ['Set unit images'], element: 'input-images-multiple' },
                { id: 'Video', content: ['Set unit video'], element: 'input-file' },
                { id: 'FloorPlan', content: ['Set unit floor plan'], element: 'input-file' },
                // { id: 'AdditionalDocuments', content: ['Additional Documents'], element: 'input-files-multiple' },
                { id: 'AdditionalDocuments', content: ['Additional Documents'], element: 'input-files-multiple' },
                { id: 'Marketing.Headline', content: ['Marketing headline', 'This title will be seen by app users'], element: 'input', required: true },
                { id: 'Marketing.Description', content: ['Marketing description', 'Set marketing description'], element: 'textarea' },
                { id: 'Marketing.Use', content: ['Marketing type', 'Select type'], selectors: ['For rent', 'For sale'], element: 'select', required: true },
                { id: 'Marketing.Price', content: ['Price', 'AED', 'Input property price'], element: 'input-group', regexp: regExps.isFloatNumber, required: true },
                { id: 'Marketing.Checks', content: ['Cheques', 'Select the cheques count'], selectors: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], element: 'select' },
                { id: 'Statuses', content: ['Mortgage available', 'Select option'], selectors: ['Available', 'Unavailable'], element: 'select' },
                { id: 'Marketing.Status', content: ['Discount', 'Select option'], selectors: ['Enabled', 'Disabled'], element: 'select' },
                { id: 'Marketing.DiscountHeadline', content: ['Discount headline', 'Input discount description'], element: 'input', required: false },
                { id: 'Marketing.DiscountStartDatetime', content: ['Discount start', 'Choose discount start date'], element: 'input-date', required: false },
                { id: 'Marketing.DiscountEndDatetime', content: ['Discount end', 'Choose discount end date'], element: 'input-date', required: false },
                { id: 'Marketing.DiscountPrice', content: ['Discount price', 'AED', 'Input property price for discount'], regexp: regExps.isFloatNumber, element: 'input-group', required: false },
                { id: 'CurrentRent', content: ['Current rent', 'AED', 'Input current rent of unit'], element: 'input-group', regexp: regExps.isFloatNumber, required: false },
                { id: 'AnnualServiceCharge', content: ['Annual service charge', 'AED', 'Input annual service charge for unit'], regexp: regExps.isFloatNumber, element: 'input-group', required: false },
                { id: 'LegalNoticeServed', content: ['Legal Notice served', 'Select option'], selectors: ['Yes', 'No'], element: 'select', required: false },
                { id: 'DateServed', content: ['Date served', 'Choose date'], element: 'input-date', required: false },
                { id: 'ImportantNotice', content: ['Important notice', 'Set important notice'], element: 'input' },
                { id: 'sold-rented', content: ['Unit status', 'Select unit status'], element: 'select', selectors: ['In-process', 'Rented/Sold', 'Drafted'], defaultValue: 'Drafted' },
                { id: 'Marketing.MarketingFormTemplate', content: ['Marketing form template'], element: 'input-file' }
            ],
            footer: [
                /* { id: 'delete', content: ['Delete'], element: 'button-primary' },
                { id: 'edit', content: ['Save changes'], element: 'button-primary' }, */
                { id: 'submit', content: ['Add marketing'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'edit_unit_modal',
            header: 'Edit unit',
            form: [
                { id: 'content', content: ['Main info'], element: 'label-primary' },
                { id: 'title_deed', content: ['Scan Title Deed'], element: 'input-file', multiple: true },
                { id: 'Property', content: ['Property', 'Choose property'], element: 'select-add' },
                { id: 'Images', content: ['Set unit images'], notRequired: true, element: 'input-file-multiple' },
                { id: 'Statuses[0]', content: ['Availability status', 'Select status'], selectors: ['Available', 'Unavailable', 'In-process', 'Waiting updates'], element: 'select' },
                // { id: 'occupied_until', content: ['Occupied until', 'Select date'], element: 'input-date' },
                { id: 'Number', content: ['Unit number', 'Input unit number'], minLength: 1, element: 'input' },
                { id: 'Bedrooms', content: ['Bedrooms', 'Input count of beds or "Studio"'], minLength: 1, element: 'input' },
                { id: 'Bathrooms', content: ['Bathrooms', 'Input count of baths'], minLength: 1, element: 'input' },
                { id: 'Statuses[1]', content: ['Mortgage available', 'Select option'], selectors: [{ label: 'Available', value: 'Mortgage' }, { label: 'Unavailable', value: 'Mortgage unavailable' }], element: 'select' },
                // статус Door close только если юнит создается агентом , не BSO
                { id: 'Statuses[2]', content: ['Door open/close', 'Select option'], selectors: ['Door open'], element: 'select' }, // , 'Door close'
                { id: 'Features', content: ['Property Features', 'Select features'], selectors: ['Close to metro', 'Close to mall', 'Close to clinic'], element: 'select', multiple: true },

                { id: 'sep', element: 'separator' },

                { content: ['Marketing'], element: 'label-primary' },
                { id: 'Marketing.Headline', content: ['Marketing headline', 'This title will be seen by app users'], element: 'label-secondary' },
                { id: 'Marketing.Use', content: ['Marketing type', 'Select type'], selectors: ['For rent', 'For sale'], element: 'label-secondary' },
                { id: 'Marketing.Price', content: ['Price', 'AED', 'Input property price'], element: 'label-secondary' },
                { id: 'Marketing.Checks', content: ['Cheques', 'Select the cheques count'], selectors: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], element: 'select' },
                { id: 'Marketing.Status', content: ['Discount', 'Select option'], selectors: ['Enabled', 'Disabled'], element: 'label-secondary' },
                { id: 'Marketing.DiscountHeadline', content: ['Discount headline', 'Input discount description'], element: 'label-secondary' },
                { id: 'Marketing.DiscountStartDatetime', content: ['Discount start', 'Choose discount start date'], element: 'label-secondary' },
                { id: 'Marketing.DiscountEndDatetime', content: ['Discount end', 'Choose discount end date'], element: 'label-secondary' },
                { id: 'Marketing.DiscountPrice', content: ['Discount price', 'AED', 'Input property price for discount'], element: 'label-secondary' },
                { id: 'edit_marketing_button', content: ['Edit marketing'], element: 'action_button' },
                { id: 'sep1', element: 'separator' },
                { content: ['Owners'], element: 'label-primary' },
                { id: 'add_owner_button', content: ['Add owner'], element: 'button' }
            ],
            footer: [
                { id: 'reset', content: ['Delete'], element: 'button-primary' },
                { id: 'submit', content: ['Save changes'], element: 'button-primary' },
                /* { id: 'add', content: ['Add unit'], element: 'button-primary' }, */
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'edit_unit_marketing_modal',
            header: 'Edit unit marketing',
            form: [
                { content: ['Main info'], element: 'label-primary' },
                { id: 'Images', content: ['Set unit images'], element: 'input-images-multiple' },
                { id: 'Video', content: ['Set unit video'], element: 'input-file' },
                { id: 'FloorPlan', content: ['Set unit floor plan'], element: 'input-file' },
                { id: 'AdditionalDocuments', content: ['Additional Documents'], element: 'input-files-multiple' },
                { id: 'Marketing.Headline', content: ['Marketing headline', 'This title will be seen by app users'], element: 'input', required: true },
                { id: 'Marketing.Use', content: ['Marketing type', 'Select type'], selectors: ['For rent', 'For sale'], element: 'select', required: true },
                { id: 'Marketing.Price', content: ['Price', 'AED', 'Input property price'], element: 'input-group', regexp: regExps.isFloatNumber, required: true },
                { id: 'Marketing.Checks', content: ['Cheques', 'Select the cheques count'], selectors: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], element: 'select' },
                { id: 'Statuses', content: ['Mortgage available', 'Select option'], selectors: ['Available', 'Unavailable'], element: 'select', required: true },
                { id: 'Marketing.Status', content: ['Discount', 'Select option'], selectors: ['Enabled', 'Disabled'], element: 'select', required: true },
                { id: 'Marketing.DiscountHeadline', content: ['Discount headline', 'Input discount description'], element: 'input' },
                { id: 'Marketing.DiscountStartDatetime', content: ['Discount start', 'Choose discount start date'], element: 'input-date' },
                { id: 'Marketing.DiscountEndDatetime', content: ['Discount end', 'Choose discount end date'], element: 'input-date' },
                { id: 'Marketing.DiscountPrice', content: ['Discount price', 'AED', 'Input property price for discount'], regexp: regExps.isFloatNumber, element: 'input-group' },
                { id: 'CurrentRent', content: ['Current rent', 'AED', 'Input current rent of unit'], element: 'input-group', regexp: regExps.isFloatNumber, required: false },
                { id: 'AnnualServiceCharge', content: ['Annual service charge', 'AED', 'Input annual service charge for unit'], element: 'input-group', regexp: regExps.isFloatNumber, required: false },
                { id: 'LegalNoticeServed', content: ['Legal Notice served', 'Select option'], selectors: ['Yes', 'No'], element: 'select', required: false },
                { id: 'DateServed', content: ['Date served', 'Choose date'], element: 'input-date', required: false },
                { id: 'ImportantNotice', content: ['Important notice', 'Set important notice'], element: 'input' },
                { id: 'sold-rented', content: ['Unit status', 'Select unit status'], element: 'select', selectors: ['In-process', 'Rented/Sold', 'Drafted'], required: true },
                { id: 'Marketing.MarketingFormTemplate', content: ['Marketing form template'], element: 'input-file' },
                { id: 'Marketing.Description', content: ['Marketing description', 'Set marketing description'], element: 'textarea' }
            ],
            footer: [
                /* { id: 'delete', content: ['Delete'], element: 'button-primary' },
                { id: 'edit', content: ['Save changes'], element: 'button-primary' }, */
                { id: 'submit', content: ['Save changes'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'agent_set_offer_modal_tenant_type',
            header: 'Add offer - step 1 of 4',
            form: [
                { id: 'TenantType', content: ['Tenant type', 'Enter the tenant type'], element: 'select', required: true, selectors: AddOfferTenantTypes },
                { id: 'unit_carousel_images' }
            ],
            footer: [
                { id: 'submit', content: ['Next step'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'agent_set_offer_modal_documents_individual',
            header: 'Add offer - step 2 of 4',
            form: [
                { id: 'Scanned.DocumentType', content: ['Client\'s document type'], element: 'select', selectors: ['Emirates ID', 'Passport'], defaultValue: 'Emirates ID' },
                { id: 'Scanned.ClientDocument', content: ['Scan client\'s document', 'Document'], element: 'document-scan-input', required: true },
                { id: 'Type.FirstName', content: ['Client First Name', 'Thomas'], element: 'input', required: true, validateEquals: ['Scanned.FirstName', 'Must be equal to first name in the scanned document', 2] },
                { id: 'Type.LastName', content: ['Client Last Name', 'A. Anderson'], element: 'input', required: true, validateEquals: ['Scanned.LastName', 'Must be equal to last name in the scanned document', 2] },
                { id: 'Type.Country', content: ['Client Nationality', 'Select country'], selectors: getCountries(), element: 'select', required: true, trigger: 'scannedData.Country' },
                // { id: 'Type.EmiratesID', content: ['Client\'s document number', '784-1234-1234567-1'], element: 'input', regexp: '^784-?[0-9]{4}-?[0-9]{7}-?[0-9]{1}$', required: true, validateEquals: ['Scanned.EmiratesID', 'Must be equal to the number of the scanned document', 2] },
                { id: 'client_birthday', content: ['Client Birth Day', 'Choose client\'s date of birth'], element: 'input-date', yearsBeforeToday: 18, required: true, trigger: 'scannedData.DOB' }
            ],
            footer: [
                { id: 'submit', content: ['Next step'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav' }
            ]
        },
        {
            id: 'agent_set_offer_modal_client_info_individual',
            header: 'Add offer - step 3 of 4',
            form: [
                // { id: 'client_emirates_id_file', content: ['Client Emirates ID Photo (Front Side)'], element: 'input-file', required: true },
                { id: 'Type.Occupation', content: ['Client Occupation', 'Client’s current occupation'], element: 'input', required: true },
                { id: 'Type.Employer', content: ['Client Employer', 'Client’s current employer'], element: 'input', required: true },
                { id: 'Type.Occupants', content: ['Client Occupants', 'Select option'], selectors: ['Single', 'Couple', 'Family'], element: 'select', required: true },
                { id: 'Type.Smoking', content: ['Client is smoker', 'Select option'], selectors: ['Yes', 'No'], element: 'select', required: true },
                { id: 'Type.Pets', content: ['Client has pets', 'Select option'], selectors: ['Yes', 'No'], element: 'select', required: true },
                //  { id: 'Type.PetType', content: ['Client pet type', 'Select option'], selectors: store.getState().app.config?.offers?.Type?.Residential?.PetTypes ?? [], element: 'select', required: true, trigger: 'scannedData.PetType' },
                { id: 'Type.InUAESince', content: ['Client in UAE since', 'Year', 'Input valid year'], element: 'input-group', regexp: regExps.isYear, required: true, dateBeforeToday: true },
                { id: 'Type.UAEBank', content: ['Client Bank in UAE', 'Select bank'], selectors: getBanks(), element: 'select', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Next step'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav' }
            ]
        },
        {
            id: 'agent_set_offer_modal_offer_details_individual',
            header: 'Add offer - step 4 of 4',
            form: [
                { id: 'Type.Offer', content: ['Offer', 'AED', 'Client’s offer for the property'], element: 'input-group', required: true, regexp: regExps.isNumber },
                { id: 'Type.Payments', content: ['Payments', 'Select count'], selectors: ['1', '2', '3', '4', '6', '12'], element: 'select', required: true },
                { id: 'Type.ContractStartDate', content: ['Contract Start Date', 'Input contract start date'], dateAfter: true, element: 'input-date', required: true },
                { id: 'Type.Duration', content: ['Contract Duration', 'Select contract duration'], selectors: _.range(6, 25).map(item => ({ label: `${item} Months`, value: item })), element: 'select', required: true },
                { id: 'Type.AdditionalNotes', content: ['Additional Notes', "Note client's special needs"], element: 'input' },
                { id: 'Scanned.DepositPaySlip', content: ['Upload a photo of deposit cheque'], element: 'input-file' }
            ],
            footer: [
                { id: 'submit', content: ['Submit'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav' }
            ]
        },
        {
            id: 'agent_set_offer_modal_documents_company',
            header: 'Add offer - step 2 of 4',
            form: [
                { id: 'Scanned.TradeLicence', content: ['Upload client\'s company trade licence', 'Trade licence'], element: 'input-file', required: true },
                { id: 'Scanned.DocumentType', content: ['Client\'s document type'], element: 'select', selectors: ['Emirates ID', 'Passport'], defaultValue: 'Emirates ID' },
                { id: 'Scanned.ClientDocument', content: ['Scan client\'s document', 'Document'], element: 'document-scan-input', required: true },
                { id: 'Type.FirstName', content: ['Client First Name', 'Thomas'], element: 'input', required: true, validateEquals: ['Scanned.FirstName', 'Must be equal to first name in the scanned document', 2] },
                { id: 'Type.LastName', content: ['Client Last Name', 'A. Anderson'], element: 'input', required: true, validateEquals: ['Scanned.LastName', 'Must be equal to last name in the scanned document', 2] },
                { id: 'Type.Country', content: ['Client Nationality', 'Select country'], selectors: getCountries(), element: 'select', required: true, trigger: 'scannedData.Country' },
                // { id: 'Type.EmiratesID', content: ['Client\'s document number', '784-1234-1234567-1'], element: 'input', regexp: '^784-?[0-9]{4}-?[0-9]{7}-?[0-9]{1}$', required: true, validateEquals: ['Scanned.EmiratesID', 'Must be equal to the number of the scanned document', 2] },
                { id: 'client_birthday', content: ['Client Birth Day', 'Choose client\'s date of birth'], element: 'input-date', yearsBeforeToday: 18, required: true, trigger: 'scannedData.DOB' }
            ],
            footer: [
                { id: 'submit', content: ['Next step'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav' }
            ]
        },
        {
            id: 'agent_set_offer_modal_client_info_company',
            header: 'Add offer - step 3 of 4',
            form: [
                { id: 'Type.BusinessName', content: ['Business Name', 'Input client business name'], element: 'input', required: true },
                { id: 'Type.BusinessOwnerName', content: ['Business Owner Name', 'Input business owner name'], element: 'input', required: true/*  validateEquals: ['Scanned.BusinessOwnerName', 'Must be equal to the business owner name in scanned document', 0.2] */ },
                { id: 'Type.BusinessActivity', content: ['Business Activity', 'Input business activity'], element: 'input', required: true },
                { id: 'Type.EstablishmentDate', content: ['Establishment Date', 'Year', 'Input establishment year'], element: 'input-group', regexp: regExps.isYear, required: true, dateBeforeToday: true },
                // { id: 'client_uae_since', content: ['Client in UAE since', 'Year', 'Input valid year'], element: 'input-group', required: true },
                { id: 'Type.UAEBank', content: ['Client Bank in UAE', 'Select bank'], selectors: getBanks(), element: 'select', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Next step'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav' }
            ]
        },
        {
            id: 'agent_set_offer_modal_offer_details_company',
            header: 'Add offer - step 4 of 4',
            form: [
                { id: 'Type.Offer', content: ['Client Offer', 'AED', 'Input client offer for property'], element: 'input-group', required: true, regexp: regExps.isNumber },
                { id: 'Type.Payments', content: ['Client Payments', 'Select count'], selectors: ['1', '2', '3', '4', '6', '12'], element: 'select', required: true },
                { id: 'Type.ContractStartDate', content: ['Contract Start Date', 'Input contract start date'], dateAfter: true, element: 'input-date', required: true },
                { id: 'Type.Duration', content: ['Contract Duration', 'Select contract duration'], selectors: _.range(6, 25).map(item => ({ label: `${item} Months`, value: item })), element: 'select', required: true },
                { id: 'Type.AdditionalNotes', content: ['Client Additional Notes', "Note client's special needs"], element: 'input' },
                { id: 'Scanned.DepositPaySlip', content: ['Upload a photo of deposit cheque'], element: 'input-file' }
            ],
            footer: [
                { id: 'submit', content: ['Submit'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav' }
            ]
        },
        {
            id: 'agent_set_offer_modal_tenant_type_sale',
            header: 'Add offer - step 1 of 3',
            form: [
                { id: 'TenantType', content: ['Tenant type', 'Enter the tenant type'], element: 'select', required: true, selectors: AddOfferTenantTypes },
                { id: 'unit_carousel_images' }
            ],
            footer: [
                { id: 'submit', content: ['Next step'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'agent_set_offer_modal_documents_individual_sale',
            header: 'Add offer - step 2 of 3',
            form: [
                { id: 'Scanned.DocumentType', content: ['Client\'s document type'], element: 'select', selectors: ['Emirates ID', 'Passport'], defaultValue: 'Emirates ID' },
                { id: 'Scanned.ClientDocument', content: ['Scan client\'s document', 'Document'], element: 'document-scan-input', required: true },
                { id: 'Type.FirstName', content: ['Client First Name', 'Thomas'], element: 'input', required: true, validateEquals: ['Scanned.FirstName', 'Must be equal to first name in the scanned document', 2] },
                { id: 'Type.LastName', content: ['Client Last Name', 'A. Anderson'], element: 'input', required: true, validateEquals: ['Scanned.LastName', 'Must be equal to last name in the scanned document', 2] },
                { id: 'Type.Country', content: ['Client Nationality', 'Select country'], selectors: getCountries(), element: 'select', required: true, trigger: 'scannedData.Country' },
                // { id: 'Type.EmiratesID', content: ['Client\'s document number', '784-1234-1234567-1'], element: 'input', regexp: '^784-?[0-9]{4}-?[0-9]{7}-?[0-9]{1}$', required: true, validateEquals: ['Scanned.EmiratesID', 'Must be equal to the number of the scanned document', 2] },
                { id: 'client_birthday', content: ['Client Birth Day', 'Choose client\'s date of birth'], element: 'input-date', yearsBeforeToday: 18, required: true, trigger: 'scannedData.DOB' }
            ],
            footer: [
                { id: 'submit', content: ['Next step'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav' }
            ]
        },
        {
            id: 'agent_set_offer_modal_offer_details_individual_sale',
            header: 'Add offer - step 3 of 3',
            form: [
                { id: 'Type.Offer', content: ['Client Offer', 'AED', 'Input client offer for property'], element: 'input-group', required: true, regexp: regExps.isNumber },
                { id: 'Type.Type', content: ['Offer Type', 'Select option'], selectors: ['Cash Buyer', 'Mortgage Buyer'], element: 'select', required: true },
                // { id: 'Type.BankName', content: ['Bank Name', 'Your Bank'], element: 'input', required: true },
                { id: 'Type.BankName', content: ['Bank Name', 'Select bank'], selectors: getBanks(), element: 'select', required: true },
                { id: 'Type.BankPreApproval', content: ['Bank Pre-approval', 'Select option'], selectors: ['Yes', 'No'], element: 'select', required: true },
                { id: 'Type.PreApprovedAmount', content: ['Pre-Approved Amount', 'Amount'], element: 'input', required: true },
                { id: 'Type.AdditionalNotes', content: ['Client Additional Notes', "Note client's special needs"], element: 'input' }
            ],
            footer: [
                { id: 'submit', content: ['Submit'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav' }
            ]
        },
        {
            id: 'agent_set_offer_modal_documents_company_sale',
            header: 'Add offer - step 2 of 3',
            form: [
                { id: 'Scanned.TradeLicence', content: ['Upload client\'s company trade licence', 'Trade licence'], element: 'input-file', required: true },
                { id: 'Scanned.DocumentType', content: ['Client\'s document type'], element: 'select', selectors: ['Emirates ID', 'Passport'], defaultValue: 'Emirates ID' },
                { id: 'Scanned.ClientDocument', content: ['Scan client\'s document', 'Document'], element: 'document-scan-input', required: true },
                { id: 'Type.FirstName', content: ['Client First Name', 'Thomas'], element: 'input', required: true, validateEquals: ['Scanned.FirstName', 'Must be equal to first name in the scanned document', 2] },
                { id: 'Type.LastName', content: ['Client Last Name', 'A. Anderson'], element: 'input', required: true, validateEquals: ['Scanned.LastName', 'Must be equal to last name in the scanned document', 2] },
                { id: 'Type.Country', content: ['Client Nationality', 'Select country'], selectors: getCountries(), element: 'select', required: true, trigger: 'scannedData.Country' },
                // { id: 'Type.EmiratesID', content: ['Client\'s document number', '784-1234-1234567-1'], element: 'input', regexp: '^784-?[0-9]{4}-?[0-9]{7}-?[0-9]{1}$', required: true, validateEquals: ['Scanned.EmiratesID', 'Must be equal to the number of the scanned document', 2] },
                { id: 'client_birthday', content: ['Client Birth Day', 'Choose client\'s date of birth'], element: 'input-date', yearsBeforeToday: 18, required: true, trigger: 'scannedData.DOB' }
            ],
            footer: [
                { id: 'submit', content: ['Next step'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav' }
            ]
        },
        {
            id: 'agent_set_offer_modal_offer_details_company_sale',
            header: 'Add offer - step 3 of 3',
            form: [
                { id: 'Type.BusinessName', content: ['Business Name', 'Input client business name'], element: 'input', required: true },
                { id: 'Type.BusinessOwnerName', content: ['Business Owner Name', 'Input business owner name'], element: 'input', required: true/*  validateEquals: ['Scanned.BusinessOwnerName', 'Must be equal to the business owner name in scanned document', 0.2] */ },
                { id: 'Type.BusinessActivity', content: ['Business Activity', 'Input business activity'], element: 'input', required: true },
                { id: 'Type.EstablishmentDate', content: ['Establishment Date', 'Year', 'Input establishment year'], element: 'input-group', regexp: regExps.isYear, required: true, dateBeforeToday: true },
                { id: 'Type.Offer', content: ['Client Offer', 'AED', 'Input client offer for property'], element: 'input-group', required: true, regexp: regExps.isNumber },
                { id: 'Type.Type', content: ['Offer Type', 'Select option'], selectors: ['Cash Buyer', 'Mortgage Buyer'], element: 'select', required: true },
                // { id: 'Type.BankName', content: ['Bank Name', 'Your Bank'], element: 'input', required: true },
                { id: 'Type.BankName', content: ['Bank Name', 'Select bank'], selectors: getBanks(), element: 'select', required: true },
                { id: 'Type.BankPreApproval', content: ['Bank Pre-approval', 'Select option'], selectors: ['Yes', 'No'], element: 'select', required: true },
                { id: 'Type.PreApprovedAmount', content: ['Pre-Approved Amount', 'Amount'], element: 'input', required: true },
                { id: 'Type.AdditionalNotes', content: ['Client Additional Notes', "Note client's special needs"], element: 'input' }
            ],
            footer: [
                { id: 'submit', content: ['Submit'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/agent/units/nav' }
            ]
        },
        {
            id: 'agent_set_offer_modal_residential',
            header: 'Add offer',
            form: [
                { id: 'Scanned.DocumentType', content: ['Client\'s document type'], element: 'select', selectors: ['Emirates ID', 'Passport'], defaultValue: 'Emirates ID' },
                { id: 'Scanned.ClientDocument', content: ['Scan client\'s document'], element: 'input-file', required: true },
                { id: 'Type.FirstName', content: ['Client First Name', 'Thomas'], element: 'input', required: true, validateEquals: ['Scanned.FirstName', 'Must be equal to first name in the scanned document', 2] },
                { id: 'Type.LastName', content: ['Client Last Name', 'A. Anderson'], element: 'input', required: true, validateEquals: ['Scanned.LastName', 'Must be equal to last name in the scanned document', 2] },
                { id: 'Type.Country', content: ['Client Nationality', 'Select country'], selectors: getCountries(), element: 'select', required: true, trigger: 'scannedData.Country' },
                // { id: 'Type.EmiratesID', content: ['Client\'s document number', '784-1234-1234567-1'], element: 'input', regexp: '^784-?[0-9]{4}-?[0-9]{7}-?[0-9]{1}$', required: true, validateEquals: ['Scanned.EmiratesID', 'Must be equal to the number of the scanned document', 2] },
                { id: 'client_birthday', content: ['Client Birth Day', 'Choose client\'s date of birth'], element: 'input-date', yearsBeforeToday: 18, required: true, trigger: 'scannedData.DOB' },
                // { id: 'client_emirates_id_file', content: ['Client Emirates ID Photo (Front Side)'], element: 'input-file', required: true },
                { id: 'Type.Occupation', content: ['Client Occupation', 'Client’s current occupation'], element: 'input', required: true },
                { id: 'Type.Employer', content: ['Client Employer', 'Client’s current employer'], element: 'input', required: true },
                { id: 'Type.Occupants', content: ['Client Occupants', 'Select option'], selectors: ['Single', 'Couple', 'Family'], element: 'select', required: true },
                { id: 'Type.Smoking', content: ['Client is smoker', 'Select option'], selectors: ['Yes', 'No'], element: 'select', required: true },
                { id: 'Type.Pets', content: ['Client has pets', 'Select option'], selectors: ['Yes', 'No'], element: 'select', required: true },
                { id: 'Type.InUAESince', content: ['Client in UAE since', 'Year', 'Input valid year'], element: 'input-group', regexp: regExps.isYear, required: true, dateBeforeToday: true },
                { id: 'Type.UAEBank', content: ['Client Bank in UAE', 'Select bank'], selectors: getBanks(), element: 'select', required: true },
                { id: 'Type.Offer', content: ['Client Offer', 'AED', 'Client’s offer for the property'], element: 'input-group', required: true, regexp: regExps.isNumber },
                { id: 'Type.Payments', content: ['Client Payments', 'Select count'], selectors: ['1', '2', '3', '4', '6', '12'], element: 'select', required: true },
                { id: 'Type.ContractStartDate', content: ['Contract Start Date', 'Input contract start date'], dateAfter: true, element: 'input-date', required: true },
                { id: 'Type.Duration', content: ['Contract Duration', 'Month', 'Input contract duration'], element: 'input-group', required: true },
                { id: 'Type.AdditionalNotes', content: ['Client Additional Notes', "Note client's special needs"], element: 'input' },
                { id: 'Scanned.DepositPaySlip', content: ['Upload client\'s deposit pay slip'], element: 'input-file' },
                // { id: "sale", subrow: [
                // { id: "client_first_name", content: ["Client First Name", "Thomas"], element: "input" ,required: true},
                // { id: "client_last_name", content: ["Client Last Name", "A. Anderson"], element: "input" ,required: true},
                // { id: "client_offer", content: ["Client Offer", "AED", "Input client offer for property"], element: "input-group" ,required: true},
                // { id: "mortgage", content: ["Mortgage", "Select option"], selectors: ["Available", "Unavailable"], element: "select" ,required: true},
                // ], element: "display" },
                { element: 'separator' },
                { id: 'marketing_headline', element: 'label-primary' },
                { id: 'images_slider', element: 'slider-images' }
            ],
            footer: [
                { id: 'submit', content: ['Add offer'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ],
            btnTitle: 'Make offer'
        },
        {
            id: 'agent_edit_offer_modal_residential',
            header: 'Edit offer',
            form: [
                { id: 'Type.FirstName', content: ['Client First Name', 'Thomas'], element: 'input' /*, required: true */ },
                { id: 'Type.LastName', content: ['Client Last Name', 'A. Anderson'], element: 'input' /*, required: true */ },
                { id: 'Type.Country', content: ['Client Nationality', 'Select country'], selectors: getCountries(), element: 'select' /*, required: true */ },
                { id: 'Type.EmiratesID', content: ['Client Emirates ID', '784-1234-1234567-1'], element: 'input', regexp: regExps.isEmiratesIDNumber /*, required: true */ },
                { id: 'client_birthday', content: ['Client Birth Day', '99/12/24'], element: 'input-date', /* regexp: '^\\d{2}\\/(?:0[1-9]|1[0-2])\\/(?:0[1-9]|[1-2]\\d|3[0-1])$', required: true, */ yearsBeforeToday: 18 }

            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ],
            btnTitle: ''
        },
        {
            id: 'agent_set_offer_modal_commercial',
            header: 'Add offer',
            form: [
                { id: 'Type.BusinessName', content: ['Business Name', 'Input client business name'], element: 'input', required: true },
                { id: 'Type.BusinessOwnerName', content: ['Business Owner Name', 'Input business owner name'], element: 'input', required: true/*  validateEquals: ['Scanned.BusinessOwnerName', 'Must be equal to the business owner name in scanned document', 0.2] */ },
                { id: 'Type.BusinessActivity', content: ['Business Activity', 'Input business activity'], element: 'input', required: true },
                { id: 'Type.EstablishmentDate', content: ['Establishment Date', 'Year', 'Input establishment year'], element: 'input-group', regexp: regExps.isYear, required: true, dateBeforeToday: true },
                // { id: 'client_uae_since', content: ['Client in UAE since', 'Year', 'Input valid year'], element: 'input-group', required: true },
                { id: 'Type.UAEBank', content: ['Client Bank in UAE', 'Select bank'], selectors: getBanks(), element: 'select', required: true },
                { id: 'Type.Offer', content: ['Client Offer', 'AED', 'Input client offer for property'], element: 'input-group', required: true, regexp: '^[0-9]+$' },
                { id: 'Type.Payments', content: ['Client Payments', 'Select count'], selectors: ['1', '2', '3', '4', '6', '12'], element: 'select', required: true },
                { id: 'Type.ContractStartDate', content: ['Contract Start Date', 'Input contract start date'], dateAfter: true, element: 'input-date', required: true },
                { id: 'Type.Duration', content: ['Contract Duration', 'Month', 'Input contract duration'], element: 'input-group', required: true },
                { id: 'Type.AdditionalNotes', content: ['Client Additional Notes', "Note client's special needs"], element: 'input' },
                { id: 'Scanned.DepositPaySlip', content: ['Upload client\'s deposit pay slip'], element: 'input-file' },
                { element: 'separator' },
                { id: 'marketing_headline', element: 'label-primary' },
                { id: 'images_slider', element: 'slider-images' }
            ],
            footer: [
                { id: 'submit', content: ['Add offer'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ],
            btnTitle: 'Make offer'
        },
        {
            id: 'agent_set_offer_modal_sale',
            header: 'Add offer',
            form: [
                { id: 'Type.FirstName', content: ['Client First Name', 'Thomas'], element: 'input', required: true },
                { id: 'Type.LastName', content: ['Client Last Name', 'A. Anderson'], element: 'input', required: true },
                { id: 'Type.Offer', content: ['Client Offer', 'AED', 'Input client offer for property'], element: 'input-group', required: true, regexp: regExps.isNumber },
                { id: 'Type.Type', content: ['Offer Type', 'Select option'], selectors: ['Cash Buyer', 'Mortgage Buyer'], element: 'select', required: true },
                { id: 'Type.BankName', content: ['Bank Name', 'Your Bank'], element: 'input', required: true },
                { id: 'Type.BankPreApproval', content: ['Bank Pre-approval', 'Select option'], selectors: ['Yes', 'No'], element: 'select', required: true },
                { id: 'Type.PreApprovedAmount', content: ['Pre-Approved Amount', 'Amount'], element: 'input', required: true },
                { id: 'Type.AdditionalNotes', content: ['Client Additional Notes', "Note client's special needs"], element: 'input' },
                { element: 'separator' },
                { id: 'marketing_headline', element: 'label-primary' },
                { id: 'images_slider', element: 'slider-images' }
            ],
            footer: [
                { id: 'submit', content: ['Add offer'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ],
            btnTitle: 'Make offer'
        },
        {
            id: 'agent_add_client_modal',
            header: 'Fill the form and select your client’s preferences',
            form: [
                { id: 'FirstName', content: ['Client First Name', 'Thomas'], element: 'input', required: true },
                { id: 'LastName', content: ['Client Last Name', 'A. Anderson'], element: 'input', required: true },
                { id: 'Email', content: ['Client Email', 'Email'], element: 'input', required: true },
                { id: 'Phone', content: ['Client Phone', 'Phone'], element: 'input', required: true },
                { id: 'Areas', content: ['Areas', 'Select options'], selectors: allAreas, element: 'select', required: true, multiple: true },
                { id: 'Features', content: ['Preferred features', 'Features'], element: 'select', selectors: ['Close to metro', 'Close to mall', 'Close to clinic'], required: true, multiple: true },
                { id: 'Appliances', content: ['Preferred appliances', 'Appliances'], element: 'select', selectors: ['Oven', 'Built-in kitchen', 'Fridge'], required: true, multiple: true },
                { id: 'Amenities', content: ['Preferred amenities', 'Amenities'], element: 'select', selectors: ['Gym', 'Pool', 'Sauna'], required: true, multiple: true },
                { id: 'Bedrooms', content: ['Preferred bedrooms', 'Bedrooms'], element: 'range', min: 1, max: 5, required: true },
                { id: 'Bathrooms', content: ['Preferred bathrooms', 'Bathrooms'], element: 'range', min: 1, max: 5, required: true },
                { id: 'PriceStart', content: ['Price from', 'AED', 'From'], element: 'input-group', required: true },
                { id: 'PriceEnd', content: ['Price to', 'AED', 'To'], element: 'input-group', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Add a client'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'client_modal',
            header: 'Client',
            form: [
                { id: 'FirstName', content: ['Client First Name'], element: 'label-secondary', icon: 'la la-user' },
                { id: 'LastName', content: ['Client Last Name'], element: 'label-secondary', icon: 'la la-user' },
                { id: 'Email', content: ['Client Email'], element: 'label-secondary', icon: 'la la-mail-bulk' },
                { id: 'Phone', content: ['Client Phone'], element: 'label-secondary', icon: 'la la-phone' },
                { id: 'Areas', content: ['Areas'], selectors: allAreas, element: 'label-secondary', icon: 'la la-map-marker' },
                { id: 'Features', content: ['Preferred features'], element: 'label-secondary', icon: 'la la-star' },
                { id: 'Appliances', content: ['Preferred appliances'], element: 'label-secondary', icon: 'la la-star' },
                { id: 'Amenities', content: ['Preferred amenities'], element: 'label-secondary', icon: 'la la-star' },
                { id: 'BedroomsStart', content: ['Bedrooms from'], element: 'label-secondary', icon: 'la la-bed' },
                { id: 'BedroomsEnd', content: ['Bedrooms to'], element: 'label-secondary', icon: 'la la-bed' },
                { id: 'BathroomsStart', content: ['Bathrooms from'], element: 'label-secondary', icon: 'la la-bath' },
                { id: 'BathroomsEnd', content: ['Bathrooms to'], element: 'label-secondary', icon: 'la la-bath' },
                { id: 'PriceStart', content: ['Preferred price from'], element: 'label-secondary', icon: 'la la-money' },
                { id: 'PriceEnd', content: ['Preferred price to'], element: 'label-secondary', icon: 'la la-money' }
            ],
            footer: [
                { id: 'close', content: ['Close'], element: 'button-secondary' }
                // { id: 'close', content: ['Close'], element: 'button-secondary', withIcon: true }
            ]
        },

        {
            id: 'viewing_actions_from_agent',
            header: 'Actions Viewing',
            form: [
                { id: 'viewing_actions_from_agent' }
            ],
            footer: [
                // { id: 'submit', content: ['Reject'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'offer_actions_from_agent',
            header: 'Actions Offer',
            form: [
                { id: 'offer_actions_from_agent' }
            ],
            footer: [
                // { id: 'submit', content: ['Reject'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'offer_actions_from_landlord',
            header: 'Actions Offer',
            form: [
                { id: 'offer_actions_from_landlord' }
            ],
            footer: [
                // { id: 'submit', content: ['Reject'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'reschedule_viewing_modal_from_agent',
            header: 'Reschedule viewing',
            form: [
                { id: 'Datetime', content: ['Time of viewing'], element: 'input-date-time', required: true }
                // { id: 'RejectReason', content: ['Reschedule reason', ''], element: 'textarea', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'reject_viewing_modal',
            header: 'Reject viewing',
            form: [
                { id: 'RejectReason', content: ['Reject reason', ''], element: 'textarea', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Reject'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'reschedule_viewing_modal',
            header: 'Reschedule viewing',
            form: [
                { id: 'Datetime', content: ['Time of viewing'], element: 'input-date-time', required: true },
                { id: 'RejectReason', content: ['Reschedule reason', ''], element: 'textarea', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'reschedule_viewing_modal_lock',
            header: 'Reschedule viewing',
            form: [
                { id: 'Datetime', content: ['Time of viewing'], element: 'input-date-time', required: true },
                { id: 'RejectReason', content: ['Reschedule reason', ''], element: 'textarea', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'reschedule_viewing_modal_keys',
            header: 'Reschedule viewing',
            form: [
                { id: 'Datetime', content: ['Time of viewing'], element: 'input-date-time', required: true },
                { id: 'RejectReason', content: ['Reschedule reason', ''], element: 'textarea', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'complain_viewing_modal',
            header: 'Make complaint on viewing',
            form: [
                { id: 'Complaint', content: ['Complaint reason', ''], element: 'textarea', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'lock_pass_viewing_modal',
            header: 'Set digital lock pass',
            form: [
                { id: 'DigitalLockPass', content: ['Digital lock pass', ''], element: 'input', regexp: regExps.isNumber, required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'lock_pass_viewing_modal_reschedule',
            header: 'Set digital lock pass',
            form: [
                { id: 'DigitalLockPass', content: ['Digital lock pass', ''], element: 'input', regexp: regExps.isNumber, required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'set_key_bookings_modal',
            header: 'Set key bookings',
            form: [
                { id: 'date_of_viewing', content: ['DATE OF VIEWING'], element: 'label-secondary' },
                { id: 'PickUpDate', content: ['Pick up time', 'Pick up date'], element: 'input-date-time', required: true },
                { id: 'DropOutDate', content: ['Return time', 'Return date'], element: 'input-date-time', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'set_key_bookings_modal_reschedule',
            header: 'Set key bookings',
            form: [
                { id: 'date_of_viewing', content: ['DATE OF VIEWING'], element: 'label-secondary' },
                { id: 'PickUpDate', content: ['Pick up time', 'Pick up time'], element: 'input-date-time', required: true },
                { id: 'DropOutDate', content: ['Return time', 'Return date'], element: 'input-date-time', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'reject_offer_modal',
            header: 'Reject offer',
            form: [
                { id: 'RejectReason', content: ['Reject reason', ''], element: 'textarea', required: true, maxLength: 255 }
            ],
            footer: [
                { id: 'submit', content: ['Reject'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'require_resubmit_offer_modal',
            header: 'Require offer resubmit',
            form: [
                { id: 'RejectReason', content: ['Resubmit reason', ''], element: 'textarea', required: true, maxLength: 255 }
            ],
            footer: [
                { id: 'submit', content: ['Require resubmit'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'negotiate_offer_modal',
            header: 'Negotiate on offer',
            form: [
                {id: 'negotiable_state'},
                // { id: 'Type[0].Offer', content: ['New price', 'AED', ''], element: 'input-group', required: true },
                { id: 'Type[0].Payments', content: ['New Payments', 'Select count'], selectors: ['1', '2', '3', '4', '6', '12'], element: 'select', required: true },
                { id: 'Type[0].ContractStartDate', content: ['New Contract Start Date', 'Input contract start date'], dateAfter: true, element: 'input-date', isBlockPast: true, required: true/* , trigger: 'scannedData.ContractStartDate' */ },
                // { id: 'Type[0].Duration', content: ['New Contract Duration', 'Month', 'Input contract duration'], element: 'input-group', required: true },
                { id: 'Type[0].Duration', content: ['New Contract Duration', 'Select contract duration'], selectors: _.range(6, 25).map(item => ({ label: `${item} Months`, value: item })), element: 'select', required: true },
                { id: 'Type[0].AdditionalNotes', content: ['New Additional Notes', "Note client's special needs"], element: 'textarea' }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
      
        {
            id: 'submit_deposit_slip_offer_modal',
            header: 'Offer confirmation',
            form: [
                { id: 'switch_deposit_slip' },
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
     
        {
            id: 'agent_offer_submit_client_info_modal',
            header: 'Send client info',
            form: [
                { id: 'state_client_data_offer_modal' },
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'agent_to_bso_make_offer_upload_custom_documents',
            header: 'Upload required document',
            form: [
                { id: 'custom_document', content: ['Upload document', 'Upload file'], element: 'input-file', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'agent_offer_submit_draft_contract_changes_modal',
            header: 'Send draft contract changes',
            form: [
                { id: 'draft_changes', content: ['Preferences Changes', ''], element: 'textarea', required: true, maxLength: 500 }
            ],
            footer: [
                { id: 'submit', content: ['Send'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },

        {
            id: 'agent_offer_choose_contract_type',
            header: 'Choose contract type',
            form: [
                { id: 'agent_offer_choose_contract_type' },            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'change_status_offer_modal',
            header: 'Check slip on offer',
            form: [
                { id: 'Statuses[0]', content: ['Offer status'], selectors: ['Downpayment', 'Signing F form', 'Signing other documents', 'Obtaining NOC', 'Transfer process', 'Completed'], element: 'select' }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'check_advertisement_links_modal',
            header: 'Check Advertisement Trakheesi ID',
            form: [
                // { id: 'Links[0].Status', content: ['https://bso.ae'], element: 'check-link' }
                { id: 'RejectReason', content: ['Reject reason', ''], element: 'textarea', required: false }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'check_a_form_modal',
            header: 'Check A Form',
            form: [
                { id: 'a_form_link', content: ['A Form', 'link'], element: 'header-link' },
                { id: 'images_slider', height: '500px', element: 'slider-images' }
            ],
            footer: [
                { id: 'submit', content: ['Approve'], element: 'button-primary' },
                { id: 'reset', content: ['Reject'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'approve_a_form',
            header: 'Approve A Form',
            form: [
                { id: 'a_form_link', content: ['A Form', 'link'], element: 'header-link' },
                { id: 'images_slider', height: '500px', element: 'slider-images' },
                { id: 'StartDate', content: ['Advertisement Start Date', 'Input advertisement start date'], element: 'input-date', required: true },
                { id: 'EndDate', content: ['Advertisement End Date', 'Input advertisement end date'], element: 'input-date', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Back'], element: 'button-secondary' }
            ]
        },
        {
            id: 'reject_a_form',
            header: 'Reject A Form',
            form: [
                { id: 'a_form_link', content: ['A Form', 'link'], element: 'header-link' },
                { id: 'images_slider', height: '500px', element: 'slider-images' },
                { id: 'RejectReason', content: ['Reject reason', ''], element: 'textarea', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Back'], element: 'button-secondary' }
            ]
        },
        {
            id: 'check_booking_form_modal',
            header: 'Upload signed booking form',
            form: [
                { id: 'SignedBookingForm', content: ['Signed booking form', 'Upload booking form'], element: 'input-file', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'faq_modal',
            header: 'Template',
            form: [
                { id: 'markdown', element: 'markdown' }
            ],
            footer: [
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        {
            id: 'admin_upload_offer_document_modal',
            header: 'Template',
            form: [
                { id: 'document', content: ['Upload document'], element: 'input-file' }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary' }
            ]
        },
        // CREATE_UNIT_V2_PIPELINE
        {
            id: 'landlord_create_unit_choose_role',
            header: 'Add a Unit',
            progress: 10,
            form: [
                { content: 'Tell us your role', element: 'modal-title' },
                { content: ['Select whether which option suits you most'], paddingBottom: 'small', paddingX: 'small', element: 'modal-description' },
                {
                    id: 'role',
                    selectors: [
                        {
                            value: 'owner',
                            title: 'I own the unit',
                            description: 'Ligula aptent conubia molestie suspendisse ut rhoncus. Hendrerit quis vehicula integer natoque consectetur dictumst enim primis ante. ',
                            icon: {
                                type: 'image',
                                src: '/assets/media/landlord/create_unit/owner.svg'
                            },
                            color: '#2168F2'
                        },
                        {
                            value: 'representative',
                            title: 'I am representing someone',
                            description: 'Ligula aptent conubia molestie suspendisse ut rhoncus. Hendrerit quis vehicula integer natoque consectetur dictumst enim primis ante. ',
                            icon: {
                                type: 'image',
                                src: '/assets/media/landlord/create_unit/representative.svg'
                            },
                            color: '#9E74F7'
                        }
                    ],
                    fillImage: false,
                    titlePlacement: 'vertical',
                    showRadio: false,
                    element: 'cards-select'
                }
            ],
            footer: [
                { id: 'submit', content: ['NEXT'], element: 'modal-submit-button'},
                { id: 'back', redirect: '/landlord/my_units', element: 'modal-back' }
            ]
        },
        {
            id: 'landlord_create_unit_users_passport',
            header: 'Add a unit',
            progress: 15,
            form: [
                { content: /* landlordAddUnitReadFromStorageV2()?.landlord_create_unit_choose_role?.role === 'representative' ? 'Upload Owner\'s Passport Copy' : */ 'Upload your Passport Copy', paddingX: 'small', element: 'modal-title' },
                { content: ['Ensure that the document is clear, legible, and visible'], paddingX: 'small', element: 'modal-description' },
                { src: '/assets/media/auth/documents/passport.png', imageHeight: '388px', element: 'modal-image'},
                { id: 'visa', content: [undefined, 'Upload a photo'], required: true, submitOnInput: true, element: 'input-file' },
                { content: ['Scan your ID'], href: '#', element: 'button-link' }
            ],
            footer: [
                { id: 'back', element: 'modal-back' }
            ]
        },
        {
            id: 'landlord_create_unit_verify_user_id',
            header: 'Create Account',
            progress: 18,
            form: [
                { content: 'ID Verification', element: 'modal-title' },
                { content: ['Complete and verify the following information'], element: 'modal-description'},
                { id: 'first_name', content: ['First Name', 'Thomas'], element: 'input', required: true, defaultValue: window.localStorage.getItem(USER_NAME_KEY) ? window.localStorage.getItem(USER_NAME_KEY)?.split(' ')[1] : '' },
                { id: 'last_name', content: ['Last Name', 'A. Anderson'], element: 'input', required: true, defaultValue: window.localStorage.getItem(USER_NAME_KEY) ? `${window.localStorage.getItem(USER_NAME_KEY)?.split(' ')[0]}` : '' },
                { id: 'gender', content: ['Gender', 'Choose your gender'], selectors: ['Male', 'Female'], element: 'select', required: true, ...(window.localStorage.getItem(USER_GENDER_KEY) ? { defaultValue: window.localStorage.getItem(USER_GENDER_KEY) } : {}) },
                { id: 'dob', content: ['Date of birth', 'Choose your date of birth'], element: 'input-date', required: true, ...(window.localStorage.getItem(USER_DOB_KEY) != null ? { defaultValue: window.localStorage.getItem(USER_DOB_KEY) } : {}) },
                { id: 'country', content: ['Country of birth', 'Choose your country of birth'], selectors: getCountries(), element: 'select', required: true, ...(window.localStorage.getItem(USER_NATIONALITY_KEY) ? { defaultValue: window.localStorage.getItem(USER_NATIONALITY_KEY) } : {}) },
                { content: ['Wrong info?', 'Click here to rescan passport'], href: '/landlord/modal/v2/landlord_create_unit_users_passport', stickToBottom: true, element: 'text-link', height: '100%' }
            ],
            footer: [
                { id: 'submit', content: ['REGISTER'], style: 'bso', element: 'modal-submit-button' },
                { id: 'back', element: 'modal-back' }
            ]
        },
        {
            id: 'landlord_create_unit_representative_auth_method',
            header: 'Add a unit',
            progress: 20,
            form: [
                { content: 'Authenticate Your Legal Representation', paddingX: 'small', element: 'modal-title' },
                { content: ['Kindly choose one process to confirm your role as authorized representative'], paddingX: 'small', element: 'modal-description' },
                {
                    links: [
                        {
                            title: 'Upload landlord’s passport copy',
                            description: 'Maximus dolor sociosqu risus magnis fames sodales metus dapibus.',
                            href: '/landlord/modal/v2/landlord_create_unit_owner_passport/-/-'
                        },
                        {
                            title: 'Request landlord approval using app',
                            description: 'Cursus mollis sed nam eget velit risus torquent penatibus.',
                            href: '/landlord/modal/v2/landlord_create_unit_find_owner/-/-'
                        }
                    ],
                    element: 'link-list'
                }
            ],
            footer: [
                { id: 'back', element: 'modal-back' }
            ]
        },
        {
            id: 'landlord_create_unit_owner_passport',
            header: 'Add a unit',
            progress: 30,
            form: [
                { content: 'Upload Landlord’s Passport Copy', paddingX: 'small', element: 'modal-title' },
                { content: ['Ensure that the document is clear, legible, and visible'], paddingX: 'small', element: 'modal-description' },
                { src: '/assets/media/auth/documents/passport.png', imageHeight: '388px', element: 'modal-image'},
                { id: 'visa', content: [undefined, 'Upload a photo'], required: true, submitOnInput: true, element: 'input-file' },
                { content: ['Scan your ID'], href: '#', element: 'button-link' }
            ],
            footer: [
                { id: 'back', element: 'modal-back' }
            ]
        },
        {
            id: 'landlord_create_unit_id_verification',
            header: 'Add a unit',
            progress: 40,
            form: [
                { content: 'ID Verification', element: 'modal-title' },
                { content: ['Complete and verify the following information'], element: 'modal-description'},
                { id: 'owner_first_name', content: ['First Name', 'Thomas'], element: 'input', disabled: true },
                { id: 'owner_last_name', content: ['Last Name', 'A. Anderson'], element: 'input', disabled: true },
                { id: 'owner_gender', content: ['Gender', 'Choose your gender'], selectors: ['Male', 'Female'], element: 'select', required: true },
                { id: 'owner_dob', content: ['Date of birth', 'Choose your date of birth'], element: 'input-date', required: true },
                { id: 'owner_country', content: ['Country of birth', 'Choose your country of birth'], selectors: getCountries(), element: 'select', required: true },
                { content: ['Wrong info?', 'Click here to rescan ID'], href: '/landlord/modal/v2/landlord_create_unit_owner_passport/-/-', stickToBottom: true, element: 'text-link', height: '100%' }
            ],
            footer: [
                { id: 'back', element: 'modal-back' },
                { id: 'submit', content: ['NEXT'], element: 'modal-submit-button'},
            ]
        },
        {
            id: 'landlord_create_unit_find_owner',
            header: 'Add a unit',
            progress: 30,
            form: [
                { content: 'Enter landlord’s registered email', paddingX: 'small', element: 'modal-title' },
                { content: ['We will send a notification to the landlord your representing through the app. Once approved, you may continue.'], element: 'modal-description' },
                { id: 'landlord_id', categoryFilter: 'Landlord', element: 'find-user-by-email' }
            ],
            footer: [
                { id: 'back', element: 'modal-back' },
                { id: 'submit', content: ['NEXT'], element: 'modal-submit-button'},
            ]
        },
        {
            id: 'landlord_create_unit_wait_for_owner',
            header: 'Add a unit',
            progress: 40,
            form: [
                { content: 'Waiting for an Approval', paddingX: 'small', element: 'modal-title' },
                { content: ['We have sent a notification to the landlord your representing. Once approved, you may continue.'], element: 'modal-description' },
                { src: '/assets/media/landlord/create_unit/transfer.png', imageHeight: '210px', element: 'modal-image'},
                { element: 'user-waiting-approve' }
            ],
            footer: [
                { id: 'back', element: 'modal-back' }
            ]
        },
        {
            id: 'landlord_create_unit_upload_title_deed',
            header: 'Add a unit',
            progress: 50,
            form: [
                { content: 'Upload Title Deed', paddingX: 'small', element: 'modal-title' },
                { content: ['Ensure that the document is clear, legible, and visible.'], paddingX: 'small', element: 'modal-description' },
                { id: 'title_deed', element: 'card-input-file', required: true },
                {
                    title: 'Guidelines to upload a proper document:',
                    checklist: [
                        'Certificate Number or QR must be readable.',
                        'Must show owner’s full name clearly.',
                        'The document should be unmarked, with no additional handwriting, stamps, or edits.',
                        'Ensure the document is properly aligned.',
                        'No parts of the document should be cut off.'
                    ],
                    element: 'info-checklist'
                },
                { element: 'contact-support', height: '100%', stickToBottom: true }
            ],
            footer: [
                { id: 'back', element: 'modal-back' },
                { id: 'submit', content: ['NEXT'], element: 'modal-submit-button'}
            ]
        },
        {
            id: 'landlord_create_unit_property_info',
            header: 'Add a unit',
            progress: 60,
            form: [
                { content: 'Complete Unit Info', paddingX: 'small', element: 'modal-title' },
                { content: ['Provide the main property details like **building name**, **area**, and **Google Maps** link of precise location.'], paddingBottom: 'small', paddingX: 'small', markdown: true, element: 'modal-description' },
                {
                    id: 'title_deed_info',
                    inputs: [
                        { id: 'title_deed_number', content: ['Title Deed No.', ''], disabled: true, paddingBottom: 'small'},
                        { id: 'title_deed_year', content: ['Year Issue', ''], disabled: true, paddingBottom: 'small'}
                    ],
                    element: 'inputs-group'
                },
                { id: 'unit_type', content: ['Type', 'Input unit type'], element: 'input', disabled: true, paddingBottom: 'small' },
                { id: 'owner_name', content: ['Unit Owner', 'Input owner name'], element: 'input', disabled: true, paddingBottom: 'small' },
                { id: 'Property.Name', content: ['Building Community Name', 'Input property name'], element: 'input', disabled: true, paddingBottom: 'small' },
                { id: 'Property.Area', content: ['Location', 'Select location'], selectors: getAreas().filter((area) => area !== 'All areas').sort(), element: 'select', required: true, paddingBottom: 'small' },
                { id: 'Property.GoogleMapsLink', content: ['Google Maps Link', 'Paste the link from google maps'], element: 'input', regexp: regExps.isGoogleMapsLink, errorMessage: 'Google Maps Link must be valid', required: true, paddingBottom: 'small' },
                { element: 'contact-support', height: '100%', stickToBottom: true }
            ],
            footer: [
                { id: 'back', element: 'modal-back' },
                { id: 'submit', content: ['NEXT'], element: 'modal-submit-button'}
            ]
        },
        {
            id: 'landlord_create_unit_unit_info',
            header: 'Add a unit',
            progress: 70,
            form: [
                { content: 'Complete Unit Info', paddingX: 'small', element: 'modal-title' },
                { content: ['Enter more helpful unit details like the **unit type**, **unit number**, **unit size**, **bedroom** and **bathroom count**.'], paddingBottom: 'small', paddingX: 'small', markdown: true, element: 'modal-description' },
                { id: 'Type', content: ['Property Type:', 'Select property type'], selectors: ['Residential', 'Commercial'], element: 'select', paddingBottom: 'small', required: true },
                { id: 'Number', content: ['Unit No.', 'Enter unit number'], minLength: 1, element: 'input', regexp: regExps.isNumber, paddingBottom: 'small', disabled: true },
                { id: 'SizeArea', content: ['Unit Size', '000'], unit: 'sqft', element: 'input', regexp: regExps.isNumber, required: true, paddingBottom: 'small', errorMessage: 'Unit Size must be valid' },
                { id: 'Utilities.ACCount', content: ['Number of A/C units installed in the property', '0'], min: 0, regexp: regExps.isNumber, paddingBottom: 'small', element: 'input', type: 'number', errorMessage: 'Number of A/C units must be valid' },
                { id: 'ac_count_unknown', content: ['I don\'t know the ac count'], element: 'input-checkbox', paddingBottom: 'small' },
                { id: 'Bedrooms', content: ['Bedroom Count', '0'], min: 0, type: 'number', regexp: regExps.isNumber, element: 'input', paddingBottom: 'small', errorMessage: 'Field must be valid' },
                { id: 'MaidsRoom', content: ['with Maid’s Room'], element: 'input-checkbox', paddingBottom: 'small' },
                { id: 'Bathrooms', content: ['Bathroom Count', '0'], min: 0, type: 'number', regexp: regExps.isNumber, element: 'input', paddingBottom: 'small', errorMessage: 'Field must be valid' },
                { id: 'GuestBathroom', content: ['with Guest Bathroom'], element: 'input-checkbox', paddingBottom: 'small' },
                { element: 'contact-support', height: '100%', stickToBottom: true }
            ],
            footer: [
                { id: 'back', element: 'modal-back' },
                { id: 'submit', content: ['ADD UNIT'], style: 'bso', element: 'modal-submit-button'}
            ]
        },
        {
            id: 'landlord_create_unit_unit_features',
            header: 'Add a unit',
            progress: 100,
            form: [
                { content: 'Complete Unit Info', paddingX: 'small', element: 'modal-title' },
                { content: ['Describe the **indoor** environment, by specifying **A/C count**'], paddingBottom: 'small', paddingX: 'small', markdown: true, element: 'modal-description' },
                { id: 'Utilities.ACCount', content: ['Number of A/C units installed in the property', '0'], min: 0, regexp: regExps.isNumber, paddingBottom: 'small', element: 'input', type: 'number', errorMessage: 'Number of A/C units must be valid' },
                { element: 'contact-support', height: '100%', stickToBottom: true }
            ],
            footer: [
                { id: 'back', element: 'modal-back' },
                { id: 'submit', content: ['ADD UNIT'], style: 'bso', element: 'modal-submit-button'}
            ]
        },
        // SERVICES
        // ORDER FIND TENANT PIPELINE
        {
            id: 'service_finding_tenant',
            header: 'Find a Tenant',
            progress: 30,
            form: [
                { content: 'Choose your ideal dates for service', element: 'modal-title' },
                { content: ['Minimum of 10 days and maximum of 30 days.'], paddingBottom: 'small', element: 'modal-description' },
                { id: 'dates', element: 'input-date-range', height: '100%', required: true }
            ],
            footer: [
                { id: 'back', element: 'modal-back' },
                { id: 'submit', content: ['NEXT'], element: 'modal-submit-button'}
            ]
        },
        {
            id: 'service_finding_tenant_unit_info',
            header: 'Find a tenant',
            progress: 50,
            form: [
                { content: 'Complete Unit Info', element: 'modal-title' },
                { content: ['Fill up the marketing info like **type**, **indoor** and **outdoor** environment.'], markdown: true, paddingBottom: 'small', element: 'modal-description' },
                { id: 'Fit', content: ['Interior Finishing'], element: 'select', paddingBottom: 'small', selectors: store.getState().app.config?.units?.Fit ?? [] },
                { id: 'Appliances', content: ['Appliances Included'], element: 'select', paddingBottom: 'small', multiple: true, selectors: store.getState().app.config?.units?.Appliances ?? [] },
                { id: 'Features', content: ['Outdoor Features'], element: 'select', paddingBottom: 'small', multiple: true, selectors: store.getState().app.config?.units?.Features ?? [] },
                { id: 'Amenities', content: ['Amenities Available'], element: 'select', paddingBottom: 'small', multiple: true, selectors: store.getState().app.config?.units?.Amenities ?? [] },
                { id: 'View', content: ['View from the property'], element: 'select', paddingBottom: 'small', selectors: store.getState().app.config?.units?.View ?? [] },
            ],
            footer: [
                { id: 'back', element: 'modal-back' },
                { id: 'submit', content: ['NEXT'], element: 'modal-submit-button'}
            ]
        },
        {
            id: 'service_finding_tenant_marketing_info',
            header: 'Find a tenant',
            progress: 50,
            form: [
                { content: 'Complete Unit Info', element: 'modal-title' },
                { content: ['Fill up the marketing info like **headline** and **price**.'], markdown: true, paddingBottom: 'small', element: 'modal-description' },
                { id: 'Marketing.Use', content: ['Marketing Type'], selectors: ['For rent', 'For sale'], defaultValue: 'For rent', disabled: true, required: true, element: 'select' },
                { id: 'Marketing.Headline', content: ['Marketing Headline', 'Input marketing headline'], required: true, element: 'input' },
                { id: 'Marketing.Price', content: ['Asking Price', '70000'], type: 'number', min: 0, unitsString: 'amount in AED', required: true, errorMessage: 'Asking Price must be valid', element: 'input' },
            ],
            footer: [
                { id: 'back', element: 'modal-back' },
                { id: 'submit', content: ['NEXT'], element: 'modal-submit-button'}
            ]
        },
        {
            id: 'service_finding_tenant_photos',
            header: 'Find a tenant',
            progress: 70,
            form: [
                { content: 'Complete Unit Info', element: 'modal-title' },
                { content: ['Upload any other files related to the unit, such as **photos**, **video**, and **floor plan**.'], markdown: true, paddingBottom: 'small', element: 'modal-description' },
                { id: 'Images', content: ['Photos of the Property'], size: 'small', type: 'image', multiple: true, element: 'card-input-file' },
                { id: 'Video', content: ['Video of the Property'], size: 'small', type: 'video', element: 'card-input-file' },
                { id: 'FloorPlan', content: ['Floor Plan of the Property'], size: 'small', type: 'image', element: 'card-input-file' },

            ],
            footer: [
                {
                    id: 'bso_photography',
                    actionText: 'Add BSO Photography',
                    price: '1,000 AED',
                    title: 'Skip this step\nand hire BSO for\nprofessional photos!',
                    description: 'Check on the box below to\nadd package during checkout.',
                    backgroundImageSrc: '/assets/media/landlord/services/images/gallery.png',
                    iconSrc: '/assets/media/landlord/services/icons/finding-tenant.svg',
                    element: 'footer-up-sale'
                },
                { id: 'back', element: 'modal-back' },
                { id: 'submit', content: ['NEXT'], element: 'modal-submit-button'}
            ]
        },
        {
            id: 'service_finding_tenant_addons',
            header: 'Find a tenant',
            progress: 100,
            form: [
                { content: 'Choose Add Ons', element: 'modal-title' },
                { content: ['Match your service with other BSO offers!'], markdown: true, paddingBottom: 'small', element: 'modal-description' },
                {
                    id: 'addons',
                    addons: [
                        {
                            id: 'unit_photography',
                            title: 'Unit Photography',
                            description: 'Lorem ipsum odor amet, consectetuer adipiscing elit. Inceptos elementum mus adipiscing nibh. ',
                            priceText: '1,000 AED',
                            iconSrc: '/assets/media/landlord/services/icons/camera.svg'
                        },
                        {
                            id: 'request_inspection',
                            title: 'Request Inspection',
                            description: 'Lorem ipsum odor amet, consectetuer adipiscing elit. Inceptos elementum mus adipiscing nibh. ',
                            priceText: 'Price will be calculated after',
                            iconSrc: '/assets/media/landlord/services/icons/inspection.svg'
                        },
                        {
                            id: 'present_value_ratio',
                            title: 'Present Value Ratio',
                            description: 'Lorem ipsum odor amet, consectetuer adipiscing elit. Inceptos elementum mus adipiscing nibh. ',
                            priceText: '1,000 AED',
                            iconSrc: '/assets/media/landlord/services/icons/business-graph.svg'
                        }
                    ],
                    // preselected: ['unit_photography'],
                    element: 'service-addons'
                }
            ],
            footer: [
                { id: 'back', element: 'modal-back' },
                { id: 'all', element: 'service-footer-count' },
                { id: 'submit', content: ['CHECKOUT'], style: 'bso', element: 'modal-submit-button'}
            ]
        },
        {
            id: 'service_finding_tenant_checkout',
            header: 'Order Confirmation',
            form: [
                {
                    id: 'services',
                    element: 'service-checkout',
                    services: [
                        {
                            ...ServicesConfig.getService('find_tenant'),
                            mainService: true
                        },
                        {
                            ...ServicesConfig.getService('unit_photography')
                        },
                        {
                            ...ServicesConfig.getService('request_inspection')
                        },
                        {
                            ...ServicesConfig.getService('present_value_ratio')
                        }
                    ]
                },
            ],
            footer: [
                { id: 'back', element: 'modal-back' },
                { id: 'submit', content: ['PAY NOW'], style: 'bso', element: 'modal-submit-button'}
            ]
        },
        {
            id: 'service_finding_tenant_end',
            form: [
                {
                    content: [
                        'Order Confirmed!',
                        'Your transaction is done. We will notify you in the next 48 hours when service is completed.'
                    ],
                    src: '/assets/media/landlord/services/images/success-badge.png',
                    height: '100%',
                    element: 'info-block'
                }
            ],
            footer: [
                { id: 'submit', content: ['OKAY'], element: 'modal-submit-button' },
                { id: 'close', redirect: '/landlord/my_units', element: 'modal-close' }
            ]
        },
        // CREATE UNIT V1 PIPELINE
        {
            id: 'landlord_create_unit',
            header: 'Add new unit in portfolio',
            form: [
                { id: 'create_unit_by_landlord_info' },
                { id: 'title_deed', content: ['Scan title deed'], element: 'input-file', multiple: false, required: true }

                // { element: 'separator' },
                // { element: 'contact-support-button' },
                // { element: 'separator' },
                // { id: 'description_title_deed' }
            ],
            footer: [
                { id: 'submit', content: ['Next'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        },
        {
            id: 'landlord_create_unit_info',
            header: 'Add new unit in portfolio',
            form: [
                { id: 'create_unit_by_landlord_info' },
                { id: 'title_deed_info', content: [], element: 'label-badge' },
                // { id: 'Number', content: ['Unit number', 'Enter unit number'], minLength: 1, element: 'input', regexp: regExps.isNumber, required: true },
                { id: 'Property.Name', content: ['Building/Community Name', 'Input property name'], element: 'input', required: true },
                { id: 'Property.Area', content: ['Location', 'Select location'], selectors: getAreas().filter((area) => area !== 'All areas').sort(), element: 'select', required: true },
                { id: 'Property.GoogleMapsLink', content: ['Google Maps Link', 'Paste the link from google maps'], element: 'input', regexp: regExps.isGoogleMapsLink }
                // { id: 'owning_status', content: ['Owning status', 'Select the owning status'], selectors: unitOwningStatuses, element: 'select', required: true },
            ],
            footer: [
                { id: 'submit', content: ['Next'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        },
        {
            id: 'landlord_create_main_info_first',
            header: 'Add new unit in portfolio',
            form: [
                { id: 'create_unit_by_landlord_info' },
                { id: 'Type', content: ['Property Type:', 'Select property type'], selectors: ['Residential', 'Commercial'], element: 'select', required: true },
                { id: 'Number', content: ['Unit Number', 'Enter unit number'], minLength: 1, element: 'input', regexp: regExps.isNumber, required: true },
                { id: 'SizeArea', content: ['Unit Size', 'sqr.ft', 'Enter unit size'], element: 'input-group', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Next'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        },
        {
            id: 'landlord_create_main_info_second',
            header: 'Add new unit in portfolio',
            form: [
                { id: 'create_unit_by_landlord_info' },
                { id: 'Bedrooms', content: ['Number of Bedrooms', 'Enter the number of bedrooms or “Studio”'], minLength: 1, element: 'input' },
                { id: 'Bathrooms', content: ['Number of Bathrooms/Restrooms', 'Enter the number of bathrooms'], minLength: 1, element: 'input' },
                { id: 'GuestBathroom,MaidsRoom', content: ['Unit has guest bathroom', 'Unit has maids room'], element: 'input-checkbox-group' }
                // { id: 'GuestBathroom', content: ['Unit with guest bathroom', 'Select option'], selectors: ['Yes', 'No'], element: 'select' },
                // { id: 'MaidsRoom', content: ['Unit with maids room', 'Select option'], selectors: ['Yes', 'No'], element: 'select' }
            ],
            footer: [
                { id: 'submit', content: ['Next'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        },
        {
            id: 'landlord_create_unit_additional_info',
            header: 'Add new unit in portfolio',
            form: [
                { id: 'create_unit_by_landlord_info' },
                { id: 'Utilities.ACCount', content: ['Number of A/C units installed in the property', 'Input AC Count'], regexp: regExps.isNumber, element: 'input' },
                { id: 'Fit', content: ['Interior Finishing', 'Select fit'], element: 'select', selectors: store.getState().app.config?.units?.Fit ?? [] },
                { id: 'Appliances', content: ['Appliances Included', 'Select appliances'], element: 'select', multiple: true, selectors: store.getState().app.config?.units?.Appliances ?? [] }
            ],
            footer: [
                { id: 'submit', content: ['Next'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        },
        {
            id: 'landlord_create_unit_additional_info_outdoor',
            header: 'Add new unit in portfolio',
            form: [
                { id: 'create_unit_by_landlord_info' },
                { id: 'Features', content: ['Outdoor Features', 'Select features'], element: 'select', multiple: true, selectors: store.getState().app.config?.units?.Features ?? [] },
                { id: 'Amenities', content: ['Amenities Available', 'Select amenities'], element: 'select', multiple: true, selectors: store.getState().app.config?.units?.Amenities ?? [] },
                { id: 'View', content: ['View from the property', 'Select view'], element: 'select', selectors: store.getState().app.config?.units?.View ?? [] }
            ],
            footer: [
                { id: 'submit', content: ['Next'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        },
        {
            id: 'landlord_create_unit_additional_info_files',
            header: 'Add new unit in portfolio',
            form: [
                { id: 'create_unit_by_landlord_info' },
                { id: 'Images', content: ['Upload a few photos of the property'], maxCount: 10, element: 'input-images-multiple' },
                { id: 'Video', content: ['Upload a video that showcases the property'], element: 'input-file' },
                { id: 'FloorPlan', content: ['Upload the Floor plan'], element: 'input-file' }
            ],
            footer: [
                { id: 'submit', content: ['Next'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        },
        {
            id: 'landlord_create_unit_owners_passport',
            header: 'Add new unit in portfolio',
            form: [
                { id: 'create_unit_by_landlord_info' },
                { id: 'passport', content: ['Upload Owner Passport'], element: 'input-file', multiple: false, required: true },
                { id: 'email', content: ['Owner email', 'Input owner\'s email'], element: 'input', regexp: regExps.isEmail, required: true },
                { id: 'phone', content: ['Owner phone number', 'Input owner\'s phone number'], element: 'input', regexp: regExps.isPhone, required: true }
            ],
            footer: [
                { id: 'submit', content: ['Next'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        },
        {
            id: 'landlord_create_unit_correct_owner_info',
            header: 'Add new unit in portfolio',
            form: [
                { id: 'create_unit_by_landlord_info' },
                { id: 'FirstName', content: ['Owner first name', 'Input owner\'s first name'], element: 'input', required: true },
                { id: 'FamilyName', content: ['Owner family name', 'Input owner\'s family name'], element: 'input', required: true },
                { id: 'DocumentNumber', content: ['Owner passport number', 'Input owner\'s document number'], element: 'input', required: true },
                { id: 'Email', content: ['Owner email', 'Input owner\'s email'], element: 'input', regexp: regExps.isEmail, required: true },
                { id: 'Phone', content: ['Owner phone number', 'Input owner\'s phone number'], element: 'input', regexp: regExps.isPhone, required: true }
            ],
            footer: [
                { id: 'submit', content: ['Next'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        },
        {
            id: 'landlord_create_unit_users_passport',
            header: 'Set unit - Scan your passport',
            form: [
                { id: 'passport', content: ['Scan your passport'], element: 'input-file', multiple: false, required: true },
                { element: 'separator' },
                { element: 'contact-support-button' },
                { element: 'separator' },
                {
                    id: 'description',
                    preLine: true,
                    content: ['', 'Scan your passport instruction'],
                    element: 'label-secondary'
                }
            ],
            footer: [
                { id: 'submit', content: ['Scan and continue'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        },
        {
            id: 'landlord_create_unit_poa',
            header: 'Add new unit in portfolio',
            form: [
                { id: 'create_unit_by_landlord_info' },
                { id: 'poa', content: ['Upload POA'], element: 'input-file', multiple: false, required: true }
            ],
            footer: [
                { id: 'submit', content: ['Next'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        },
        {
            id: 'landlord_create_unit_special_requests',
            header: 'Finding tenant',
            form: [
                { id: 'create_unit_by_landlord_info' },
                { id: 'SpecialRequests.Smoking,SpecialRequests.Pets', content: ['No smoking', 'No pets'], element: 'input-checkbox-group' }
            ],
            footer: [
                { id: 'submit', content: ['Next'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        },
        {
            id: 'landlord_create_unit_marketing',
            header: 'Finding tenant',
            form: [
                { id: 'create_unit_by_landlord_info' },
                { id: 'Marketing.Headline', content: ['Marketing Headline', 'Headline that highlights the property’s best features.'], element: 'input', required: true },
                // { id: 'Marketing.Description', content: ['Marketing description', 'Set marketing description'], element: 'textarea' },
                { id: 'Marketing.Use', content: ['Intended Use', 'Select type'], selectors: ['For rent', 'For sale'], element: 'select', required: true },
                { id: 'Marketing.Price', content: ['Listing Price', 'AED', 'Input property price'], element: 'input-group', required: true }
            ],
            footer: [
                { id: 'submit', content: ['Save'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        },
        {
            id: 'landlord_create_unit_request_validation',
            header: 'Upload documents to verify Title Deed',
            form: [
                { id: 'passports', content: ['Upload the passports of landlords'], element: 'input-file', multiple: true, required: true },
                { id: 'poa', content: ['Upload the Power Of Attorney'], element: 'input-file', multiple: false, required: false }
            ],
            footer: [
                { id: 'submit', content: ['Send documents for verification'], element: 'button-primary' },
                { id: 'close', content: ['Close'], element: 'button-secondary', redirect: '/landlord/my_units', attention: true }
            ]
        }
    ]
    return modals
}
