import _ from "lodash"
import { ACCESS_KEY, urlAPI } from "../../../urls"
import { dataFetchingFinish, dataFetchingStart } from "../../slices/modalSlice"

import dayjs from 'dayjs'
import { getErrorss } from "../../../functions/errors/GetErrors"
import { type AppDispatch, type RootState } from "../../store"
import { setOffers } from "../../slices/offersSlice"
import { getQueryOffers } from "../../../functions/shared/api/queries/getQueryOffers"

export const updateOfferDetailsFromAgent = (offerID: number, offerData: Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
  
    try {
        dispatch(dataFetchingStart())
        const offer = _.find(getState().offers.offers, offerItem => offerItem.id === offerID)

        if (offerData?.Type != null) {
            if ('ContractStartDate' in offerData.Type) {
                offerData.Type.ContractStartDate = dayjs(offerData.Type.ContractStartDate).add(dayjs(offerData.Type.ContractStartDate).utcOffset(), 'minutes').toISOString()
            }
            offerData.Type = [{
                ...(offer?.attributes?.Type[0] ?? {}),
                ...((offerData.Type as any[])?.[0] ?? {}),
               
            }] as any
        }

        const bodyObject = {
            method: 'PUT',
            collection: 'offers',
            id : offerID,
            body: { 
                ...offerData,
                Notify: offerData.notifyInjection  ? offerData.notifyInjection : 'BSO'  
            },
            query:  getQueryOffers()
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${String(jwt)}`
            },
            body: JSON.stringify(bodyObject)
        })
        const dataJSON = await response.json()

        if (dataJSON.success === true) {
            if (dataJSON.response.error != null) {
                dispatch(dataFetchingFinish())
                return { isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(setOffers(dataJSON.response.data))
                dispatch(dataFetchingFinish())
                return {isSuccessful: true, textNotification: 'Offer has been updated successfully' }
            }
        } else {
            dispatch(dataFetchingFinish())
            return { isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (err) {
        return {
            isSuccessful: false,
            textNotification: 'Something went wrong. Try again later'
        }
    }
}