import React, { useEffect, useState } from 'react'
import { type IOffer } from '../../../../store/slices/offersSlice'
import { type Unit } from '../../../../store/slices/unitsSlice'
import { Steps } from 'antd'
import { type TimelineItem } from '../viewingTimelines/ViewingTimeline'
import { SchedulerStatus } from '../../../../functions/agent/date/SchedulerStatus'
import Icon from '../../../icon/Icon'
import Approved from '../../../../functions/agent/statuses/Approved'
import renderOfferStatus from "../../../../functions/agent/offers/renderOfferStatus";

import StepComponent from './StepComponent'
import { ShowReason } from '../timelineUtils/ShowReason'
import StepNavigation from './StepNavigation'
import { iconProcess } from './iconProcess'
interface IProps {
    offer: IOffer
    unit: Unit

}

function TimelineSale({ offer, unit }: IProps): JSX.Element {
    let process = 0

    const offerStatuses = [renderOfferStatus(offer?.attributes?.Statuses[0], offer.id)]

    const textsOfferTimelineSale = {
        stepClientData: "Request for Client Documents and Additional Information",
        descriptionStepClientData: 'To proceed further with the offer process, we kindly request you to upload the necessary client documents along with any additional information required. This will help us ensure a smooth and efficient progression of the process.', // 'It will be as deposit , its usual practice before baying property , it will be refound when began pay process.Need to change text.   ', // 'Our specialist will be sent your offer soon.',


        stepInProcess: "Wait for Client Documents and Additional Information approval",
        descriptionInProcess: 'Our specialist will approve',



        stepApprovedOrRejected: 'The client documents were approved!\nYour offer was sent to landlord.',
        descriptionApprovedOrRejected: 'Please wait for the unit’s landlord\'s confirmation of your offer.',

        stepConfirmed: 'Your offer was confirmed by the landlord.',
        descriptionConfirmed: 'Please send us your client\'s contact info: email address and phone number.',

        stepContractDraftSending: 'Wait for the next updates: Our specialist prepare for you draft contract.',
        descriptionStepContractDraftSending: '',

        stepContractDraftConfirmed: 'The draft contract has been sent.',
        descriptionStepContractDraftConfirmed: 'Please check , agree , or send changes to BSO Management according to your preferences.',

        stepChooseContractType: 'Choose contract type for prepare main contract to client.',
        descriptionStepChooseContractType: 'If digital send us Contract B* number or just choose manual contract, confirm your choice.',


        stepMOU: 'MOU process is active', // : our specialist prepare for you main contract.
        descriptionStepMOU: 'Our specialist prepare main contract.',

        stepMOU2: ' We have sent contract to your client.', //  Waiting for sign and send back.
        descriptionStepMOU2: 'Please wait until your client signed the contract that we sent to his email.',

        stepMOU3: 'Contract signed by your client.',
        descriptionStepMOU3: "", // 'Please wait until our specialist will be reviewing your consent.',

        dynamicStep: 'Wait for the next updates:', // : our specialist prepare for you main contract.
        descriptionDynamicStep: 'We will keep you informed of every update by sending notifications, ensuring you are always up to date and well-informed.',

        stepCompleted: 'Deal closed successfully.',
        descriptionCompleted: 'Congratulations on another completed offer! Let’s make a new one with the BSO Club!',
        addTitle: '',
    }
    if (offerStatuses?.includes('Client docs required')) {
        process = 0
    } else if (offerStatuses?.includes('Resubmit required')) {
        process = 0
        textsOfferTimelineSale.stepClientData = 'Resubmit and wait sent offer.'
        textsOfferTimelineSale.descriptionStepClientData = 'Our specialist will be sent your offer soon after you resubmit.'
    } else if (offerStatuses?.includes('Canceled') || offerStatuses?.includes('Canceled by yourself') || offerStatuses?.includes('Canceled by agent')) {
        process = 0
        textsOfferTimelineSale.stepClientData = 'You have canceled Offer!'
        textsOfferTimelineSale.descriptionStepClientData = ''
    } else if (offerStatuses?.includes('Rejected')) {
        process = 1
        textsOfferTimelineSale.stepApprovedOrRejected = 'Your offer was rejected by BSO.'
        textsOfferTimelineSale.descriptionApprovedOrRejected = 'Unfortunately, our specialist has decided to reject your offer because of the reason stated above'

        // } else if (offerStatuses?.includes('Waiting for slip approval')) {
        //     process = 1
    } else if (offerStatuses?.includes('In-process')) {
        process = 1
    } else if (offerStatuses?.includes("Sent to landlord")) {
        process = 2
    } else if (offerStatuses?.includes('Confirmed')) {
        process = 3
        textsOfferTimelineSale.stepConfirmed = 'Landlord confirmed offer.'
        textsOfferTimelineSale.addTitle = '.'
        textsOfferTimelineSale.descriptionConfirmed = 'Send email and phone client.'
    } else if (offerStatuses?.includes('Negotiated by agent') || offerStatuses?.includes('Negotiated by yourself')) {
        process = 3
        textsOfferTimelineSale.stepConfirmed = 'You requested the offer negotiation'
        textsOfferTimelineSale.descriptionConfirmed = 'Please wait until our specialist or the landlord takes action about your negotiation of this offer'
    } else if (offerStatuses?.includes('Negotiated by BSO')) {
        process = 3
        textsOfferTimelineSale.stepConfirmed = 'The BSO offered another option from landlord'
        textsOfferTimelineSale.descriptionConfirmed = 'If you are satisfied, wait for a call from BSO, or provide updated offer details.'
    } else if (offerStatuses?.includes('Negotiated by landlord')) {
        process = 3
        textsOfferTimelineSale.stepConfirmed = 'The landlord offered another option'
        textsOfferTimelineSale.descriptionConfirmed = 'If you are satisfied, wait call from BSO, or provide updated offer details.'
    } else if (offerStatuses?.includes('Pending BSO admin confirmation')) {
        process = 3
        textsOfferTimelineSale.stepConfirmed = 'In the process of reviewing your consent.'
        textsOfferTimelineSale.descriptionConfirmed = 'Please wait until our specialist will be reviewing your consent.'
    } else if (offerStatuses?.includes('Waiting draft contract send')) {
        process = 4
    } else if (offerStatuses?.includes('Waiting draft contract approve')) {
        process = 5
    } else if (offerStatuses?.includes('Draft contract approved')) {
        process = 6
    } else if (offerStatuses?.includes('F Form requested') || offerStatuses?.includes('Manual contract requested')) {
        process = 7
    } else if (offerStatuses?.includes('F Form sent') || offerStatuses?.includes('Manual contract sent')) {
        process = 8
    } else if (offerStatuses?.includes('F Form signed') || offerStatuses?.includes('Manual contract signed')) {
        process = 9
    } else if (offerStatuses?.includes('Waiting for updates')) {
        process = 10
        textsOfferTimelineSale.descriptionDynamicStep = offer.attributes?.StatusUpdateDescription?.includes("UPLOAD-URGENT") ? "" : String(offer.attributes?.StatusUpdateDescription ?? '')
    } else if (offerStatuses?.includes('Completed')) {
        process = 11
    } else {
        process = 10
        textsOfferTimelineSale.dynamicStep = 'Current action: ' + offerStatuses[0];
        textsOfferTimelineSale.descriptionDynamicStep = offer.attributes?.StatusUpdateDescription?.includes("UPLOAD-URGENT") ? "" : String(offer.attributes?.StatusUpdateDescription ?? '')
    }





    const groupSale: TimelineItem[] = [

        {
            title: <StepComponent
                title={textsOfferTimelineSale.stepClientData}
                description={textsOfferTimelineSale.descriptionStepClientData}
                process={0}
                currentProcess={process}
                statuses={offerStatuses}
                rejectReason={process === 0 ? offer?.attributes?.RejectReason ?? undefined : undefined}
            />,
            status: process === 0 && (offerStatuses?.[0]?.includes('Canceled') || offerStatuses?.includes('Rejected')) ? 'finish' : undefined,
            description: textsOfferTimelineSale.descriptionStepClientData
        },

        ...(offerStatuses?.[0]?.includes('Canceled') ? [] : [
            ...offerStatuses?.includes('Rejected') ? [{
                title: <StepComponent
                    title={textsOfferTimelineSale.stepApprovedOrRejected}
                    description={textsOfferTimelineSale.descriptionApprovedOrRejected}
                    process={1}
                    currentProcess={process}
                    statuses={offerStatuses}
                    rejectReason={process === 1 ? offer?.attributes?.RejectReason ?? undefined : undefined}
                />,
                status: 'finish',
                description: textsOfferTimelineSale.descriptionApprovedOrRejected
            }] : [
                {
                    title: <StepComponent
                        title={textsOfferTimelineSale.stepInProcess}
                        description={textsOfferTimelineSale.descriptionInProcess}
                        process={1}
                        currentProcess={process}
                        statuses={offerStatuses}
                        rejectReason={process === 1 ? offer?.attributes?.RejectReason ?? undefined : undefined}
                    />,

                    description: textsOfferTimelineSale.descriptionInProcess
                },
                {
                    title: <StepComponent
                        title={textsOfferTimelineSale.stepApprovedOrRejected}
                        description={textsOfferTimelineSale.descriptionApprovedOrRejected}
                        process={2}
                        currentProcess={process}
                        statuses={offerStatuses}

                    />,
                    description: textsOfferTimelineSale.descriptionApprovedOrRejected
                }
                ,
                {
                    title: <StepComponent
                        title={textsOfferTimelineSale.stepConfirmed}
                        description={textsOfferTimelineSale.descriptionConfirmed}
                        process={3}
                        currentProcess={process}
                        statuses={offerStatuses}
                        negotiationReason={process === 3 ? offer?.attributes?.Type?.[0]?.AdditionalNotes ?? undefined : undefined}

                    />,
                    description: textsOfferTimelineSale.descriptionConfirmed
                },
                /// ///
                {
                    title: <StepComponent
                        title={textsOfferTimelineSale.stepContractDraftSending}
                        description={textsOfferTimelineSale.descriptionStepContractDraftSending}
                        process={4}
                        currentProcess={process}
                        statuses={offerStatuses}
                        negotiationReason={process === 4 ? offer?.attributes?.Type?.[0]?.AdditionalNotes ?? undefined : undefined}
                    />,
                    description: textsOfferTimelineSale.descriptionStepContractDraftSending
                },
                {
                    title: <StepComponent
                        title={textsOfferTimelineSale.stepContractDraftConfirmed}
                        description={textsOfferTimelineSale.descriptionStepContractDraftConfirmed}
                        process={5}
                        currentProcess={process}
                        statuses={offerStatuses}
                    />,
                    description: textsOfferTimelineSale.descriptionStepContractDraftConfirmed
                },
                {
                    title: <StepComponent
                        title={textsOfferTimelineSale.stepChooseContractType}
                        description={textsOfferTimelineSale.descriptionStepChooseContractType}
                        process={6}
                        currentProcess={process}
                        statuses={offerStatuses}
                    />,
                    description: textsOfferTimelineSale.descriptionStepChooseContractType
                },
                {
                    title: <StepComponent
                        title={textsOfferTimelineSale.stepMOU}
                        description={textsOfferTimelineSale.descriptionStepMOU}
                        process={7}
                        currentProcess={process}
                        statuses={offerStatuses}
                    />,
                    description: textsOfferTimelineSale.descriptionStepMOU
                },
                {
                    title: <StepComponent
                        title={textsOfferTimelineSale.stepMOU2}
                        description={textsOfferTimelineSale.descriptionStepMOU2}
                        process={8}
                        currentProcess={process}
                        statuses={offerStatuses}
                    />,
                    description: textsOfferTimelineSale.descriptionStepMOU2
                },
                {
                    title: <StepComponent
                        title={textsOfferTimelineSale.stepMOU3}
                        description={textsOfferTimelineSale.descriptionStepMOU3}
                        process={9}
                        currentProcess={process}
                        statuses={offerStatuses}
                    />,
                    description: textsOfferTimelineSale.descriptionStepMOU3
                }, {
                    title: <StepComponent
                        title={textsOfferTimelineSale.dynamicStep}
                        description={textsOfferTimelineSale.descriptionDynamicStep}
                        process={10}
                        currentProcess={process}
                        statuses={offerStatuses}
                    />,
                    description: textsOfferTimelineSale.descriptionDynamicStep
                },
                {
                    title: <StepComponent
                        title={textsOfferTimelineSale.stepCompleted}
                        description={textsOfferTimelineSale.descriptionCompleted}
                        process={11}
                        currentProcess={process}
                        statuses={offerStatuses}
                    />,
                    description: textsOfferTimelineSale.descriptionCompleted,
                    status: process === 11 ? 'finish' : undefined
                }
            ]
        ])

    ]

    const [sliceSteps, setSliceSteps] = useState([groupSale[process]])
    const totalSteps = groupSale.length;
    useEffect(() => {
        groupSale.forEach((el, i) => { el.icon = iconProcess(i + 1, process, totalSteps) })
    }, [sliceSteps, process])

    const [line, setLine] = useState('middle')



    return (
        <div className='my-8 pt-8' style={{ maxWidth: '450px', width: '100%' }}>


            <Steps
                current={line === 'next' ? 0 : line === 'middle' ? 0 : process}
                percent={60}
                items={sliceSteps}
                direction='vertical'
            />

            <StepNavigation
                process={process}
                line={line}
                setLine={setLine}
                setSliceSteps={setSliceSteps}
                group={groupSale}
                offerStatuses={offerStatuses}
                totalSteps={totalSteps}
            />
        </div>
    )
}

export default TimelineSale
