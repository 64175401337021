import { type IOffer } from "../../../store/slices/offersSlice"

export const reduceOffer = (entry: Record<any, any>, storeOffer: IOffer | undefined): Record<string, any> => {
   
    const reducedOffer = {
        id: entry.id,
        attributes: {
            ...(entry),
            User: {
                data: {
                    id:  entry?.User?.id ?? storeOffer?.attributes?.User?.data?.id,
                    attributes: {}
                }
            },
            Unit : {
                data: {
                    id: entry?.Unit?.id,
                    attributes: {
                        Marketing : entry?.Unit?.Marketing ?? storeOffer?.attributes?.Unit?.data?.attributes?.Marketing ,
                        User : {
                            data: {
                                id: entry?.Unit?.User?.id ?? storeOffer?.attributes?.Unit?.data?.attributes?.User?.data?.id ,
                                attributes: {
                                    Category : entry?.Unit?.User?.Category ?? storeOffer?.attributes?.Unit?.data?.attributes?.User?.data?.attributes?.Category
                                }
                            }
                        }
                    }
                }
            },
            Documents : {
                data: entry.Documents.map((doc:any) => ({ id: doc.id, attributes: { ...doc } })) ?? storeOffer?.attributes.Documents.data ?? []
            }
        }
    }
    return reducedOffer
}