
import React, { useState } from 'react'
import {
    type FieldErrors,
    type UseFormRegister,
} from 'react-hook-form'



import DefaultInput from '../../../app/custom/inputs/DefaultInput'
import { regExps } from '../../../constants'
import Markdown from 'react-markdown'
import CustomToggleButtonGroup from './CustomToggleButtonGroup'


interface IProps {

    errors: FieldErrors<any>
    register: UseFormRegister<any>
    offerID: number
}




export default function StateTypeContract({
    errors,
    register,
    offerID
}: IProps): JSX.Element {


    const [typeContract, setTypeContract] = useState('Digital Contract')
    const rowContractB = { id: 'contract_b', content: ['Contract B* number', 'Enter Contract B number'], element: 'input', required: true }



    return (

        <div >
            {
                <>
                    <div className="fs-6 fw-bold mb-1 text-uppercase">Select contract type</div>
                    <CustomToggleButtonGroup onSelect={setTypeContract} selectedValue={typeContract} options={[{ value: 'Digital Contract', label: 'Digital Contract' }, { value: 'Manual Contract', label: 'Manual Contract' }]} />

                    <div className="separator separator-dashed my-4 gap-4"></div>
                    {typeContract === 'Digital Contract' &&
                        <>
                            <DefaultInput row={rowContractB} register={register} errors={errors} />
                            <div className="separator separator-dashed my-4 gap-4"></div>
                        </>
                    }
                    <div className={'rounded rounded-sm bg-gray-300 p-4'}>
                        <div className='fw-semibold fs-6  lh-1 mt-2'>
                            <Markdown>
                                In Dubai, the process of concluding digital contracts in real estate is regulated by the Real Estate Regulatory Agency (RERA) and involves the use of standardized forms:
                            </Markdown>
                            <Markdown>
                                **Form A:** An agreement between the seller and the agent, required for listing the property.
                            </Markdown>
                            <Markdown>
                                **Form B:** An agreement between the buyer and the agent, mandatory when purchasing a property through a realtor.
                            </Markdown>
                            <Markdown>
                                **Form F:** Also known as the Memorandum of Understanding (MOU); it is a sales agreement between the buyer and the seller, signed after terms of the deal are agreed upon.
                            </Markdown>
                            <Markdown>
                                Agents can use the Dubai REST application, provided by the Dubai Land Department, to create Form B. The process involves logging into the app, selecting the relevant options, and entering the required details.
                                While digital processes are becoming the standard, Dubai still allows the use of traditional methods for contract finalization. Manually signed documents, such as the MOU, remain a valid practice, especially when parties prefer face-to-face interactions or lack access to digital platforms.
                            </Markdown>
                            <Markdown>
                                This flexibility enables participants in the real estate market to choose between digital and traditional methods, depending on their preferences and circumstances.
                            </Markdown>
                        </div>
                    </div>

                </>


            }

        </div >

    )
}
