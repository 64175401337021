import QueryString from 'qs'
import { ACCESS_KEY, urlAPI } from '../../../urls'

export async function sendNotification (notificationTime: Date, userID: number, ObjectID: number | undefined, rule: string, text: string): Promise<void> {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const sentBodyForNotification = {
        method: 'POST',
        collection: 'user-notifies',
        body: {
            mode: 'publish',
            executeAt: notificationTime,
            RuleID: ObjectID,
            Rule: rule,
            User: userID,
            Text: text,
            Type: 'Mobile',
            publishedAt: null
        }
        // query: QueryString.stringify({
        //     populate: ['User']
        // }, {
        //     encodeValuesOnly: true
        // })
    }

    const requestForNotification = await fetch(`${urlAPI}`, {
        method: 'POST',
        body: JSON.stringify(sentBodyForNotification),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`
        }
    })
    await requestForNotification.json()
}
