import { getErrorss, getMessageForError } from "../../../../../functions/errors/GetErrors"
import { getQueryOffers } from "../../../../../functions/shared/api/queries/getQueryOffers"
import { ACCESS_KEY, urlAPI, USER_CATEGORY_KEY, USER_ID_KEY } from "../../../../../urls"
import { documentAddSuccess } from "../../../../slices/documentSlice"
import { dataFetchingFinish, dataFetchingStart } from "../../../../slices/modalSlice"
import { setOffers, type IOffer } from "../../../../slices/offersSlice"
import { type AppDispatch, type RootState } from "../../../../store"
import { createDocument } from "../../../documentsActions/createDocument"

export const addOfferSaleContractDocuments = (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<{ isSuccessful: boolean, textNotification?: string, textNavigate?: string }> => {
    try {                                                          
        dispatch(dataFetchingStart())
        const { offerID , file , status , categoryDoc } = data
        const jwt = window.localStorage.getItem(ACCESS_KEY)  ?? ''
        const userID = window.localStorage.getItem(USER_ID_KEY)
        const userCATEGORY = window.localStorage.getItem(USER_CATEGORY_KEY)
        const offer = getState().offers.offers.find((offerItem:IOffer) => offerItem.id === Number(offerID))
      
        const fileList = file?.File
        if (file != null) {
            const { isSuccessful, response } = await dispatch(createDocument([file], {
                RefID: `REF_DOC_${categoryDoc.toLowerCase().replaceAll(" " , '_')}_${new Date().getTime()}`,
                Category: categoryDoc,
                Statuses: ['In-process'],
                Offer: offer?.id
            }, 'offers', String(offer?.id)))
          
            if (isSuccessful && response?.data?.id != null) {
                dispatch(documentAddSuccess(response?.data))
                const bodyObject = {
                    method: 'PUT',
                    id: Number(offerID),
                    collection: 'offers',
                    query: getQueryOffers(),
                    body: {
                        ...offer?.attributes,
                        Documents: [...(offer?.attributes?.Documents?.data?.map(doc => ({ id: doc.id })) ?? []),  { id: response?.data?.id }] , // , response?.data?.id
                        Statuses: [status],
                        RejectReason: null,
                        Notify: 'Agent', 
                    }
                }
                const responseOffer = await fetch(`${urlAPI}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'BSO-Hash': '02ns30nsBSO!',
                        Authorization: `Bearer ${String(jwt)}`
                    },
                    body: JSON.stringify(bodyObject)
                })
                const responseJSON = await responseOffer.json()
                const typeOffer = offer?.attributes?.Type?.[0]?.__component?.split('.')[1] ?? 'not'
                if (responseJSON.success === true && responseJSON?.response?.data?.id != null) {
                    // await sendNotification(notifyDelay(), Number(userID), Number(objectID), 'admin-offer-client-info', 'Offer - Waiting booking form sign')
                    dispatch(setOffers(responseJSON.response.data))
                    dispatch(dataFetchingFinish())
                    return {
                        textNotification: categoryDoc   + ' has been successfully added',
                        textNavigate:  userCATEGORY === 'Agent' ? 
                            `/agent/modalView/offer_modal/${offer?.attributes?.Unit?.data?.id}/${offerID as string}/not?type=${typeOffer}` :
                            `/landlord/modalView/magic_offer_modal/${offer?.attributes?.Unit?.data?.id}/${offerID as string}/catalog?type=${typeOffer}` ,  
                        isSuccessful: true
                    }
                } else {
                    dispatch(dataFetchingFinish())
                    return {
                        textNotification: getErrorss(responseJSON),
                        isSuccessful: false
                    }}
            } else {
                dispatch(dataFetchingFinish())
                return {
                    textNotification: getErrorss(response),
                    isSuccessful: false
                }
            }
        } else {
            dispatch(dataFetchingFinish())
            return {
                textNotification: `Please upload ${categoryDoc} again`,
                isSuccessful: false
            }
        }
    } catch (err) {
        console.error(err)
        dispatch(dataFetchingFinish())
        return {
            textNotification: getErrorss(getMessageForError("Something went wrong. Try again later")),
            isSuccessful: false
        }
    }

}
