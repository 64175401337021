import React, { useState } from 'react'
import MyDataPicker from '../../../../app/MyDataPicker'
import _ from 'lodash'
import { useAppSelector } from '../../../../hooks/redux'
import { regExps } from '../../../../constants'
import { type RootState } from '../../../../store/store'

import CustomSelect from '../../../../app/custom/selects/CustomSelect'
import AreaInput from '../../../../app/custom/inputs/AreaInput'
import { Space, Switch } from 'antd'
import { getBanks } from '../../../../functions/getDataForSelects/dataFunctions'
import { Controller, type Control, type FieldErrors, type FieldValues, type UseFormRegister, type UseFormSetValue } from 'react-hook-form'
import CustomFormLable from '../../../../app/custom/customModalUtils/CustomFormLable'
import GroupInput from '../../../../app/custom/inputs/GroupInput'
import CustomToggleButtonGroup from '../CustomToggleButtonGroup'

interface AgentToBSOOfferOfferInfoProps {
    setValue: UseFormSetValue<FieldValues>
    errors: FieldErrors<any>
    register: UseFormRegister<any>
    control: Control<any>
    offerID: number
    unitID: number
}

export default function AgentToBSOOfferOfferInfo({
    setValue,
    errors,
    register,
    control,
    offerID,
    unitID
}: AgentToBSOOfferOfferInfoProps): JSX.Element {
    const units = useAppSelector((state: RootState) => state.units.units)
    const unit = units.find((unitItem) => unitItem.id === unitID)
    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const offer = offers.find((offerItem) => offerItem.id === offerID)

    const isSale = unit?.attributes?.Marketing?.Use === 'For sale'

    const [type, setType] = useState('cash')



    const rowTypeOffer = {
        id: 'Type.Offer',
        content: ['Offer', 'AED', 'Client’s offer for the property'],
        element: 'input-group',
        required: true,
        regexp: regExps.isNumber,
        defaultValue: offer?.attributes?.Type?.[0]?.Offer ?? unit?.attributes?.Marketing?.Price ?? undefined
    }
    const rowTypeContractStartDate = {
        id: 'Type.ContractStartDate',
        content: ['Contract Start Date', 'Input contract start date'],
        dateAfter: true,
        element: 'input-date',
        required: true,
        defaultValue: offer?.attributes?.Type?.[0]?.ContractStartDate ?? undefined
    }
    const rowTypePayments = {
        id: 'Type.Payments',
        content: ['Payment installments', 'Select count'],
        selectors: ['1', '2', '3', '4', '6', '12'],
        element: 'select',
        required: true,
        defaultValue: offer?.attributes?.Type?.[0]?.Payments ?? undefined
    }
    const rowTypeDuration = {
        id: 'Type.Duration',
        content: ['Contract Duration', 'Select contract duration'],
        selectors: _.range(6, 25).map(item => ({ label: `${item} Months`, value: item })),
        element: 'select',
        required: true,
        defaultValue: offer?.attributes?.Type[0]?.Duration ?? undefined
    }
    const rowBankName = {
        id: 'Type.BankName',
        content: ['Bank Name', 'Select bank'],
        selectors: [...getBanks(), 'Other bank - (Add in notes)'],
        element: 'select',
        required: type === 'mortgage',
        defaultValue: offer?.attributes?.Type?.[0]?.BankName ?? undefined
    }
    const rowPreApprovedAmount = {
        id: 'Type.PreApprovedAmount',
        content: ['Pre-Approved Amount', 'AED', 'Amount'],
        element: 'input',
        required: type === 'mortgage',
        defaultValue: offer?.attributes?.Type?.[0]?.PreApprovedAmount ?? undefined,
        regexp: regExps.isNumber
    }
    const rowOfferType = {
        id: 'Type.Type',
        content: ['Offer Type', 'Select option'],
        selectors: ['Cash Buyer', 'Mortgage Buyer'],
        element: 'select',
        required: true,
        defaultValue: offer?.attributes?.Type?.[0]?.Type ?? undefined
    }
    const rowTypeAdditionalNotes = {
        id: 'Type.AdditionalNotes',
        content: ['Additional Notes', "Note client's special needs"],
        element: 'input',
        maxLength: 5000,
        defaultValue: offer?.attributes?.Type[0]?.AdditionalNotes ?? undefined
    }

    const paymentOptions = [
        { value: 'cash', label: 'Cash' },
        { value: 'mortgage', label: 'Mortgage' }
    ];



    return (
        <>

            <div className="fv-row  mt-8 p-0 w-100">

                <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                    <CustomFormLable row={rowTypeOffer} />
                    <div className="input-group">
                        <input
                            {...register(rowTypeOffer.id, {
                                required: `Field "${String(rowTypeOffer.content?.[0])}" is required`,
                                validate: (value: string) => {
                                    let result: boolean = true
                                    if (rowTypeOffer.regexp != null) {
                                        result = new RegExp(rowTypeOffer.regexp).test(value)
                                    }
                                    // handleOfferChange(value)
                                    return result
                                }
                            })}
                            name={rowTypeOffer.id}
                            className="form-control form-control-solid form-control-lg"
                            type="text"
                            placeholder={rowTypeOffer.content?.[2]}
                            defaultValue={rowTypeOffer?.defaultValue}
                        />
                        <span className="input-group-text border-0">{rowTypeOffer.content?.[1]}</span>
                    </div>
                    {
                        _.get(errors, rowTypeOffer.id) != null && (
                            _.get(errors, rowTypeOffer.id)?.type === 'validate'
                                ? <div className="text-danger">{`${String(rowTypeOffer?.content?.[0])} must be valid `} </div>
                                : <div className="text-danger">{String(_.get(errors, rowTypeOffer.id)?.message)}</div>
                        )
                    }
                </div>

                {!isSale
                    ? <>
                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                            <CustomFormLable row={rowTypeContractStartDate} />
                            <MyDataPicker control={control} register={register} row={rowTypeContractStartDate} setValue={setValue} isBlockPast />
                            {_.get(errors, rowTypeContractStartDate.id) != null && (
                                <div className="text-danger">{String(_.get(errors, rowTypeContractStartDate.id)?.message)}</div>
                            )}
                        </div>

                        <CustomSelect control={control} register={register} data={rowTypePayments} setValue={setValue} errors={errors} />
                        <CustomSelect control={control} register={register} data={rowTypeDuration} setValue={setValue} errors={errors} />


                    </>
                    : <>
                        <div >
                            <div className="fs-6 fw-bold mb-1 text-uppercase">Payment type</div>
                            <CustomToggleButtonGroup
                                options={paymentOptions}
                                selectedValue={type}
                                onSelect={setType}
                            />
                            <input type="hidden" {...register('Type.Type', { value: type })} />
                        </div>
                        <div className="separator separator-dashed my-4"></div>
                        <CustomSelect control={control} register={register} data={rowBankName} setValue={setValue} errors={errors} />
                        <GroupInput register={register} errors={errors} row={rowPreApprovedAmount} />
                        <AreaInput
                            register={register}
                            errors={errors}
                            row={rowTypeAdditionalNotes}
                        />
                    </>
                }
            </div >

        </>

    )
}
