import { store } from '../../store/store'
import _ from 'lodash'

export default function awaitAction(callback: any): void {

    const targets: Array<{ path: string, checker: (value: any) => boolean }> =
        [
            {
                path: 'swalsStates.oneCycleShowPopupsViewingReschedule',
                checker: (val: boolean) => !val
            },
            {
                path: 'swalsStates.showPopupViewingFeedback',
                checker: (val: boolean) => !val
            },
            {
                path: 'swalsStates.showPopupViewingReschedule',
                checker: (val: boolean) => !val
            },
            {
                path: 'swalsStates.newViewingRescheduleSocket',
                checker: (val: boolean) => !val
            },
            {
                path: 'swalsStates.newViewingFeedbackSocket',
                checker: (val: boolean) => !val
            }
        ]
    try {


        const checkConditions = (): boolean => targets.every(target => target.checker(_.get(store.getState(), target.path)))
        // console.log(`Await action for the task ${JSON.stringify(targets.map((target) => target.path))} has started`)
        if (checkConditions()) {
            callback()
            // console.log(`Await action for the task ${JSON.stringify(targets.map((target) => target.path))} has succeeded`)
            return
        }
        let depth = 0
        const interval = setInterval(() => {
            if (depth < 60) {
                depth++
                if (checkConditions()) {
                    clearInterval(interval)
                    void callback()
                    // console.log(`Await action for the task ${JSON.stringify(targets.map((target) => target.path))} has succeeded`)
                }
            } else {
                clearInterval(interval)
                // console.log(`Await action for the task ${JSON.stringify(targets.map((target) => target.path))} has failed. ( 5 minutes timeout )`)
            }
        }, 5000)
    } catch (error) {
        console.log(error)
    }
}
