import { type IOffer } from "../../../../store/slices/offersSlice";

export const filterAndSortOffers = (unit: any, task : IOffer,  sortType :Record<string, any>): boolean => {
    
    const sortByStatus =  sortType.byOfferStatus 
    const sortByTypeUnit = sortType.sortByTypeUnit
    const statuses = task?.attributes?.Statuses as any[]; 

    const statusMatch = sortByStatus === 'all' ||
    (sortByStatus === 'Negotiated offers' && (
        Array.isArray(task?.attributes?.Statuses) && (
            statuses.includes('Negotiated by BSO') ||
            statuses.includes('Negotiated by landlord') ||
            statuses.includes('Negotiated by agent') ||
            statuses.includes('Negotiated by other agent')
        )
    )) ||
    (sortByStatus === 'Rejected' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Rejected')) ||
    (sortByStatus === 'Approved' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Approved')) ||
    (sortByStatus === 'Client docs required' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Client docs required')) ||
    (sortByStatus === 'Resubmit required' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Resubmit required')) ||
    (sortByStatus === 'Confirmed' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Confirmed')) ||
    (sortByStatus === 'Canceled' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Canceled')) ||
    (sortByStatus === 'Completed' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Completed')) ||
    (sortByStatus === 'In-process' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('In-process')) ||
    (sortByStatus === 'Other' && Array.isArray(task?.attributes?.Statuses) && !(
        statuses.includes('Rejected') ||
        statuses.includes('Approved') ||
        statuses.includes('Confirmed') ||
        statuses.includes('Canceled') ||
        statuses.includes('In-process') ||
        statuses.includes('Resubmit required') ||
        statuses.includes('Negotiated by BSO') ||
        statuses.includes('Negotiated by landlord') ||
        statuses.includes('Negotiated by agent') ||
        statuses.includes('Completed') ||
        statuses.includes('Negotiated by other agent') ||
        statuses.includes('Client docs required')
        
    ));

    if (!statusMatch) return false;
    
    if (sortByTypeUnit === 'all') return true;
    if (sortByTypeUnit === 'sale') return unit?.Marketing?.Use === 'For sale';
    if (sortByTypeUnit === 'rent') return unit?.Marketing?.Use === 'For rent';
    return false;
};