
export const reduceDocument = (entry: Record<any, any>, storeDocument:  any): any => {
   

    const reducedDocument = {
        id: entry.id,
        attributes: {
            ...(entry),
            User: {
                data: {
                    id:  entry?.User?.id ?? storeDocument?.attributes?.User?.data?.id,
                    attributes: {}
                }
            },
            Offer:
            {
                data: {
                    id:  entry?.Offer?.id ?? storeDocument?.attributes?.Offer?.data?.id,
                    attributes: {...entry?.Offer}
                }
            },
           
           
        }
    }
    return reducedDocument
}