import React, { useState } from 'react'
import {
    type Control,
    type FieldErrors,
    type FieldValues,
    type UseFormRegister,
    type UseFormSetValue
} from 'react-hook-form'

import _ from 'lodash'
import CustomSingleFileInput from '../../../app/custom/inputs/CustomSingleFileInput'
import DefaultInput from '../../../app/custom/inputs/DefaultInput'
import { regExps } from '../../../constants'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { getOfferTypeName } from '../../../functions/agent/offers/getOfferTypeName'
import Markdown from 'react-markdown'
import CustomToggleButtonGroup from './CustomToggleButtonGroup'


interface IProps {

    errors: FieldErrors<any>
    register: UseFormRegister<any>
    offerID: number
}




export default function SwitchPaymentType({
    errors,
    register,
    offerID
}: IProps): JSX.Element {

    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const offer = offers.find((offerItem) => offerItem.id === offerID)
    const documents = useAppSelector((state: RootState) => state.documents.documents)

    const offerDocs = documents.filter((docItem) => (
        offer?.attributes?.Documents?.data?.some((offerDocItem) => (
            docItem.id === offerDocItem.id
        ))
    ))
    //   const tradeLicenceDoc = offerDocs?.find((docItem) => docItem?.attributes?.Type?.[0]?.__component === 'documents.trade-license')
    const passportDoc = offerDocs?.find((docItem) => docItem?.attributes?.Type?.[0]?.__component === 'documents.passport' || docItem?.attributes?.Category?.includes("Visa"))
    const saleType = getOfferTypeName(offer).includes('sale')
    const [typePayment, setTypePayment] = useState(saleType ? 'Bank cheque' : 'Bank transfer')

    const rowDepositCheque = {
        id: `deposit_cheque`,
        content: [saleType ? 'Upload 10% cheque copy' : `Payment ${typePayment} confirmation`, 'Upload client\'s Deposit slip confirmation'],
        element: 'input-file',
        required: true,
        description: `Upload a screenshot of the deposit pay. Below is the bank details where you can send your payment.`
    }


    const rowVisa = {
        id: 'visa',
        content: ['Passport'],
        element: 'input-file',
        required: true,
    }

    const rowEmail = { id: 'email', content: ['Client\'s email', 'Input client\'s email'], element: 'input', regexp: regExps.isEmail, required: true, defaultValue: offer?.attributes.Type[0]?.Email }
    const rowPhone = { id: 'phone', content: ['Client\'s phone', 'Input client\'s phone'], element: 'input', regexp: regExps.isPhone, required: true, defaultValue: offer?.attributes.Type[0]?.Phone }


    const payOptions = [
        { value: 'Bank transfer', label: 'Bank transfer' },
        { value: 'Bank cheque', label: 'Bank cheque' }
    ];

    return (

        <div >
            {!saleType ?
                <>
                    <div className="fs-6 fw-bold mb-1 text-uppercase">Select payment type</div>
                    <CustomToggleButtonGroup options={payOptions} selectedValue={typePayment} onSelect={setTypePayment} />
                    <div className="separator separator-dashed my-4 gap-4"></div>
                    <CustomSingleFileInput row={rowDepositCheque} register={register} errors={errors} getValues={() => { }} />
                    {(passportDoc == null) && <CustomSingleFileInput row={rowVisa} register={register} errors={errors} getValues={() => { }} />}
                    <DefaultInput row={rowEmail} register={register} errors={errors} />
                    <DefaultInput row={rowPhone} register={register} errors={errors} />
                </>
                :
                <>
                    {(passportDoc == null) && <CustomSingleFileInput row={rowVisa} register={register} errors={errors} getValues={() => { }} />}
                    <DefaultInput row={rowEmail} register={register} errors={errors} />
                    <DefaultInput row={rowPhone} register={register} errors={errors} />
                    {/* <div className={'rounded rounded-sm bg-gray-300 p-4'}>

                        <div className='fw-semibold fs-5  lh-1 mt-2'>
                            <Markdown>
                                First, you are required to make an initial payment or deposit, amounting to 10% of the purchase price. This payment secures the selected property under your name. It is held by the company represented by your real estate agent and will be returned upon the successful completion of the transaction. Simultaneously, a reservation is placed on the property, preventing any further operations or transactions involving it during the process.
                            </Markdown>
                        </div>
                    </div>
                    <div className="separator separator-dashed my-4 gap-4"></div>
                    <CustomSingleFileInput row={rowDepositCheque} register={register} errors={errors} getValues={() => { }} /> */}
                </>
            }

        </div >

    )
}
