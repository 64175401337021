


export const filterAndSortViewings = (unit: any, task : any,  sortType :Record<string, any>): boolean => {
    
    const sortByStatus =  sortType.byViewingStatus
    const sortByTypeUnit = sortType.sortByTypeUnit
    const statuses = task?.attributes?.Statuses as any[]; 

    const statusMatch = sortByStatus === 'all' ||
    (sortByStatus === 'Rescheduled by BSO' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Rescheduled by BSO')) ||
    (sortByStatus === 'Rescheduled by agent' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Rescheduled by agent')) ||
    (sortByStatus === 'Rescheduled by other agent' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Rescheduled by other agent')) ||
    (sortByStatus === 'Rejected' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Rejected')) ||
    (sortByStatus === 'Approved' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Approved')) ||
    (sortByStatus === 'Confirmed' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Confirmed')) ||
    (sortByStatus === 'Completed' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Completed')) ||
    (sortByStatus === 'In-process' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('In-process')) ||
    (sortByStatus === 'Canceled' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Canceled')) ||
    (sortByStatus === 'Canceled by other agent' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Canceled by other agent')) ||
    (sortByStatus === 'Waiting for complain' && Array.isArray(task?.attributes?.Statuses) && statuses.includes('Waiting for complain'));

   

    if (!statusMatch) return false;
    
    if (sortByTypeUnit === 'all') return true;
    if (sortByTypeUnit === 'sale') return unit?.Marketing?.Use === 'For sale';
    if (sortByTypeUnit === 'rent') return unit?.Marketing?.Use === 'For rent';
    return false;
};