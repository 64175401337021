import React, { useEffect, useMemo, useState } from 'react'

import { store, type RootState } from '../../../store/store'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'

import Loader from '../../../app/custom/loaders/Loader'

import { mainButtonClosing, setShowPopupScheduleFilters } from '../../../store/slices/modalSlice'

import ViewingsList from './viewingsList/ViewingsList'

import { type IViewing } from '../../../store/slices/viewingsSlice'
import { type IOffer } from '../../../store/slices/offersSlice'

import { nanoid } from 'nanoid'

import SchedulerHeader from './SchedulerHeader'
import AgentMainButton from '../elements/buttons/AgentMainButton'
import ActionBookViewing from '../elements/buttons/ActionBookViewing'
import ActionMakeOffer from '../elements/buttons/ActionMakeOffer'
import AgentFooter from '../../agentModule/AgentFooter'
import AdvertiseList from './advertisesList/AdvertiseList'
import OffersList from './offersList/OffersList'
import ReferralsList from './referalsList/ReferralsList'
import ScheduleViewingAttention from './attenton/ScheduleViewingAttention'
import ScheduleOfferAttention from './attenton/ScheduleOfferAttention'
import ScheduleAdvertiseAttention from './attenton/ScheduleAdvertiseAttention'
import UseUserType, { type UserType } from '../../../hooks/UseUserType'
import { advertiseAttention, aprImage, offersAttention, othrImage, placeholders, REACT_APP_BSO_USER_ID, rejImage, USER_ID_KEY, viewingsAttention } from '../../../urls'
import _ from 'lodash'
import LandlordFooter from '../../landlordModule/LandlordFooter'

import { AnimatePresence, motion } from 'framer-motion'
import { useLocation, useParams } from 'react-router-dom'

import { type User } from '../../../store/slices/userSlice'
import { type Unit } from '../../../store/slices/unitsSlice'
import ScheduleFilters from './filters/ScheduleFilters'
import ScheduleFiltersList from './filters/ScheduleFiltersList'
import UniverseAction from '../elements/buttons/UniverseAction'
import BroadcastList from './broadcastsList/BroadcastsList'
import { getBelongsUnitForOffer, getBelongsUnitForViewing } from '../../../functions/shared/schedule/getBelongsUnit'
import { getDynamicHeight } from '../../../functions/utils/getDynamicHeight'

import { filterAndSortOffers } from './filters/filterAndSortOffers'
import { filterAndSortViewings } from './filters/filterAndSortViewings'
// import { filterUnits } from './filters/filterTasks'

export default function ScheduleSh(): JSX.Element {
    const { unitID } = useParams()
    const location = useLocation().pathname
    const { userType } = UseUserType()
    let notifyApp: UserType | 'BSO' = userType

    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const userID = currentUser.id ?? window.localStorage.getItem(USER_ID_KEY)
    if (String(userID) === REACT_APP_BSO_USER_ID) {
        notifyApp = 'BSO'
    }
    const isAgentTasks = location === '/agent/my_schedule'
    const isAdminTasks = currentUser.id === Number(process.env.REACT_APP_BSO_USER_ID)

    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    const userDevice = useAppSelector((state) => state.app.userDevice)
    const showFilters = isDev ? ['viewings', 'offers', 'attention', 'adverts'] : [] // 'referrals', 'broadcasts'
    const scheduleFilters = useAppSelector((state: RootState) => state.modal.scheduleFilters)
    const filterNavigate = useAppSelector((state: RootState) => state.schedule.filterNavigate)

    const isShowFilters = showFilters.includes(filterNavigate)
    const isMyTasks = location === '/agent/my_schedule'
    const [scheduleFiltersApply, setScheduleFiltersApply] = useState(false)
    const dispatch = useAppDispatch()

    const mainButtonOpen = useAppSelector((state: RootState) => state.modal.mainButtonOpen)
    const showPopupScheduleFilters = useAppSelector((state: RootState) => state.modal.showPopupScheduleFilters)

    const loading = useAppSelector((state: RootState) => state.user.loading)

    const viewings = useAppSelector((state: RootState) => state.viewings.viewings)
    const offers = useAppSelector((state: RootState) => state.offers.offers)

    const advertises = useAppSelector((state: RootState) => state.advertises.advertises)
    const userBroadcastMessages = useAppSelector((state: RootState) => state.chat.userBroadcastMessages)

    const storeUnits = useAppSelector((state: RootState) => state.units.units)
    const isUnitsBSOIds = storeUnits
        .filter((_unit) => _unit.attributes.ManagedBy === 'BSO')
        .map((_unit) => _unit.id)






    const [filteredViewings, setFilteredViewings] = useState(viewings.filter((el: IViewing) => {
        return el?.attributes?.User?.data?.id === Number(userID) || el?.attributes?.Unit?.data?.attributes?.User?.data?.id === Number(userID)
    }))

    const [filteredOffers, setFilteredOffers] = useState(offers.filter((offer: IOffer) => {
        return (offer?.attributes?.User?.data?.id === Number(userID) || offer?.attributes?.Unit?.data?.attributes?.User?.data?.id === Number(userID)) && (offer?.attributes?.Type?.[0]?.Offer)
    }))







    const [filteredAdvertises, setFilteredAdvertises] = useState(advertises.filter((advertise) => {
        return advertise?.attributes?.User?.data?.id === Number(userID) || advertise?.attributes?.Unit?.data?.attributes?.User?.data?.id === Number(userID)
    }))
    const [broadcastMessages, setBroadcastMessages] = useState(userBroadcastMessages)

    const [offersNotifies, setOffersNotifies] = useState(filteredOffers.filter((el: any) => {
        return el?.attributes?.Notify === notifyApp || el?.attributes?.Notify === 'Both' || (el?.attributes?.Notify === 'Other Agent' && scheduleFilters.sort !== 'bso_units' && el?.attributes.User?.data?.id !== Number(userID))
    }))
    const [viewingsNotifies, setViewingsNotifies] = useState(filteredViewings.filter((el: any) => {
        return (el?.attributes?.Notify === notifyApp || el?.attributes?.Notify === 'Both') || (el?.attributes?.Notify === 'Other Agent' && scheduleFilters.sort !== 'bso_units' && el?.attributes.User?.data?.id !== Number(userID))
    }))
    const [advertisesNotifies, setAdvertisesNotifies] = useState(filteredAdvertises.filter((el: any) => {
        return el?.attributes?.Notify === notifyApp || el?.attributes?.Notify === 'Both'
    }))

    const [allAttention, setAllAttention] = useState([
        ...offersNotifies,
        ...viewingsNotifies,
        ...advertisesNotifies
    ])


    useEffect(() => {
        let newViewings = []
        let newOffers = []
        let newAdvertises: any = []
        let newBroadcasts: any = []
        const agentUnitsIds = (((currentUser as User)?.Units) != null) && Array.isArray(currentUser?.Units) && currentUser?.Units?.length > 0
            ? currentUser?.Units.map((_unit: Unit) => _unit.id)
            : []

        if (scheduleFilters.sort === 'my_units') {
            newViewings = [...viewings].filter(
                (el) => {
                    const unit = el?.attributes?.Unit?.data?.attributes;
                    const creatorID = el?.attributes?.User?.data?.id
                    const unitTypeMatch = filterAndSortViewings(unit, el, scheduleFilters);
                    return unit?.User?.data?.id === Number(userID) && creatorID != null && unitTypeMatch
                }
            )
            newOffers = [...offers].filter(
                (el) => {
                    const unit = el?.attributes?.Unit?.data?.attributes;
                    const creatorID = el?.attributes?.User?.data?.id
                    const unitTypeMatch = filterAndSortOffers(unit, el, scheduleFilters);
                    return unit?.User?.data?.id === Number(userID) && creatorID != null && (el?.attributes?.Type?.[0]?.Offer != null && el?.attributes?.Type?.[0]?.Offer > 0) && unitTypeMatch
                }
            )
            newBroadcasts = [...broadcastMessages]
        } else if (scheduleFilters.sort === 'bso_units') {
            newViewings = [...viewings].filter(
                (el) => {
                    const unit = el?.attributes?.Unit?.data
                    const creatorID = el?.attributes?.User?.data?.id
                    const unitTypeMatch = filterAndSortViewings(unit?.attributes, el, scheduleFilters);
                    return creatorID === Number(userID) && unit?.id != null && isUnitsBSOIds.includes(unit?.id) && unitTypeMatch
                })
            newOffers = [...offers].filter(
                (el) => {
                    const unit = el?.attributes?.Unit?.data
                    const creatorID = el?.attributes?.User?.data?.id
                    const unitTypeMatch = filterAndSortOffers(unit?.attributes, el, scheduleFilters);
                    return creatorID === Number(userID) && unit?.id != null && isUnitsBSOIds.includes(unit?.id) && (el?.attributes?.Type?.[0]?.Offer != null && el?.attributes?.Type?.[0]?.Offer > 0) && unitTypeMatch
                })
            newAdvertises = [...advertises].filter(
                (el) => el?.attributes?.User?.data?.id === Number(userID) && el?.attributes?.Unit?.data?.id != null
            )
            newBroadcasts = [...broadcastMessages]
        } else if (scheduleFilters.sort === 'other_units') {
            newViewings = [...viewings].filter((el) => {
                const unit = el?.attributes?.Unit?.data
                const creatorID = el?.attributes?.User?.data?.id
                const unitTypeMatch = filterAndSortViewings(unit?.attributes, el, scheduleFilters);
                return creatorID === Number(userID) && unit?.id != null && el?.attributes?.AgentToAgentID && el?.attributes?.AgentToAgentID !== Number(userID) && unitTypeMatch
            })
            newOffers = [...offers].filter((el) => {
                const unit = el?.attributes?.Unit?.data
                const creatorID = el?.attributes?.User?.data?.id
                const unitTypeMatch = filterAndSortOffers(unit?.attributes, el, scheduleFilters);
                return creatorID === Number(userID) && unit?.id != null && !isUnitsBSOIds.includes(unit?.id) && !agentUnitsIds.includes(unit?.id) && (el?.attributes?.Type?.[0]?.Offer != null && el?.attributes?.Type?.[0]?.Offer > 0) && unitTypeMatch
            })

        } else if (userID === Number(REACT_APP_BSO_USER_ID)) {
            newViewings = [...viewings].filter((viewing) => {
                const unit = viewing?.attributes?.Unit?.data
                const creatorID = viewing?.attributes?.User?.data?.id
                const unitTypeMatch = filterAndSortViewings(unit?.attributes, viewing, scheduleFilters);
                return creatorID != null && isUnitsBSOIds.includes(unit?.id as number) && unitTypeMatch
            })
            newOffers = [...offers].filter((offer: any) => {
                const unit = offer?.attributes?.Unit?.data
                const creatorID = offer?.attributes?.User?.data?.id
                const unitTypeMatch = filterAndSortOffers(unit?.attributes, offer, scheduleFilters);
                return creatorID != null && isUnitsBSOIds.includes(unit?.id as number) && (offer?.attributes?.Type?.[0]?.Offer) && unitTypeMatch
            })
            newAdvertises = [...advertises].filter((advertise) => advertise?.attributes?.User?.data?.id != null)
        } else {
            newViewings = [...viewings].filter(
                (el) => {
                    const unit = el?.attributes?.Unit?.data
                    const creatorID = el?.attributes?.User?.data?.id
                    const unitTypeMatch = filterAndSortViewings(unit?.attributes, el, scheduleFilters);
                    return creatorID === Number(userID) || unit?.attributes?.User?.data?.id === Number(userID) && unitTypeMatch
                })
            newOffers = [...offers].filter(
                (el) => {
                    const unit = el?.attributes?.Unit?.data
                    const creatorID = el?.attributes?.User?.data?.id
                    const unitTypeMatch = filterAndSortOffers(unit?.attributes, el, scheduleFilters);
                    return (creatorID === Number(userID) || unit?.attributes?.User?.data?.id === Number(userID)) && (el?.attributes?.Type?.[0]?.Offer) && unitTypeMatch
                }
            )
            newAdvertises = [...advertises].filter(
                (el) => el?.attributes?.User?.data?.id === Number(userID)
            )
            newBroadcasts = [...broadcastMessages]
        }

        setBroadcastMessages(newBroadcasts)
        setFilteredViewings(newViewings)
        setFilteredOffers(newOffers)
        setFilteredAdvertises(newAdvertises)
        setScheduleFiltersApply(false)

        setOffersNotifies(newOffers.filter((el: any) => {
            const task = getBelongsUnitForOffer(el)
            if (notifyApp === "Agent") {
                return (el?.attributes?.Notify === notifyApp && task.type !== 'MY UNIT') || el?.attributes?.Notify === 'Both' || (el?.attributes?.Notify === 'Other Agent' && scheduleFilters.sort !== 'bso_units' && el?.attributes.User?.data?.id !== Number(userID))
            } else if (notifyApp === "BSO") {
                return ((el?.attributes?.Notify === notifyApp || el?.attributes?.Notify === "Both") && task.type === 'BSO UNIT') && el?.attributes.User?.data?.id !== Number(userID)
            }
        }))

        setViewingsNotifies(newViewings.filter((el: any) => {
            const task = getBelongsUnitForViewing(el)
            if (notifyApp === "Agent") {
                return (el?.attributes?.Notify === notifyApp && task.type !== 'MY UNIT') || el?.attributes?.Notify === 'Both' || (el?.attributes?.Notify === 'Other Agent' && scheduleFilters.sort !== 'bso_units' && el?.attributes.User?.data?.id !== Number(userID))
            } else if (notifyApp === "BSO") {
                return ((el?.attributes?.Notify === notifyApp || el?.attributes?.Notify === "Both") && task.type === 'MY UNIT') && el?.attributes.User?.data?.id !== Number(userID)
            }
        }))
        setAdvertisesNotifies(newAdvertises?.filter((el: any) => {
            return el?.attributes?.Notify === notifyApp || el?.attributes?.Notify === 'Both'
        }))

        setAllAttention([
            ...newOffers.filter((el: any) => {
                const task = getBelongsUnitForOffer(el)
                if (notifyApp === "Agent") {
                    return (el?.attributes?.Notify === notifyApp && task.type !== 'MY UNIT') || el?.attributes?.Notify === 'Both' || (el?.attributes?.Notify === 'Other Agent' && scheduleFilters.sort !== 'bso_units' && el?.attributes.User?.data?.id !== Number(userID))
                } else if (notifyApp === "BSO") {
                    return ((el?.attributes?.Notify === notifyApp || el?.attributes?.Notify === "Both") && task.type === 'BSO UNIT') && el?.attributes.User?.data?.id !== Number(userID)
                }
            }),
            ...newViewings.filter((el: any) => {
                const task = getBelongsUnitForViewing(el)
                if (notifyApp === "Agent") {
                    return (el?.attributes?.Notify === notifyApp && task.type !== 'MY UNIT') || el?.attributes?.Notify === 'Both' || (el?.attributes?.Notify === 'Other Agent' && scheduleFilters.sort !== 'bso_units' && el?.attributes.User?.data?.id !== Number(userID))
                } else if (notifyApp === "BSO") {
                    return ((el?.attributes?.Notify === notifyApp || el?.attributes?.Notify === "Both") && task.type === 'MY UNIT') && el?.attributes.User?.data?.id !== Number(userID)
                }
            }),
            ...newAdvertises.filter((el: any) => {
                return el?.attributes?.Notify === notifyApp || el?.attributes?.Notify === 'Both'
            })
        ])
    }, [scheduleFiltersApply, viewings, offers, advertises, userBroadcastMessages]) // scheduleFilters,

    useEffect(() => {
        dispatch(mainButtonClosing())
    }, [])

    const isThereNotices = (viewingsNotifies?.length + offersNotifies?.length + advertisesNotifies?.length) > 0
    const [expanded, setExpanded] = useState<false | string>(false)
    if (loading) return <Loader />



    return (
        <>
            <div className="page d-flex flex-column flex-row-fluid h-100" id="header_scheduler"
                style={{ minHeight: `${getDynamicHeight()}px` }}
            >
                <SchedulerHeader
                    allAttention={allAttention.length}
                    countView={filteredViewings.length}
                    countOffers={filteredOffers.length}
                    advertisesCount={filteredAdvertises.length}
                    broadcastMessagesCount={broadcastMessages.length}
                    viewingsNotifies={viewingsNotifies?.length}
                    offersNotifies={offersNotifies?.length}
                    advertisesNotifies={advertisesNotifies?.length}

                />
                {userDevice === 'IPhone'
                    ? <div className="h-25px"></div>
                    : <></>
                }
                <div
                    className="m-0"
                    style={{
                        height: filterNavigate === 'attention'
                            ? '128px'
                            : '124px'
                    }}
                >
                </div >
                <div
                    className={'content d-flex flex-column flex-fill p-0 mx-4 my-2 '}
                    id="calendar_tasks_list"
                >
                    {/* <ScheduleLandlordPMCAttentionCard pendingTasksCount={allAttention.length} /> */}
                    {filterNavigate === 'attention'
                        ? (
                            isThereNotices
                                ? <>
                                    {
                                        <Accordion key={'Advertises'} group={'Advertises'} expanded={expanded}
                                            setExpanded={setExpanded} countLable={advertisesNotifies.length}>
                                            {advertisesNotifies
                                                .map((adv: any) => (
                                                    <div
                                                        key={adv.id}
                                                        className="card shadow-sm mb-4 position-relative"
                                                    >
                                                        <ScheduleAdvertiseAttention
                                                            key={"list-attention-advertises"}
                                                            advertise={adv}
                                                        />
                                                    </div>
                                                ))}
                                        </Accordion>
                                    }

                                    {
                                        <Accordion key={'Offers'} group={'Offers'} expanded={expanded} setExpanded={setExpanded} countLable={offersNotifies.length}>
                                            {offersNotifies
                                                .map((offer: any) => (
                                                    <div
                                                        key={offer.id}
                                                        className="card shadow-sm mb-2 position-relative"
                                                    >
                                                        <ScheduleOfferAttention key={"list-attention-offers"} offer={offer} />
                                                    </div>
                                                ))}
                                        </Accordion>
                                    }
                                    {
                                        <Accordion key={'Viewings'} group={'Viewings'} expanded={expanded} setExpanded={setExpanded} countLable={viewingsNotifies.length}>
                                            {viewingsNotifies.length > 0 && viewingsNotifies.map((view: any) => (
                                                <div key={view.id} className="card shadow-sm mb-2 mt-1 position-relative">
                                                    <ScheduleViewingAttention key={"list-attention-viewings"} view={view} />
                                                </div>
                                            ))}
                                        </Accordion>}
                                </>
                                :
                                <div className={'d-flex position-relative align-items-end'} >
                                    <img
                                        className="w-75 object-fit-cover m-auto rounded rounded-4  opacity-100 mt-8"
                                        src={placeholders.Notices}
                                        alt={'liable_picture'}
                                    />
                                    <span className="fs-2x fs-lg-2 fw-bold text-white text-uppercase position-absolute text-center" style={{ bottom: '14%', left: '50%', transform: 'translateX(-50%)', textShadow: '1px 1px 10px #332d2dd9' }}>
                                        You don’t have any new notifications
                                    </span>
                                </div>
                        )
                        : null
                    }

                    {
                        filterNavigate === 'viewings'
                            ? (
                                <>
                                    <ViewingsList agentViewings={filteredViewings} key={"list-viewings"} />
                                </>
                            )
                            : null
                    }
                    {
                        filterNavigate === 'offers'
                            ? (
                                <OffersList offers={filteredOffers} key={"list-offers"} />
                            )
                            : null
                    }
                    {
                        filterNavigate === 'referrals'
                            ? (
                                <ReferralsList key={"list-referrals"} />
                            )
                            : null
                    }
                    {
                        filterNavigate === 'adverts'
                            ? (
                                <AdvertiseList advertises={filteredAdvertises} key={"list-adverts"} />
                            )
                            : null
                    }

                    {
                        filterNavigate === 'broadcasts'
                            ? <BroadcastList broadcastMessages={broadcastMessages} key={"list-broadcasts"} />
                            : null
                    }

                    {/* {
        filterNavigate === 'tasks'
        ? <TasksList tasks={[]} key={nanoid()} />
        : null
    } */}


                </div >

                <div
                    className="m-0"
                    style={{ height: `${'190px'}` }}
                />

                <div
                    className="fixed-bottom  mx-auto px-4 py-2 bg-white"
                    style={{
                        bottom: userDevice === 'IPhone' ? '9.65rem' : '8.2rem',
                        left: '0px',
                        zIndex: 1
                    }}
                >
                    {userType === 'Agent'
                        ? isMyTasks
                            ? null
                            : <>
                                <div className="d-flex flex-row w-100 h-100 gap-2 ">
                                    <div className={`col-${isShowFilters ? '8' : '12'}`}>
                                        <AgentMainButton title={'Choose action'}>
                                            {/* <UniverseAction title='BOOK VIEWING' descriptionName='Make an appointment to show the best units to your clients' icon='event_available' /> */}
                                            <ActionBookViewing />
                                            <ActionMakeOffer />
                                            {/* <ActionCreateRefferal /> */}
                                        </AgentMainButton>
                                    </div>
                                    {isShowFilters &&
                                        <div className="col-4 me-2">
                                            <div className="btn btn-bso fs-6 w-100 py-4 d-flex align-items-center justify-content-center"
                                                onClick={() => dispatch(setShowPopupScheduleFilters(true))}
                                            >
                                                <i className="ki-duotone ki-setting-3  fs-2x  m-n2 pe-2 ">
                                                    <span className="path1 "></span>
                                                    <span className="path2 "></span>
                                                    <span className="path3 "></span>
                                                    <span className="path4 "></span>
                                                    <span className="path5 "></span>
                                                </i>Filters
                                            </div >
                                        </div>
                                    }
                                </div>
                            </>
                        :
                        isShowFilters && isDev && userID === Number(REACT_APP_BSO_USER_ID) &&
                        <div className="btn btn-bso fs-5 w-auto py-4 d-flex align-items-center justify-content-center ls-1"
                            onClick={() => dispatch(setShowPopupScheduleFilters(true))}
                        >
                            <i className="ki-duotone ki-setting-3 fs-2x m-n2 pe-2 ">
                                <span className="path1 "></span>
                                <span className="path2 "></span>
                                <span className="path3 "></span>
                                <span className="path4 "></span>
                                <span className="path5 "></span>
                            </i>Filters
                        </div >
                    }

                </div >

                <div className="position-relative">
                    {
                        mainButtonOpen
                            ? null
                            : userType === 'Agent' ? <AgentFooter /> : <LandlordFooter />
                    }
                </div>
                <div className="d-none pb-14"></div>
            </div >

            {
                showPopupScheduleFilters
                    ? <ScheduleFilters title='ScheduleFilters'>
                        < ScheduleFiltersList key={nanoid()} scheduleFiltersApply={scheduleFiltersApply} setScheduleFiltersApply={setScheduleFiltersApply} />
                    </ScheduleFilters >
                    : null}

        </>
    )
}
const Accordion = ({ group, expanded, setExpanded, countLable, children }: any) => {
    const isOpen = group === expanded

    return (
        <>
            <motion.header
                initial={false}
                onClick={() => setExpanded(isOpen ? false : group)}
                // eslint-disable-next-line react/no-children-prop
                children={
                    <div key={nanoid()} className={'position-relative'}>
                        <img
                            className="w-100 h-100px object-fit-cover mb-2 rounded opacity-100"
                            src={group === 'Advertises' ? advertiseAttention : group === 'Offers' ? offersAttention : group === 'Viewings' ? viewingsAttention : ''}
                            alt={`${group}_group_liable`}
                        />
                        <span className="d-block fs-2  min-w-30px max-w-30px text-center position-absolute rounded-0 rounded-bottom rounded-end-0  translate-middle badge badge-secondary opacity-75"
                            style={{ top: '12px', right: '-15px' }}
                        >
                            {countLable}
                        </span>
                        <span className="ps-4 fs-4x  fs-lg-3x  fw-semi-bold text-white position-absolute lh-1 ls-n2  " style={{ top: '9px', left: '0px', textShadow: '1px 1px 10px #332d2dd9' }}>
                            {group}
                            <div className="ps-8 ms-8 fs-lg-1x">Attentions</div>
                        </span>
                    </div>
                }
            />
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: 'auto' },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        {children}
                    </motion.section>
                )}
            </AnimatePresence>
        </>
    )
}
