import React, { type ReactElement } from 'react'
import { nanoid } from 'nanoid'

import NotAdded from '../../../../functions/agent/statuses/NotAdded'
import Approved from '../../../../functions/agent/statuses/Approved'
import Icon from '../../../icon/Icon'
import downloadBlobByURL from '../../../../functions/utils/downloadBlobByURL'

export interface ViewModalContactInfoProps {
    title: string
    info: Array<string | ReactElement>
    controls?: Array<string | ReactElement>
    documents: Array<{
        docType: string
        document: any
        ignoreStatus?: boolean
    }>
    clientName: string
}

export default function ViewModalContactInfo({ title, info, documents, controls = [], clientName }: ViewModalContactInfoProps): ReactElement {
    return <div className="fv-row mb-2 m-0 p-0 w-100 ">
        <label className="form-label fs-5 fw-bold text-dark m-0">
            {title}
        </label>
        <div className="d-flex flex-wrap fw-normal text-dark mb-2">
            {
                info.map((element) => <div
                    className="badge badge-light-primary border border-primary fs-6 my-1 me-2 text-break text-wrap"
                    style={{ maxWidth: '100%' }}
                    key={nanoid()}
                >
                    {element}
                </div>)
            }
            {
                controls.map((element) => element)
            }
            <div className={'mb-2 w-100 '} />
            {
                documents.map((element) => <div className={'w-100 rounded d-flex gap-2 align-items-center border border-gray-300 border-dashed my-1'} key={nanoid()}>
                    <div className="min-w-125px py-2 px-3 w-100 g-0 d-flex flex-row justify-content-between">
                        <div className="fs-5 g-0 d-flex flex-column justify-content-center">
                            <div className="">
                                <span className='fs-6 fw-bold counted'>{element?.docType} :</span>
                            </div>
                        </div>
                        <div className={''}>
                            {
                                element.document != null
                                    ? <Approved statusText="Added" fontsize='' />
                                    : <NotAdded />
                            }
                        </div>
                    </div>
                    {
                        <Icon name={'download_for_offline_24dp'} className={'text-muted fs-5x'} onClick={() => {
                            if (element.document?.attributes?.Type?.[0]?.__component === "documents.emirates-id" && [element.document.attributes.LinkFront, element.document.attributes.LinkBack].some(Boolean)) {
                                if (element.document?.attributes?.LinkFront != null) {
                                    void downloadBlobByURL(
                                        element.document.attributes.LinkFront,
                                        `${clientName}-Emirates-ID-Front`
                                    )

                                }
                                if (element.document?.attributes?.LinkBack != null) {
                                    void downloadBlobByURL(
                                        element.document.attributes.LinkBack,
                                        `${clientName}-Emirates-ID-Back`
                                    )

                                }
                            } if (element.document?.attributes?.Type?.[0]?.__component === "documents.passport" && Boolean(element.document.attributes.Link)) {
                                void downloadBlobByURL(
                                    element.document.attributes.Link,
                                    `${clientName}-Passport`
                                )
                            } if (element.document?.attributes?.Category === 'Deposit Cheque') {
                                if (element.document?.attributes?.Link != null) {
                                    void downloadBlobByURL(
                                        element.document.attributes.Link,
                                        `${clientName}-Deposit-Pay-Slip`
                                    )
                                }
                            } if (element.document?.attributes?.Category === 'Draft Contract') {
                                if (element.document?.attributes?.Link != null) {
                                    void downloadBlobByURL(
                                        element.document.attributes.Link,
                                        `${clientName}-Draft-Contract`
                                    )
                                }
                            } if (element.document?.attributes?.Type?.[0]?.__component === "documents.trade-license" && Boolean(element.document.attributes.Link)) {
                                void downloadBlobByURL(
                                    element.document.attributes.Link,
                                    `${clientName}-Trade-License`
                                )
                            } if (element?.document?.attributes?.Category === 'Universal') {
                                void downloadBlobByURL(
                                    element?.document?.attributes.Link,
                                    `${clientName}-${element.document?.attributes?.Name}`
                                )
                            }
                        }} />
                    }
                </div>)
            }
        </div>
        <div className="fv-row my-2  p-2 w-100">
            {/* <div className="separator border border-bso border-3 px-2 my-2"/> */}
        </div>
    </div>
}
