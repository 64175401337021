import { List } from 'antd'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { getStatusesOffers } from '../../../../functions/agent/schedule/getStatusesOffers'
import OfferListItem from './OfferListItem'
import { type IOffer, offersSlice } from '../../../../store/slices/offersSlice'


import {
    prImage,
    othrImage,
    rejImage,
    aprImage,
    completedImage,
    resubmitRequiredImage,
    cancelledImage, negotiatedImage, confirmedImage,
    placeholders
} from '../../../../urls'

interface IProps {
    offers: IOffer[]
}

export default function OffersList({ offers }: IProps): JSX.Element {
    let statuses: any = []
    statuses = getStatusesOffers(offers)

    const filterNavigate = useAppSelector((state: RootState) => state.schedule.filterNavigate)
    const offersScrollPosition = useAppSelector((state: RootState) => state.offers.offersScrollPosition)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (filterNavigate === 'offers') {
            setTimeout(() => {
                window.scrollTo({ top: Number(offersScrollPosition), behavior: 'smooth' })
            }, 100)
        }
    }, [filterNavigate])

    useEffect(() => {
        const scrollWrapper = document.querySelector('#root')
        const handleScroll = (event: Event): void => {
            const target = event?.target as HTMLElement
            if (target.scrollTop > 0) {
                dispatch(offersSlice.actions.setOffersScrollPosition(target.scrollTop))
            }
        }
        scrollWrapper?.addEventListener('scroll', handleScroll, { passive: true })
        return () => {
            ///    dispatch(offersSlice.actions.setOffersScrollPosition(offersScrollPosition + 2))
            scrollWrapper?.removeEventListener('scroll', handleScroll)
        }
    }, [filterNavigate])

    return (
        <>
            {offers.length > 0
                ? <List
                    dataSource={statuses}
                    renderItem={(item: any, index: number) => {
                        const { status } = item
                        let srcImage = othrImage
                        switch (status) {
                            case 'In-process':
                                srcImage = prImage
                                break
                            case 'Client docs required':
                                srcImage = prImage
                                break

                            case 'Resubmit required':
                                srcImage = resubmitRequiredImage
                                break
                            case 'Canceled':
                                srcImage = cancelledImage
                                break
                            case 'Negotiated offers':
                                srcImage = negotiatedImage
                                break
                            case 'Approved':
                                srcImage = aprImage
                                break
                            case 'Confirmed':
                                srcImage = confirmedImage
                                break
                            case 'Rejected':
                                srcImage = rejImage
                                break
                            case 'Completed':
                                srcImage = completedImage
                                break

                            default:
                                break
                        }
                        return (
                            <>
                                {item.offersLength > 0
                                    ? <div key={status} className={'position-relative'}>
                                        <>
                                            <img
                                                className=" w-100 h-100px object-fit-cover mb-2 rounded opacity-100"
                                                src={srcImage}
                                                alt={status}
                                            />
                                            <span className="d-block fs-2 min-w-30px max-w-30px text-center position-absolute rounded-0 rounded-bottom rounded-end-0 translate-middle badge badge-secondary opacity-75" style={{ top: '12px', right: '-15px' }}>
                                                {item.offersLength}
                                            </span>
                                            <span className="ps-4 fs-5x fs-lg-3x fw-semi-bold text-white position-absolute lh-1" style={{ top: '12px', left: '0px', textShadow: '1px 1px 10px #332d2dd9' }}>
                                                {status === 'Approved' ? 'Sent to landlord' : status}
                                            </span>
                                        </>
                                    </div>
                                    : null}
                                <OfferListItem key={item.id} {...item} isMessageOffer={item.isMessage} />
                            </>
                        )
                    }}
                />
                : <div className={'d-flex position-relative align-items-end'}>
                    <img
                        className="w-75 object-fit-cover m-auto rounded rounded-4 opacity-100  mt-8"
                        src={placeholders.Offers}
                        alt={'liable_picture'}
                    // style={{ height: '50vh' }}
                    />
                    <span className="fs-2x fs-lg-2 fw-bold text-white text-uppercase position-absolute text-center" style={{ bottom: '14%', left: '50%', transform: 'translateX(-50%)', textShadow: '1px 1px 10px #332d2dd9' }}>
                        You don’t have any active offers yet
                    </span>
                </div>
            }

        </>
    )
}
