import QueryString from "qs"
import { REACT_APP_BSO_USER_ID, USER_CATEGORY_KEY, USER_ID_KEY } from "../../../../urls"






export const getQueryOffers = (): string => {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const userCATEGORY = window.localStorage.getItem(USER_CATEGORY_KEY)

    let offersQueryObject = {}

    if (userCATEGORY === 'Agent') {
        offersQueryObject = {
            filters: {
                $or: [
                    {
                        User: {
                            id: {
                                $eq: userID
                            }
                        }
                    },
                    {
                        Unit: {
                            id: {
                                $notNull: true
                            },
                            User: {
                                id: {
                                    $eq: userID
                                }
                            }
                        }
                    },
                    {
                        Unit: {
                            id: {
                                $notNull: true
                            },
                            ManagedBy: {
                                $eq: "BSO"
                            },
                            User: {
                                id: {
                                    $eq: Number(REACT_APP_BSO_USER_ID)
                                }
                            }
                        }
                    },
                ]
            },
            populate: {
                User: {
                    fields: ['id', 'DeviceToken', 'Category']
                },
                Unit: {
                    fields: ['id', 'Marketing'],
                    populate: {
                        Marketing: '*',
                        User: {
                            fields: ['id', 'Category']
                        }
                    }
                },
                Documents: {
                    fields: ['*']
                },
                Type: true
            },

        }
    } else if (userCATEGORY === 'Landlord' && Number(userID) !== Number(REACT_APP_BSO_USER_ID)) {
        offersQueryObject = {
            filters: {
                $or: [
                    {
                        User: {
                            id: {
                                $notNull: true
                            }
                        }
                    }

                ]
            },
            populate: {
                User: {
                    fields: ['id', 'DeviceToken']
                },
                Unit: {
                    fields: ['id', 'Marketing'],
                    populate: {
                        Marketing: '*',
                        User: {
                            fields: ['id', 'Category']
                        }
                    }
                },
                Documents: {
                    fields: ['*']
                },
                Type: true
            }
        }
    } else if (Number(userID) === Number(REACT_APP_BSO_USER_ID)) {
        offersQueryObject = {
            filters: {
                $or: [
                    {
                        Unit: {
                            id: {
                                $notNull: true
                            },
                            ManagedBy: {
                                $eq: "BSO"
                            },
                            User: {
                                id: {
                                    $eq: Number(userID)
                                }
                            }
                        }
                    },
                ]
            },
            populate: {
                User: {
                    fields: ['id', 'DeviceToken']
                },
                Unit: {
                    fields: ['id', 'Marketing'],
                    populate: {
                        Marketing: '*',
                        User: {
                            fields: ['id', 'Category']
                        }
                    }
                },
                Documents: {
                    fields: ["*"]
                },
                Type: true
            }
        }
    }

    const offersQuery = QueryString.stringify(offersQueryObject, { encodeValuesOnly: true })

    return offersQuery
}