import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDubaiDate'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { getQueryAdvertises } from '../../../functions/shared/api/queries/getQueryAdvertises'
import { sendNotification } from '../../../functions/shared/notifications/sendNotification'
import { ACCESS_KEY, urlAPI } from '../../../urls'
import { setAdvertises } from '../../slices/advertisesSlice'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'
import { type User } from '../../slices/userSlice'
import { type AppDispatch, type RootState } from '../../store'

export const removeLinksAdvertiseAction = (formValues: Record<string, unknown>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) as string
    try {
        dispatch(dataFetchingStart())
        const sendObject = {
            method: 'PUT',
            collection: 'unit-advertises',
            id: Number(formValues.advID),
            query: getQueryAdvertises(), 
            body: {
                Statuses: ['Ads removed'],
                Notify: 'BSO'
            }
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                const currentUnit = getState().units?.units?.filter((el: any) => el.id === Number(formValues.unitID))[0]
                const currentUser = getState().user.currentUser
                const nameUser = getNameUser(currentUser as User)
                const unitAdress = getUnitAddress(currentUnit)
                const inMomentViewingForAdmin = toUTCWithOffset(getCurrentDubaiDate(), 1)

                dispatch(dataFetchingFinish())
                dispatch(setAdvertises(dataJSON.response.data))

                await sendNotification(inMomentViewingForAdmin, Number(currentUser.id), dataJSON.response.data.id, 'admin-advertise-ads-removed', `Attention admin, ${nameUser} deleted links an advertise for ${unitAdress}.Please check`)
                return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: 'Your ad removal verification has been successfully received. You will be notified soon.' }
            }
        } else {
            dispatch(dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
        dispatch(dataFetchingFinish())
        return { textNavigate: '', isSuccessful: false, textNotification: 'Something went wrong, try again.' }
    }
}
