import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import AgentHeader from '../AgentHeader'
import AgentFooter from '../AgentFooter'
import AgentSlideManuals from './others/AgentSlideManuals'
import AlertsVerified from '../alerts/AlertsVerified'
import { mainButtonClosing } from '../../../store/slices/modalSlice'

import TopPerformers from './others/TopPerformers'
import CurrentLevelBar from './others/CurrentLevelBar'
import CreditBalance from './others/CreditBalance'
import Wallet from './others/Wallet'
import RecentlyUploadUnits from './others/RecentlyUploadUnits'
import { type ISettings, type User } from '../../../store/slices/userSlice'
import { generateProgressProfile } from '../../../functions/agent/profile/generateProgressProfile'

export default function AgentDashboard(): JSX.Element {
    const dispatch = useAppDispatch()

    const mainButtonOpen = useAppSelector((state: RootState) => state.modal.mainButtonOpen)
    // useEffect(() => {
    //     dispatch(mainButtonClosing())
    // }, [])
    useEffect(() => {
        const closeMainButton = async () => {
            try {
                await dispatch(mainButtonClosing())
            } catch (error) {
                console.error('Ошибка в mainButtonClosing:', error)
            }
        }
        closeMainButton()
    }, [dispatch])
    const currentUser = useAppSelector(
        (state: RootState) => state.user.currentUser
    ) as User
    const settings = currentUser?.Settings as ISettings[]

    const documents = useAppSelector(
        (state: RootState) => state.documents.documents
    ).filter(doc => doc.attributes?.User?.data?.id === Number(currentUser.id))

    const [progressProfile, setProgressProfile] = useState<number>(generateProgressProfile(currentUser, documents))

    return (
        <>
            <AlertsVerified />

            <div className="page d-flex flex-column flex-row-fluid fs-5 position-relative">
                {/* <div id={'error_id_animation'} data-animation-path="public/assets/media/animations/Error.json" data-anim-loop="true" data-name={'error'}></div> */}
                <AgentHeader active="Dashboard" />
                <div className="content d-flex flex-column flex-fill p-0 m-4">
                    <div className="w-100 mw-450px mx-auto ">
                        <AgentSlideManuals />
                        <div className="d-flex flex-row align-items-stretch ">
                            <div className="col-8 pe-2">
                                <div className="card shadow-sm mb-1 h-100">
                                    <div className="card-body p-2 pb-1">
                                        <TopPerformers />
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="card shadow-sm mb-1  h-100 text-center">
                                    <div className="card-body p-2 pb-1">
                                        <CurrentLevelBar progressProfile={progressProfile} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <RecentlyUploadUnits />
                        <div className="d-flex flex-row align-items-stretch my-4">
                            <div className="col-6 pe-2">
                                <div className="card shadow-sm mb-4 h-100">
                                    <div className="card-body p-2 pb-1">
                                        <CreditBalance settings={settings} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card shadow-sm mb-4 h-100 text-center bg-dark">
                                    <div className="card-body p-2 pb-1">
                                        <Wallet settings={settings} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="m-0" style={{ height: `160px` }} />

            {mainButtonOpen
                ? null
                : <>
                    <AgentFooter /></>}
        </>
    )
}
