import { type IOffer } from '../../../store/slices/offersSlice'

export const getStatusesOffers = (offers: IOffer[] | []): any => {
    const statuses = [] as any

    const negotiatedOffers = offers.filter(offer => offer?.attributes?.Statuses.includes('Negotiated by BSO') || offer?.attributes?.Statuses.includes('Negotiated by landlord') || offer?.attributes?.Statuses.includes('Negotiated by agent') || offer?.attributes?.Statuses.includes('Negotiated by other agent'))
    const rejectedOffers = offers.filter(offer => offer?.attributes?.Statuses.includes('Rejected'))
    const approvedOffers = offers.filter(offer => offer?.attributes?.Statuses.includes('Approved'))
    const resubmitOffers = offers.filter(offer => offer?.attributes?.Statuses.includes('Resubmit required'))

    const clientInfoRequired = offers.filter(offer => offer?.attributes?.Statuses.includes('Client docs required'))
    const confirmedOffers = offers.filter(offer => offer?.attributes?.Statuses.includes('Confirmed'))
    const canceledOffers = offers.filter(offer => offer?.attributes?.Statuses.includes('Canceled'))
    const completedOffers = offers.filter(offer => offer?.attributes?.Statuses.includes('Completed'))
    const inProcessOffers = offers.filter(offer => offer?.attributes?.Statuses.includes('In-process'))
    const otherOffers = offers.filter(offer =>
        !(offer?.attributes?.Statuses.includes('Rejected')) &&
        !(offer?.attributes?.Statuses.includes('Approved')) &&
        !(offer?.attributes?.Statuses.includes('Confirmed')) &&
        !(offer?.attributes?.Statuses.includes('Canceled')) &&
        !(offer?.attributes?.Statuses.includes('In-process')) &&
        !(offer?.attributes?.Statuses.includes('Resubmit required')) &&
        !(offer?.attributes?.Statuses.includes('Negotiated by BSO')) &&
        !(offer?.attributes?.Statuses.includes('Negotiated by landlord')) &&
        !(offer?.attributes?.Statuses.includes('Negotiated by agent')) &&
        !(offer?.attributes?.Statuses.includes('Completed')) &&
        !offer?.attributes?.Statuses.includes('Negotiated by other agent') &&
        !offer?.attributes?.Statuses.includes('Client docs required')
    )
    statuses.push({
        status: 'Client docs required',
        offers: clientInfoRequired,
        offersLength: clientInfoRequired.length
    })
    statuses.push({
        status: 'Completed',
        offers: completedOffers,
        offersLength: completedOffers.length
    })
    statuses.push({
        status: 'Negotiated offers',
        offers: negotiatedOffers,
        offersLength: negotiatedOffers.length
    })

    statuses.push({
        status: 'Resubmit required',
        offers: resubmitOffers,
        offersLength: resubmitOffers.length
    })
    statuses.push({
        status: 'In-process',
        offers: inProcessOffers,
        offersLength: inProcessOffers.length
    })

    statuses.push({
        status: 'Rejected',
        offers: rejectedOffers,
        offersLength: rejectedOffers.length
    })
    statuses.push({
        status: 'Canceled',
        offers: canceledOffers,
        offersLength: canceledOffers.length
    })
    statuses.push({
        status: 'Approved',
        offers: approvedOffers,
        offersLength: approvedOffers.length
    })
    statuses.push({
        status: 'Confirmed',
        offers: confirmedOffers,
        offersLength: confirmedOffers.length
    })
    statuses.push({
        status: 'Other',
        offers: otherOffers,
        offersLength: otherOffers.length
    })

    return statuses
}
