
import React from 'react'
import {
    type FieldErrors,
    type UseFormRegister,
} from 'react-hook-form'

import CustomSingleFileInput from '../../../../app/custom/inputs/CustomSingleFileInput'
import DefaultInput from '../../../../app/custom/inputs/DefaultInput'
import { regExps } from '../../../../constants'
import { useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { getOfferTypeName } from '../../../../functions/agent/offers/getOfferTypeName'


interface IProps {

    errors: FieldErrors<any>
    register: UseFormRegister<any>
    offerID: number
}




export default function StateClientData({
    errors,
    register,
    offerID
}: IProps): JSX.Element {


    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const offer = offers.find((offerItem) => offerItem.id === offerID)
    const documents = useAppSelector((state: RootState) => state.documents.documents)
    const offerDocs = documents.filter((docItem) => (
        offer?.attributes?.Documents?.data?.some((offerDocItem) => (
            docItem.id === offerDocItem.id
        ))
    ))
    const passportDoc = offerDocs?.find((docItem) => docItem?.attributes?.Type?.[0]?.__component === 'documents.passport' || docItem?.attributes?.Category?.includes("Visa"))
    const saleType = getOfferTypeName(offer).includes('sale')

    const rowVisa = {
        id: 'visa',
        content: ['Client\'s Passport'],
        element: 'input-file',
        required: true,
    }

    const rowEmail = { id: 'email', content: ['Client\'s email', 'Input client\'s email'], element: 'input', regexp: regExps.isEmail, required: true, defaultValue: offer?.attributes.Type[0]?.Email }
    const rowPhone = { id: 'phone', content: ['Client\'s phone', 'Input client\'s phone'], element: 'input', regexp: regExps.isPhone, required: true, defaultValue: offer?.attributes.Type[0]?.Phone }


    return (

        <div >
            {!saleType ?
                <>
                    <div className='text-center fs-6 fw-bold p-2 my-2'>Please reload application</div>
                </>
                :
                <>
                    {(passportDoc == null) && <CustomSingleFileInput row={rowVisa} register={register} errors={errors} getValues={() => { }} />}
                    <DefaultInput row={rowEmail} register={register} errors={errors} />
                    <DefaultInput row={rowPhone} register={register} errors={errors} />
                </>
            }
        </div >

    )
}
