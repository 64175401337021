
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import CustomFormLable from '../../../app/custom/customModalUtils/CustomFormLable'
import MyDataPicker from '../../../app/MyDataPicker'
import _ from 'lodash'
import CustomSelect from '../../../app/custom/selects/CustomSelect'

import { useAppSelector } from '../../../hooks/redux'

import { generateDefaultValueFormField } from '../../../functions/agent/AgentModals'

import { regExps } from '../../../constants'
import { type RootState } from '../../../store/store'

import AreaInput from '../../../app/custom/inputs/AreaInput'

export const calculatePercentageChange = (originalValue: number, newValue: number): number => {
    if (originalValue === 0) {
        return newValue === 0 ? 0 : 100
    }
    return ((newValue - originalValue) / originalValue) * 100
}

export default function StateTypeOfferLast({
    setValue,
    errors,
    register,
    control
}: any): JSX.Element {
    const { modalID, unitID, objectID } = useParams()

    const units = useAppSelector((state: RootState) => state.units.units)
    const currentUnit = units?.filter((el: any) => el.id === Number(unitID))[0]
    const isSale = currentUnit.attributes.Marketing?.Use === 'For sale'

    const currentOffer = useAppSelector((state: RootState) => state.offers.offers)?.filter((offer) => offer.id === Number(objectID))[0]

    const isNegotiateModal = modalID === 'agent_to_agent_negotiate_offer'
    const [currentOfferValue, setCurrentOfferValue] = useState<number>(currentOffer?.attributes?.Type[0]?.Offer || 0)
    const [newOfferValue, setNewOfferValue] = useState<number>(currentOffer?.attributes?.Type[0]?.Offer || 0)

    const handleOfferChange = (value: string) => {
        setCurrentOfferValue(isNaN(parseFloat(value)) ? 0 : parseFloat(value))
        const numericValue = parseFloat(value)
        setNewOfferValue(isNaN(numericValue) ? 0 : numericValue)
    }

    const percentageChange = calculatePercentageChange(currentOffer?.attributes?.Type[0]?.Offer || 0, newOfferValue)
    const isPositiveChange = percentageChange >= 0

    const rowTypeOffer = { id: 'Type.Offer', content: ['Offer', 'AED', 'Client’s offer for the property'], element: 'input-group', required: true, regexp: regExps.isNumber, ...(isNegotiateModal && generateDefaultValueFormField(currentOffer?.attributes?.Type[0]?.Offer)) }
    const rowTypeContractStartDate = { id: 'Type.ContractStartDate', content: ['Contract Start Date', 'Input contract start date'], dateAfter: true, element: 'input-date', isBlockPast: true, required: true, ...(isNegotiateModal && generateDefaultValueFormField(currentOffer?.attributes?.Type[0]?.ContractStartDate)) }
    const rowTypePayments = { id: 'Type.Payments', content: ['Payments', 'Select count'], selectors: ['1', '2', '3', '4', '6', '12'], element: 'select', required: true, ...(generateDefaultValueFormField(isNegotiateModal && currentOffer?.attributes?.Type[0]?.Payments)) }
    const rowTypeDuration = { id: 'Type.Duration', content: ['Contract Duration', 'Select contract duration'], selectors: _.range(6, 25).map(item => ({ label: `${item} Months`, value: item })), element: 'select', required: true, ...(isNegotiateModal && generateDefaultValueFormField(currentOffer?.attributes?.Type[0]?.Duration)) }
    const rowTypeAdditionalNotes = { id: 'Type.AdditionalNotes', content: ['Additional Notes', "Note client's special needs"], element: 'input', maxLength: 5000, ...(isNegotiateModal && generateDefaultValueFormField(currentOffer?.attributes?.Type[0]?.AdditionalNotes)) }

    return (
        <>
            <div className="fv-row  mt-8 p-0 w-100">
                {isNegotiateModal && rowTypeOffer.defaultValue && (
                    <div className="d-flex justify-content-between mt-n4">
                        <div className="d-flex flex-wrap d-grid gap-4">
                            <div className="rounded fw-bold px-4 py-2 border-end-dashed border-end border-gray-300  ">
                                <span className="fs-5 fw-semibold text-gray-600"> Previous Deal</span>
                                <div className="my-2">
                                    <span className="fs-3hx fw-bold text-gray-800 me-2 lh-1 ls-n2">{rowTypeOffer.defaultValue}</span>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap d-grid gap-4">
                            <div className="rounded fw-bold px-4 py-2">
                                <span className="fs-5 fw-semibold text-gray-600"> Your Deal</span>
                                <div className="my-2">
                                    <span className="fs-3hx fw-bold text-gray-800 me-2 lh-1 ls-n2">{currentOfferValue}</span>
                                    <span className={`badge fs-base ${isPositiveChange ? 'badge-light-success' : 'badge-light-danger'}`}>
                                        <i className={`ki-duotone ki-arrow-${isPositiveChange ? 'up' : 'down'} fs-5 text-${isPositiveChange ? 'success' : 'danger'} ms-n1`}>
                                            <span className="path1"></span><span className="path2"></span>
                                        </i>
                                        {Math.abs(percentageChange).toFixed(2)}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                    <CustomFormLable row={rowTypeOffer} />
                    <div className="input-group">
                        <input
                            {...register(rowTypeOffer.id, {
                                required: `Field "${rowTypeOffer.content?.[0]}" is required`,
                                validate: (value: string) => {
                                    let result: boolean = true
                                    if (rowTypeOffer.regexp != null) {
                                        result = new RegExp(rowTypeOffer.regexp).test(value)
                                    }
                                    handleOfferChange(value)
                                    return result
                                }
                            })}
                            name={rowTypeOffer.id}
                            className="form-control form-control-solid form-control-lg"
                            type="text"
                            placeholder={rowTypeOffer.content?.[2]}
                            defaultValue={rowTypeOffer?.defaultValue}
                        />
                        <span className="input-group-text border-0">{rowTypeOffer.content?.[1]}</span>
                    </div>
                    {
                        _.get(errors, rowTypeOffer.id) != null && (
                            _.get(errors, rowTypeOffer.id).type === 'validate'
                                ? <div className="text-danger">{`${rowTypeOffer.content?.[0]} must be valid `} </div>
                                : <div className="text-danger">{_.get(errors, rowTypeOffer.id).message}</div>
                        )
                    }
                </div>

                <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                    <CustomFormLable row={rowTypeContractStartDate} />
                    <MyDataPicker control={control} register={register} row={rowTypeContractStartDate} setValue={setValue} />
                    {_.get(errors, rowTypeContractStartDate.id) != null && (
                        <div className="text-danger">{_.get(errors, rowTypeContractStartDate.id).message}</div>
                    )}
                </div>

                {!isSale
                    ? <>
                        <CustomSelect control={control} register={register} data={rowTypePayments} setValue={setValue} errors={errors} />
                        <CustomSelect control={control} register={register} data={rowTypeDuration} setValue={setValue} errors={errors} />
                    </>
                    : null
                }

                <AreaInput
                    register={register}
                    errors={errors}
                    row={rowTypeAdditionalNotes} />

            </div >

        </>

    )
}
