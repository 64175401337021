import React from 'react'
// import LandlordFooter from './footer'
import ActionButton from './footer/ActionButton'
import { useLocation } from 'react-router'
import _ from 'lodash'
import VoicePlayer from '../shared/voice-player'
import { useAppSelector } from '../../hooks/redux'
import { type RootState } from '../../store/store'
import LandlordFooter from './LandlordFooter'
import { getDynamicHeight } from '../../functions/utils/getDynamicHeight'

export interface LandlordLayoutProps {
    children: JSX.Element
}

export const noFooterRoutes = ['/landlord/chats/+', '/landlord/modal/*', '/landlord/store_modal/*', '/landlord/services/*']
export const actionButtonActiveRoutes = ['/landlord/dashboard', '/landlord/schedule']
export const fullFooterRoutes = [] // ['/landlord/schedule']

export default function LandlordLayout({ children }: LandlordLayoutProps): JSX.Element {
    const { pathname } = useLocation()

    const active = _.includes(actionButtonActiveRoutes, pathname)
    const fullFooter = _.includes(fullFooterRoutes, pathname)
    const showFooter = !_.some(noFooterRoutes, (route) => new RegExp(route).test(pathname))

    // const { isShown } = useAppSelector((state: RootState) => state.voice)
    return (
        <>
            {/* isShown
                ? <>
                    <VoicePlayer/>
                    <div style={{ height: '31px' }}/>
                </>
                : <></>
            */}
            <div className="page  " style={{ minHeight: `${getDynamicHeight()}px` }}>
                {
                    showFooter
                        ? <>
                            <div style={{
                                position: 'relative',
                                overflow: 'auto'
                            }}>
                                {children}
                                <div style={{ height: '9rem' }} />
                            </div>
                            <ActionButton active={active} fullFooter={fullFooter} />
                            {/* <LandlordFooter fullFooter={fullFooter}/> */}
                            <LandlordFooter />
                        </>
                        : children
                }</div>

        </>
    )
}
