import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface IUserVar {
    data: {
        id: number
        attributes: {}
    } | null
}

export interface Document {
    id: number
    attributes: {
        createdAt: string
        updatedAt: string
        RefID: string
        Link: string
        PrepareDate: string
        SignDate: string
        ExpiryDate: string
        Statuses: string[]
        LinkBack?: string | null
        LinkFront?: string | null
        User: IUserVar
        Type?: Array<{
            id: number
            __component: string
            Name: string | null
            Number: string | null
            DateOfBirth: string | null
            Gender: string | null
            Nationality: string | null
            Type: string | null
        }>
        Valid?: boolean | null
        Offer?: { data: { id: number } } | null
        Category : string | null
        Name : string | null
    }
}

interface DocumentsState {
    documents: Document[]
    documentsLoading: boolean
    temporaryTitleDeed: any
    error: string
    maxAttemptsScan:number
}

const initialState: DocumentsState = {
    documents: [],
    documentsLoading: false,
    temporaryTitleDeed: null,
    error: '',
    maxAttemptsScan : 1 
}

export const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        documentsFetching (state) {
            state.documentsLoading = true
        },
        documentsFetchingSuccess (state, action) {
            state.error = ''
            state.documents = action.payload
            state.documentsLoading = false
        },
        documentsFetchingError (state, action) {
            state.documentsLoading = false
            state.error = action.payload.message
        },
        documentAddUpdate (state, action) {
            const updatedDocuments = state.documents.map((doc: any) => {
                if (doc.id === action.payload.id) {
                    const updatedUser = {
                        data: {
                            id: action.payload.User?.data?.id || doc.attributes.User.data?.id,
                            attributes: {}
                        }
                    }
                    const updatedOffer = {
                        data: {
                            id: action.payload.Offer?.data?.id || doc.attributes.Offer.data?.id,
                            attributes: {}
                        }
                    }
                    return {
                        id: action.payload.id,
                        attributes: {
                            createdAt: action.payload.createdAt,
                            updatedAt: action.payload.updatedAt,
                            RefID: action.payload.RefID,
                            Link: action.payload.Link,
                            PrepareDate: action.payload.PrepareDate,
                            SignDate: action.payload.SignDate,
                            ExpiryDate: action.payload.ExpiryDate,
                            Statuses: action.payload.Statuses,
                            Type: action.payload.Type,
                            User: updatedUser,
                            Offer: updatedOffer,
                            Unit: action.payload.Unit,
                            Landlord: action.payload.Landlord,
                            Tenant: action.payload.Tenant,
                            Lease: action.payload.Lease,
                            Task: action.payload.Task,
                            Comment: action.payload.Comment
                        }
                    }
                } else {
                    return doc
                }
            })

            state.documents = updatedDocuments
        },
        documentUpdateArchive (state, action: PayloadAction<Document>) {
            state.error = ''
            const newDocument = action.payload
            const actualDocuments = state.documents.filter((doc) => doc.id !== newDocument.id)
            state.documents = [...actualDocuments, newDocument]
            state.documentsLoading = false
        },
        documentAddSuccess (state, action: PayloadAction<Document>) {
            const actualDocuments = [...state.documents, action.payload] 
            state.documents = actualDocuments
            state.error = ''
            state.documentsLoading = false
        },
        documentDelete (state, action: PayloadAction<number>) {
            state.documents = state.documents.filter(doc => doc.id !== action.payload)
        },
        documentAddError (state, action) {
            state.documentsLoading = false
            state.error = action.payload.message
        },
        setTemporaryTitleDeed (state, action) {
            state.temporaryTitleDeed = action.payload
        },
        setMaxAttemptsScan (state, action) {
            state.maxAttemptsScan = action.payload
        }
    }
})

export const { documentAddUpdate,documentAddSuccess, documentsFetchingSuccess, setTemporaryTitleDeed ,setMaxAttemptsScan} = documentsSlice.actions

export default documentsSlice.reducer
