import { type IUnit } from '../types'
import { useAppDispatch, useAppSelector } from './redux'
import { type RootState } from '../store/store'
import { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { removeDataAttributes } from '../functions/adapters/universal/removeDataAttributes'
import { type User } from '../store/slices/userSlice'
import getUnitOfferStatus from '../functions/shared/units/getUnitOfferStatus'
import dayjs from 'dayjs'
import {
    checkIfUnitIsInValidationProcess
} from '../store/actionsCreators/unitActions/landlordUnitsActions/utils/checkIfUnitIsISInValidationProcess'

const useUnitsCatalog = (myUnits: boolean = false): IUnit[] => {
    const filters = useAppSelector((state: RootState) => state.units.filters)
    const units = useAppSelector((state: RootState) => state.units.units)
    const unitsCategory = useAppSelector((state: RootState) => state.units.unitsCategory)
    const searchString = useAppSelector((state: RootState) => state.units.searchString)
    const myArchivedUnitsOpen = useAppSelector((state: RootState) => state.units.myArchivedUnitsOpen)
    const myFavoritesUnitsOpen = useAppSelector((state: RootState) => state.units.myFavoritesUnitsOpen)

    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const [catalog, setCatalog] = useState<IUnit[]>()
    const userAreas = useMemo(() => (currentUser as User)?.Settings?.[0]?.Areas ?? [], [currentUser.Settings])
    const userFavoriteUnits = useMemo(() => (currentUser as User).FavoriteUnits ?? [], [currentUser]);


    useEffect(() => {
        let newCatalog = _.filter(units, (unitItem) => {
            if (unitItem.attributes === undefined) {
                return false
            }
            const unit = removeDataAttributes(_.cloneDeep(unitItem))

            if (myFavoritesUnitsOpen) {
                if (!userFavoriteUnits.includes(unit.id)) {
                    return false;
                }
            } else {
                if(unitsCategory  === "agents" ) {
                    if (unit.HideDate != null && new Date().getTime() - new Date(unit.HideDate).getTime() > 0) {
                        return false
                    }
                } else if (unitsCategory === 'landlords') {
                    console.log('unitsCategory === landlords')
                    // TODO LANDLORDS LIST
                    // if (Object.keys(filters).includes('Landlord')) {
                    //     // console.log(unit.User.id)
                    //     if (unit.User.id === Number(process.env.REACT_APP_BSO_USER_ID)) {
                    //         return false
                    //     }
                    //     if (dispatch(checkIfUnitIsInValidationProcess(unit.id)) !== false) {
                    //         return false
                    //     }
                    //     const unitUser = users.find(user => user.id === unit.User.id)
                    //     // console.log(unitUser)
                    //     if (unitUser == null || unitUser?.Category !== 'Landlord') {
                    //         return false
                    //     }
                    //     // if (unit?.Orders?.some((order: any) => dayjs().isBefore(dayjs(order?.InvoiceDueDate)) && order?.Service?.[0]?.__component === 'services.finding-tenant') !== true) {
                    //     //     return false
                    //     // }
                    // }
                
                } else if (unitsCategory === "rent" || unitsCategory === "sell") {
                    if (unit?.Statuses?.includes('Archived') === true && !myUnits) {
                        return false
                    }
                    if (unit.User.id !== Number(process.env.REACT_APP_BSO_USER_ID) && !myUnits) {
                        return false
                    }
                    if (unit.HideDate != null && new Date().getTime() - new Date(unit.HideDate).getTime() > 0 && !myUnits) {
                        return false
                    }
                    if (unit?.Statuses?.includes('Drafted') === true && !myUnits) {
                        return false
                    }
                    if (myUnits) {
                      
                        if (unit.User.id !== currentUser.id) {
                            return false
                        }
                        if (unit.Statuses?.includes('Archived') === true && !myArchivedUnitsOpen ) {
                            return false
                        }
                        if (unit.Statuses?.includes('Archived') !== true && myArchivedUnitsOpen) {
                            return false
                        }
                    }
                } 

                if (unit?.Marketing == null && !myUnits) {
                    return false
                }
            }
            let result = true
            for (const key in filters) {
                let resultKey = key.replaceAll('_', '.')
                if (key === 'Landlord') {
                    // Ignore filter
                } else if (key === '>Offers[0]_Statuses[0]') {
                    result = result && getUnitOfferStatus(unit) === 'Under offer'
                } else if (key[0] === '>') {
                    if (!_.includes([null, undefined, ''], filters[key])) {
                        resultKey = resultKey.slice(1)
                        // console.log('ATTRIBUTE', resultKey, _.get(unit, resultKey))
                        if (filters[key]?.length > 0) {
                            result = result && _.includes(filters[key], _.get(unit, resultKey))
                        }
                    }
                } else if (key[0] === '<') {
                    if (!_.includes([null, undefined, ''], filters[key])) {
                        resultKey = resultKey.slice(1)
                        result = result && _.includes(_.get(unit, resultKey), filters[key])
                    }
                } else if (_.isArray(filters[key])) {
                    if (!_.includes([null, undefined, ''], filters[key])) {
                        resultKey = resultKey.slice(1)
                        result = result && (filters[key][0] <= _.get(unit, resultKey)) && _.get(unit, resultKey) <= filters[key][0]
                    }
                } else if (key.slice(0, 5) === 'FROM-') {
                    if (!_.includes([null, undefined, ''], filters[key]) && !_.isNaN(+(filters[key]))) {
                        resultKey = resultKey.slice(5)
                        result = result && (_.get(unit, resultKey) >= +(filters[key]))
                    }
                } else if (key.slice(0, 5) === 'UPTO-') {
                    if (!_.includes([null, undefined, ''], filters[key]) && !_.isNaN(+(filters[key]))) {
                        resultKey = resultKey.slice(5)
                        result = result && (_.get(unit, resultKey) <= +(filters[key]))
                    }
                } else if (_.isString(filters[key]) && filters[key]?.slice(0, 5) === 'MORE-') {
                    const controlValue = +(filters[key].slice(5))
                    if (!_.isNaN(controlValue)) {
                        result = result && (_.get(unit, resultKey) > controlValue)
                    }
                } else if (filters[key] === 'More') {
                    result = result && (_.get(unit, resultKey) > 3)
                } else if (_.isString(filters[key]) && filters[key].slice(0, 5) === 'SORT-') {
                    // escape sorting setting
                } else {
                    if (!_.includes([null, undefined, ''], filters[key])) {
                        // console.log('ATTRIBUTES [unit] [filter]', _.get(unit, resultKey), filters[key])
                        result = result && ((_.get(unit, resultKey) === (filters[key])) || (_.get(unit, resultKey) === +(filters[key])) || ((_.get(unit, resultKey) == null)))
                    }
                }
            }
      
            if(myFavoritesUnitsOpen  ){
                result = userFavoriteUnits.includes(unit.id)
            } else {
                if (unitsCategory === 'rent'){
                    result = result && (unit?.Marketing?.Use === 'For rent' || (unit?.Marketing?.Use == null))
                } 
                if (unitsCategory === 'sell' ){
                    result = result && (unit?.Marketing?.Use === 'For sale' || (unit?.Marketing?.Use == null))
                } 
                if (unitsCategory === "agents"){
                    result = result &&  unit?.Marketing && unit?.Marketing?.Headline &&
                     unit?.Statuses?.includes('Door close') && Boolean(unit.User.id) &&  unit.User.id !== currentUser.id &&
                     unit.ManagedBy !== 'BSO' && !unit?.Statuses?.includes('Archived')  
                } 
            }

            if (searchString?.trim()?.length > 0) {
                result = result && (
                    _.includes(unit?.Marketing?.Headline?.toLowerCase(), searchString?.toLowerCase()?.trim()) ||
                    _.includes(unit?.Property?.Area?.toLowerCase(), searchString?.toLowerCase()?.trim()) ||
                    _.includes(unit?.Property?.Name?.toLowerCase(), searchString?.toLowerCase()?.trim()) ||
                    _.includes(searchString?.toLowerCase()?.trim(), unit?.Property?.Area?.toLowerCase()) ||
                    _.includes(searchString?.toLowerCase()?.trim(), unit?.Property?.Name?.toLowerCase())
                )
            }
            return result
        })

        switch (filters['SORT-by']) {
            case 'Creation date descending':
                newCatalog = _.sortBy(newCatalog, ['attributes.updatedAt'])
                _.reverse(newCatalog)
                break
            case 'Creation date ascending':
                newCatalog = _.sortBy(newCatalog, ['attributes.updatedAt'])
                break
            default:
                newCatalog = _.sortBy(newCatalog, ['attributes.updatedAt'])
                if (!_.includes(userAreas, 'All areas') && !myUnits) {
                    newCatalog = _.sortBy(newCatalog, (unit) => {
                        return _.includes(userAreas, unit?.attributes?.Property?.data?.attributes?.Area)
                    })
                }
                newCatalog = _.sortBy(newCatalog, (unit) => getUnitOfferStatus(unit) !== 'Free')
                newCatalog = _.sortBy(newCatalog, (unit) => dayjs().isBefore(dayjs(unit.attributes.CreateDate).add(1, 'day')))
                _.reverse(newCatalog)
                break
        }
        setCatalog(newCatalog)

    }, [units, filters, userAreas, unitsCategory, searchString, myArchivedUnitsOpen, myFavoritesUnitsOpen])

    return catalog ?? []
}

export default useUnitsCatalog
