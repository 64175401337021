import { type AppDispatch, type RootState } from '../../../store'
import { ACCESS_KEY, urlAPI, USER_ID_KEY } from '../../../../urls'
import { uploadMultipleForOffers } from '../../documentsActions/upload/uploadMultipleForOffers'

import { offersSlice, setOffers } from '../../../slices/offersSlice'
import { getErrorss } from '../../../../functions/errors/GetErrors'
import { dataFetchingFinish, dataFetchingStart } from '../../../slices/modalSlice'
import { sendNotification } from '../../../../functions/shared/notifications/sendNotification'
import { getQueryOffers } from '../../../../functions/shared/api/queries/getQueryOffers'


export const makeOfferAgentToBSOClientInfo = (unitID: number, offerID?: number) => (data: Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(dataFetchingStart())
    try {

        const jwt = window.localStorage.getItem(ACCESS_KEY)
        const userID = window.localStorage.getItem(USER_ID_KEY)
        const unit = getState().units.units.find((unitItem) => unitItem.id === unitID)
        const offer = getState().offers.offers.find((offerItem) => offerItem.id === offerID)
      
        if (unit != null) {
            const isCommercial = data.offer_is_commercial === true
            const isSale = unit.attributes?.Marketing?.Use === 'For sale'

            let type: Record<string, any>




            if (isCommercial) {  
                type = {
                    //       __component: isSale ? 'offer.sale-commercial' : 'offer.commercial',
                    BusinessName: data.Type.BusinessName,
                    BusinessOwnerName: data.Type.BusinessOwnerName,
                    BusinessActivity: data.Type.BusinessActivity,
                    EstablishmentDate:  data.Type.EstablishmentDate ,
                    AdditionalNotes: data.Type.AdditionalNotes,
                }
            } else {
                type = {
                    //     __component: isSale ? 'offer.sale' : 'offer.residential',
                    FirstName: data.Type.FirstName,
                    LastName: data.Type.LastName,
                    Occupation: data.Type.Occupation,
                    Employer: data.Type.Employer,
                    Occupants: data.Type.Occupants,
                    Pets: data.Type.Pets,
                    InUAESince : data.Type.InUAESince ,
                    PetType: data.Type.Pets === true ? data.Type.PetType : null,
                    AdditionalNotes: data.Type.AdditionalNotes,
                    Smoking: data.Type.Smoking === true ? data.Type.Smoking: null,
                }
            }
            data.offerID = offerID

    

            const loadDocs = await dispatch(uploadMultipleForOffers(data, 'agent_to_bso_offer'))
    
      
            const bodyObject = {
                method:  'PUT',
                id: offerID,
                collection: 'offers',
                query:  getQueryOffers(),

                body: {
                    Type: [{
                        ...(offer?.attributes?.Type?.[0] ?? {}),
                        ...type
                    }],
                    ProcessStartDate: new Date() ,
                    Statuses: ['In-process'],
                    Notify: 'Both',
                    Documents: [...(offer?.attributes?.Documents?.data?.map(doc => ({ id: doc.id })) ?? []), ...(loadDocs && loadDocs.length > 0 ? loadDocs : []) ] ,
                }
            }
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'BSO-Hash': '02ns30nsBSO!',
                    Authorization: `Bearer ${String(jwt)}`
                },
                body: JSON.stringify(bodyObject)
            })
            const responseJSON = await response.json()
            if (responseJSON.success === true) {
                if (responseJSON.response.error != null) {
                    dispatch(offersSlice.actions.offersFetchingError(getErrorss(responseJSON)))
                    dispatch(dataFetchingFinish())
                    return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
                } else {      
                    dispatch(setOffers(responseJSON.response.data))
                    void sendNotification(new Date(new Date().getTime() + 10000), Number(userID), responseJSON.response.data.id , 'admin-offer-in-process', 'Offer - in process')
                    dispatch(dataFetchingFinish())
                    return {
                        textNavigate: `/agent/modalView/offer_modal/${unitID}/${responseJSON?.response?.data?.id}/create?type=${String(responseJSON?.response?.data?.attributes?.Type?.[0]?.__component ?? 'not').slice(6)}`,
                        isSuccessful: true,
                        textNotification: 'The additional information has been added',
                        title: "Success"
                    }
                }
            }
        }
    } catch (err: any) {
        console.error(`Error in makeOfferAgentToBSOClientInfo ${String(err.message)}`)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false,
        textNotification: 'Something went wrong. Try again later'
    }
}
