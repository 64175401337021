import React, { type ReactElement, useMemo, useState } from "react";
import { type RootState, store } from "../../../../store/store";
import { removeDataAttributes } from "../../../../functions/adapters/universal/removeDataAttributes";
import _ from "lodash";
import { swalSuccess } from "../../../../functions/swalOptions";
import ViewModalContactInfoControls from "./ViewModalContactInfoControls";
import { type Document } from "../../../../store/slices/documentSlice";
import downloadBlobByURL from "../../../../functions/utils/downloadBlobByURL";
import copyToClipboard from "../../../../functions/utils/copyToClipboard";
import { useAppSelector } from "../../../../hooks/redux";

interface CopyClientInfoProps {
    offerID: number;
}

export default function CopyClientInfo({
    offerID,
}: CopyClientInfoProps): ReactElement {
    const [documentsLoading, setDocumentsLoading] = useState(false);
    const clientInfoConfig: Array<{
        template: string;
        path: string;
        checkPath?: string;
    }> =
        [

            {
                template: "First Name: {value}",
                path: "Type[0].FirstName",
            },
            {
                template: "Last Name: {value}",
                path: "Type[0].LastName",
            },
            {
                template: "Business Name: {value}",
                path: "Type[0].BusinessName",
            },
            {
                template: "Offer Type: {value}",
                path: "Type[0].Type",
            },
            {
                template: "Business Activity: {value}",
                path: "Type[0].BusinessActivity",
            },
            {
                template: "Business Establishment Date: {value}",
                path: "Type[0].EstablishmentDate",
            },
            {
                template: "Business Owner Name: {value}",
                path: "Type[0].BusinessOwnerName",
            },
            {
                template: "Email: {value}",
                path: "Type[0].Email",
            },
            {
                template: "Phone: {value}",
                path: "Type[0].Phone",
            },
            {
                template: "Country: {value}",
                path: "Type[0].Country",
            },
            {
                template: "In UAE Since: {value}",
                path: "Type[0].InUAESince",
            },
            {
                template: "UAE Bank: {value}",
                path: "Type[0].UAEBank",
            },
            {
                template: "Bank Name: {value}",
                path: "Type[0].BankName",
            },
            {
                template: "Bank Pre-Approval: {value}",
                path: "Type[0].BankPreApproval",
            },
            {
                template: "Pre-Approved Amount: {value} AED",
                path: "Type[0].PreApprovedAmount",
                checkPath: "Type[0].BankPreApproval",
            },
            {
                template: "Offer: {value} AED",
                path: "Type[0].Offer",
            },
            {
                template: "Occupation: {value}",
                path: "Type[0].Occupation",
            },
            {
                template: "Occupants: {value}",
                path: "Type[0].Occupants",
            },
            {
                template: "Employer: {value}",
                path: "Type[0].Employer",
            },
            {
                template: "Pets: {value}",
                path: "Type[0].Pets",
            },
            {
                template: "Pet Type: {value}",
                path: "Type[0].PetType",
                checkPath: "Type[0].Pets",
            },
            {
                template: "Client is smoker: {value}",
                path: "Type[0].Smoking",
            },
            {
                template: "Payments: {value}",
                path: "Type[0].Payments",
            },
            {
                template: "Contract Start Date: {value}",
                path: "Type[0].ContractStartDate",
            },
            {
                template: "Contract Duration: {value}",
                path: "Type[0].Duration",
            },
            {
                template: "Additional Notes: {value}",
                path: "Type[0].AdditionalNotes",
            },
        ];

    const [text, documents, clientName] = useMemo(() => {
        let text = "";
        const offers = store.getState().offers.offers;
        const offer = removeDataAttributes(
            _.cloneDeep(
                offers?.find((offerItem) => offerItem.id === offerID) ?? {}
            )
        );
        clientInfoConfig.forEach((field) => {
            const value = _.get(offer, field.path);
            let optionalCheckIsOK = true;
            if (field.checkPath != null) {
                optionalCheckIsOK = Boolean(_.get(offer, field.checkPath));
            }
            if (value != null && optionalCheckIsOK) {
                text += field.template.replace("{value}", value) + "\n\r";
            }
        });

        const documentIDs = offer?.Documents?.map((doc: any) => doc.id);
        const documents: Document[] = store.getState().documents.documents.filter(

            (doc) =>
                documentIDs?.includes(doc.id) === true &&
                ([
                    "documents.emirates-id",
                    "documents.passport",
                    "documents.trade-license",
                ].includes(String(doc?.attributes?.Type?.[0]?.__component)) || doc?.attributes?.Category === 'Deposit Cheque' || doc?.attributes?.Category === 'Draft Contract' || doc?.attributes?.Category === "Offer Process Document")
        );
        const clientName = (
            String(offer?.Type?.[0]?.FirstName ?? "") +
            " " +
            String(offer?.Type?.[0]?.LastName ?? "") +
            " " +
            String(offer?.Type?.[0]?.BusinessName ?? "") +
            " " +
            String(offer?.Type?.[0]?.BusinessOwnerName ?? "")
        ).trim();
        return [text, documents, clientName];
    }, [offerID]);

    function copy(): void {
        const { isSuccessful } = copyToClipboard(text);
        if (isSuccessful) {
            void swalSuccess("Client info has been copied successfully");
        }
    }

    function download(): void {
        setDocumentsLoading(true);
        const promises: Array<Promise<void>> = [];
        documents.forEach((doc) => {

            if (doc?.attributes?.Type?.[0]?.__component === "documents.emirates-id" && [doc.attributes.LinkFront, doc.attributes.LinkBack].some(Boolean)) {
                if (doc?.attributes?.LinkFront != null) {
                    promises.push(
                        downloadBlobByURL(
                            doc.attributes.LinkFront,
                            `${clientName}-Emirates-ID-Front`
                        )
                    );
                }
                if (doc?.attributes?.LinkBack != null) {
                    promises.push(
                        downloadBlobByURL(
                            doc.attributes.LinkBack,
                            `${clientName}-Emirates-ID-Back`
                        )
                    );
                }
            } if (doc?.attributes?.Type?.[0]?.__component === "documents.passport" && Boolean(doc.attributes.Link)) {
                promises.push(
                    downloadBlobByURL(
                        doc.attributes.Link,
                        `${clientName}-Passport`
                    )
                );

            } if (doc?.attributes?.Category === 'Deposit Cheque') {
                if (doc?.attributes?.Link != null) {
                    promises.push(
                        downloadBlobByURL(
                            doc.attributes.Link,
                            `${clientName}-Deposit-Pay-Slip`
                        )
                    );
                }
            } if (doc?.attributes?.Category === 'Draft Contract') {
                if (doc?.attributes?.Link != null) {
                    promises.push(
                        downloadBlobByURL(
                            doc.attributes.Link,
                            `${clientName}-Draft-Contract`
                        )
                    );
                }
            } if (doc?.attributes?.Type?.[0]?.__component === "documents.trade-license" && Boolean(doc.attributes.Link)) {
                promises.push(
                    downloadBlobByURL(
                        doc.attributes.Link,
                        `${clientName}-Trade-License`
                    )
                );
            }
            if (doc?.attributes?.Category === 'Universal') {
                if (doc?.attributes?.Link != null) {
                    promises.push(
                        downloadBlobByURL(
                            doc.attributes.Link,
                            `${clientName}-${doc.attributes?.Name}`
                        )
                    );
                }
            }



        });
        Promise.all(promises).finally(() => {
            setDocumentsLoading(false);
        });
    }
    return (
        <ViewModalContactInfoControls
            controls={[
                {
                    text: "Copy client info",
                    action: copy,
                },
                {
                    text: "Download docs",
                    action: download,
                    loading: documentsLoading,
                },
            ]}
        />
    );
}
