
import { authSlice, setLandlordDocumentSwalShown } from '../../slices/authSlice'
import { type RootState, type AppDispatch, store } from '../../store'

import {getErrors, getErrorss} from '../../../functions/errors/GetErrors'

import { type ApiResponse, type IRegisterForm } from '../../../types/userTypes/TAuth'
import {
    urlAUTH,
    urlAPI,
    ACCESS_KEY,
    USER_ID_KEY,
    USER_DOC_TYP_KEY,
    USER_DOC_NUM_KEY,
    USER_DOC_EXP_KEY,
    USER_CATEGORY_KEY,
    LANDLORD_AWAIT_FIRST_UNIT_CREATION_KEY
} from '../../../urls'
import { openChatByUserID } from '../chatActions'

import { nanoid } from 'nanoid'
import { env } from 'eslint-config-standard-with-typescript'
import Swal from 'sweetalert2'
import { animationOptions, landlordRegistrationSwal, swalConfirm, swalOptions } from '../../../functions/swalOptions'
// import { getQueryCurrentUser } from '../../../functions/shared/users/getQueryCurrentUser'

import { getQueryCurrentUser } from '../../../functions/shared/api/queries/getQueryCurrentUser'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { sendNotification } from '../../../functions/shared/notifications/sendNotification'
import {userSlice} from "../../slices/userSlice";
import {modalSlice} from "../../slices/modalSlice";
import {setStatuses} from "../../slices/agentSlice";
import getAgentStatuses from "../../../functions/agent/documents/getAgentStatuses";
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDubaiDate'

export const register = (formValues: IRegisterForm, referralID: number | undefined = undefined) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(authSlice.actions.authFetching(true))
        const number = getState().auth.userNUMBER

        const sendObject = {
            username: `${formValues.email.split('@')[0]}_${nanoid().slice(0, 6)}`.replaceAll(' ', '_').replaceAll('.', '_').replaceAll('-', '_'),
            email: formValues.email,
            phone: number,
            password: formValues.password,
            category: formValues.type === 'Broker' ? 'Agent' : formValues.type,
            ReferredBy: referralID
        }

        const response = await fetch(`${urlAUTH}/register`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const dataJSON = await response.json() as ApiResponse
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(authSlice.actions.registerFailed(dataJSON))
                return { ...dataJSON, textNavigate: '/auth/register2', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(authSlice.actions.emailSuccess(formValues?.email))
                dispatch(authSlice.actions.registerSuccess({
                    jwt: dataJSON.response.jwt,
                    id: dataJSON.response.id,
                    category: formValues.type === 'Broker' ? 'Agent' : formValues.type
                }))

                return { ...dataJSON, textNavigate: '/auth/register_scan_emirates_front', isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(authSlice.actions.registerFailed(getErrorss(dataJSON)))
            return { textNavigate: '/auth/register1', isSuccessful: false, textNotification: 'Some error occurred,please try again' }
        }
    } catch (error) {
        console.log(error)
    }
}

export const registerEnd = (formValues: IRegisterForm) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    const userTypeDoc = window.localStorage.getItem(USER_DOC_TYP_KEY)
    const userNumDoc = window.localStorage.getItem(USER_DOC_NUM_KEY)
    const userExpDoc = window.localStorage.getItem(USER_DOC_EXP_KEY)
    const userCategory = window.localStorage.getItem(USER_CATEGORY_KEY)
    const phone = getState().user?.currentUser?.phone ?? getState().auth.userNUMBER
    const email = getState().user?.currentUser?.email ?? getState().auth.userEMAIL

    let dataDoc

    if (userTypeDoc === 'id_back') {
        dataDoc = {
            EmiratesIDNumber: userNumDoc,
            EmiratesIDExpiryDate: userExpDoc
        }
    } if (userTypeDoc === 'visa') {
        dataDoc = {
            PassportNumber: userNumDoc,
            PassportExpiryDate: userExpDoc
        }
    }
    try {
        dispatch(authSlice.actions.authFetching(true))
        const today = new Date().toISOString().split('T')[0]; 
        const userType = { ...dataDoc, __component: 'contacts.individual' }
        const sendObject = {
            method: 'PUT',
            collection: 'users',
            query: getQueryCurrentUser(),
            id: Number(userID),
            body: {
                Type: [userType],
                ContactInfo: {
                    FirstName: formValues.first_name.trim(),
                    FamilyName: formValues.last_name.trim(),
                    DateOfBirth: formValues.dob,
                    Country: formValues.country,
                    Gender: formValues.gender,
                    Phone: phone,
                    Email: email
                },

                Chats: [Number(process.env.REACT_APP_BSO_BROADCAST_ID ?? '4')],
                Settings: [{
                    __component: userCategory === 'Landlord' ? 'settings.landlord' : 'settings.agent',
                    Membership: 'Bronze',
                    Notifies: {
                        Broadcast: true,
                        Chat: true,
                        Messages: true,
                        Offers: true,
                        Requests: true,
                        Scheduler: true,
                        ViewingBefore1Hour: true,
                        ViewingBefore2Hours: true,
                        ViewingBefore30Minutes: true,
                        Viewings: true
                    },
                    ShowStats: true,
                    Company: formValues.company_name ?? "" ,
                    Languages : formValues.languages  ?? [], 
                }],
                Stats : {
                    ViewingClicks:0,
                    OfferClicks:0,
                    AdvertiseClicks:0 ,
                    AgentToAgentViewingClicks:0 ,
                    AgentToAgentOfferClicks:0 ,
                    LoginCounts: [{ date: today, count: 0 }]
                }
            }
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json() as unknown as ApiResponse

        const { id: supportChatID } = await dispatch(openChatByUserID(Number(process.env.REACT_APP_BSO_USER_ID ?? '57'), Number(userID)))

        if (responseJSON.success && supportChatID != null) {
            if (responseJSON.response.error != null) {
                dispatch(authSlice.actions.registerFailed(responseJSON.response))
                return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
            } else {
                let redirectModule = ''

                if (responseJSON.response.Category === 'Agent') {
                    redirectModule = '/agent'
                } else if (responseJSON.response.Category === 'Landlord') {
                    localStorage.setItem(LANDLORD_AWAIT_FIRST_UNIT_CREATION_KEY, 'true')
                    redirectModule = '/auth/register_choose_landlord_type'
                    // redirectModule = '/landlord/modal/landlord_create_unit/-/-'
                }

                const currentDubaiDate = getCurrentDubaiDate()
                const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, -129600)
                await sendNotification(inMomentViewingForAdmin, Number(userID), Number(userID), 'user-register-delete', 'Hi,hi hi admin, this user registred 90 days ago, you know?.')

                return { ...responseJSON, textNavigate: redirectModule, isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(authSlice.actions.registerFailed(responseJSON))
            return { textNavigate: '', isSuccessful: false, textNotification: 'Some error occurred,please try again' }
        }
    } catch (error) {
        console.log(error)
    }
}

export const verifyUser = (tutorial?: boolean, updateUserState?: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    const newMail = getState().user?.currentUser?.newEmail
    const email = newMail ?? getState().user?.currentUser?.email ?? getState().auth.userEMAIL
    try {
        await fetch(`${urlAUTH}/sendVerifyMail`, {
            method: 'POST',
            body: JSON.stringify({ email, renew: !!newMail }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        if (tutorial === true) {
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify({
                    method: 'PUT',
                    collection: 'users',
                    id: Number(userID),
                    body: {
                        tutorialCompleted: true
                    },
                    query: updateUserState === true ? getQueryCurrentUser() : undefined
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                }
            })
            if (updateUserState === true) {
                const dataJSON = await response.json()

                if (dataJSON.response.error != null) {
                    dispatch(userSlice.actions.userFetchingError(getErrors(dataJSON)))
                } else {
                    dispatch(userSlice.actions.userFetchingSuccess(dataJSON.response))
                    dispatch(modalSlice.actions.setIsUserVerified(dataJSON?.response?.emailVerified))

                    if (dataJSON.response?.Category === 'Agent') {
                        dispatch(setStatuses(getAgentStatuses(dataJSON.response?.Documents)))
                    }
                }

            }
        }
    } catch (error) {
        console.log(error)
    }
}

export const verifyUserForChangeEmail = (email: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''

    try {
        const verifyEmail = await fetch(`${urlAUTH}/sendVerifyMail`, {
            method: 'POST',
            body: JSON.stringify({ email, renew: true }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const verifyEmailJSON = await verifyEmail.json()
        return verifyEmailJSON
    } catch (error) {
        console.log(error)
    }
}

export const showLandlordDocumentRegistrationSwal = (location: string) => (dispatch: AppDispatch, getState: () => RootState): void => {
    if (
        ['register_scan_emirates_front', 'register_scan_passport'].includes(location) &&
        !getState().auth.landlordDocumentSwalShown
    ) {
        void landlordRegistrationSwal().finally(() => {
            dispatch(setLandlordDocumentSwalShown(true))
        })
    }
}
